import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { graphqlOperation, API, Storage } from 'aws-amplify';
import { Image } from 'components/organisms';
import ShopPhotosAndVideos from './ShopPhotosAndVideos';
import { updateShop } from 'graphql/mutations';
import { useServices } from 'views/Services/serviceContext';
import { useShops } from 'context/shop/shopsContext';
import { useLoader } from 'layouts/loaderContext';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  root: {
    padding: '1rem 0'
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'black',
    backgroundColor: ' #ffffff',
    height: 150
  },
  image: {
    height: '10rem',
    width: '14rem',
    boxShadow:
      'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px',
    margin: '1rem'
  }
}));

function UploadedShopPhotos(props) {
  const { selectedShop, setSelectedShop } = props;
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const { dispatch } = useShops();
  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const { services, dispatch: dispatchService } = useServices();
  const classes = useStyles();

  useEffect(() => {
    setPhotos(selectedShop?.photos || []);
    setVideos(selectedShop?.videos || []);
  }, [selectedShop]);

  useEffect(() => {
    services?.length <= 0 && dispatchService({ type: 'getService' });
  }, [dispatchService]);

  const handleDefaultPhotoUpdate = async (el, category = 'primaryPhoto') => {
    const input = {
      id: selectedShop.id,
      _version: selectedShop._version
    };
    input[category] = el;
    setLoading(true);
    try {
      const result = await API.graphql(graphqlOperation(updateShop, { input }));
      dispatch({
        type: 'updateShop',
        payload: result.data.updateShop
      });
      setSelectedShop(result.data.updateShop);
      enqueueSnackbar('Default photo updated', {
        variant: 'success',
        autoHideDuration: 2000
      });
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('Something went wrong!!!', {
        variant: 'error',
        autoHideDuration: 2000
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (url = '', status, mimeType = 'images') => {
    let input = {
      id: selectedShop.id,
      _version: selectedShop._version
    };

    input[mimeType === 'images' ? 'photos' : 'videos'] =
      selectedShop[mimeType === 'images' ? 'photos' : 'videos']
        ?.filter((item) => item)
        .map((item) => ({
          ...item,
          status:
            item[mimeType === 'images' ? 'photoURL' : 'videoURL'] === url
              ? status
              : item.status
        })) || [];

    setLoading(true);
    try {
      const result = await API.graphql(graphqlOperation(updateShop, { input }));
      dispatch({
        type: 'updateShop',
        payload: result.data.updateShop
      });
      setSelectedShop(result.data.updateShop);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (url, type = 'image') => {
    let input = {
      id: selectedShop.id,
      _version: selectedShop._version
    };
    if (type.includes('image')) {
      Storage.remove(
        selectedShop.photos?.find((photo) => photo?.photoURL === url)?.photoURL
      );
      input.photos =
        selectedShop.photos?.filter((item) => item?.photoURL !== url) || [];
      if (selectedShop?.primaryPhoto?.photoURL === url) {
        input.primaryPhoto = null;
      }
    } else {
      Storage.remove(
        selectedShop.videos?.find((video) => video?.videoURL === url)?.videoURL
      );
      input.videos =
        selectedShop.videos?.filter((item) => item?.videoURL !== url) || [];
    }
    setLoading(true);
    try {
      const result = await API.graphql(graphqlOperation(updateShop, { input }));
      dispatch({
        type: 'updateShop',
        payload: result.data.updateShop
      });
      setSelectedShop(result.data.updateShop);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.root}>
      {selectedShop?.primaryPhoto?.photoURL ? (
        <Grid
          item
          container
          direction="column"
          alignItems="flex-start"
          justify="space-between"
          xs={12}
          sm={6}
          md={4}
          style={{ marginBottom: '2rem' }}>
          <Typography variant="h5">Default Photo</Typography>
          <Image
            docKey={selectedShop?.primaryPhoto?.photoURL}
            mimeType={selectedShop?.primaryPhoto?.MIMETypes}
            className={classes.image}
          />
        </Grid>
      ) : null}
      <ShopPhotosAndVideos
        heading="Shop Photos"
        data={photos}
        primaryPhoto={selectedShop?.primaryPhoto}
        handleDelete={handleDelete}
        handleUpdate={handleUpdate}
        handleDefaultPhotoUpdate={handleDefaultPhotoUpdate}
      />

      <ShopPhotosAndVideos
        heading="Shop Videos"
        data={videos}
        handleDelete={handleDelete}
        handleUpdate={handleUpdate}
      />
    </Box>
  );
}

export default UploadedShopPhotos;
