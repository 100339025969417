import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Marker } from 'components/molecules';

function createMapOptions(maps) {
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.LEFT_TOP,
      style: maps.ZoomControlStyle.SMALL
    }
  };
}

const Map = props => {
  const { state } = props;

  const defaultProps = {
    center: {
      lat: 51.53314,
      lng: -0.057032
    },
    zoom: 14
  };

  const storeLocation = {
    lat: state?.location?.lat || 51.53314,
    lng: state?.location?.lng || -0.057032
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '162px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCZRcF1TsHJKaYYeUkJ4BXhLTNyzs63Ges' }}
        center={storeLocation}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={createMapOptions}>
        <Marker
          lat={state?.location?.lat || ''}
          lng={state?.location?.lng || ''}
          text={state?.name}
        />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
