/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const disableUser = /* GraphQL */ `
  mutation DisableUser($username: String!, $id: String!) {
    disableUser(username: $username, id: $id) {
      statusCode
      body
    }
  }
`;
export const enableUser = /* GraphQL */ `
  mutation EnableUser($username: String!, $id: String!) {
    enableUser(username: $username, id: $id) {
      statusCode
      body
    }
  }
`;
export const resetPassword = /* GraphQL */ `
  mutation ResetPassword($username: String!) {
    resetPassword(username: $username) {
      statusCode
      body
    }
  }
`;
export const addNewShopUser = /* GraphQL */ `
  mutation AddNewShopUser(
    $email: String!
    $title: String
    $firstName: String
    $lastName: String
    $picture: String
    $groups: String
    $address: String
    $postalCode: String
    $shopId: String
    $phoneNumber: String
    $language: [String]
    $dateOfBirth: AWSDate
    $status: UserStatus
  ) {
    addNewShopUser(
      email: $email
      title: $title
      firstName: $firstName
      lastName: $lastName
      picture: $picture
      groups: $groups
      address: $address
      postalCode: $postalCode
      shopId: $shopId
      phoneNumber: $phoneNumber
      language: $language
      dateOfBirth: $dateOfBirth
      status: $status
    ) {
      id
      groups
      picture
      username
      userId
      firstName
      lastName
      title
      defaultAddress
      email
      address
      postalCode
      phoneNumber
      createdAt
      shopID
      status
      language
      dateOfBirth
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAnOrder = /* GraphQL */ `
  mutation CreateAnOrder(
    $shopID: String!
    $orderList: String!
    $collection: String!
    $delivery: String!
    $total: Float!
    $collectionAndDeliveryFee: Float!
    $currentShopFees: String!
  ) {
    createAnOrder(
      shopID: $shopID
      orderList: $orderList
      collection: $collection
      delivery: $delivery
      total: $total
      collectionAndDeliveryFee: $collectionAndDeliveryFee
      currentShopFees: $currentShopFees
    ) {
      orderID
      shopID
      createdAt
    }
  }
`;
export const updateUserDetail = /* GraphQL */ `
  mutation UpdateUserDetail(
    $id: String
    $username: String
    $title: String
    $firstName: String
    $lastName: String
    $phoneNumber: String
    $defaultAddress: String
    $picture: String
    $address: String
    $postalCode: String
    $language: [String]
    $dateOfBirth: AWSDate
    $status: UserStatus
  ) {
    updateUserDetail(
      id: $id
      username: $username
      title: $title
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      defaultAddress: $defaultAddress
      picture: $picture
      address: $address
      postalCode: $postalCode
      language: $language
      dateOfBirth: $dateOfBirth
      status: $status
    ) {
      statusCode
      body
    }
  }
`;
export const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      name
      description
      orderIndex
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      items {
        items {
          id
          serviceID
          name
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          description
          discount
          discountDescription
          unit
          tags
          price
          image
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      photoGallaryImages {
        items {
          id
          serviceID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      name
      description
      orderIndex
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      items {
        items {
          id
          serviceID
          name
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          description
          discount
          discountDescription
          unit
          tags
          price
          image
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      photoGallaryImages {
        items {
          id
          serviceID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      name
      description
      orderIndex
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      items {
        items {
          id
          serviceID
          name
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          description
          discount
          discountDescription
          unit
          tags
          price
          image
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      photoGallaryImages {
        items {
          id
          serviceID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      serviceID
      name
      description
      image
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        photoGallaryImages {
          nextToken
          startedAt
        }
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          description
          discount
          discountDescription
          unit
          tags
          price
          image
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      serviceID
      name
      description
      image
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        photoGallaryImages {
          nextToken
          startedAt
        }
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          description
          discount
          discountDescription
          unit
          tags
          price
          image
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      serviceID
      name
      description
      image
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        photoGallaryImages {
          nextToken
          startedAt
        }
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          description
          discount
          discountDescription
          unit
          tags
          price
          image
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createShop = /* GraphQL */ `
  mutation CreateShop(
    $input: CreateShopInput!
    $condition: ModelShopConditionInput
  ) {
    createShop(input: $input, condition: $condition) {
      id
      name
      googlePlaceId
      contactName
      address
      postcode
      town
      country
      email
      phoneNumber
      location {
        lat
        lng
      }
      workHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      collectionHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      deliveryHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      image
      minPriceForFree
      distanceRange
      standardDeliveryFee
      AsapDeliveryFee
      language
      range
      shopID
      about
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
      }
      photos {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
      }
      primaryPhoto {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
      }
      videos {
        name
        videoURL
        autoPlay
        thumbURL
        description
        MIMETypes
        status
      }
      status
      isLive
      consentLetterCount
      consentLetterLastDate
      enableAsapDelivery
      collectionInstructions
      deliveryInstructions
      websiteLink
      createdBy
      updatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          description
          discount
          discountDescription
          unit
          tags
          price
          image
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      reviews {
        items {
          id
          shopID
          userID
          title
          category1
          category2
          category3
          category4
          category5
          description
          createdAt
          updatedAt
          status
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateShop = /* GraphQL */ `
  mutation UpdateShop(
    $input: UpdateShopInput!
    $condition: ModelShopConditionInput
  ) {
    updateShop(input: $input, condition: $condition) {
      id
      name
      googlePlaceId
      contactName
      address
      postcode
      town
      country
      email
      phoneNumber
      location {
        lat
        lng
      }
      workHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      collectionHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      deliveryHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      image
      minPriceForFree
      distanceRange
      standardDeliveryFee
      AsapDeliveryFee
      language
      range
      shopID
      about
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
      }
      photos {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
      }
      primaryPhoto {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
      }
      videos {
        name
        videoURL
        autoPlay
        thumbURL
        description
        MIMETypes
        status
      }
      status
      isLive
      consentLetterCount
      consentLetterLastDate
      enableAsapDelivery
      collectionInstructions
      deliveryInstructions
      websiteLink
      createdBy
      updatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          description
          discount
          discountDescription
          unit
          tags
          price
          image
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      reviews {
        items {
          id
          shopID
          userID
          title
          category1
          category2
          category3
          category4
          category5
          description
          createdAt
          updatedAt
          status
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteShop = /* GraphQL */ `
  mutation DeleteShop(
    $input: DeleteShopInput!
    $condition: ModelShopConditionInput
  ) {
    deleteShop(input: $input, condition: $condition) {
      id
      name
      googlePlaceId
      contactName
      address
      postcode
      town
      country
      email
      phoneNumber
      location {
        lat
        lng
      }
      workHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      collectionHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      deliveryHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      image
      minPriceForFree
      distanceRange
      standardDeliveryFee
      AsapDeliveryFee
      language
      range
      shopID
      about
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
      }
      photos {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
      }
      primaryPhoto {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
      }
      videos {
        name
        videoURL
        autoPlay
        thumbURL
        description
        MIMETypes
        status
      }
      status
      isLive
      consentLetterCount
      consentLetterLastDate
      enableAsapDelivery
      collectionInstructions
      deliveryInstructions
      websiteLink
      createdBy
      updatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          description
          discount
          discountDescription
          unit
          tags
          price
          image
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      reviews {
        items {
          id
          shopID
          userID
          title
          category1
          category2
          category3
          category4
          category5
          description
          createdAt
          updatedAt
          status
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      shopID
      serviceID
      itemID
      category
      name
      description
      discount
      discountDescription
      unit
      tags
      price
      image
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        photoGallaryImages {
          nextToken
          startedAt
        }
      }
      item {
        id
        serviceID
        name
        description
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        products {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      shopID
      serviceID
      itemID
      category
      name
      description
      discount
      discountDescription
      unit
      tags
      price
      image
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        photoGallaryImages {
          nextToken
          startedAt
        }
      }
      item {
        id
        serviceID
        name
        description
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        products {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      shopID
      serviceID
      itemID
      category
      name
      description
      discount
      discountDescription
      unit
      tags
      price
      image
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        photoGallaryImages {
          nextToken
          startedAt
        }
      }
      item {
        id
        serviceID
        name
        description
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        products {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      groups
      picture
      username
      userId
      firstName
      lastName
      title
      defaultAddress
      email
      address
      postalCode
      phoneNumber
      createdAt
      shopID
      status
      language
      dateOfBirth
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      shopID
      orderID
      orderList {
        productID
        category
        name
        description
        discount
        discountCoupon
        discountCouponID
        discountDescription
        overallDiscount
        unit
        price
        quantity
      }
      userID
      status
      collection {
        date
        time
        type
        address
        driver {
          userID
        }
      }
      delivery {
        date
        time
        type
        address
        driver {
          userID
        }
      }
      createdAt
      updatedAt
      updatedBy
      paymentStatus
      orderCancelOrDeclineReason
      total
      paidAmount
      coupons
      collectionAndDeliveryFee
      currentShopFees {
        standardDeliveryFee
        AsapDeliveryFee
        minPriceForFree
      }
      payments {
        items {
          id
          orderID
          shopID
          userID
          amount
          createdAt
          createdBy
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
      userDetail {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      shopID
      orderID
      orderList {
        productID
        category
        name
        description
        discount
        discountCoupon
        discountCouponID
        discountDescription
        overallDiscount
        unit
        price
        quantity
      }
      userID
      status
      collection {
        date
        time
        type
        address
        driver {
          userID
        }
      }
      delivery {
        date
        time
        type
        address
        driver {
          userID
        }
      }
      createdAt
      updatedAt
      updatedBy
      paymentStatus
      orderCancelOrDeclineReason
      total
      paidAmount
      coupons
      collectionAndDeliveryFee
      currentShopFees {
        standardDeliveryFee
        AsapDeliveryFee
        minPriceForFree
      }
      payments {
        items {
          id
          orderID
          shopID
          userID
          amount
          createdAt
          createdBy
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
      userDetail {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const createOrderHistory = /* GraphQL */ `
  mutation CreateOrderHistory(
    $input: CreateOrderHistoryInput!
    $condition: ModelOrderHistoryConditionInput
  ) {
    createOrderHistory(input: $input, condition: $condition) {
      id
      orderID
      order {
        id
        shopID
        orderID
        orderList {
          productID
          category
          name
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          quantity
        }
        userID
        status
        collection {
          date
          time
          type
          address
        }
        delivery {
          date
          time
          type
          address
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        orderCancelOrDeclineReason
        total
        paidAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
        }
        payments {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      userID
      status
      changeLog
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress(
    $input: CreateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    createAddress(input: $input, condition: $condition) {
      id
      userID
      postcode
      buildingName
      buildingNumber
      line1
      line2
      town
      county
      district
      country
      phoneNumber
      contactName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress(
    $input: UpdateAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    updateAddress(input: $input, condition: $condition) {
      id
      userID
      postcode
      buildingName
      buildingNumber
      line1
      line2
      town
      county
      district
      country
      phoneNumber
      contactName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress(
    $input: DeleteAddressInput!
    $condition: ModelAddressConditionInput
  ) {
    deleteAddress(input: $input, condition: $condition) {
      id
      userID
      postcode
      buildingName
      buildingNumber
      line1
      line2
      town
      county
      district
      country
      phoneNumber
      contactName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const createCounter = /* GraphQL */ `
  mutation CreateCounter(
    $input: CreateCountersInput!
    $condition: ModelCountersConditionInput
  ) {
    createCounter(input: $input, condition: $condition) {
      id
      counterName
      currentValue
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCounter = /* GraphQL */ `
  mutation UpdateCounter(
    $input: UpdateCountersInput!
    $condition: ModelCountersConditionInput
  ) {
    updateCounter(input: $input, condition: $condition) {
      id
      counterName
      currentValue
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      sender
      orderID
      message
      status
      readAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      sender
      orderID
      message
      status
      readAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      sender
      orderID
      message
      status
      readAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerReview = /* GraphQL */ `
  mutation CreateCustomerReview(
    $input: CreateCustomerReviewInput!
    $condition: ModelCustomerReviewConditionInput
  ) {
    createCustomerReview(input: $input, condition: $condition) {
      id
      shopID
      userID
      title
      category1
      category2
      category3
      category4
      category5
      description
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const updateCustomerReview = /* GraphQL */ `
  mutation UpdateCustomerReview(
    $input: UpdateCustomerReviewInput!
    $condition: ModelCustomerReviewConditionInput
  ) {
    updateCustomerReview(input: $input, condition: $condition) {
      id
      shopID
      userID
      title
      category1
      category2
      category3
      category4
      category5
      description
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const deleteCustomerReview = /* GraphQL */ `
  mutation DeleteCustomerReview(
    $input: DeleteCustomerReviewInput!
    $condition: ModelCustomerReviewConditionInput
  ) {
    deleteCustomerReview(input: $input, condition: $condition) {
      id
      shopID
      userID
      title
      category1
      category2
      category3
      category4
      category5
      description
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      orderID
      shopID
      userID
      amount
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createAd = /* GraphQL */ `
  mutation CreateAd($input: CreateAdInput!, $condition: ModelAdConditionInput) {
    createAd(input: $input, condition: $condition) {
      id
      shopID
      startDate
      endDate
      isClosed
      template
      content
      amount
      page
      priority
      orderedDate
      type
      redirectLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updateAd = /* GraphQL */ `
  mutation UpdateAd($input: UpdateAdInput!, $condition: ModelAdConditionInput) {
    updateAd(input: $input, condition: $condition) {
      id
      shopID
      startDate
      endDate
      isClosed
      template
      content
      amount
      page
      priority
      orderedDate
      type
      redirectLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const deleteAd = /* GraphQL */ `
  mutation DeleteAd($input: DeleteAdInput!, $condition: ModelAdConditionInput) {
    deleteAd(input: $input, condition: $condition) {
      id
      shopID
      startDate
      endDate
      isClosed
      template
      content
      amount
      page
      priority
      orderedDate
      type
      redirectLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const createGallaryPhoto = /* GraphQL */ `
  mutation CreateGallaryPhoto(
    $input: CreateGallaryPhotoInput!
    $condition: ModelGallaryPhotoConditionInput
  ) {
    createGallaryPhoto(input: $input, condition: $condition) {
      id
      serviceID
      picture {
        photoURL
        thumbURL
        MIMETypes
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateGallaryPhoto = /* GraphQL */ `
  mutation UpdateGallaryPhoto(
    $input: UpdateGallaryPhotoInput!
    $condition: ModelGallaryPhotoConditionInput
  ) {
    updateGallaryPhoto(input: $input, condition: $condition) {
      id
      serviceID
      picture {
        photoURL
        thumbURL
        MIMETypes
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteGallaryPhoto = /* GraphQL */ `
  mutation DeleteGallaryPhoto(
    $input: DeleteGallaryPhotoInput!
    $condition: ModelGallaryPhotoConditionInput
  ) {
    deleteGallaryPhoto(input: $input, condition: $condition) {
      id
      serviceID
      picture {
        photoURL
        thumbURL
        MIMETypes
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createSearchBanner = /* GraphQL */ `
  mutation CreateSearchBanner(
    $input: CreateSearchBannerInput!
    $condition: ModelSearchBannerConditionInput
  ) {
    createSearchBanner(input: $input, condition: $condition) {
      id
      photoURL
      image
      MIMETypes
      title
      color
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateSearchBanner = /* GraphQL */ `
  mutation UpdateSearchBanner(
    $input: UpdateSearchBannerInput!
    $condition: ModelSearchBannerConditionInput
  ) {
    updateSearchBanner(input: $input, condition: $condition) {
      id
      photoURL
      image
      MIMETypes
      title
      color
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteSearchBanner = /* GraphQL */ `
  mutation DeleteSearchBanner(
    $input: DeleteSearchBannerInput!
    $condition: ModelSearchBannerConditionInput
  ) {
    deleteSearchBanner(input: $input, condition: $condition) {
      id
      photoURL
      image
      MIMETypes
      title
      color
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createCoupon = /* GraphQL */ `
  mutation CreateCoupon(
    $input: CreateCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    createCoupon(input: $input, condition: $condition) {
      id
      shopID
      productID
      title
      description
      startDateTime
      endDateTime
      maxUsesPerUser
      discountType
      discountAmount
      discountUnit
      conditionAmount
      conditionUnit
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteCoupon = /* GraphQL */ `
  mutation DeleteCoupon(
    $input: DeleteCouponInput!
    $condition: ModelCouponConditionInput
  ) {
    deleteCoupon(input: $input, condition: $condition) {
      id
      shopID
      productID
      title
      description
      startDateTime
      endDateTime
      maxUsesPerUser
      discountType
      discountAmount
      discountUnit
      conditionAmount
      conditionUnit
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createShopSiteRedirectRecord = /* GraphQL */ `
  mutation CreateShopSiteRedirectRecord($shopID: String!) {
    createShopSiteRedirectRecord(shopID: $shopID)
  }
`;
export const createRedirect = /* GraphQL */ `
  mutation CreateRedirect(
    $input: CreateRedirectInput!
    $condition: ModelRedirectConditionInput
  ) {
    createRedirect(input: $input, condition: $condition) {
      id
      shopID
      userID
      event
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
