import { API, graphqlOperation } from 'aws-amplify';
import { getRedirectByShopID } from 'graphql/queries';

const loadRedirectsByShopID = (payload) =>
  new Promise((resolve, reject) =>
    API.graphql(graphqlOperation(getRedirectByShopID, payload))
      .then((data) => resolve(data.data.getRedirectByShopID))
      .catch((err) => reject(err))
  );

export { loadRedirectsByShopID };
