import React, { useState, useEffect } from 'react';
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  ListItem,
  ListItemText,
  Divider,
  TextField,
  Button,
  Avatar,
  MenuItem,
  IconButton,
  CardActions,
  Typography,
  Grid,
  List
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { CustomSearchBar } from 'components/organisms';
import { useCustomers } from 'context/customers/customersContext';
import { getInitials } from 'helpers';
import { searchStringInItems } from 'common/utilFunctions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1.5rem',
    gap: '1rem'
  },
  filterBtn: {
    display: 'flex',
    gap: '1rem',
    ['@media (max-width:1380px)']: {
      flexDirection: 'column'
    }
  },
  profileImg: {
    height: 40,
    borderRadius: '50%',
    marginRight: '1rem'
  }
}));

const FilterUser = () => {
  const classes = useStyles();
  const [searchBy, setSearchBy] = useState('');
  const { customers, dispatch } = useCustomers();
  const [page, setPage] = useState(1);

  const itemsPerPage = 10;
  const noOfPages = Math.ceil(customers.length / itemsPerPage);

  useEffect(() => {
    dispatch({ type: 'getCustomers' });
  }, []);

  const paginationHandler = (event, value) => {
    setPage(value);
  };
  const onSearchChange = (event) => {
    setPage(1);
    setSearchBy(event.target.value.toLowerCase());
  };

  const filterSearchCustomer = ({ name = '', phoneNumber = '' }) =>
    searchStringInItems(searchBy, [name, phoneNumber.toString()]);

  const customerMenuFilterHandler = (e) => {
    dispatch({ type: 'sortCustomers', payload: { sort: e.target.value } });
  };

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        item
        xs={12}
        style={{
          paddingTop: '1rem'
        }}>
        <Grid item xs={12}>
          <Typography variant="h3">Customers List</Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justify="flex-end"
          style={{ margin: '1rem 0' }}>
          <Grid item md={3} xs={6}>
            <List>
              <ListItem>
                <TextField
                  select
                  variant="standard"
                  size="small"
                  fullWidth
                  defaultValue="newest"
                  onChange={customerMenuFilterHandler}>
                  <MenuItem value="newest">Newest</MenuItem>
                  <MenuItem value="oldest">Oldest</MenuItem>
                </TextField>
              </ListItem>
            </List>
          </Grid>
          <Grid container alignItems="center" item md={3} xs={6}>
            <CustomSearchBar
              value={searchBy}
              onChange={onSearchChange}
              placeholder="Search by name / ph. number"
              onClear={() => setSearchBy('')}
            />
          </Grid>
        </Grid>
        <Grid container item direction="column" xs={12}>
          {customers
            .filter(filterSearchCustomer)
            .slice((page - 1) * itemsPerPage, page * itemsPerPage)
            .map((item) => (
              <Card
                key={`users-orders-${item.id}`}
                style={{
                  marginBottom: '0.5rem',
                  paddingTop: '0.5rem',
                  height: '8rem'
                }}>
                <CardContent>
                  <Grid
                    container
                    justify="space-between"
                    style={{ marginLeft: '1.5rem', marginBottom: '1rem' }}>
                    <Grid
                      container
                      item
                      xs={4}
                      spacing={5}
                      justify="flex-start"
                      alignItems="center"
                      direction="row">
                      <Avatar className={classes.profileImg}>
                        {getInitials(item.name)}
                      </Avatar>

                      <Link to={`/admin/customers/${item.id}`}>
                        <ListItemText
                          primary={item.name}
                          secondary={item.phoneNumber}
                        />
                      </Link>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={4}
                      alignItems="center"
                      justify="flex-end">
                      <Typography
                        align="right"
                        style={{ marginRight: '1.5rem' }}>
                        {`${item.location}  ${item.distance} `}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid
                    container
                    justify="space-between"
                    alignItems="center"
                    item
                    xs={12}
                    style={{ padding: '1rem' }}>
                    <Grid container item xs={3}>
                      <Typography variant="body2">{item.orderDate}</Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    spacing={4}
                    justify="flex-end"
                    alignItems="center">
                    <Link to={`/admin/customers/${item.id}`}>
                      <Button
                        variant="outlined"
                        style={{ marginRight: '1rem' }}
                        color="primary"
                        size="small">
                        order history
                      </Button>
                    </Link>
                    <IconButton color="primary">
                      <a href={`tel:${item.phoneNumber}`}>
                        <CallRoundedIcon />
                      </a>
                    </IconButton>
                    <IconButton color="primary">
                      <a href={`mailTo:${item.email}`}>
                        <MailOutlineRoundedIcon />
                      </a>
                    </IconButton>
                  </Grid>
                </CardActions>
              </Card>
            ))}
        </Grid>
        <Grid container item xs={12} justify="center">
          <Pagination
            count={noOfPages}
            page={page}
            onChange={paginationHandler}
            defaultPage={1}
            color="secondary"
            shape="rounded"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FilterUser;
