import { regexEmail, regexPhoneNumber, webLinkRegex } from './constants';
import { checkCouponCondition } from './utilFunctions';

const checked = (value, options) => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
};

export const validatePhoneNumber = (val) => {
  let error = '';

  if (!val) {
    error = 'Please specify the business phone number';
  } else if (!regexPhoneNumber.test(val)) {
    error = 'This is not a valid Phone Number!';
  } else if (val.length < 11 || val.length > 13) {
    error = 'Please enter 11 to 13 digit contact number. E.g. 07712345678';
  }

  return error;
};

export default {
  checked
};

export const shopFieldvalidation = (selectedShop, setFormErrors = () => {}) => {
  const errors = {};

  if (!webLinkRegex.test(selectedShop.websiteLink)) {
    errors.websiteLink = 'This is not a valid Website!';
  } else {
    errors.websiteLink = '';
  }
  if (!selectedShop.phoneNumber) {
    errors.phoneNumber = 'Please specify the business phone number';
  } else if (!regexPhoneNumber.test(selectedShop.phoneNumber)) {
    errors.phoneNumber = 'This is not a valid Phone Number!';
  } else if (selectedShop.phoneNumber.length < 11) {
    errors.phoneNumber =
      'Please enter 11 to 13 digit contact number. E.g. 07712345678';
  }
  if (!selectedShop.email) {
    errors.email = 'Please specify the business email address';
  } else if (!regexEmail.test(selectedShop.email)) {
    errors.email = 'This is not a valid email format!';
  }
  setFormErrors(errors);
  return errors;
};

export const validateCartCoupons = (cart = []) =>
  cart.map((cartItem) => {
    if (cartItem?.discountCoupon?.discountType === 'conditional') {
      const conditionsMet = checkCouponCondition(
        cartItem.discountCoupon.conditionUnit,
        cartItem.discountCoupon.conditionAmount,
        cart,
        cartItem.id
      );
      if (!conditionsMet) {
        return {
          ...cartItem,
          price: cartItem.price + cartItem.discount,
          discountCouponID: '',
          discountCoupon: '',
          discount: '',
          overallDiscount: ''
        };
      }
    }
    return cartItem;
  });

export const validateImageAspectRatio = (e, requiredRatio, callBack) => {
  const reader = new FileReader();
  reader.readAsDataURL(e.target.files[0]);
  reader.onload = function (e) {
    const image = new Image();
    image.src = e.target.result;
    image.onload = function () {
      const height = this.height;
      const width = this.width;
      const ratio = width / height;
      const variableRange = 0.2;
      const differenceInRatio = Math.abs(requiredRatio - ratio);

      if (differenceInRatio <= variableRange) {
        callBack(true);
      } else {
        callBack(false);
      }
    };
  };
};
