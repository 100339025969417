import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Box
} from '@material-ui/core';
import '../styles.css';
import CollectAndDeliver from './CollectAndDeliver';
import AddressModal from './AddressModal';
import {
  Address,
  CartCard,
  NavigationBreadcrumbs,
  WorkingHoursMenu
} from 'components/organisms';
import { ShopCurrentWorkingStatus } from 'components/molecules';
import { routes } from 'common/constants';
import PaymentOptions from './PaymentOptions';
import { useAmplifyAuth } from 'context';
import { getAddress, listAddresss } from 'graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { useShops } from 'context/shop/shopsContext';
import { useHistory } from 'react-router-dom';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useSnackbar } from 'notistack';
import MUIRichTextEditor from 'mui-rte';
import StyledRadio from 'components/molecules/StyledRadio';

const useStyles = makeStyles((theme) => ({
  root: {},
  ordersHeader: {
    width: '100%',
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    lineHeight: '0.1em',
    margin: '10px 0'
  },
  ordersHeaderText: {
    background: '#fff',
    paddingRight: '10px'
  },
  blueBtn: {
    color: theme.palette.blue.main
  },
  blueContainedBtn: {
    backgroundColor: theme.palette.blue.main,
    color: 'white'
  },
  fomrControlLabelRoot: {
    marginRight: '5rem'
  },
  greyText: {
    color: theme.palette.grey.secondary,
    fontSize: '16px',
    marginRight: '3px'
  }
}));

export default function ReviewComp({ shopName = '' }) {
  const classes = useStyles();
  const {
    state: { user }
  } = useAmplifyAuth();
  const [collectionAddress, setCollectionAddress] = useState(null);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [collectionHrs, setCollectionHrs] = React.useState([]);
  const [deliveryHrs, setDeliveryHrs] = React.useState([]);
  const [collectionTime, setCollectionTime] = React.useState(null);
  const [collectionSlot, setCollectionSlot] = React.useState(null);
  const [deliverySlot, setDeliverySlot] = React.useState(null);
  const [deliveryTime, setDeliveryTime] = React.useState(null);
  const [addressList, setAddressList] = useState({
    loading: false,
    items: []
  });
  const { selectedShop, dispatch } = useShops();
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [show, setShow] = useState(true);
  const [status, setStatus] = useState('INITIAL');
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [collectionMethod, setCollectionMethod] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [workingHoursMenuAnchor, setWorkingHoursMenuAnchor] = useState(null);
  const [currAddressType, setCurrAddressType] = useState('Collection');
  const [isASAP, setIsASAP] = useState(false);
  const [typeOfOrder, setTypeOfOrder] = useState('Schedule');
  const [isDefaultAddressChange, setIsDefaultAddressChange] = useState({
    isChange: false,
    option: ''
  });
  const [isASAPEnabled, setIsASAPEnabled] = useState(false);
  const [standardDeliveryFee, setStandardDeliveryFee] = useState(0);
  const [minPriceForFree, setMinPriceForFree] = useState(0);
  const [asapDeliveryFee, setAsapDeliveryFee] = useState(0);
  const [instructions, setInstructions] = useState({
    collection: null,
    delivery: null
  });
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleEdit = () => {
    setEdit((prevState) => !prevState);
    setShow(false);
  };

  const changeAddress = (type = 'collection') => {
    setCurrAddressType(type);
    setOpen(true);
    setAddressList({ loading: true, items: [] });
    if (user?.username) {
      API.graphql(
        graphqlOperation(listAddresss, {
          filter: { userID: { eq: user.username } },
          limit: 1000
        })
      ).then(({ data }) => {
        const _addressList = data.listAddresss.items;
        let items = [];
        if (_addressList.length > 0) {
          items = _addressList.filter((a) => !a._deleted);
        }
        setAddressList({ loading: false, items });
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    setEdit((prevState) => !prevState);
  };

  useEffect(() => {
    if (selectedShop?.id) {
      //TODO : move to context
      const queryData = `query GetShop { getShop(id: "${selectedShop.id}") {
      enableAsapDelivery
      standardDeliveryFee
      AsapDeliveryFee
      minPriceForFree
      collectionHrs {
        closeDay
        closeTime
        openDay
        openTime
      }
      deliveryHrs {
        closeDay
        closeTime
        openDay
        openTime
      }
      collectionInstructions
      deliveryInstructions
    }}`;
      API.graphql(graphqlOperation(queryData)).then(({ data }) => {
        if (data.getShop) {
          const collectHrs = data.getShop.collectionHrs;

          if (data.getShop.enableAsapDelivery) {
            setIsASAPEnabled(data.getShop.enableAsapDelivery);
          }
          if (data.getShop.minPriceForFree) {
            setMinPriceForFree(data.getShop.minPriceForFree);
          }
          if (data.getShop.standardDeliveryFee) {
            setStandardDeliveryFee(data.getShop.standardDeliveryFee);
          }
          if (data.getShop.AsapDeliveryFee) {
            setAsapDeliveryFee(data.getShop.AsapDeliveryFee);
          }
          setInstructions({
            collection: data.getShop.collectionInstructions,
            delivery: data.getShop.deliveryInstructions
          });

          if (collectHrs.length < 0) {
            setCollectionMethod('Drop');
          }
          setCollectionHrs(collectHrs);
          const delHrs = data.getShop.deliveryHrs;
          if (delHrs.length < 0) {
            setDeliveryMethod('Pickup');
          }
          setDeliveryHrs(delHrs);
        }
      });
    }
  }, [selectedShop]);

  useEffect(() => {
    if (user?.defaultAddress) {
      API.graphql(
        graphqlOperation(getAddress, { id: user.defaultAddress })
      ).then(({ data }) => {
        const _address = data?.getAddress || [];
        setCollectionAddress(_address);
        setDeliveryAddress(_address);
      });
    }
    if (!selectedShop?.id) {
      const _cart = localStorage.getItem('cart');
      const parseCartData = JSON.parse(_cart);
      const { item } = parseCartData || {};
      if (item?.length > 0 && item[0].shopID) {
        dispatch({
          type: 'getSelectedShop',
          payload: { shopId: item[0].shopID }
        });
      } else {
        history.push('/home');
      }
    }
  }, [user]);

  useEffect(() => {
    if (status === 'SUCCESS') {
      enqueueSnackbar('Order placed successfully', {
        variant: 'success',
        autoHideDuration: 3000
      });
      history.replace(routes.customer.order);
    }
  }, [status]);

  const typeChangeHandler = (e, newVal, setValue = () => {}) => {
    setValue(newVal);
    if (newVal === 'Drop') {
      setCollectionSlot(null);
      setCollectionTime(null);
    }
    if (newVal === 'Pickup') {
      setDeliverySlot(null);
      setDeliveryTime(null);
    }
  };

  const renderAddress = (
    type = '',
    value = '',
    setValue = () => {},
    options = []
  ) => {
    return (
      <Grid container style={{ marginBottom: '30px' }}>
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.ordersHeader}>
            <span className={classes.ordersHeaderText}>
              {`${typeOfOrder} ${type} Options`}
            </span>
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justify="space-between"
          alignItems="center"
          style={{ padding: '20px 0 4px 4px' }}>
          <Grid item xs={10}>
            <RadioGroup
              row
              aria-label="payment-options-radio"
              name="payment-options-radio"
              value={value}
              onChange={(e, newVal) => typeChangeHandler(e, newVal, setValue)}>
              {options.map((item) => (
                <FormControlLabel
                  key={`order-review-address-${value}-options-items=${item}`}
                  classes={{ root: classes.fomrControlLabelRoot }}
                  value={item}
                  control={<StyledRadio color="primary" />}
                  label={item}
                />
              ))}
            </RadioGroup>
          </Grid>
          <Grid item xs={1} container justify="flex-end">
            {((type === 'Collection' &&
              value === 'Collection' &&
              collectionAddress) ||
              (type === 'Delivery' &&
                value === 'Delivery' &&
                deliveryAddress)) && (
              <Button
                onClick={() => changeAddress(type)}
                className={classes.blueBtn}
                style={{ padding: '0', minWidth: '7.5rem' }}>
                Change Address
              </Button>
            )}
          </Grid>
        </Grid>
        {value === '' || value === 'asap' ? (
          <></>
        ) : value === 'Collection' || value === 'Delivery' ? (
          <>
            <Grid item xs={12}>
              {type === 'Collection' && !isASAP && (
                <CollectAndDeliver
                  allowedHrs={collectionHrs}
                  setSlot={setCollectionSlot}
                  setTime={setCollectionTime}
                />
              )}
              {type === 'Delivery' && !isASAP && (
                <CollectAndDeliver
                  allowedHrs={deliveryHrs}
                  collectionDate={collectionTime}
                  collectionSlot={collectionSlot}
                  setSlot={setDeliverySlot}
                  setTime={setDeliveryTime}
                />
              )}
              {((type === 'Collection' && collectionAddress) ||
                (type === 'Delivery' && deliveryAddress)) && (
                <>
                  {type === 'Collection' ? (
                    <Address data={collectionAddress} mode={0} />
                  ) : (
                    <Address data={deliveryAddress} mode={0} />
                  )}
                  {collectionAddress &&
                    user.defaultAddress !== collectionAddress.id &&
                    type === 'Collection' && (
                      <FormControlLabel
                        classes={{ root: classes.fomrControlLabelRoot }}
                        label={`Make this my default address`}
                        control={<Checkbox color="primary" />}
                        onChange={(event, val) => {
                          setIsDefaultAddressChange({
                            isChange: val,
                            option: 'collection'
                          });
                        }}
                      />
                    )}
                  {deliveryAddress &&
                    user.defaultAddress !== deliveryAddress.id &&
                    type === 'Delivery' && (
                      <FormControlLabel
                        classes={{ root: classes.fomrControlLabelRoot }}
                        label={`Make this my default address`}
                        control={<Checkbox color="primary" />}
                        onChange={(event, val) => {
                          setIsDefaultAddressChange({
                            isChange: val,
                            option: 'delivery'
                          });
                        }}
                      />
                    )}
                </>
              )}
            </Grid>
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              {((type === 'Collection' && !collectionAddress) ||
                (type === 'Delivery' && !deliveryAddress)) && (
                <Button
                  onClick={() => changeAddress(type)}
                  className={classes.blueContainedBtn}>
                  ADD NEW ADDRESS
                </Button>
              )}
            </Grid>
          </>
        ) : (
          <Grid container style={{ marginTop: '10px' }}>
            <Grid item xs={12} md={6}>
              <Grid container alignItems="flex-start">
                <LocationOnOutlinedIcon className={classes.greyText} />
                <Typography variant="subtitle1" className={classes.greyText}>
                  Store Location
                </Typography>
              </Grid>
              <Typography variant="subtitle1" style={{ marginTop: '5px' }}>
                &nbsp;
                {`${selectedShop?.address}, ${selectedShop?.town}, ${selectedShop?.postcode}`}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container alignItems="flex-start">
                <AccessTimeOutlinedIcon className={classes.greyText} />
                <Typography variant="subtitle1" className={classes.greyText}>
                  Opening Hours
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                alignItems="center"
                style={{ height: 'fit-content', marginTop: '5px' }}>
                <Button
                  variant="text"
                  onClick={(e) => setWorkingHoursMenuAnchor(e.currentTarget)}
                  style={{ padding: 0 }}
                  endIcon={<KeyboardArrowDownIcon fontSize="small" />}>
                  <ShopCurrentWorkingStatus workHrs={selectedShop?.workHrs} />
                </Button>
              </Grid>
              <WorkingHoursMenu
                anchorEl={workingHoursMenuAnchor}
                handleClose={() => setWorkingHoursMenuAnchor(null)}
                workingHours={
                  selectedShop && selectedShop.workHrs
                    ? selectedShop.workHrs
                    : []
                }
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '20px' }}>
              <Grid container alignItems="flex-start">
                <InfoOutlinedIcon className={classes.greyText} />
                <Typography variant="subtitle1" className={classes.greyText}>
                  {type === 'Collection' ? 'Drop ' : 'Pickup '} Instructions
                </Typography>
              </Grid>
              {type === 'Collection' ? (
                !!instructions.collection ? (
                  <MUIRichTextEditor
                    readOnly
                    toolbar={false}
                    defaultValue={instructions.collection}
                  />
                ) : (
                  'Not available'
                )
              ) : !!instructions.delivery ? (
                <MUIRichTextEditor
                  readOnly
                  toolbar={false}
                  defaultValue={instructions.delivery}
                />
              ) : (
                'Not available'
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <>
      {status === 'INITIAL' ? (
        <Grid container style={{ marginBottom: '90px' }}>
          <Grid item xs={12}>
            <NavigationBreadcrumbs
              showShopList
              links={[
                {
                  label: shopName.split('-').join(' '),
                  link: history.location.pathname
                    .split('/')
                    .slice(0, -1)
                    .join('/')
                }
              ]}
              currentLink="Order Review"
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignItems="center"
            style={{ minHeight: '64px' }}>
            <Typography variant="h3">Order Review</Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid>
              <RadioGroup
                row
                aria-label="asap-schedule-radio"
                name="asap-schedule-radio"
                defaultValue="schedule"
                style={{ marginBottom: '2rem' }}>
                <FormControlLabel
                  value="asap"
                  control={<StyledRadio color="primary" />}
                  label="ASAP"
                  style={{ marginRight: '6rem' }}
                  disabled={!isASAPEnabled}
                  onChange={() => {
                    setIsASAP(true);
                    setTypeOfOrder('ASAP');
                    setCollectionMethod('');
                    setDeliveryMethod('');
                    setCollectionSlot(null);
                    setCollectionTime(null);
                    setDeliverySlot(null);
                    setDeliveryTime(null);
                  }}
                />

                <FormControlLabel
                  value="schedule"
                  control={<StyledRadio color="primary" />}
                  label="Schedule"
                  onChange={() => {
                    setIsASAP(false);
                    setTypeOfOrder('Schedule');
                    setCollectionMethod('');
                    setDeliveryMethod('');
                  }}
                />
              </RadioGroup>
            </Grid>

            <Box>
              {renderAddress(
                'Collection',
                collectionMethod,
                setCollectionMethod,
                collectionHrs.length > 0 ? ['Collection', 'Drop'] : ['Drop']
              )}
              {renderAddress(
                'Delivery',
                deliveryMethod,
                setDeliveryMethod,
                deliveryHrs.length > 0 ? ['Delivery', 'Pickup'] : ['Pickup']
              )}

              {typeOfOrder === 'Schedule' && (
                <Typography
                  variant="body1"
                  style={{
                    color: '#ff1f00b8',
                    padding: '1rem 0',
                    fontWeight: '600'
                  }}>
                  NOTE: Collection & Delivery - Date and Time can be changed by
                  the shop
                </Typography>
              )}
            </Box>

            <PaymentOptions
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <CartCard
              btnText="place order"
              isDefaultAddressChange={isDefaultAddressChange}
              order={{
                typeOfOrder,
                collectionMethod,
                deliveryMethod,
                collectionAddress,
                deliveryAddress,
                collectionSlot,
                collectionTime,
                deliverySlot,
                deliveryTime,
                standardDeliveryFee,
                minPriceForFree,
                asapDeliveryFee,
                paymentOptions:
                  paymentMethod === 'cash' ? 'Cash' : 'Debit/Credit Card',
                setStatus
              }}
            />
          </Grid>
        </Grid>
      ) : status === 'FAILURE' ? (
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ padding: '2rem' }}>
          <Typography variant="h1">Error in placing Order</Typography>
        </Grid>
      ) : null}
      <AddressModal
        open={open}
        handleClose={handleClose}
        currAddressType={currAddressType}
        collectionAddress={collectionAddress}
        deliveryAddress={deliveryAddress}
        addressList={addressList}
        handleEdit={handleEdit}
        show={show}
        edit={edit}
        setCollectionAddress={setCollectionAddress}
        setDeliveryAddress={setDeliveryAddress}
        setOpen={setOpen}
      />
    </>
  );
}
