function descendingComparator(a, b, property) {
  if (b[property] < a[property]) {
    return -1;
  }
  if (b[property] > a[property]) {
    return 1;
  }
  return 0;
}

const shopsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'updateData': {
      return payload || [];
    }
    case 'addShop': {
      return [...state, payload];
    }
    case 'addShops': {
      return [...state, ...payload];
    }
    case 'sortShop': {
      const property = payload.property || 'shopID';
      const direction = payload.direction || 'shopID';
      const sortState = state.sort((a, b) => {
        return direction === 'desc'
          ? descendingComparator(a, b, property)
          : -descendingComparator(a, b, property);
      });
      return [...sortState];
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

export default shopsReducer;
