import { Toolbar, Typography } from '@material-ui/core';
import React from 'react';

const Header = ({ content }) => {
  return (
    <>
      <Toolbar style={{ paddingLeft: '0px' }}>
        <Typography
          style={{
            fontSize: '24px',
            fontFamily: 'Roboto'
          }}
        >
          {content}
        </Typography>
      </Toolbar>
    </>
  );
};

export default Header;
