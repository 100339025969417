import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  Grid,
  TextField,
  MenuItem
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

export function DeclineDialog({
  onSuccess = () => {},
  dialogType,
  setDialogType,
  order
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [value, setValue] = useState('');
  const [otherValue, setOtherValue] = useState('');
  const [isOtherDecline, setIsOtherDecline] = useState(false);

  const handleChange = event => {
    setValue(event.target.value);
    event.target.value === 'other'
      ? setIsOtherDecline(true)
      : setIsOtherDecline(false);
  };

  const otherDeclineReasonHandler = event => {
    setOtherValue(event.target.value);
  };

  const handleClose = () => {
    setValue('');
    setDialogType(null);
    setIsOtherDecline(false);
  };

  const updatedOrder = {
    ...order,
    orderCancelOrDeclineReason: value === 'other' ? otherValue : value
  };

  const handleSubmit = () => {
    if (!value || (value === 'other' && !otherValue)) {
      enqueueSnackbar('please provide a valid reason for cancelling', {
        variant: 'error',
        preventDuplicate: true
      });
      return;
    }
    onSuccess({ order: updatedOrder, sts: 'declined' });
    setValue('');
    setOtherValue('');
    setIsOtherDecline(false);
  };

  return (
    <Dialog
      open={dialogType === 'declined'}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Are you sure you want to decline this order?
      </DialogTitle>
      <DialogContent style={{ height: 'fit-content', overflow: 'hidden' }}>
        <Grid container>
          <Grid item xs={12} style={{ marginBottom: '0.5rem' }}>
            <TextField
              select
              variant="outlined"
              size="small"
              fullWidth
              value={value}
              required
              error={value === ''}
              onChange={handleChange}
              label="Select Reason">
              <MenuItem value={'Delivery date & time is unachievable'}>
                Delivery date &amp; time is unachievable
              </MenuItem>
              <MenuItem value={'All our washing machines are fully occupied'}>
                All our washing machines are fully occupied
              </MenuItem>
              <MenuItem value={'unexpectedly we are not taking orders now'}>
                Unexpectedly we are not taking orders now
              </MenuItem>
              <MenuItem value={'other'}>Other reasons</MenuItem>
            </TextField>
          </Grid>

          {isOtherDecline && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="other-reasons-text"
                label="Other Reasons"
                multiline
                rows={3}
                variant="outlined"
                onChange={otherDeclineReasonHandler}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" autoFocus>
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          autoFocus>
          Decline
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeclineDialog.propTypes = {
  declineOrder: PropTypes.func,
  handleClose: PropTypes.func
};

{
  /* <Grid item xs={12} style={{ paddingTop: '2rem' }}>
            <Typography variant="h6">Recommend Shops</Typography>
</Grid>
<Grid item xs={12}>
            <Typography variant="body1">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem
              accusantium atque neque natus quos quisquam fugiat mollitia
              dignissimos harum architecto.
            </Typography>
</Grid>
<Grid
            item
            container
            xs={12}
            spacing={1}
            style={{ paddingTop: '1rem' }}>
            {recommendShopOptions.map((item, index) => (
              <Grid
                item
                container
                direction="column"
                xs={6}
                md={4}
                key={`recommend-shop-${index}`}
                justify="center"
                alignItems="center">
                <Card>
                  <CardMedia>{item.image}</CardMedia>
                  <CardContent>
                    <Typography variant="h5">{item.name}</Typography>
                    <Typography variant="body2">{item.details}</Typography>
                  </CardContent>
                </Card>
                <Button
                  color={item.clicked ? 'default' : 'primary'}
                  variant="contained"
                  size="small"
                  style={{ marginTop: '1rem' }}
                  // disabled={item.clicked}
                >
                  {item.clicked ? 'Recommended' : 'Recommend'}
                </Button>
              </Grid>
            ))}
</Grid> */
}
