import React, { useState, useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper
} from '@material-ui/core';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { SubSideNavBar } from 'components/organisms';
import { routes } from 'common/constants';
import { useHistory } from 'react-router-dom';
// import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
// import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
// import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
// import FormatPaintOutlinedIcon from '@material-ui/icons/FormatPaintOutlined';
// import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';

const fields = [
  {
    title: 'Profile',
    name: 'profile',
    icon: <PersonOutlineIcon />,
    description: 'Manage your personal information',
    route: routes.serviceProvider.settingsProfile
  },
  {
    title: 'Password',
    name: 'password',
    icon: <LockOpenIcon />,
    description: 'Change your password',
    route: routes.serviceProvider.settingsPassword
  }
  // {
  //   title: 'Notifications',
  //   name: 'notifications',
  //   icon: <NotificationsActiveOutlinedIcon />,
  //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  // },
  // {
  //   title: 'Appearence',
  //   name: 'appearence',
  //   icon: <FormatPaintOutlinedIcon />,
  //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  // },
  // {
  //   title: 'Billing',
  //   name: 'billing',
  //   icon: <ReceiptOutlinedIcon />,
  //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  // },
  // {
  //   title: 'Lorem',
  //   name: 'lorem',
  //   icon: <SettingsOutlinedIcon />,
  //   description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.'
  // }
];

const SideBar = ({ setCurrentTab }) => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (val) => {
    setActiveTab(val);
    setCurrentTab(fields[val].name);
    history.replace(fields[val].route);
  };

  useEffect(
    () =>
      handleChange(
        fields.findIndex(
          (field) => field.route === history.location.pathname
        ) || 0
      ),
    []
  );

  return (
    <SubSideNavBar
      currentItem={fields.find((item, index) => index === activeTab)}>
      <List
        component={Paper}
        style={{
          padding: '0',
          width: '100%',
          boxShadow: '0px 0px 4px 0px #00000040'
        }}>
        {fields.map((item, index) => (
          <ListItem
            key={`admin-settings-sidebar-field-${index}`}
            component={Paper}
            button
            alignItems="center"
            selected={activeTab === index}
            onClick={() => handleChange(index)}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} secondary={item.description} />
          </ListItem>
        ))}
      </List>
    </SubSideNavBar>
  );
};

export default SideBar;
