import React from 'react';
import WorkingHours from '../../Shops/components/ShopDetails/WorkingHours';
import { CardActions } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import ActionButtons from './ActionButtons';

const ShopWorkingHours = (props) => {
  let { actionType, shopId } = useParams();
  const {
    validation,
    className,
    google,
    value,
    index,
    shopId: _shopId,
    pageType,
    selectedShop,
    formErrors,
    onCancel,
    handleSubmit,
    handleChange,
    disabled,
    setDisabled,
    setSelectedShop,
    ...rest
  } = props;

  if (_shopId) {
    shopId = _shopId;
  }

  return (
    <>
      <WorkingHours
        sorryMsg="Closed"
        title="Shop Open Hours"
        subheader="Information about Shop Working Hours"
        type="workHrs"
        hours={selectedShop['workHrs'] ? selectedShop['workHrs'] : []}
        selectedShop={selectedShop}
        setSelectedShop={setSelectedShop}
        setDisabled={setDisabled}
      />
      <CardActions>
        <ActionButtons
          handleSubmit={handleSubmit}
          handleCancel={onCancel}
          disabled={disabled}
        />
      </CardActions>
    </>
  );
};

export default ShopWorkingHours;
