import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Table,
  TableSortLabel,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  TablePagination
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { getUserByShop } from 'graphql/queries';
import { disableUser } from 'graphql/mutations';
import { graphqlOperation, API } from 'aws-amplify';
import { useAmplifyAuth } from 'context';
import { useSnackbar } from 'notistack';
import { useLoader } from 'layouts/loaderContext';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import { getFormattedName, unitFormatter } from 'common/utilFunctions';
import { Image } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 60,
    width: 60
  },
  evenRow: {
    backgroundColor: theme.palette.action.hover
  },
  textField: {
    '&:placeholder': {
      fontSize: '10px',
      color: 'red'
    }
  },
  pending: { color: theme.palette.warning.main },
  enabled: { color: theme.palette.success.main },
  disabled: { color: theme.palette.error.main },
  deceased: { color: theme.palette.error.main }
}));

const columns = ['Created Date', 'User Profile', 'Designation', 'Status'];

const StaffList = ({
  selectedStaff,
  setSelectedStaff,
  setShowDetails,
  setShowAddForm
}) => {
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const classes = useStyles();
  const [searchBy, setSearchBy] = React.useState('');
  const [dateSortType, setDateSortType] = useState('');
  const {
    state: { user }
  } = useAmplifyAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const { setLoading } = useLoader();
  const filteredUsers = rows
    .filter((row) => getFormattedName(row).toLowerCase().includes(searchBy))
    .sort((a, b) =>
      dateSortType === 'asc'
        ? new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
        : dateSortType === 'desc'
        ? new Date(a?.createdAt).getTime() - new Date(b?.createdAt).getTime()
        : 0
    );

  const handleStaffSelect = (staff) => {
    setSelectedStaff(staff);
    setShowDetails(true);
  };

  // const handleStaffDelete = (staff) => {
  //   setSelectedStaff(staff);
  //   setShowConfirmDeleteDialog(true);
  // };

  useEffect(() => {
    let mounted = true;
    if (user?.shopId) {
      setLoading(true);
      let sBar2 = enqueueSnackbar('Loading staff...', {
        variant: 'info',
        preventDuplicate: true
      });
      API.graphql(graphqlOperation(getUserByShop, { shopID: user.shopId }))
        .then(({ data }) => {
          const users =
            data.getUserByShop.items?.sort(
              (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
            ) || [];
          const pendingUsers = users.filter(
            (user) => user?.status === 'pending'
          );
          const enabledUsers = users.filter(
            (user) => user?.status === 'enabled'
          );
          const otherUsers = users.filter(
            (user) => user?.status !== 'pending' && user?.status !== 'enabled'
          );
          mounted && setRows([...pendingUsers, ...enabledUsers, ...otherUsers]);
        })
        .catch((err) => {
          console.log('error', err);
          enqueueSnackbar('Something went wrong', {
            variant: 'error',
            autoHideDuration: 1000
          });
        })
        .finally(() => {
          setLoading(false);
          closeSnackbar(sBar2);
        });
    }

    return () => {
      mounted = false;
    };
  }, []);

  const onSearchChange = (event) =>
    setSearchBy(event.target.value.toLowerCase());

  const onDisabled = async (username, id) => {
    setLoading(true);
    API.graphql(
      graphqlOperation(disableUser, {
        username,
        id
      })
    )
      .then(() => {
        enqueueSnackbar('User deleted successfully', {
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 3000
        });
        setRows(rows.filter((user) => user?.id !== id));
      })
      .catch((error) => {
        console.log('error', error);
        enqueueSnackbar('Something went wrong. Try again..', {
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 1000
        });
      })
      .finally(() => setLoading(false));
  };

  const handleCancel = () => {
    setSelectedStaff({});
    setShowConfirmDeleteDialog(false);
  };

  const handlePageChange = (_event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleConfirm = () =>
    onDisabled(selectedStaff.email, selectedStaff.id).finally(() =>
      handleCancel()
    );

  return (
    <>
      <Grid
        container
        item
        alignItems="center"
        justify="space-between"
        style={{ marginTop: '1rem' }}>
        <Grid
          item
          md={5}
          xs={12}
          container
          justify="space-between"
          alignItems="center">
          <TextField
            placeholder="search by name"
            size="small"
            fullWidth
            classes={{ root: classes.textField }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!searchBy ? (
                    <SearchIcon fontSize="small" />
                  ) : (
                    <IconButton
                      size="small"
                      onClick={() => setSearchBy('')}
                      aria-label="search-filters-staff"
                      edge="end">
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
            InputLabelProps={{ shrink: true }}
            value={searchBy}
            variant="outlined"
            onChange={onSearchChange}
          />
        </Grid>
        <Grid item md={3} xs={12} container justify="flex-end">
          <Button
            style={{ height: 'fit-content' }}
            color="primary"
            variant="contained"
            size="small"
            onClick={() => setShowAddForm(true)}
            endIcon={<GroupAddIcon />}>
            Add New Staff
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        spacing={5}
        style={{ marginTop: '1rem' }}>
        <Divider />
        <Grid container item alignItems="center" xs={12}>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  {columns.map((item, index) => (
                    <TableCell
                      key={`staff-list-column-${index}`}
                      align="center">
                      {item === 'Created Date' ? (
                        <TableSortLabel
                          active={!!dateSortType}
                          direction={dateSortType === 'asc' ? 'asc' : 'desc'}
                          onClick={(e) =>
                            setDateSortType(
                              dateSortType === 'asc' ? 'desc' : 'asc'
                            )
                          }>
                          {item}
                        </TableSortLabel>
                      ) : (
                        item
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, id) => (
                    <TableRow
                      key={`staff-member-list-row-${id}`}
                      className={id % 2 === 0 ? `${classes.evenRow}` : ''}>
                      <TableCell component="th" scope="row">
                        <Button
                          onClick={() => handleStaffSelect(row)}
                          style={{ textTransform: 'capitalize' }}
                          color="primary">
                          {getFormattedName(row)}
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {row?.createdAt
                          ? moment(new Date(row.createdAt)).format('DD/MM/YYYY')
                          : 'N/A'}
                      </TableCell>
                      <TableCell align="center">
                        <Grid container justify="center">
                          {row?.picture?.split('/').at(0) === 'images' ? (
                            <Image
                              docKey={row.picture}
                              className={classes.avatar}
                            />
                          ) : (
                            <Avatar
                              className={classes.avatar}
                              variant="rounded"
                            />
                          )}
                        </Grid>
                      </TableCell>
                      <TableCell align="center">
                        {unitFormatter(row?.groups?.at(0) || '')}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes[row?.status || 'pending']}>
                        {unitFormatter(row?.status || '')}
                      </TableCell>
                      {/* staff delete button */}
                      {/* <TableCell align="center">
                        <IconButton
                          disabled={row?.email === user?.email}
                          onClick={() => handleStaffDelete(row)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={filteredUsers.length}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handleRowsPerPageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 25]}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/* dialog to confirm user delete */}
      <Dialog
        open={showConfirmDeleteDialog}
        onClose={handleCancel}
        aria-labelledby="confirm-delete-staff-switch-dialog-title"
        aria-describedby="confirm-delete-staff-switch-dialog-description">
        <DialogTitle id="confirm-delete-staff-switch-dialog-title">
          Are you sure you want to delete this Staff Member?
        </DialogTitle>
        <DialogActions>
          <Button size="small" onClick={handleCancel} variant="outlined">
            No
          </Button>
          <Button
            size="small"
            onClick={handleConfirm}
            color="primary"
            variant="contained">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StaffList;
