import { useAmplifyAuth } from 'context';
import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const WrapWithProtectedLayout = ({
  component: Comp,
  layout: Layout,
  rest: props
}) => {
  const [loading, setLoading] = useState(true);
  let {
    state: { user }
  } = useAmplifyAuth();
  let history = useHistory();
  useEffect(() => {
    let timerId;
    if (user && user.username) {
      setLoading(false);
    } else {
      timerId = setTimeout(
        () =>
          history.replace(
            `/login?redirect=${props.location.pathname}${props.location.search}`
          ),
        500
      );
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [user]);

  if (loading) {
    return (
      <Box style={{ display: 'flex', height: '100%' }}>
        <CircularProgress style={{ margin: 'auto' }} />
      </Box>
    );
  }
  if (Layout) {
    return (
      <Layout>
        <Comp {...props} />
      </Layout>
    );
  }
  return <Comp {...props} />;
};

export default WrapWithProtectedLayout;
