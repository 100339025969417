const adPages = [
  {
    label: 'Home',
    value: 'home',
    disabled: false
  },
  {
    label: 'Shop List',
    value: 'list',
    disabled: false
  }
];

const adTypes = [
  {
    label: 'Shop Ad',
    value: 'shop',
    disabled: false
  },
  {
    label: 'Default Ad',
    value: 'default',
    disabled: false
  }
];

const templates = [
  {
    label: 'Template 1',
    value: 'tem1',
    disabled: false,
    visibleAt: 'list'
  },
  {
    label: 'Template 2',
    value: 'tem2',
    disabled: false,
    visibleAt: 'list'
  },
  {
    label: 'Template 3',
    value: 'tem3',
    disabled: true,
    visibleAt: 'home'
  },
  {
    label: 'Template 4',
    value: 'tem4',
    disabled: true,
    visibleAt: 'home'
  },
  {
    label: 'Template 5',
    value: 'tem5',
    disabled: false,
    visibleAt: 'home'
  },
  {
    label: 'Template 6',
    value: 'tem6',
    disabled: false,
    visibleAt: 'list'
  }
];

const templateFields = {
  tem1: [
    {
      type: 'image',
      value: '',
      file: null,
      name: 'image',
      imageRatio: 131 / 50,
      imageRatioText: '131 x 50'
    },
    {
      type: 'text',
      max: 195,
      label: 'Description',
      name: 'description',
      value:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Odit fugiat, ipsum dolorem voluptatem quis, fugit a ad ab eius debitis labore totam provident assumenda ullam magni qui recusandae, temporibus'
    }
  ],
  tem2: [
    {
      type: 'text',
      max: 30,
      label: 'Heading',
      name: 'heading',
      value: 'Lorem ipsum dolor sit amet con'
    },
    {
      type: 'text',
      max: 22,
      label: 'Sub-Heading 1',
      name: 'subHeading1',
      value: 'Lorem ipsum dolor sit.'
    },
    {
      type: 'text',
      max: 22,
      label: 'Sub-Heading 2',
      name: 'subHeading2',
      value: 'Lorem ipsum dolor sit.'
    },
    {
      type: 'text',
      max: 114,
      label: 'Body 1',
      name: 'body1',
      value:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dicta animi aperiam veniam placeat eum? Aliquam in ullam'
    },
    {
      type: 'text',
      max: 114,
      label: 'Body 2',
      name: 'body2',
      value:
        'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dicta animi aperiam veniam placeat eum? Aliquam in ullam'
    }
  ],
  tem3: [
    { type: 'image', value: '', file: null, name: 'image' },
    {
      type: 'text',
      max: 100,
      label: 'Description',
      name: 'description',
      value:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit ratione quas in. Placeat voluptas enim.'
    }
  ],
  tem4: [
    { type: 'image', value: '', file: null, name: 'banner' },
    { type: 'image', value: '', file: null, name: 'logo' }
  ],
  tem5: [
    {
      type: 'image',
      value: '',
      file: null,
      name: 'image',
      imageRatio: 131 / 50,
      imageRatioText: '131 x 50'
    }
  ],
  tem6: [
    {
      type: 'image',
      value: '',
      file: null,
      name: 'image',
      imageRatio: 4 / 3,
      imageRatioText: '4 x 3'
    }
  ]
};

const initialState = {
  shop: null,
  startDate: new Date().toISOString().split('T').at(0),
  endDate: '',
  priority: '',
  page: '',
  amount: '',
  template: '',
  type: 'shop',
  redirectLink: '',
  orderedDate: new Date().toISOString().split('T').at(0)
};

const formFieldsForMendatoryCheck = ['startDate', 'endDate', 'template'];

const priorities = [
  {
    label: 1,
    labelHelper: 'highest',
    value: 'P1'
  },
  {
    label: 2,
    value: 'P2'
  },
  {
    label: 3,
    labelHelper: 'lowest',
    value: 'P3'
  }
];

export {
  priorities,
  formFieldsForMendatoryCheck,
  initialState,
  templateFields,
  templates,
  adPages,
  adTypes
};
