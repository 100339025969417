import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { useLoader } from '../../../../layouts/loaderContext';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Password = props => {
  const [showPassword, setShowPassword] = useState({
    password: false,
    oldPassword: false,
    confirmPassword: false
  });
  const getInitialState = () => ({
    password: '',
    oldPassword: '',
    confirmPassword: ''
  });
  const { className, ...rest } = props;
  const classes = useStyles();
  const [password, setPassword] = useState(getInitialState());
  const { setLoading } = useLoader();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const resetState = () => {
    setPassword(getInitialState());
  };

  function validateForm() {
    return (
      password.oldPassword.length > 0 &&
      password.password.length > 0 &&
      password.password === password.confirmPassword
    );
  }

  const handleChange = event => {
    setPassword({
      ...password,
      [event.target.name]: event.target.value
    });
  };

  async function handleChangeClick(event) {
    event.preventDefault();
    let sBar = enqueueSnackbar('Updating password...', {
      variant: 'info',
      preventDuplicate: true
    });
    try {
      setLoading(true);
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        password.oldPassword,
        password.password
      );
      closeSnackbar(sBar);
      setLoading(false);
      sBar = enqueueSnackbar('Password Updated ...', {
        variant: 'success',
        preventDuplicate: true
      });
      resetState();
    } catch (error) {
      closeSnackbar(sBar);
      sBar = enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: true
      });
      setLoading(false);
    }
  }

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form onSubmit={handleChangeClick}>
        <CardHeader subheader="Update password" title="Password" />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Current Password"
            name="oldPassword"
            type={showPassword.oldPassword ? 'text' : 'password'}
            variant="outlined"
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        oldPassword: !showPassword.oldPassword
                      })
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword.oldPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
            required
          />
          <TextField
            fullWidth
            label="New Password"
            name="password"
            style={{ marginTop: '1rem' }}
            variant="outlined"
            onChange={handleChange}
            type={showPassword.password ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        password: !showPassword.password
                      })
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword.password ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
            required
          />
          <TextField
            fullWidth
            label="Confirm password"
            name="confirmPassword"
            style={{ marginTop: '1rem' }}
            variant="outlined"
            onChange={handleChange}
            required
            type={showPassword.confirmPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      setShowPassword({
                        ...showPassword,
                        confirmPassword: !showPassword.confirmPassword
                      })
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showPassword.confirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            type="submit"
            disabled={!validateForm()}>
            Update
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
