import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  IconButton,
  Link,
  Chip,
  useMediaQuery,
  Button
} from '@material-ui/core';
import { useAmplifyAuth } from 'context';
import {
  capitalizeStr,
  getShopTotalRating as getTotalRating,
  showPrice
} from 'common/utilFunctions';
import {
  ItemDisplayer,
  WorkingHoursMenu,
  NavigationBreadcrumbs
} from 'components/organisms';
import { ShopCurrentWorkingStatus } from 'components/molecules';
import ShopReviews from './components/ShopReviews';
import { useParams } from 'react-router-dom';
import { useShops } from 'context/shop/shopsContext';
import { useHistory } from 'react-router';
import { useCoupons } from 'context/coupons/couponsContext';
import Map from './components/Map';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import StarIcon from '@material-ui/icons/Star';
import AboutShop from './components/AboutShop';
import PopoverComponent from 'components/organisms/PopoverComponent';
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon
} from 'react-share';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { useSnackbar } from 'notistack';
import ShopVideos from './components/ShopVideos';
import { API, graphqlOperation } from 'aws-amplify';
import { createShopSiteRedirectRecord } from 'graphql/mutations';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ImageSlider from './components/ImageSlider';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      overflow: 'hidden',
      padding: '0 30px',
      borderTop: '1px solid #0000001A',
      [theme.breakpoints.up('md')]: {
        padding: '0 5rem'
      }
    },
    blackIcon: {
      color: 'black'
    },
    greyText: {
      color: theme.palette.grey.secondary
    },
    greyIcon: {
      color: theme.palette.grey.secondary,
      fontSize: '12px'
    },
    favouriteButton: {
      color: theme.palette.favourite.main
    },
    blueText: {
      color: theme.palette.blue.main
    },
    offerBox: {
      padding: '12px 6px',
      fontSize: '14px',
      fontWeight: '900',
      marginTop: '10px',
      backgroundColor: '#99ccee44'
    },
    currentRatingBox: {
      backgroundColor: '#EDD242',
      height: '70px',
      minWidth: '88px',
      borderRadius: '5px',
      marginRight: '1rem'
    },
    reviewPromoBox: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: '10px'
      }
    },
    shopOnHoldAlertText: {
      color: '#b00',
      fontWeight: 'bold'
    },
    languageChip: {
      color: 'white',
      backgroundColor: theme.palette.blue.main,
      borderColor: theme.palette.blue.main
    },
    shopWebsiteLinkButton: {
      cursor: 'pointer'
    },
    shopWebsiteLinkButtonIcon: {
      position: 'relative',
      fontSize: '12px',
      top: '2px',
      marginRight: '2px'
    }
  };
});

const ShopDetail = (props) => {
  let { shopID } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [workingHoursMenuAnchor, setWorkingHoursMenuAnchor] = useState(null);
  const { selectedShop, dispatch } = useShops();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const shareURL = window.location.href;
  let {
    state: { user }
  } = useAmplifyAuth();
  const { dispatch: dispatchCoupons } = useCoupons();

  useEffect(() => {
    if (selectedShop?.id)
      dispatchCoupons({
        type: 'getCouponsByShop',
        payload: { shopID: selectedShop?.id }
      });
  }, [selectedShop]);

  const {
    name = '',
    address = '',
    town = '',
    postcode = '',
    phoneNumber = '',
    workHrs = [],
    reviews = { items: [] },
    primaryPhoto = {},
    photos = [],
    videos = [],
    distanceRange = 0,
    minPriceForFree = 0,
    language = [],
    websiteLink = ''
  } = selectedShop || {};

  let filteredPhotos = photos?.filter(
    (photo) =>
      photo &&
      photo?.status === 'approved' &&
      photo?.MIMETypes.includes('image')
  );

  if (primaryPhoto?.photoURL) {
    const primaryPhotoURL = primaryPhoto?.photoURL;
    filteredPhotos = [
      primaryPhoto,
      ...filteredPhotos.filter((photo) => photo.photoURL !== primaryPhotoURL)
    ];
  }

  const filteredVideos = videos?.filter(
    (video) =>
      video &&
      video?.status === 'approved' &&
      video?.MIMETypes.includes('video')
  );

  const getShopDetails = () => {
    dispatch({
      type: 'getShopByID',
      payload: {
        shopID
      }
    }).then((data) => {
      if (data === 'NO_DATA') {
        history.push('/home');
        return null;
      }
    });
  };

  useEffect(() => {
    getShopDetails();
  }, []);

  const copyLinkHandler = () => {
    navigator.clipboard.writeText(shareURL);
    enqueueSnackbar('Shop copied to clipboard', {
      variant: 'success',
      autoHideDuration: 1000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      }
    });
  };

  const getTotalReviewText = () => {
    const currentRating = getTotalRating(reviews.items);
    if (currentRating >= 4.5) {
      return 'Excellent';
    } else if (currentRating >= 4) {
      return 'Very Good';
    } else if (currentRating >= 3.5) {
      return 'Good';
    } else if (currentRating >= 2.5) {
      return 'Moderate';
    } else if (currentRating >= 1.5) {
      return 'Bad';
    } else {
      return 'Very Bad';
    }
  };

  const handleShopSiteRedirect = async () => {
    let loadingBar;
    try {
      loadingBar = enqueueSnackbar('Loading shops website...', {
        variant: 'info',
        persist: true,
        preventDuplicate: true
      });
      await API.graphql(
        graphqlOperation(createShopSiteRedirectRecord, {
          shopID: selectedShop.id
        })
      );
      window.open(websiteLink, '_blank');
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        autoHideDuration: 2000,
        preventDuplicate: true
      });
    } finally {
      closeSnackbar(loadingBar);
    }
  };

  return selectedShop ? (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.root}>
        <Grid item xs={12}>
          <NavigationBreadcrumbs showShopList currentLink={selectedShop.name} />
        </Grid>
        <Grid container>
          {md && <ImageSlider photos={filteredPhotos} fitHeight="342px" />}
          <Grid
            container
            item
            md={6}
            xs={12}
            justify="center"
            style={{ gap: '10px' }}>
            <Grid
              container
              item
              xs={12}
              style={{
                padding: '10px 20px'
              }}
              component={Paper}>
              <Grid container justify="space-between">
                <Grid container item xs={10} style={{ gap: '0.25rem' }}>
                  <Grid container item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        variant="h3"
                        className={classes.blackIcon}
                        style={{ fontWeight: 800 }}>
                        {capitalizeStr(name)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" className={classes.blackIcon}>
                        <LocationOnOutlinedIcon
                          className={classes.blackIcon}
                          style={{ fontSize: '12px' }}
                        />
                        &nbsp; {`${address}, ${town}, ${postcode}`}
                      </Typography>
                    </Grid>
                  </Grid>
                  {distanceRange > 0 && (
                    <Grid container item xs={12} alignItems="center">
                      <LocalShippingOutlinedIcon
                        className={classes.blackIcon}
                        style={{ fontSize: '12px' }}
                      />
                      <Typography variant="body1" className={classes.blackIcon}>
                        &nbsp;{'  '}
                        {`Collection and delivery upto ${distanceRange}
                      miles `}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  justify="flex-end"
                  alignItems="flex-start"
                  item
                  xs={2}>
                  {user && user.username && (
                    <FormControlLabel
                      onClick={(e) => e.stopPropagation()}
                      control={
                        <Checkbox
                          icon={<FavoriteBorder fontSize="small" />}
                          checkedIcon={
                            <Favorite
                              fontSize="small"
                              className={classes.favouriteButton}
                            />
                          }
                          name="shop-favourite-check"
                        />
                      }
                      style={{ marginRight: 0 }}
                    />
                  )}
                  <IconButton
                    style={{
                      padding: 0,
                      height: 38,
                      width: 38
                    }}>
                    <PopoverComponent>
                      <Grid container style={{ gap: '0.5rem' }}>
                        <IconButton
                          size="small"
                          style={{
                            color: 'blue',
                            height: '30px',
                            backgroundColor: '#46373736'
                          }}
                          onClick={copyLinkHandler}>
                          <FileCopyRoundedIcon />
                        </IconButton>
                        <FacebookShareButton url={shareURL}>
                          <FacebookIcon size={30} round={true} />
                        </FacebookShareButton>
                        <WhatsappShareButton url={shareURL}>
                          <WhatsappIcon size={30} round={true} />
                        </WhatsappShareButton>
                        <TwitterShareButton url={shareURL}>
                          <TwitterIcon size={30} round={true} />
                        </TwitterShareButton>
                        <EmailShareButton url={shareURL}>
                          <EmailIcon size={30} round={true} />
                        </EmailShareButton>
                      </Grid>
                    </PopoverComponent>
                  </IconButton>
                </Grid>
              </Grid>
              {language?.length > 0 ? (
                <Grid
                  container
                  item
                  xs={12}
                  alignItems="center"
                  style={{ gap: '0.5rem', margin: '0.5rem 0 1rem' }}>
                  {language.map((lang, index) => (
                    <Chip
                      variant="outlined"
                      size="small"
                      label={lang}
                      key={`customer-side-shop-detail-language-items-${index}`}
                      className={classes.languageChip}
                    />
                  ))}
                </Grid>
              ) : null}
              {!!websiteLink ? (
                <Grid container item xs={12} alignItems="center">
                  <Link
                    className={classes.shopWebsiteLinkButton}
                    variant="body2"
                    onClick={handleShopSiteRedirect}>
                    <OpenInNewIcon
                      className={classes.shopWebsiteLinkButtonIcon}
                    />
                    go to shop website
                  </Link>
                </Grid>
              ) : null}
              <Grid
                container
                alignItems="flex-end"
                justify="space-between"
                style={{ padding: '0.25rem 0 0.625rem' }}>
                <Grid
                  container
                  item
                  justify="center"
                  alignItems="flex-start"
                  xs={12}
                  md={6}
                  style={{ height: 'fit-content' }}>
                  <Grid item xs={12} container alignItems="center">
                    <AccessTimeIcon className={classes.greyIcon} />
                    <Typography variant="body2" className={classes.greyText}>
                      &nbsp; Opening Hours
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container alignItems="center">
                    <Button
                      variant="text"
                      onClick={(e) =>
                        setWorkingHoursMenuAnchor(e.currentTarget)
                      }
                      style={{ padding: 0 }}
                      endIcon={<KeyboardArrowDownIcon fontSize="small" />}>
                      <ShopCurrentWorkingStatus workHrs={workHrs} />
                    </Button>
                  </Grid>
                  <WorkingHoursMenu
                    anchorEl={workingHoursMenuAnchor}
                    handleClose={() => setWorkingHoursMenuAnchor(null)}
                    workingHours={workHrs}
                  />
                </Grid>
                <Grid
                  container
                  item
                  md={6}
                  xs={12}
                  justify="center"
                  alignItems="center"
                  style={{ paddingLeft: md ? '0.5rem' : 0 }}>
                  <Grid item xs={12} container alignItems="center">
                    <PhoneOutlinedIcon className={classes.greyIcon} />
                    <Typography variant="body2" className={classes.greyText}>
                      &nbsp; Phone Number
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    justify="space-between"
                    alignItems="center"
                    style={{ paddingLeft: md ? '0.2rem' : 0 }}>
                    <Typography variant="subtitle1">{phoneNumber}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {!md && filteredPhotos?.length > 0 ? (
              <Grid container>
                <ImageSlider photos={filteredPhotos} fitHeight="142px" />
              </Grid>
            ) : null}

            <Grid
              container
              justify="space-between"
              alignItems="flex-start"
              item
              xs={12}>
              <Grid item xs={12} md={6} component={Paper}>
                <Map state={selectedShop} />
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                className={classes.reviewPromoBox}
                direction="column"
                justify="center"
                alignItems="center">
                <Grid
                  container
                  alignItems="center"
                  component={Paper}
                  style={{ padding: '10px' }}>
                  <Grid
                    container
                    alignItems="center"
                    justify="center"
                    direction="column"
                    style={{ width: 'fit-content' }}
                    className={classes.currentRatingBox}>
                    {reviews.items.length > 0 ? (
                      <>
                        <Typography
                          variant="h3"
                          style={{ fontWeight: '900', marginBottom: '3px' }}>
                          {getTotalRating(reviews.items)}
                        </Typography>
                        <Typography variant="h6">
                          Out of 5
                          <StarIcon
                            style={{
                              fontSize: '14px',
                              paddingTop: '3px'
                            }}
                          />
                        </Typography>
                      </>
                    ) : (
                      <Typography variant="h6">
                        No ratings
                        <br />
                        yet
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    style={{ width: 'fit-content' }}>
                    {reviews.items.length > 0 ? (
                      <>
                        <Typography variant="h6" style={{ fontWeight: '900' }}>
                          {getTotalReviewText()}
                        </Typography>
                        <Typography variant="body2">
                          Based on {reviews.items.length} Ratings
                        </Typography>
                        <Link
                          href="#anchor-to-shop-reviews-footer"
                          className={classes.blueText}>
                          Read Reviews
                        </Link>
                      </>
                    ) : (
                      <Typography variant="h6" style={{ fontWeight: '900' }}>
                        No reviews yet
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                {minPriceForFree > 0 && (
                  <Grid
                    container
                    alignItems="center"
                    component={Paper}
                    className={classes.offerBox}>
                    <Typography variant="h6">
                      Free collection & delivery for order amount -
                      <br />
                      <strong>{`${showPrice(minPriceForFree)} & above`}</strong>
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          {selectedShop?.status === 'onhold' ? (
            <Typography variant="h6" className={classes.shopOnHoldAlertText}>
              Sorry for the inconvenience. Call the shop and place an order.
              &nbsp;
            </Typography>
          ) : null}
        </Grid>
      </Grid>
      <ItemDisplayer currentShop={selectedShop} />
      {selectedShop.about && <AboutShop currentShop={selectedShop} />}
      {filteredVideos?.length > 0 && <ShopVideos videos={filteredVideos} />}
      <ShopReviews
        reviews={reviews.items}
        shopID={selectedShop.id}
        refresh={getShopDetails}
      />
    </>
  ) : (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}>
      <CircularProgress style={{ margin: '2rem' }} />
    </Grid>
  );
};

export default ShopDetail;
