import React, { useRef } from 'react';
import { Grid, GridList, GridListTile, IconButton } from '@material-ui/core';
import { Image } from 'components/organisms';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import noImagePic from 'assets/png/no-image-available.png';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      overflow: 'hidden',
      padding: '0 30px',
      borderTop: '1px solid #0000001A',
      [theme.breakpoints.up('md')]: {
        padding: '0 5rem'
      }
    },
    offerCards: {
      position: 'relative',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        paddingRight: '1.25rem'
      }
    },
    card: {
      backgroundImage:
        'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8))',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    },
    scrollBtn: {
      position: 'absolute',
      top: '45%',
      zIndex: 15,
      backgroundColor: '#72737499'
    },
    listTileRoot: {
      height: '100% !important',
      padding: '0 !important'
    }
  };
});

const ImageSlider = (props) => {
  const classes = useStyles();
  const shopsPhotosRef = useRef(null);
  const singleShopsPhotoRef = useRef(null);
  const { photos, fitHeight } = props;

  const scrollDiv = (scrollLeft = false) => {
    const scrollWidth = singleShopsPhotoRef.current
      ? singleShopsPhotoRef.current.offsetWidth
      : 500;
    shopsPhotosRef.current.scrollBy({
      left: !scrollLeft ? scrollWidth * -1 : scrollWidth,
      behavior: 'smooth'
    });
  };

  return (
    <Grid container item md={6} xs={12} className={classes.offerCards}>
      {photos?.length > 1 && (
        <IconButton
          size="small"
          className={classes.scrollBtn}
          style={{ left: '4%' }}
          onClick={() => scrollDiv()}>
          <ArrowBackIosIcon fontSize="small" style={{ color: 'white' }} />
        </IconButton>
      )}
      {photos?.length > 0 ? (
        <GridList
          ref={shopsPhotosRef}
          className="hide-scrollbar"
          style={{
            flexWrap: 'nowrap',
            transform: 'translateZ(0)',
            width: '100%',
            height: '100%'
          }}>
          {photos?.map((item, index) => (
            <GridListTile
              classes={{ root: classes.listTileRoot }}
              ref={singleShopsPhotoRef}
              key={`popular-shop-introone-items-${index}`}
              style={{
                width: '100%',
                maxWidth: '100%'
              }}
              className={classes.card}>
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ height: '100%', backgroundColor: 'aliceblue' }}
                alignItems="center">
                <Image docKey={item.photoURL} height={fitHeight} />
              </Grid>
            </GridListTile>
          ))}
        </GridList>
      ) : (
        <img src={noImagePic} width="100%" height={fitHeight} />
      )}
      {photos?.length > 1 && (
        <IconButton
          size="small"
          className={classes.scrollBtn}
          style={{ left: '89%' }}
          onClick={() => scrollDiv(true)}>
          <ArrowForwardIosIcon fontSize="small" style={{ color: 'white' }} />
        </IconButton>
      )}
    </Grid>
  );
};

export default ImageSlider;
