import React, { useEffect, useContext } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCoupons } from 'context/coupons/couponsContext';
import { CartContext } from 'context/cart/cartContext';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0 0',
    padding: '1rem 0',
    borderTop: '1px solid #D2D8DD',
    borderBottom: '1px solid #D2D8DD'
  },
  heading: {
    marginBottom: '0.25rem'
  },
  totalText: {
    fontWeight: 850,
    color: '#444444'
  },
  coupon: {
    margin: '0.5rem 0'
  },
  couponBtn: {
    borderRadius: '1rem',
    fontSize: '0.75rem',
    textTransform: 'none'
  },
  checkoutBtn: {
    backgroundColor: theme.palette.blue.main,
    color: 'white',
    textTransform: 'uppercase'
  }
}));

const CartCoupons = ({
  handleApplyCouponClick = () => {},
  showAppliedOnly = false
}) => {
  const classes = useStyles();
  const { cart } = useContext(CartContext);
  const { coupons = [], dispatch } = useCoupons();
  const filteredCoupons = coupons
    .filter((item) => item.discountType === 'conditional')
    .filter((item) =>
      showAppliedOnly
        ? cart.some((product) => product.discountCouponID === item.id)
        : true
    );

  useEffect(() => {
    let shopID = '';
    if (cart.length > 0) shopID = cart.at(0).shopID;
    if (shopID && coupons?.length <= 0)
      dispatch({ type: 'getCouponsByShop', payload: { shopID } });
  }, []);

  return filteredCoupons.length > 0 ? (
    <Grid container className={classes.root}>
      <Grid item xs={12} className={classes.heading}>
        <Typography variant="h5" className={classes.totalText}>
          Coupons
        </Typography>
      </Grid>
      {filteredCoupons.map((item, index) => {
        const isCouponApplied = cart.some(
          (product) => product.discountCouponID === item.id
        );
        return (
          <Grid
            item
            xs={12}
            key={`cart-card-coupons-items-${index}`}
            container
            justify="space-between"
            className={classes.coupon}>
            <Grid item xs={8}>
              <Typography variant="subtitle1">{item.title || ''}</Typography>
              <Typography variant="body1">{item.description || ''}</Typography>
            </Grid>
            <Grid item xs={4}>
              {cart.some((product) => product.id === item.productID) ? (
                <Button
                  size="small"
                  disabled={isCouponApplied}
                  variant="contained"
                  color="primary"
                  onClick={handleApplyCouponClick(item)}
                  className={clsx(classes.checkoutBtn, classes.couponBtn)}
                  fullWidth>
                  {isCouponApplied ? 'Applied' : 'Apply Coupon'}
                </Button>
              ) : null}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  ) : null;
};

export default CartCoupons;
