import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Avatar,
  Typography,
  Grid,
  Button
} from '@material-ui/core';
import { AccountDetails } from '..';
import { useAmplifyAuth } from '../../../../context';
import { useSnackbar } from 'notistack';
import { updateUser } from '../../../../graphql/mutations';
import { Storage, graphqlOperation, API } from 'aws-amplify';
import {
  getFileUploadKey,
  getStorageUrl,
  compressImage
} from 'common/utilFunctions';

const useStyles = makeStyles((theme) => ({
  root: { minWidth: '100%', marginBottom: '0.5rem' },
  avatar: {
    marginRight: '2rem',
    height: 94,
    width: 94,
    flexShrink: 0,
    flexGrow: 0
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  },
  changeButton: {
    color: theme.palette.text.secondary,
    padding: '0.5rem 1.5rem',
    height: 'fit-content'
  },
  input: {
    display: 'none'
  },
  uploadLabel: {
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    }
  }
}));

const AccountProfile = (props) => {
  const { className, ...rest } = props;
  const [values, setValues] = useState({ picture: '' });
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    state: { user }
  } = useAmplifyAuth();

  useEffect(() => {
    let mounted = true;
    user &&
      getStorageUrl(user.picture).then(
        (picture) => mounted && setValues({ picture, _version: user._version })
      );
    return () => {
      mounted = false;
    };
  }, [user]);

  const handleChange = (event) => {
    if (event.target.name === 'image') {
      setSelectedImage(event.target.files[0]);
    }
  };

  const onUpload = async () => {
    if (!selectedImage) {
      enqueueSnackbar('Please select an image', {
        variant: 'warning',
        preventDuplicate: true
      });
      return;
    }
    let sBar = enqueueSnackbar('Saving Photo...', {
      variant: 'info',
      preventDuplicate: true
    });
    const { type: mimeType } = selectedImage;
    const compressedFile = await compressImage(selectedImage);
    const key = getFileUploadKey(compressedFile, 'images');
    const input = {
      id: user.id,
      _version: values._version,
      picture: key
    };

    try {
      await Storage.put(key, compressedFile, {
        contentType: mimeType,
        level: 'public'
      });
      const data = await API.graphql(
        graphqlOperation(updateUser, {
          input
        })
      );
      const picture = await getStorageUrl(data.data.updateUser.picture);
      setValues({
        picture,
        _version: data.data.updateUser._version
      });
      setSelectedImage('');
      enqueueSnackbar('Photo updated successfully', {
        variant: 'success',
        preventDuplicate: true
      });
    } catch (e) {
      enqueueSnackbar('Failed', {
        variant: 'warning',
        preventDuplicate: true
      });
    } finally {
      closeSnackbar(sBar);
    }
  };

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Grid
            container
            direction="row"
            justify="space-around"
            alignItems="center">
            <Grid
              container
              item
              direction="row"
              justify="flex-start"
              alignItems="center"
              xs={12}
              md={8}>
              <Avatar
                className={classes.avatar}
                src={
                  !!selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : values.picture || ''
                }
                alt={user.firstName || ''}
              />
              <div>
                <label
                  htmlFor="contained-button-file"
                  style={{ cursor: 'pointer' }}>
                  <Typography
                    gutterBottom
                    variant="h4"
                    className={classes.uploadLabel}>
                    Upload a new photo
                  </Typography>
                  <Typography gutterBottom variant="h4"></Typography>
                </label>
              </div>
            </Grid>
            <Grid
              container
              item
              direction="row"
              justify="flex-end"
              alignItems="flex-end"
              xs={12}
              md={2}>
              <input
                accept="image/*"
                name="image"
                className={classes.input}
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleChange}
              />
              <Button onClick={onUpload} variant="contained" component="span">
                Change
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <AccountDetails />
    </>
  );
};

AccountProfile.propTypes = {
  className: PropTypes.string
};

export default AccountProfile;
