import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    display: 'flex',
    height: 24,
    width: 24,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '2px',
    borderColor: '#ccc'
  },
  active: {
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    borderColor: '#194378',
    backgroundColor: '#ABCE46'
  },
  completed: {
    borderColor: '#194378',
    backgroundColor: '#194378'
  }
});

const OrderStatusConnectorIcon = (props) => {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}>
      {completed ? <></> : <CheckCircleOutlineIcon />}
    </div>
  );
};

export default OrderStatusConnectorIcon;
