import React, { useState } from 'react';
import {
  Grid,
  LinearProgress,
  Typography,
  Box,
  Button,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import StyledRating from './StyledRating';
import AddReviewDialog from './AddReviewDialog';
import { useAmplifyAuth } from 'context';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { createCustomerReview } from 'graphql/mutations';
import { graphqlOperation, API, Storage } from 'aws-amplify';
import { Image } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '50px 30px',
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      padding: '60px 5rem'
    }
  },
  progressColor: {
    backgroundColor: theme.palette.yellow.main
  },
  addReviewBox: {},
  customerReviewsBox: {
    borderBottom: '1px solid #C4C4C4',
    paddingBottom: '35px',
    marginBottom: '35px'
  },
  blueBtn: {
    backgroundColor: theme.palette.blue.main,
    color: 'white'
  },
  userImg: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    marginRight: '0.625rem',
    [theme.breakpoints.up('md')]: {
      marginRight: 0,
      height: 60,
      width: 60
    }
  }
}));

const STAR_VALUE_TO_PERCENTAGE = 20;

const LinearProgressWithLabel = (props) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" style={{ marginTop: '8px' }}>
      <Box width="100%" mr={1}>
        <LinearProgress
          variant="determinate"
          style={{
            height: '10px',
            borderRadius: '1.5rem',
            backgroundColor: '#E5E5E5'
          }}
          classes={{ barColorPrimary: classes.progressColor }}
          {...props}
        />
      </Box>
      <Box minWidth={40}>
        <Typography variant="body1">{`${(
          props.value / STAR_VALUE_TO_PERCENTAGE
        ).toFixed(1)} star`}</Typography>
      </Box>
    </Box>
  );
};

const initialReview = {
  category1: 0,
  category2: 0,
  category3: 0,
  category4: 0,
  title: '',
  description: ''
};

const ratingCategories = [
  { label: 'Washing Quality', name: 'category1' },
  { label: 'Service', name: 'category2' },
  { label: 'Delivery', name: 'category3' },
  { label: 'Price', name: 'category4' }
];

const isRatingCategory = (name) =>
  name === 'category1' ||
  name === 'category2' ||
  name === 'category3' ||
  name === 'category4';

const ShopReviews = ({ reviews, shopID, refresh }) => {
  const classes = useStyles();
  const history = useHistory();
  const md = useMediaQuery(useTheme().breakpoints.up('md'));
  const { enqueueSnackbar } = useSnackbar();
  const {
    state: { user }
  } = useAmplifyAuth();
  const [showAddReviewDialog, setShowAddReviewDialog] = useState(false);
  const [newReview, setNewReview] = useState(initialReview);

  const getTotalRating = (reviews) =>
    parseFloat(
      (
        reviews
          .map(
            (item) =>
              (item.category1 +
                item.category2 +
                item.category3 +
                item.category4) /
              4
          )
          .reduce((p, c) => p + c, 0) / reviews.length
      ).toFixed(1)
    );

  const totalRating = getTotalRating(reviews);

  const handleChange = (e, newVal) =>
    setNewReview({
      ...newReview,
      [e.target.name]: isRatingCategory(e.target.name)
        ? parseFloat(newVal)
        : newVal || e.target.value
    });

  const handleCancel = () => {
    setShowAddReviewDialog(false);
    setNewReview(initialReview);
  };

  const handleSubmit = async () => {
    try {
      await API.graphql(
        graphqlOperation(createCustomerReview, {
          input: { ...newReview, userID: user.id, shopID }
        })
      );
      enqueueSnackbar('Review submitted', {
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: 1000
      });
      refresh();
      handleCancel();
    } catch (error) {
      console.log('error', error);
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        autoHideDuration: 1000,
        preventDuplicate: true
      });
    }
  };

  return (
    <>
      <Grid
        container
        justify="space-between"
        className={classes.root}
        id="anchor-to-shop-reviews-footer">
        <Grid item xs={12} md={3}>
          {reviews.length > 0 && (
            <Grid
              container
              direction="column"
              className={classes.customerReviewsBox}>
              <Typography variant="h3" id="anchor-to-shop-reviews-footer">
                Customer reviews
              </Typography>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                style={{
                  marginTop: '42px',
                  paddingRight: '15px',
                  marginBottom: '35px'
                }}>
                <StyledRating
                  name="read-only-customer-rating"
                  value={totalRating}
                  readOnly
                />
                |
                <Typography
                  style={{
                    fontWeight: '450'
                  }}>
                  {reviews.length} Ratings
                </Typography>
              </Grid>
              {ratingCategories
                .map((item) => ({
                  ...item,
                  value:
                    parseFloat(
                      (
                        reviews.reduce((p, c) => p + c[item.name], 0) /
                        reviews.length
                      ).toFixed(1)
                    ) * STAR_VALUE_TO_PERCENTAGE
                }))
                .map((item, index) => (
                  <Grid
                    container
                    direction="column"
                    style={{ marginBottom: '20px' }}
                    key={`shop-detail-cutomer-reviews-categories-items-${index}`}>
                    <Typography>{item.label}</Typography>
                    <LinearProgressWithLabel value={item.value} />
                  </Grid>
                ))}
            </Grid>
          )}
          <Grid container direction="column" className={classes.addReviewBox}>
            <Typography variant="h3">Your review</Typography>
            <Typography
              variant="h6"
              style={{ marginTop: '0.75rem', marginBottom: '1.52rem' }}>
              {reviews.length > 0
                ? 'Share your thoughts with other customers'
                : 'Be the first one to review this shop'}
            </Typography>
            {user && user.username ? (
              <Button
                variant="contained"
                fullWidth
                size="large"
                onClick={() => setShowAddReviewDialog(true)}
                className={classes.blueBtn}>
                WRITE REVIEW
              </Button>
            ) : (
              <Button
                variant="contained"
                fullWidth
                size="large"
                href={`/login?redirect=${history.location.pathname}`}
                className={classes.blueBtn}>
                Login first to write review
              </Button>
            )}
          </Grid>
        </Grid>
        {reviews.length > 0 && (
          <Grid item xs={12} md={8} style={{ marginTop: md ? 0 : '4rem' }}>
            <Typography
              variant="h3"
              style={{ marginBottom: md ? '1.1rem' : '0.5rem' }}>
              Top reviews
            </Typography>
            <Grid container direction="column">
              {reviews.map((item, index) => (
                <Grid
                  container
                  justify="space-between"
                  style={{
                    padding: '1.7rem 0 1.25rem',
                    borderBottom: '1px solid #C4C4C4'
                  }}
                  key={`shop-reviews-details-items-${index}`}>
                  {md ? (
                    <Grid item xs={1}>
                      <Image
                        className={classes.userImg}
                        docKey={item.user?.picture}
                      />
                    </Grid>
                  ) : null}
                  <Grid
                    item
                    xs={11}
                    container
                    direction="column"
                    style={{ paddingLeft: md ? '1.25rem' : 0 }}>
                    <Grid container justify="space-between" alignItems="center">
                      <Grid container item xs={12} md={6} alignItems="center">
                        {!md ? (
                          <Image
                            className={classes.userImg}
                            docKey={item.user?.picture}
                          />
                        ) : null}
                        <Typography variant="h4">
                          {item.user?.username
                            ? item.user.firstName
                              ? `${item.user.firstName} ${item.user.lastName}`
                              : item.user.username
                            : '' || ''}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        container
                        justify={md ? 'flex-end' : 'flex-start'}>
                        <Typography variant="subtitle2">
                          Reviewed on{' '}
                          {moment(item.createdAt).format('MMMM Do YYYY')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <StyledRating
                      name="read-only-customer-rating"
                      value={getTotalRating([item])}
                      readOnly
                      style={{ margin: '0.5rem 0 0.75rem' }}
                    />
                    <Typography variant="h4">{item.title || ''}</Typography>
                    <Typography variant="h6" style={{ margin: '0.75rem 0' }}>
                      {item.description || ''}
                    </Typography>
                    {/* <Button
                    size="large"
                    variant="outlined"
                    style={{ maxWidth: '7.5rem' }}>
                    Helpful
                  </Button> */}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
      </Grid>
      <AddReviewDialog
        review={newReview}
        handleChange={handleChange}
        open={showAddReviewDialog}
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        ratingCategories={ratingCategories}
      />
    </>
  );
};

export default ShopReviews;
