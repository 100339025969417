/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCounters = /* GraphQL */ `
  subscription OnCreateCounters {
    onCreateCounters {
      id
      counterName
      currentValue
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCounters = /* GraphQL */ `
  subscription OnUpdateCounters {
    onUpdateCounters {
      id
      counterName
      currentValue
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage {
    onCreateMessage {
      id
      sender
      orderID
      message
      status
      readAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage {
    onUpdateMessage {
      id
      sender
      orderID
      message
      status
      readAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage {
    onDeleteMessage {
      id
      sender
      orderID
      message
      status
      readAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomerReview = /* GraphQL */ `
  subscription OnCreateCustomerReview {
    onCreateCustomerReview {
      id
      shopID
      userID
      title
      category1
      category2
      category3
      category4
      category5
      description
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const onUpdateCustomerReview = /* GraphQL */ `
  subscription OnUpdateCustomerReview {
    onUpdateCustomerReview {
      id
      shopID
      userID
      title
      category1
      category2
      category3
      category4
      category5
      description
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const onDeleteCustomerReview = /* GraphQL */ `
  subscription OnDeleteCustomerReview {
    onDeleteCustomerReview {
      id
      shopID
      userID
      title
      category1
      category2
      category3
      category4
      category5
      description
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
