import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAmplifyAuth } from 'context';
import { makeStyles } from '@material-ui/core/styles';
import { routes } from 'common/constants';
import OrdersTable from './components/OrdersTable';
import { CustomSearchBar } from 'components/organisms';
import { StyledTabs, StyledTab, TabPanel } from '../../components/molecules';
import { Button, Grid, IconButton, AppBar, Tooltip } from '@material-ui/core';
import { useOrders } from 'context/order/ordersContext';
import { DeclineDialog } from './components/dialogs/DeclineDialog';
import { ConfirmOrderDialog } from './components/dialogs/ConfirmOrderDialog';
import { ItemisedDialog } from './components/dialogs/ItemisedDialog';
import OrderDetailsDialog from './components/dialogs/OrderDetailsDialog';
import ReplayIcon from '@material-ui/icons/Replay';

const statusByTab = [
  ['newOrder'],
  ['itemized'],
  ['accepted', 'inProgress'],
  ['readyForDelivery', 'delivered']
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const tabRoutes = [
  { id: 0, route: routes.serviceProvider.ordersNew },
  { id: 1, route: routes.serviceProvider.orderItemPricing },
  { id: 2, route: routes.serviceProvider.orderProcessing },
  { id: 3, route: routes.serviceProvider.orderDelivery }
];

const Orders = () => {
  const classes = useStyles();
  const { dispatch } = useOrders();
  const history = useHistory();
  const [dialogType, setDialogType] = useState(null);
  const [payload, setPayload] = useState({});
  const [value, setValue] = React.useState(
    tabRoutes.find((item) => item.route === history.location.pathname)?.id || 0
  );
  const [status, setStatus] = useState(statusByTab[0]);
  const [searchBy, setSearchBy] = React.useState('');
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const {
    state: { user }
  } = useAmplifyAuth();

  useEffect(() => {
    setStatus(statusByTab[value]);
  }, [value]);

  const loadOrders = (useSearchString = true, newQuery = true) => {
    const { shopId, shopID } = user;
    if (shopId || shopID) {
      dispatch({
        type: 'getOrders',
        payload: {
          shopId: shopID || shopId,
          status,
          orderId: useSearchString ? searchBy : '',
          newQuery
        }
      });
    }
  };

  useEffect(() => {
    loadOrders();
  }, [user, status]);

  const onSearchChange = (event) => {
    if (event.key === 'Enter') loadOrders();
    else setSearchBy(event.target.value.toLowerCase());
  };

  const onSearchClear = () => {
    setSearchBy('');
    loadOrders(false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.replace(tabRoutes.find((item) => item.id === newValue)?.route);
  };

  const handleSubmit = (newPayload) => {
    setDialogType(null);
    setShowOrderDetails(false);
    dispatch({ type: 'updateOrder', payload: newPayload });
  };

  const updateOrder = (order, sts) => {
    if (sts === 'inProgress' || sts === 'readyForDelivery') {
      dispatch({ type: 'updateOrder', payload: { order, sts } });
    } else {
      setPayload({ order, sts });
      setDialogType(sts);
    }
  };

  const ordersTableCommonProps = {
    showMessages: false,
    updateOrder,
    setShowOrderDetails,
    loadOrders
  };

  return (
    <>
      <Grid className={classes.root}>
        <Grid container item justify="space-between" xs={12}>
          <Tooltip title="reload" placement="right">
            <IconButton onClick={() => loadOrders()}>
              <ReplayIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Button
            color="secondary"
            component="a"
            href={routes.serviceProvider.orderHistory}
            variant="contained">
            order history
          </Button>
        </Grid>
        <AppBar
          position="static"
          style={{
            backgroundColor: 'transparent',
            color: '#263238',
            paddingBottom: '1rem',
            marginTop: '1rem',
            boxShadow: 'none'
          }}>
          <Grid container justify="space-between" alignItems="center">
            <Grid container item xs={8} justify="flex-start">
              <StyledTabs
                value={value}
                onChange={handleChange}
                aria-label="orders-tabs">
                <StyledTab label="New Orders" {...a11yProps(0)} />
                <StyledTab label="Item & Pricing" {...a11yProps(1)} />
                <StyledTab label="Processing" {...a11yProps(2)} />
                <StyledTab label="Delivery" {...a11yProps(3)} />
              </StyledTabs>
            </Grid>
            <Grid item container xs={4} justify="flex-end">
              <CustomSearchBar
                value={searchBy}
                onChange={onSearchChange}
                placeholder="Search Order"
                onClear={onSearchClear}
                onSearch={() => loadOrders()}
              />
            </Grid>
          </Grid>
        </AppBar>
        <TabPanel value={value} index={0}>
          <OrdersTable
            stage={1}
            {...ordersTableCommonProps}
            status="newOrder"
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OrdersTable
            stage={2}
            {...ordersTableCommonProps}
            status="itemized"
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <>
            <OrdersTable
              stage={3}
              status="accepted"
              style={{ marginBottom: '1rem' }}
              {...ordersTableCommonProps}
            />
            <OrdersTable
              stage={4}
              status="inProgress"
              {...ordersTableCommonProps}
            />
          </>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <>
            <OrdersTable
              stage={5}
              status="readyForDelivery"
              style={{ marginBottom: '1rem' }}
              {...ordersTableCommonProps}
            />
            <OrdersTable
              stage={6}
              status="delivered"
              {...ordersTableCommonProps}
            />
          </>
        </TabPanel>
      </Grid>
      <DeclineDialog
        dialogType={dialogType}
        onSuccess={handleSubmit}
        setDialogType={setDialogType}
        order={payload.order}
      />
      <ConfirmOrderDialog
        dialogType={dialogType}
        onSuccess={handleSubmit}
        setDialogType={setDialogType}
        order={payload.order}
      />
      <ItemisedDialog
        dialogType={dialogType}
        onSuccess={handleSubmit}
        order={payload.order}
        setDialogType={setDialogType}
      />
      <OrderDetailsDialog
        dialogType={dialogType}
        order={payload.order}
        setDialogType={setDialogType}
        updateOrder={updateOrder}
        showOrderDetails={showOrderDetails}
        setShowOrderDetails={setShowOrderDetails}
      />
    </>
  );
};

export default Orders;
//  declined;
//  newOrder;
//  itemized;
//  accepted;
//  inProgress;
//  readyForDelivery;
//  delivered;
//  cancel;
