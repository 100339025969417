import * as routes from './largeConstants/routes.json';
import { counties } from './largeConstants/counties.json';
import { countries } from './largeConstants/countries.json';

const num = 'zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen'.split(
  ' '
);

const tens = 'twenty thirty forty fifty sixty seventy eighty ninety'.split(' ');

const maxInputDateForDOB = `${new Date().getFullYear() - 18}-${
  new Date().getMonth() < 10
    ? `0${new Date().getMonth()}`
    : new Date().getMonth()
}-${
  new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()
}`;

const reCaptchaSiteKeys = {
  dev: '6LeLEIYgAAAAALOVD12b73LusUxlmL4Aw10N4g44',
  prod: '6LcRgakhAAAAANISDHTPguzDXzIFE9IejgoQ_cJK'
};

const webLinkRegex = /^((http(s?)?):\/\/)?([w|W]{3}\.)?[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?(\/[a-zA-Z0-9\/\-]{2,})?$/;

const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

const regexPhoneNumber = /\s*(([+](\s?\d)([-\s]?\d)|0)?(\s?\d)([-\s]?\d){9}|[(](\s?\d)([-\s]?\d)+\s*[)]([-\s]?\d)+)\s*/i;

const googleFormLinks = {
  joinUs: 'https://forms.gle/WhZFWPCEPcH81CR29',
  businessWithUs: 'https://forms.gle/NzXrAnNmF7W9NaBZ6'
};

const shopFormFields = [
  {
    helper: 'Please specify the business name',
    label: 'Business Name',
    property: 'name'
  },
  {
    helper: 'Please specify the business contact name',
    label: 'Business Contact Name',
    property: 'contactName'
  },
  {
    helper: '',
    label: 'Business Contact Number',
    property: 'phoneNumber'
  },
  {
    helper: 'Please specify the business address',
    label: 'Address',
    property: 'address'
  },
  {
    helper: '',
    label: 'Email',
    property: 'email'
  },
  {
    label: 'Postal Code',
    property: 'postcode'
  },
  {
    label: 'County',
    property: 'town',
    isSelect: true
  },
  {
    label: 'Country',
    property: 'country',
    isSelect: true
  }
];

const days = 'Sunday Monday Tuesday Wednesday Thursday Friday Saturday'
  .split(' ')
  .map((item, index) => ({ id: index, name: item }));

const socialSiteLinks = {
  facebook: 'https://www.facebook.com/profile.php?id=100089255919530',
  instagram: 'https://www.instagram.com/launder_itcouk/'
};

export {
  routes,
  maxInputDateForDOB,
  reCaptchaSiteKeys,
  counties,
  countries,
  num,
  tens,
  webLinkRegex,
  googleFormLinks,
  regexEmail,
  regexPhoneNumber,
  shopFormFields,
  days,
  socialSiteLinks
};
