import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import StarBorderOutlinedIcon from '@material-ui/icons/StarBorderOutlined';
import StarIcon from '@material-ui/icons/Star';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.yellow.main
    // border: '1px solid #EDA942'
  },
  sizeSmall: {
    fontSize: '.6rem'
  }
}));

const StyledRating = ({ isText, isSmallText, ...props }) => {
  const classes = useStyles();

  return (
    <Grid container alignItems="center" style={{ width: 'fit-content' }}>
      <Rating
        precision={props.readOnly ? 0.1 : 0.5}
        emptyIcon={
          <StarBorderOutlinedIcon
            classes={{ root: classes.root }}
            fontSize={props.size}
          />
        }
        icon={
          <StarIcon classes={{ root: classes.root }} fontSize={props.size} />
        }
        {...props}
        classes={{
          sizeSmall: classes.sizeSmall
        }}
      />
      {isSmallText && (
        <Typography
          style={{ marginLeft: '2px', verticalAlign: 'middle', marginTop: 4 }}>
          {props.value || 0}
        </Typography>
      )}
      {props.isText && (
        <Typography style={{ marginLeft: '5px' }}>
          {props.value || 0} out of {props.max || 5}
        </Typography>
      )}
    </Grid>
  );
};

StyledRating.propTypes = {
  name: PropTypes.string,
  isText: PropTypes.bool,
  isSmallText: PropTypes.bool,
  value: PropTypes.number,
  max: PropTypes.number,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func
};
StyledRating.defaultProps = {
  isText: true,
  isSmallText: false,
  readOnly: false,
  onChange: () => {},
  name: ''
};

export default StyledRating;
