import React, { useEffect, useState } from 'react';
import { Typography, Grid, Tabs, Tab } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { routes } from 'common/constants';
import { useOrders } from 'context/order/ordersContext';
import { Header, OrderCard } from './components';
import { NavigationBreadcrumbs } from 'components/organisms';
import { makeStyles } from '@material-ui/core/styles';
import { useAmplifyAuth } from 'context';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Pagination from '@material-ui/lab/Pagination';
import OrderCancelDialog from 'components/organisms/OrderCancelDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    padding: '0 30px',
    marginBottom: '100px',
    borderTop: '1px solid #0000001A',
    [theme.breakpoints.up('md')]: {
      padding: '0 5rem'
    }
  },
  tabSelected: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white !important'
  },
  tabRoot: {
    color: theme.palette.grey.secondary,
    textTransform: 'none',
    maxWidth: '100%'
  },
  tabsRoot: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.grey.secondary
  },
  ordersHeader: {
    width: '100%',
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    lineHeight: '0.1em',
    margin: '10px 0'
  },
  ordersHeaderText: {
    background: '#fff',
    paddingRight: '10px',
    fontWeight: '600'
  },
  pageContentBox: {
    paddingTop: '1.5rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      paddingLeft: '2.125rem'
    }
  }
}));

const a11yProps = (index) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`
});

const tabs = [
  {
    id: 0,
    name: 'Current Orders',
    icon: <EditOutlinedIcon fontSize="small" />,
    route: routes.customer.order
  },
  {
    id: 1,
    name: 'Order History',
    icon: <NotificationsNoneOutlinedIcon fontSize="small" />,
    route: routes.customer.orderHistory
  }
];

const itemsPerPage = 5;

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const { orders: allOrders, dispatch } = useOrders();
  const [pastOrders, setPastOrders] = useState([]);
  const [currOrders, setCurrOrders] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [currentTab, setCurrentTab] = useState(
    history.location.pathname.split('/').at(-1) === 'history' ? 1 : 0
  );
  const [selectedOrder, setSelectedOrder] = useState();
  const [orderCancelReason, setOrderCancelReason] = useState('');
  const orders = currentTab === 0 ? currOrders : pastOrders;
  const [page, setPage] = useState(1);
  const {
    state: { user }
  } = useAmplifyAuth();
  const noOfPages = orders && Math.ceil(orders.length / itemsPerPage);

  const handleTabSwitch = (e, newVal) => {
    setCurrentTab(newVal);
    setPage(1);
    history.replace(tabs.find((item) => item.id === newVal)?.route);
  };

  const paginationHandler = (event, value) => {
    setPage(value);
  };

  const orderCancelReasonHandler = (reason) => {
    if (reason !== '') {
      setOrderCancelReason(reason);
    }
  };

  useEffect(() => {
    if (user?.id || user?.sub) {
      dispatch({
        type: 'getOrdersByUserId',
        payload: {
          userId: user?.id || user?.sub
        }
      });
    }
  }, [user]);

  useEffect(() => {
    const _pastOrders = allOrders.filter(
      (o) =>
        o.status === 'delivered' ||
        o.status === 'declined' ||
        o.status === 'cancel'
    );
    const _currOrders = allOrders.filter(
      (o) =>
        !(
          o.status === 'delivered' ||
          o.status === 'declined' ||
          o.status === 'cancel'
        )
    );
    setCurrOrders(_currOrders);
    setPastOrders(_pastOrders);
    setShowConfirmDialog(false);
  }, [allOrders]);

  const handleCancelClick = (order) => () => {
    setSelectedOrder(order);
    setShowConfirmDialog(true);
  };

  const handleCancelConfirm = () => {
    dispatch({
      type: 'updateOrder',
      payload: {
        order: {
          ...selectedOrder,
          status: 'cancel',
          orderCancelOrDeclineReason: orderCancelReason
        },
        updateMessage: 'Cancelling order...'
      }
    });
  };

  return user ? (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <NavigationBreadcrumbs currentLink="Orders" />
        </Grid>
        <Grid item xs={12}>
          <Header content="My Orders" />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} md={2}>
            <Tabs
              classes={{ root: classes.tabsRoot }}
              orientation="vertical"
              value={currentTab}
              onChange={handleTabSwitch}
              aria-label="my-orders-vertical-tabs">
              {tabs.map((tab) => (
                <Tab
                  value={tab.id}
                  classes={{
                    root: classes.tabRoot,
                    selected: classes.tabSelected
                  }}
                  label={
                    <Grid container justify="space-between" alignItems="center">
                      <Grid
                        item
                        container
                        alignItems="center"
                        justify="space-between"
                        style={{ width: 'fit-content' }}>
                        {tab.icon}
                        &nbsp; &nbsp;
                        {tab.name}
                      </Grid>
                      <Grid
                        item
                        container
                        alignItems="center"
                        style={{ width: 'fit-content' }}>
                        <ChevronRightIcon fontSize="small" />
                      </Grid>
                    </Grid>
                  }
                  {...a11yProps(tab.id)}
                  key={`my-orders-vertical-tabs-items-${tab.id}`}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={10} className={classes.pageContentBox}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.ordersHeader}>
                <span className={classes.ordersHeaderText}>
                  {currentTab === 0 ? 'Current Orders' : 'Order History'}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: '18px' }}>
              {orders.length > 0 ? (
                orders
                  .sort((a, b) =>
                    !a.orderID
                      ? -1
                      : !b.orderID
                      ? 1
                      : b.orderID.split('-')[1] - a.orderID.split('-')[1]
                  )
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  .map((order, index) => (
                    <OrderCard
                      order={order}
                      index={index}
                      handleCancelClick={handleCancelClick}
                      showCancelButton={currentTab === 0}
                      key={`my-orders-order-item-${index}`}
                    />
                  ))
              ) : (
                <Grid container justify="center">
                  No orders available.
                </Grid>
              )}
              <Grid container item xs={12} justify="center">
                <Pagination
                  count={noOfPages}
                  page={page}
                  onChange={paginationHandler}
                  defaultPage={1}
                  color="secondary"
                  shape="rounded"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <OrderCancelDialog
        open={showConfirmDialog}
        close={setShowConfirmDialog}
        orderCancelReasonHandler={orderCancelReasonHandler}
        handleCancelConfirm={handleCancelConfirm}
      />
    </>
  ) : (
    <Grid container justify="center">
      <Header content="Please login to review your orders" />
    </Grid>
  );
};

export default Account;
