export const AD_PER_SLIDE = {
  md: {
    count: 4,
    indexArr: [0, 1, 2, 3]
  },
  sm: {
    count: 2,
    indexArr: [0, 1]
  },
  xs: {
    count: 1,
    indexArr: [0]
  }
};

export const tinySliderSettings = {
  lazyload: true,
  mouseDrag: true,
  autoplayButtonOutput: false,
  autoplay: true,
  speed: 300,
  autoplayTimeout: 4000,
  controls: false,
  center: true,
  nav: false,
  rewind: true
};

export const initialFilters = {
  services: [],
  distance: [10],
  shops: []
};
