import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Grid,
  MenuItem
} from '@material-ui/core';
import { useSnackbar } from 'notistack';

const AddProduct = ({
  open,
  handleClose,
  services,
  values,
  setValues,
  addProducts
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      Object.keys(values)
        .filter((key) => key !== 'description')
        .some((item) => !values[item])
    ) {
      enqueueSnackbar('All fields are mendatory', {
        autoHideDuration: 3000,
        variant: 'error'
      });
      return;
    }
    addProducts();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      aria-labelledby="add-product-dialog-title"
      aria-describedby="add-product-dialog-description">
      <DialogTitle id="add-product-dialog-title">Add Product</DialogTitle>
      <DialogContent>
        <Grid container item xs={12}>
          <TextField
            margin="dense"
            InputLabelProps={{ shrink: true }}
            select
            label="Select Service"
            fullWidth
            onChange={handleChange}
            name="service"
            value={values.service}
            variant="outlined">
            <MenuItem value={''}>
              <i>None</i>
            </MenuItem>
            {services.map((item, index) => (
              <MenuItem
                key={`add-product-form-service-item-${index}`}
                value={item}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid
          container
          item
          xs={12}
          style={{ marginTop: '1rem', marginBottom: '1rem' }}>
          <TextField
            margin="dense"
            InputLabelProps={{ shrink: true }}
            label="New Product Name"
            onChange={handleChange}
            fullWidth
            name="name"
            value={values.name}
            variant="outlined"
          />
        </Grid>
        <Grid container item xs={12}>
          <TextField
            margin="dense"
            label="Description"
            InputLabelProps={{ shrink: true }}
            fullWidth
            value={values.description}
            name="description"
            onChange={handleChange}
            multiline
            variant="outlined"
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify="flex-end">
          <Button
            onClick={handleClose}
            size="small"
            style={{ marginRight: '0.5rem' }}>
            Cancel
          </Button>
          <Button
            size="small"
            onClick={handleSubmit}
            color="primary"
            variant="contained">
            Save
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddProduct;
