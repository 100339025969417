import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import NotesIcon from '@material-ui/icons/Notes';
import { useServices } from '../serviceContext';
import { Image } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: 30
  },
  input: {
    width: '90%'
  },
  imgHolder: {
    width: 38,
    height: 38,
    textAlign: 'center',
    border: '1px solid #bbb',
    marginRight: 10
  },
  image: {
    maxHeight: 36,
    maxWidth: 36
  },
  editBtn: {
    display: 'none'
  },
  listItem: {
    '&:hover .itemEditBtn': {
      display: 'block'
    }
  }
}));

export default function CustomListItem({
  item = {},
  editable = false,
  setNewItem,
  saveItem
}) {
  const classes = useStyles();
  const {
    setEditData,
    setDeleting,
    setPhotoGalleryData,
    setSelectedImg
  } = useServices();
  let newName;

  const handleChange = (event) => {
    newName = event.target.value;
  };

  const onEditHandler = () => {
    setEditData({ ...item, moduleType: 'Item' });
    setSelectedImg(item?.image);
    setPhotoGalleryData({
      serviceId: item?.serviceID,
      serviceName: item?.name
    });
  };

  const onDeleteHandler = () => {
    setEditData({ ...item, moduleType: 'Item' });
    setDeleting(true);
  };

  if (editable) {
    return (
      <ListItem component="div">
        <ListItemIcon className={classes.listItemIcon}>
          <MoreVertIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText
          primary={
            <TextField
              variant="outlined"
              onChange={handleChange}
              margin="dense"
              className={classes.input}
            />
          }
        />
        <ListItemSecondaryAction>
          <IconButton onClick={() => newName && saveItem(newName)} edge="end">
            <CheckBoxIcon fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => {
              setNewItem(false);
            }}
            edge="end">
            <CloseIcon fontSize="small" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  return (
    <ListItem classes={{ container: classes.listItem }} component="div">
      <ListItemIcon className={classes.listItemIcon}>
        <MoreVertIcon fontSize="small" />
      </ListItemIcon>
      {item.image && (
        <div className={classes.imgHolder}>
          <Image docKey={item.image} className={classes.image} />
        </div>
      )}
      <ListItemText primary={item.name} />
      <ListItemSecondaryAction>
        <Grid container>
          <IconButton
            className={`${classes.editBtn} itemEditBtn`}
            edge="end"
            onClick={onEditHandler}>
            <NotesIcon fontSize="small" />
          </IconButton>
          <IconButton
            className={`${classes.editBtn} itemEditBtn`}
            edge="end"
            onClick={onDeleteHandler}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Grid>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
