import { useAmplifyAuth } from 'context';
import React, { useEffect, useState } from 'react';

const CartContext = React.createContext({});

function CartProvider(props) {
  const [cart, setCart] = useState([]);
  const {
    state: { user }
  } = useAmplifyAuth();

  useEffect(() => {
    const _cart = localStorage.getItem('cart');
    const parseCartData = JSON.parse(_cart);
    const { item, username } = parseCartData || {};
    if (user?.username) {
      if (user?.username !== username) {
        localStorage.removeItem('cart');
      }
    }
    if (item?.length > 0) {
      setCart(item);
    }
  }, [user]);
  return (
    <CartContext.Provider value={{ cart, setCart }}>
      {props.children}
    </CartContext.Provider>
  );
}

export { CartContext, CartProvider };
