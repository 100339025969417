import React, { useEffect } from 'react';
import LogoName from '../../components/organisms/LogoName';
import { useAmplifyAuth } from 'context';
import { I18n, Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { routes } from 'common/constants';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import '@aws-amplify/ui-react/styles.css';

I18n.putVocabulariesForLanguage('en', {
  'Sign In': 'Login', // Tab header
  'Sign in': 'LOGIN' // Button label
});

const staffAccessibleUrls = [
  routes.serviceProvider.orders,
  routes.serviceProvider.ordersNew,
  routes.serviceProvider.orderItemPricing,
  routes.serviceProvider.orderProcessing,
  routes.serviceProvider.orderDelivery,
  routes.serviceProvider.orderHistory,
  routes.serviceProvider.settings,
  routes.serviceProvider.settingsProfile,
  routes.serviceProvider.settingsPassword
];

const useStyles = makeStyles(() => ({
  root: {
    margin: '0 auto',
    padding: '2rem 0 3rem',
    minHeight: '32rem',
    gap: '2.5rem'
  }
}));

const AdminLogin = (props) => {
  const { onUserSignIn = () => {} } = props;
  const { search } = useLocation();
  let {
    state: { user }
  } = useAmplifyAuth();
  const classes = useStyles();
  const history = useHistory();
  const redirectURL = search.split('redirect=')[1] || '/home';

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(handleUserRedirect);
  }, [user]);

  const getRedirectURL = (state = {}) => {
    const {
      isAdmin = false,
      isLaundryAdmin = false,
      isSales = false,
      isLaundryStaff = false
    } = state;

    const goToDashboard =
      (isLaundryAdmin && !redirectURL.includes('service-provider/')) ||
      (isLaundryStaff &&
        !(
          redirectURL.includes('service-provider/') &&
          staffAccessibleUrls.includes(redirectURL)
        )) ||
      (isAdmin && !redirectURL.includes('admin/')) ||
      (isSales && !redirectURL.includes('sales/')) ||
      !(isAdmin || isLaundryAdmin || isSales || isLaundryStaff);

    return goToDashboard
      ? isAdmin
        ? routes.admin.dashboard
        : isSales
        ? routes.sales.dashboard
        : isLaundryAdmin
        ? routes.serviceProvider.shop
        : isLaundryStaff
        ? routes.serviceProvider.orders
        : '/home'
      : redirectURL;
  };

  const handleUserRedirect = (user) => {
    onUserSignIn(!!user?.attributes?.email_verified);

    const groups =
      user?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
    const state = {
      isAdmin: groups?.includes('admin'),
      isSales: groups?.includes('sales'),
      isLaundryAdmin: groups?.includes('laundryAdmin'),
      isLaundryStaff: groups?.includes('laundryStaff')
    };

    const url = getRedirectURL(state);
    history.replace(url);
  };

  const handleSignInResponse = (user) => {
    if (user?.signInUserSession) handleUserRedirect(user);
    return user;
  };

  return (
    <>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.root}>
        <Grid item xs={12}>
          <LogoName />
        </Grid>
        <Authenticator
          hideSignUp={true}
          socialProviders={[]}
          services={{
            handleSignIn: async (formData) =>
              Auth.signIn({
                ...formData,
                username: formData.username.trim().toLowerCase()
              }).then(handleSignInResponse),
            handleForgotPasswordSubmit: async ({ username, code, password }) =>
              Auth.forgotPasswordSubmit(username, code, password)
          }}
        />
      </Grid>
    </>
  );
};

export default AdminLogin;
