import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { API, graphqlOperation } from 'aws-amplify';
import ItemizeRow from './ItemizeRow';
import { Grid, Container, Typography, TextField } from '@material-ui/core';
import {
  showPrice,
  getOrderTotal,
  getCollectionAndDeliveryFeeText,
  getCollectionAndDeliveryFee,
  showPartialPaymentRecord,
  getOrderDiscountTotal
} from 'common/utilFunctions';
import { getPaymentByOrderId } from 'graphql/queries';
import PaymentStatusTag from 'components/molecules/PaymentStatusTag';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  column: {
    maxWidth: 100
  }
}));

const ItemizeTable = ({ value, order, setOrder }) => {
  const classes = useStyles();
  const items = value?.items?.items || [];
  const [prevTotal, setPrevTotal] = useState(0);
  const [partiallyPaidRecord, setPartiallyPaidRecord] = useState([]);

  useEffect(() => {
    let mounted = true;
    mounted && setPrevTotal(order?.total);
    mounted && getPartialPaymentsRecord(order?.id);

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let mounted = true;

    const minPriceForFree = order?.currentShopFees?.minPriceForFree || 0;
    let collectionAndDeliveryFee =
      order?.collection?.type === 'asap'
        ? order?.currentShopFees?.AsapDeliveryFee
        : 0;
    if (getOrderTotal(order) < minPriceForFree || !minPriceForFree) {
      if (!!order.collection.address) {
        collectionAndDeliveryFee += order?.currentShopFees?.standardDeliveryFee;
      }
      if (!!order.delivery.address) {
        collectionAndDeliveryFee += order?.currentShopFees?.standardDeliveryFee;
      }
    }

    mounted &&
      setOrder({
        ...order,
        collectionAndDeliveryFee,
        total: getOrderTotal(order) + collectionAndDeliveryFee
      });

    return () => {
      mounted = false;
    };
  }, [order.orderList]);

  const getPartialPaymentsRecord = async (id) => {
    if (id) {
      const response = await API.graphql(
        graphqlOperation(getPaymentByOrderId, {
          orderID: id
        })
      );
      const data = await response.data.getPaymentByOrderId.items;
      setPartiallyPaidRecord(
        data
          .map((el) => ({
            amount: el.amount,
            date: el.createdAt,
            lastChanged: el._lastChangedAt
          }))
          .sort((a, b) => a.lastChanged - b.lastChanged)
      );
    }
  };

  const handleNumberChange = (event) => {
    const replaceRegex = /[^0-9.]/g;
    event.target.value = event.target.value.replace(replaceRegex, '');
  };

  let localTotal = order?.total || 0;

  return (
    <>
      <Table className={classes.table} aria-label="itemize-items-table">
        <TableHead>
          <TableRow style={{ background: '#C2E7FFB2' }}>
            <TableCell padding="checkbox"></TableCell>
            <TableCell className={classes.column}>NAME</TableCell>
            <TableCell className={classes.column} align="center">
              ITEM DESCRIPTION
            </TableCell>
            <TableCell className={classes.column} align="center">
              QUANTITY
            </TableCell>
            <TableCell className={classes.column} align="center">
              UNITS
            </TableCell>
            <TableCell className={classes.column} align="center">
              PRICE
            </TableCell>
            <TableCell className={classes.column} align="center">
              DISCOUNT
            </TableCell>
            <TableCell className={classes.column} align="center">
              TOTAL
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((row, i) => (
            <ItemizeRow
              key={`product_${i}`}
              row={row}
              order={order}
              setOrder={setOrder}
            />
          ))}
          <TableRow style={{ background: '#C2E7FFB2' }}>
            <TableCell
              colSpan={8}
              align="right"
              style={{ paddingRight: '2rem' }}>
              SUB TOTAL &nbsp; &nbsp; {showPrice(getOrderTotal(order))}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <Grid container>
          <Grid container item xs={8} justify="center" alignItems="center">
            <PaymentStatusTag order={order} prevTotal={prevTotal} />
          </Grid>
          <Grid container item xs={4} direction="row" spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography>TAX</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleNumberChange}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                onChange={handleNumberChange}
              />
            </Grid>

            <Grid item xs={12} md={8}>
              <Typography>Sub Total</Typography>
            </Grid>
            <Grid item xs={12} md={4} align="right">
              {showPrice(getOrderTotal(order))}
            </Grid>

            {order.collection.type === 'asap' ? (
              <>
                <Grid item xs={12} md={8}>
                  <Typography>Asap Fee</Typography>
                </Grid>
                <Grid item xs={12} md={4} align="right">
                  {showPrice(order?.shop?.AsapDeliveryFee || 0)}
                </Grid>
              </>
            ) : null}

            {!!order?.collection?.address || !!order?.delivery?.address ? (
              <>
                <Grid item xs={12} md={8}>
                  <Typography>
                    {getCollectionAndDeliveryFeeText(order)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4} align="right">
                  {getCollectionAndDeliveryFee(order)}
                </Grid>
              </>
            ) : null}

            <Grid item xs={12} md={6}>
              <Typography>Overall Discount</Typography>
            </Grid>
            <Grid item xs={12} md={6} container justify="flex-end">
              {showPrice(getOrderDiscountTotal(order))}
            </Grid>

            <Grid
              container
              item
              justify="flex-end"
              style={{ backgroundColor: '#C2E7FFB2' }}>
              <Typography variant="h6">
                TOTAL &nbsp; &nbsp; {showPrice(order?.total)}
              </Typography>
            </Grid>
            {(order?.paymentStatus === 'partiallyPaid' ||
              (order?.paymentStatus === 'paid' &&
                order?.total > prevTotal)) && (
              <Grid container item justify="flex-end">
                <Typography variant="body1">
                  Balance &nbsp; &nbsp;
                  {showPrice(order?.total - (order?.paidAmount || 0))}
                </Typography>
              </Grid>
            )}
            {partiallyPaidRecord?.length > 0 ? (
              showPartialPaymentRecord(partiallyPaidRecord, localTotal) ? (
                <Grid container item xs={12} style={{ padding: 0 }}>
                  {partiallyPaidRecord?.length > 0 && (
                    <>
                      {partiallyPaidRecord.map((el, i) => {
                        localTotal = localTotal - el.amount;
                        return (
                          <Grid
                            container
                            item
                            xs={12}
                            justify="space-between"
                            key={`partial-payment-records-in-item-and-pricing-items-${i}`}>
                            <Typography>
                              Partial Cash Payment on :&nbsp;
                              {moment(el.date).format('MMM Do, YYYY')}
                              <br />
                              {localTotal <= 0
                                ? 'Total Amount Due:'
                                : 'Balance Due:'}
                            </Typography>
                            <Typography variant="body1" align="right">
                              {showPrice(el.amount)}
                              <br />
                              {showPrice(localTotal)}
                            </Typography>
                          </Grid>
                        );
                      })}
                    </>
                  )}
                </Grid>
              ) : (
                <Grid container item xs={12} style={{ padding: 0 }}>
                  <>
                    <Grid
                      container
                      item
                      xs={12}
                      justify="space-between"
                      key={`partial-payment-records-in-item-and-pricing-items`}>
                      <Typography>
                        Cash Payment on :&nbsp;
                        {moment(partiallyPaidRecord[0]?.date).format(
                          'MMM Do, YYYY'
                        )}
                      </Typography>
                      <Typography variant="body1" align="right">
                        {showPrice(partiallyPaidRecord[0]?.amount)}
                      </Typography>
                    </Grid>
                  </>
                </Grid>
              )
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ItemizeTable;
