import React, { useState, useEffect, useContext } from 'react';
import {
  Menu,
  Box,
  IconButton,
  Grid,
  Typography,
  Divider,
  Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { getShop } from 'graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { Link as RouterLink } from 'react-router-dom';
import { CartContext } from 'context/cart/cartContext';
import QuantitySelect from './QuantitySelect';
import { useAmplifyAuth } from 'context';

const useStyles = makeStyles((theme) => ({
  box: {
    width: 'auto',
    padding: '0 25px 10px'
  },
  menuItemMainDiv: {
    marginTop: 17
  },
  cartMainHeadingText: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Helvetica',
    fontStyle: 'regular'
  },
  cartSubHeadingText: {
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Helvetica',
    fontStyle: 'regular'
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  div1: {
    marginTop: 17,
    marginLeft: '-15px',
    paddingRight: 0,
    paddingLeft: 20,
    display: 'flex',
    justifyContent: 'space-between'
  },
  divider: {
    marginTop: 17
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'flex-end',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.black,
    marginTop: 17,
    marginLeft: 5,
    marginBottom: 10,
    width: 350,
    height: 50
  },
  itemLister: {
    width: 350,
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '300px'
  },
  numberOfItemBox: {
    width: 50,
    height: 25,
    textAlign: 'center',
    borderColor: theme.palette.white,
    '& p': {
      color: theme.palette.white
    }
  },
  cartQuantityName: {
    maxWidth: '39%',
    flexBasis: '39%',
    fontSize: '12px'
  },
  cartQuantityBox: {
    display: 'flex',
    width: '45px',
    height: 19,
    justifyItems: 'center',
    alignItems: 'center',
    textAlign: 'right',
    justifyContent: 'center',
    marginLeft: '10px',
    fontSize: '11px'
  }
}));

export default function CartMenu(props) {
  const [cartShop, setCartShop] = useState({ shopID: '', name: '' });
  const { anchorEl, setAnchorEl, cartItems } = props;
  const {
    state: { user }
  } = useAmplifyAuth();
  const classes = useStyles();
  const cartItem = cartItems.reduce((r, a) => {
    r[a.service.name] = [...(r[a.service.name] || []), a];
    return r;
  }, Object.create(null));
  const total = cartItems.reduce((r, o) => o.price * o.quantity + r, 0);
  const { cart, setCart } = useContext(CartContext);

  useEffect(() => {
    cart.length > 0 &&
      localStorage.setItem(
        'cart',
        JSON.stringify({ username: user?.username || '', item: cart })
      );
    cart.length > 0 &&
      API.graphql(graphqlOperation(getShop, { id: cart[0].shopID }))
        .then(({ data }) => {
          setCartShop({ shopID: data.getShop.shopID, name: data.getShop.name });
        })
        .catch((err) => console.log('error', err));
  }, []);

  const handleCartClose = () => {
    setAnchorEl(null);
  };

  const MenuItemList = (props) => {
    const updateCart = (item, q) => {
      let updated = false;
      const arr = cart.map((cartItem) => {
        const { quantity = 0, id } = cartItem;
        if (id === item.id) {
          const updatedQuantity = q;
          updated = true;
          if (updatedQuantity >= 0) {
            return { ...cartItem, quantity: updatedQuantity };
          } else if (q === 1) {
            return { ...cartItem, quantity: 1 };
          }
        }
        return cartItem;
      });
      if (!updated) {
        arr.push({ ...item, quantity: q });
      }
      const cartObj = arr.filter((o) => o.quantity > 0);
      cartObj.length === 0 && setAnchorEl(null);
      localStorage.setItem(
        'cart',
        JSON.stringify({ username: user?.username || '', item: cartObj })
      );
      setCart(cartObj);
    };

    return (
      <div className={classes.menuItemMainDiv}>
        <Typography className={classes.cartMainHeadingText}>
          {props.AddedCartItem}
        </Typography>
        {cartItem[props.AddedCartItem].map((item, i) => {
          return (
            <Grid className={classes.div1} container key={i} spacing={2}>
              <Grid className={classes.cartQuantityName} item xs={3}>
                <Typography className={classes.cartSubHeadingText} variant="h6">
                  {item.name || item.item.name}
                </Typography>
              </Grid>
              <Grid item style={{ display: 'flex', maxWidth: '43%' }} xs={7}>
                <Grid item xs={5}>
                  <Typography style={{ fontSize: '12px' }} variant="h6">
                    £ {item.price.toFixed(2)} x{' '}
                  </Typography>
                </Grid>
                <Grid item style={{ marginTop: '-10px' }} xs={7}>
                  {/* <Box className={classes.cartQuantityBox}> */}
                  <QuantitySelect item={item} updateCart={updateCart} />
                  {/* <IconButton style={{ padding: '0px' }}>
                    <ArrowDropDownIcon fontSize="small" />
                  </IconButton> */}
                  {/* </Box> */}
                </Grid>
              </Grid>
              <Grid
                item
                style={{ padding: '0px', marginRight: '4px', marginTop: '3px' }}
                xs={2}>
                <Typography
                  style={{ fontSize: '12px', marginTop: '5px' }}
                  variant="h6">
                  £ {`${Number(item.price * item.quantity).toFixed(2)}`}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
        <Divider className={classes.divider} />
      </div>
    );
  };

  return (
    <div>
      <Menu
        anchorEl={anchorEl}
        elevation={1}
        getContentAnchorEl={null}
        id="simple-menu"
        keepMounted
        onClose={handleCartClose}
        open={Boolean(anchorEl)}>
        <div className={classes.header}>
          <IconButton onClick={handleCartClose}>
            <CloseIcon
              size="large"
              style={{ width: '20px', height: '18px', color: 'black' }}
            />
          </IconButton>
        </div>
        <Box className={classes.box}>
          <div>
            <Typography variant="h4">Your Order</Typography>
            <div className={classes.itemLister}>
              {Object.keys(cartItem).map((item, i) => {
                return <MenuItemList AddedCartItem={item} key={i} />;
              })}
            </div>
          </div>
          <div className={classes.footer}>
            <div>
              <Box border={2} className={classes.numberOfItemBox}>
                <Typography
                  variant="h6"
                  style={{ color: '#fff', marginTop: 3 }}>
                  {cart.reduce((r, o) => o.quantity + r, 0)}
                </Typography>
              </Box>
            </div>
            <div>
              <RouterLink
                to={`/shop/${
                  cartShop.shopID
                }/${cartShop.name
                  .toLowerCase()
                  .split(' ')
                  .join('-')}/checkout`}>
                <Button style={{ color: 'white' }}>Checkout</Button>
              </RouterLink>
            </div>
            <div>
              <Typography style={{ color: 'white' }}>
                £ {total.toFixed(2)}
              </Typography>
            </div>
          </div>
        </Box>
      </Menu>
    </div>
  );
}
