import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/core/styles';
import validate from 'validate.js';
import { chartjs } from './helpers';
import adminTheme from './theme/AdminTheme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import { AmplifyTheme } from 'aws-amplify-react';
import { ServicesProvider } from './views/Services/serviceContext';
import { ShopsProvider } from './context/shop/shopsContext';
import { RedirectsProvider } from './context/redirect/redirectsContext';
import { ProductsProvider } from './views/Products/productsContext';
import { LoaderProvider } from './layouts/loaderContext';
import { OrdersProvider } from './context/order/ordersContext';
import { SearchBannerProvider } from 'context/searchBanner/searchBannerContext';
import { CouponsProvider } from 'context/coupons/couponsContext';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';
import { AmplifyAuthProvider } from 'context';
import { CartProvider } from 'context/cart/cartContext';
import { SelectContextProvider } from 'store';
import { CustomerContextProvider } from 'context/customers/customersContext';

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const useStyles = makeStyles(() => ({
  root: { fontSize: '4rem  !important' },
  success: { backgroundColor: 'purple', fontSize: '1rem  !important' },
  error: { backgroundColor: 'blue', fontSize: '1rem  !important' },
  info: { fontSize: '1rem  !important', fontSize: '1rem  !important' },
  warning: { color: '#000 !important', fontSize: '1rem  !important' }
}));

const App = () => {
  const [authState, setAuthState] = useState({
    isLoggedIn: false,
    isEmailVerified: false
  });
  const handleUserSignIn = (isEmailVerified = false) => {
    setAuthState({ isLoggedIn: true, isEmailVerified });
  };
  const childProps = {
    isLoggedIn: authState.isLoggedIn,
    isEmailVerified: authState.isEmailVerified,
    onUserSignIn: handleUserSignIn
  };
  const classes = useStyles();

  return (
    <ThemeProvider theme={adminTheme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        classes={{
          root: classes.root,
          variantSuccess: classes.success,
          variantError: classes.error,
          variantWarning: classes.warning,
          variantInfo: classes.info
        }}
        maxSnack={5}
        TransitionComponent={Slide}>
        <LoaderProvider>
          <AmplifyAuthProvider>
            <ServicesProvider>
              <OrdersProvider>
                <ShopsProvider>
                  <ProductsProvider>
                    <SelectContextProvider>
                      <CartProvider>
                        <RedirectsProvider>
                          <CouponsProvider>
                            <CustomerContextProvider>
                              <SearchBannerProvider>
                                <Router history={browserHistory}>
                                  <Routes childProps={childProps} />
                                </Router>
                              </SearchBannerProvider>
                            </CustomerContextProvider>
                          </CouponsProvider>
                        </RedirectsProvider>
                      </CartProvider>
                    </SelectContextProvider>
                  </ProductsProvider>
                </ShopsProvider>
              </OrdersProvider>
            </ServicesProvider>
          </AmplifyAuthProvider>
        </LoaderProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

const aTheme = {
  ...AmplifyTheme,
  navBar: {
    ...AmplifyTheme.navBar,
    backgroundColor: '#ffc0cb'
  },
  button: {
    ...AmplifyTheme.button
  },
  sectionBody: {
    ...AmplifyTheme.sectionBody,
    padding: '5px'
  },
  sectionHeader: {
    ...AmplifyTheme.sectionHeader
  },
  sectionFooterSecondaryContent: {
    display: 'none'
  }
};

export default App;
