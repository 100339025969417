import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Tabs, Tab, Checkbox } from '@material-ui/core';
import { useServices } from 'views/Services/serviceContext';
import { useProducts } from 'views/Products/productsContext';
import { useAmplifyAuth } from 'context';
import { useOrders } from 'context/order/ordersContext';
import ItemizeTable from './ItemizeTable';
import ItemizedOrderTable from './ItemizedOrderTable';

const useStyles = makeStyles((theme) => ({
  root: {},
  autoComplete: {
    margin: theme.spacing(1),
    minWidth: '80%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  footer: {
    flexGrow: 1,
    padding: 10
  },
  tabs: {
    border: '1px solid #DDDDDD',
    borderBottom: 'none',
    marginRight: 5
  },
  wrapper: {
    display: 'table'
  },
  checkbox: {
    paddingLeft: 0
  }
}));

const ItemizeProduct = ({ order, setOrder }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { services, dispatch: dispatchService } = useServices();
  const { orders } = useOrders();
  const { selectedShop, dispatch } = useProducts();
  let {
    state: { user }
  } = useAmplifyAuth();
  const [serviceWithProduct, setServiceWithProduct] = useState([]);

  useEffect(() => {
    dispatchService({ type: 'getService' });
  }, [dispatchService]);

  useEffect(() => {
    if (user && user.shopId) {
      dispatch({ type: 'getProductsByShopId', payload: { id: user.shopId } });
    }
  }, [user]);

  useEffect(() => {
    const fff = async () => {
      if (services.length > 0 && orders.length > 0) {
        const _serviceWithProduct = await dispatch({
          type: 'getProductsWithService'
        });
        setServiceWithProduct(_serviceWithProduct);
      }
    };
    fff();
  }, [services, orders]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const selectAllItems = (event, id) => {
    const currentService = serviceWithProduct.find((item) => item.id === id);
    const newOrder = event.target.checked
      ? {
          ...order,
          orderList: [
            ...order.orderList,
            ...currentService.items.items
              .filter(
                (serviceItem) =>
                  !order.orderList.some(
                    (item) => item.productID === serviceItem.productID
                  )
              )
              .map((serviceItem) => ({
                productID: serviceItem.productID,
                description: null,
                discount: null,
                name: serviceItem.name,
                price: serviceItem.product.price,
                quantity: 1,
                unit: serviceItem.product.unit
              }))
          ]
        }
      : {
          ...order,
          orderList: [
            ...order.orderList.filter(
              (item) =>
                !currentService.items.items.some(
                  (serviceItem) => serviceItem.productID === item.productID
                )
            )
          ]
        };
    setOrder(newOrder);
  };

  return (
    <Paper>
      {!!selectedShop && (
        <>
          <ItemizedOrderTable order={order} />
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            style={{ marginTop: '1rem' }}>
            {serviceWithProduct &&
              serviceWithProduct.map(
                ({ id, name, items: { items = [] } = { items: [] } }, key) => {
                  const newCheckAllItem = items.some((item) =>
                    order?.orderList?.some(
                      (o) => o.productID === item.productID
                    )
                  );
                  return (
                    <Tab
                      key={`order-items-pricings-tabs-${key}`}
                      value={key}
                      classes={{
                        root: classes.tabs,
                        wrapper: classes.wrapper
                      }}
                      label={
                        <>
                          <Checkbox
                            checked={newCheckAllItem}
                            disabled={value !== key}
                            inputProps={{
                              'aria-label':
                                'order-item-dialog-product-list-select-all-items'
                            }}
                            onChange={(e) => selectAllItems(e, id)}
                            className={classes.checkbox}
                          />
                          <span>{name}</span>
                        </>
                      }
                    />
                  );
                }
              )}
          </Tabs>
          <ItemizeTable
            value={serviceWithProduct[value]}
            order={order}
            setOrder={setOrder}
          />
        </>
      )}
    </Paper>
  );
};

export default ItemizeProduct;
