import React, { useState, useEffect } from 'react';
import { Image } from 'components/organisms';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    padding: '1rem 1.5rem'
  },
  content: {
    gap: '0.75rem'
  },
  colorInputWrapper: {
    padding: '0.5rem',
    gap: '1rem',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end'
    }
  }
}));

const initialBanner = {
  photoURL: '',
  file: null,
  title: '',
  color: '#fff'
};

const SearchBannerModal = ({
  open = false,
  banner = { photoURL: '', title: '', color: '#fff' },
  handleClose = () => {},
  handleSubmit = () => {}
}) => {
  const classes = useStyles();
  const [currentBanner, setCurrentBanner] = useState(initialBanner);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (banner?.photoURL) {
      setCurrentBanner(banner);
    } else {
      setCurrentBanner(initialBanner);
    }
  }, [banner]);

  const handleChange = (e) => {
    setDisabled(false);
    setCurrentBanner({ ...currentBanner, [e.target.name]: e.target.value });
  };

  const handleImageChange = (e) => {
    const _file = e.target.files[0];
    if (_file) {
      setDisabled(false);
      const reader = new FileReader();
      reader.onload = () =>
        setCurrentBanner({
          ...currentBanner,
          file: _file,
          photoURL: reader.result
        });
      reader.readAsDataURL(_file);
    }
  };

  const handleSubmitClick = () => handleSubmit(currentBanner);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      disableEscapeKeyDown
      aria-labelledby="search-banner-form-dialog-title">
      <DialogTitle
        id="search-banner-form-dialog-title"
        className={classes.title}>
        Add / Edit Search Banner
      </DialogTitle>
      <DialogContent>
        <Grid container className={classes.content}>
          <Grid container>
            <Grid item xs={12} md={9} style={{ padding: '0.5rem' }}>
              <TextField
                variant="outlined"
                label="Enter Title"
                size="small"
                InputLabelProps={{ shrink: true }}
                name="title"
                fullWidth
                value={currentBanner.title}
                onChange={handleChange}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={3}
              container
              justify="flex-end"
              alignItems="center"
              className={classes.colorInputWrapper}>
              <label htmlFor="banner-modal-color-input">Font Color:</label>
              <input
                id="banner-modal-color-input"
                name="color"
                value={currentBanner.color}
                onChange={handleChange}
                type="color"
              />
            </Grid>
          </Grid>
          <Grid container justify="center">
            <input
              accept="image/*"
              id="banner-modal-photo-input"
              name="photoURL"
              onChange={handleImageChange}
              type="file"
              hidden
            />
            <label htmlFor="banner-modal-photo-input">
              <Button
                color="primary"
                variant="contained"
                size="small"
                component="span">
                Upload Image
              </Button>
            </label>
          </Grid>
          <Grid container justify="center">
            <Image
              docKey={currentBanner.photoURL}
              loadFromStorage={
                currentBanner.photoURL?.split('/').at(0) === 'images'
              }
              width="100%"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSubmitClick}
          color="primary"
          variant="contained"
          disabled={disabled}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SearchBannerModal;
