import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import WrapWithLayout from './WrapWithLayout';

const ProtectedRoute = ({
  render: Comp,
  props: childProps,
  layout,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(rProps) =>
        childProps.isLoggedIn ? (
          childProps.isEmailVerified ? (
            WrapWithLayout(Comp, layout, { ...rProps, ...childProps })
          ) : (
            <Redirect
              to={`/verify-email?redirect=${rProps.location.pathname}${rProps.location.search}`}
            />
          )
        ) : (
          <Redirect
            to={`/auth?redirect=${rProps.location.pathname}${rProps.location.search}`}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
