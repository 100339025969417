import React, { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { routes } from 'common/constants';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { useAmplifyAuth } from 'context';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#EDD242',
    padding: '2px 8px',
    textTransform: 'capitalize',
    margin: '0 0.5rem',
    color: theme.palette.text.navyLight,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: '#EDD242'
    },
    [theme.breakpoints.down('md')]: {
      padding: '2px 5px',
      margin: '0'
    }
  },
  menu: {
    margin: '1.3rem 0 0 0',
    textAlign: 'center'
  }
}));

const SignInButton = () => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  let {
    state: { user },
    handleSignOut
  } = useAmplifyAuth();

  const handleClick = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorMenuEl(null);
  };

  return (
    <div>
      {user?.email ? (
        <>
          <Button
            variant="contained"
            onClick={handleClick}
            size="small"
            className={classes.button}
            endIcon={<ExpandMoreRoundedIcon />}>
            {user.firstName
              ? user.email === user.firstName
                ? user.email
                : `${user.firstName} ${user.lastName || ''}`
              : 'guest'}
          </Button>
          <Menu
            anchorEl={anchorMenuEl}
            onClick={handleClose}
            onClose={handleClose}
            open={!!anchorMenuEl}
            className={classes.menu}>
            <MenuItem>
              <RouterLink to={routes.customer.profile}>My Profile</RouterLink>
            </MenuItem>
            <MenuItem>
              <RouterLink to={routes.customer.order}>My Orders</RouterLink>
            </MenuItem>
            {/* chat tab for future use: */}
            {/* <MenuItem>
              <RouterLink to={routes.customer.chat}>Chat</RouterLink>
            </MenuItem> */}
            <MenuItem onClick={handleSignOut}>Logout</MenuItem>
          </Menu>
        </>
      ) : (
        <Button
          size="small"
          variant="contained"
          className={classes.button}
          href={`${routes.customer.login}?redirect=${history.location.pathname}${history.location.search}`}>
          Create an account / Login
        </Button>
      )}
    </div>
  );
};

SignInButton.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default SignInButton;
