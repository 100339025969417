import React, { useState, useEffect } from 'react';
import { Popover } from 'components/atoms';
import { Image } from 'components/organisms';
import {
  Grid,
  Chip,
  Typography,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import { DocumentDeleteDialog } from 'components/organisms';
import { makeStyles } from '@material-ui/core/styles';
import ViewDialog from './ViewDialog';
import { useAmplifyAuth } from 'context';
import { useServices } from 'views/Services/serviceContext';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0'
  },
  content: {
    padding: '1rem'
  },
  statusChip: {
    backgroundColor: theme.palette.warning.main,
    textTransform: 'capitalize',
    color: theme.palette.white,
    borderRadius: '0 0.75rem 0.75rem 0'
  },
  approved: {
    backgroundColor: theme.palette.success.main
  },
  pending: {
    backgroundColor: theme.palette.warning.main
  },
  rejected: {
    backgroundColor: theme.palette.error.main
  },
  image: {
    height: '10rem',
    width: '14rem',
    color: theme.palette.primary.main,
    boxShadow:
      'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
  },
  iconButton: {
    padding: '0.5rem 0.25rem 0.5rem 0',
    marginLeft: '-1.5rem'
  }
}));

const ShopPhotosAndVideos = (props) => {
  const {
    heading,
    data,
    handleDelete,
    handleUpdate,
    handleDefaultPhotoUpdate = () => {},
    primaryPhoto = {}
  } = props;
  const [showViewDialog, setShowViewDialog] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const { services } = useServices();
  const classes = useStyles();
  const {
    state: { isAdmin = false }
  } = useAmplifyAuth();

  const handleDeleteClick = () => setShowDeleteConfirmDialog(true);

  const handleUpdateClick = (status) => () => {
    if (currentItem?.MIMETypes?.includes('image')) {
      handleUpdate(currentItem.photoURL, status, 'images');
    } else {
      handleUpdate(currentItem.videoURL, status, 'videos');
    }
    handleMenuClose();
  };

  const handleViewOpen = () => setShowViewDialog(true);

  const handleModalClose = () => {
    setShowDeleteConfirmDialog(false);
    setShowViewDialog(false);
  };

  const handleModalConfirm = () => {
    if (currentItem.MIMETypes.includes('image')) {
      handleDelete(currentItem.photoURL, currentItem.MIMETypes);
    } else {
      handleDelete(currentItem.videoURL, currentItem.MIMETypes);
    }
    handleModalClose();
    handleMenuClose();
  };

  const handleSelectPhotoAsDefaultClick = (category = 'primaryPhoto') => () =>
    handleDefaultPhotoUpdate(currentItem, category);

  const handleMenuOpen = (item) => (e) => {
    setCurrentItem(item);
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => setMenuAnchor(null);

  const handlePopoverOpen = (item) => (e) => {
    setCurrentItem(item);
    setPopoverAnchor(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setCurrentItem(null);
    setPopoverAnchor(null);
  };

  return (
    <>
      <Grid container>
        <h4>{heading}</h4>
        <Grid container item xs={12} className={classes.root}>
          {data.length > 0 ? (
            data.map((el, i) => (
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                justify="center"
                xs={12}
                sm={6}
                md={4}
                key={`shop-photos-${i}`}
                className={classes.content}>
                <Grid
                  container
                  justify="center"
                  onMouseEnter={handlePopoverOpen(el)}
                  onMouseLeave={handlePopoverClose}>
                  <Image
                    docKey={
                      heading === 'Shop Photos' ? el.photoURL : el.videoURL
                    }
                    mimeType={el.MIMETypes}
                    className={classes.image}
                  />
                </Grid>
                <Grid container justify="center" alignItems="center">
                  <IconButton
                    aria-haspopup="true"
                    onClick={handleMenuOpen(el)}
                    className={classes.iconButton}>
                    <MoreVertIcon />
                  </IconButton>
                  <Chip
                    size="small"
                    label={el.status || 'Pending'}
                    className={clsx(
                      classes.statusChip,
                      classes[el.status || 'pending']
                    )}
                  />
                </Grid>
              </Grid>
            ))
          ) : (
            <Typography align="center">No {heading} Available</Typography>
          )}
        </Grid>
      </Grid>
      <Menu
        anchorEl={menuAnchor}
        keepMounted
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}>
        {currentItem?.MIMETypes?.includes('image') ? (
          <MenuItem onClick={handleViewOpen}>View</MenuItem>
        ) : null}
        {isAdmin ? (
          [
            <MenuItem
              key="approve-btn-menuitem"
              disabled={currentItem?.status === 'approved'}
              onClick={handleUpdateClick('approved')}>
              Approve
            </MenuItem>,
            <MenuItem
              key="reject-btn-menuitem"
              disabled={currentItem?.status === 'rejected'}
              onClick={handleUpdateClick('rejected')}>
              Reject
            </MenuItem>
          ]
        ) : currentItem?.status === 'approved' ? (
          <MenuItem
            disabled={primaryPhoto?.photoURL === currentItem?.photoURL}
            onClick={handleSelectPhotoAsDefaultClick('primaryPhoto')}>
            Set as Default Photo (16:9)
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
      </Menu>
      <Popover
        anchorEl={popoverAnchor}
        handlePopoverClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}>
        <Typography variant="h6">{currentItem?.name}</Typography>
        {services.some((item) => item.id === currentItem?.serviceID) ? (
          <Typography>
            service:{' '}
            {services.find((item) => item.id === currentItem?.serviceID)?.name}
          </Typography>
        ) : null}
      </Popover>
      <ViewDialog
        open={showViewDialog}
        handleCancel={handleModalClose}
        docKey={currentItem?.photoURL || ''}
      />
      <DocumentDeleteDialog
        open={showDeleteConfirmDialog}
        confirmText="delete this file"
        handleCancel={handleModalClose}
        handleConfirm={handleModalConfirm}
      />
    </>
  );
};

export default ShopPhotosAndVideos;
