import React from 'react';
import { Link } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { Template1, Template2, Template5, Template6 } from './components';
import userFlowTheme from 'theme/HomeTheme';
import { useAdTemplateStyles } from './templateStyles';

const templates = {
  tem1: Template1,
  tem2: Template2,
  tem5: Template5,
  tem6: Template6
  // need to configure properly later
  // tem3: Template3,
  // tem4: Template4,
};

const AdTemplates = ({
  ad = {
    shop: {},
    template: 'tem6',
    page: 'home',
    redirectLink: '',
    content: ''
  },
  showAsButton = false,
  ...rest
}) => {
  const classes = useAdTemplateStyles();
  let Component = templates[ad.template];
  const linkAllyProps = !!ad.redirectLink
    ? {
        href: ad.redirectLink,
        target: '_blank',
        rel: 'noreferrer'
      }
    : !!ad.shop?.shopID
    ? {
        href: `/shop/${
          ad.shop?.shopID || ''
        }/${ad.shop?.name?.toLowerCase()?.split(' ')?.join('-')}`,
        target: '_self'
      }
    : {
        href: '#'
      };
  const adProps = {
    content: JSON.parse(ad.content || ''),
    shopName: ad.shop?.name || '',
    ...rest
  };

  return Component ? (
    <ThemeProvider theme={userFlowTheme}>
      {showAsButton ? (
        <Link className={classes.templateButton} {...linkAllyProps}>
          <Component {...adProps} />
        </Link>
      ) : (
        <Component {...adProps} />
      )}
    </ThemeProvider>
  ) : null;
};

export default AdTemplates;
