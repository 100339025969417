import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { googleFormLinks } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  root: {},
  linksBox: {
    backgroundColor: 'rgb(59 171 189/0.3)',
    padding: '0.5rem 0.75rem',
    margin: '0'
  },
  linkFont: {
    letterSpacing: '0.01em',
    fontWeight: 600,
    cursor: 'pointer',
    padding: '0 0.5rem',
    color: theme.palette.yellow?.main || '#EDD242'
  }
}));

const JoinLinks = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body2" className={classes.linksBox}>
        <Link
          className={classes.linkFont}
          href={googleFormLinks.businessWithUs}
          target="_blank"
          rel="noreferrer">
          Business With Us
        </Link>
      </Typography>
    </>
  );
};

export default JoinLinks;
