import React, { useEffect } from 'react';
import { Typography, Grid, IconButton, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { JoinLinks } from 'components/organisms';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSearchBanner } from 'context/searchBanner/searchBannerContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import throttle from 'lodash/throttle';
import parse from 'autosuggest-highlight/parse';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';

function loadScript(src, position, id) {
  if (!position) return;
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    alignItems: 'center',
    textAlign: 'center',
    color: 'black',
    padding: '0 1.5rem',
    [theme.breakpoints.up('md')]: {
      height: '581px',
      padding: '0 15.5rem'
    }
  },
  rootSize: {
    height: '454px',
    [theme.breakpoints.up('md')]: {
      height: '581px'
    }
  },
  searchButton: {
    height: '51.25px',
    width: '49px',
    padding: '0',
    backgroundColor: theme.palette.yellow?.main || '#EDD242',
    color: 'white',
    borderRadius: '0 0.25rem 0.25rem 0',
    '&:hover': {
      backgroundColor: '#FFDF42'
    },
    [theme.breakpoints.up('md')]: {
      width: '54px'
    }
  },
  offer: {
    backgroundColor: '#1f6893'
  },
  heading: {
    marginBottom: '0.5rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '65px',
      fontWeight: 'bold',
      lineHeight: '69px'
    }
  },
  textInputWrapper: {
    backgroundColor: '#fff',
    borderColor: 'transparent',
    borderRadius: '0.25rem 0 0 0.25rem'
  },
  autoCompleteRoot: {
    width: 'calc(100% - 49px)',
    '& fieldset': {
      border: 'none !important'
    },
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 54px)'
    }
  },
  autoCompletePaper: {
    margin: '0',
    opacity: '0.98'
  }
}));

export default function Search() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  let history = useHistory();
  const classes = useStyles();
  const navigateTo = (value) => history.push(`/shop/postcode/${value}`);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const { banner, dispatch } = useSearchBanner();
  const loaded = React.useRef(false);

  useEffect(() => {
    dispatch({ type: 'getBanner' });
  }, []);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyCZRcF1TsHJKaYYeUkJ4BXhLTNyzs63Ges&libraries=places',
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  const onClickHandler = () => {
    if (value) {
      localStorage.setItem('recentPostCode', value);
      navigateTo(value);
    }
  };

  const enterSearchHandler = (e) => {
    if (e.key === 'Enter' && value) {
      localStorage.setItem('recentPostCode', value);
      navigateTo(value);
    }
  };

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current) {
      if (window.google?.maps?.places) {
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
      } else {
        return undefined;
      }
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: { country: 'GB' }
      },
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return banner?.image ? (
    <>
      <Grid
        container
        className={clsx(classes.root, classes.rootSize)}
        style={{ backgroundImage: `url(${banner.image})` }}
        justify="center">
        <Grid container justify="center" item xs={12} md={8}>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              className={classes.heading}
              align="left"
              style={{ color: banner.color || '#fff' }}>
              {banner.title || ''}
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Autocomplete
              autoComplete
              autoSelect
              disableClearable
              forcePopupIcon={false}
              filterOptions={(x) => x}
              filterSelectedOptions
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
              }
              includeInputInList
              onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);

                if (newValue?.terms?.length > 0) {
                  const len = newValue.terms.length;
                  const val = newValue.terms[len - 2]?.value;
                  setValue(val);
                }
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  margin="none"
                  placeholder="Search by Postal Code"
                  className={classes.textInputWrapper}
                  variant="outlined"
                  onChange={enterSearchHandler}
                  onKeyDown={enterSearchHandler}
                />
              )}
              renderOption={(option) => {
                const matches =
                  option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                  option.structured_formatting.main_text,
                  matches.map((match) => [
                    match.offset,
                    match.offset + match.length
                  ])
                );

                return (
                  <Grid alignItems="center" container>
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400
                          }}>
                          {part.text}
                        </span>
                      ))}

                      <Typography color="secondary" variant="body2">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              }}
              value={value}
              classes={{
                root: classes.autoCompleteRoot,
                paper: classes.autoCompletePaper
              }}
            />
            <IconButton
              className={classes.searchButton}
              onClick={onClickHandler}
              variant="contained">
              <ArrowForwardIcon />
            </IconButton>
          </Grid>
          {md ? (
            <Grid item xs={12}>
              <Typography
                variant="body2"
                align="left"
                className={classes.bannerText}>
                <br />
                Eg. AB10 1 - ZE3 9
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      {md ? null : (
        <Grid container justify="center" className={classes.offer}>
          <JoinLinks />
        </Grid>
      )}
    </>
  ) : (
    <Skeleton variant="rect" animation="wave" className={classes.rootSize} />
  );
}
