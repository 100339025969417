import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAmplifyAuth } from 'context';
import { CartContext } from 'context/cart/cartContext';
import { validateCartCoupons } from 'common/validators';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  orderIncDecBtnRoot: {
    minWidth: 'fit-content'
  }
}));

const OrderUpDownButtons = ({ className = '', item }) => {
  const classes = useStyles();
  const { cart, setCart } = useContext(CartContext);
  const {
    state: { user }
  } = useAmplifyAuth();

  const getItemQuantity = (item) =>
    cart.some((cartItem) => cartItem.id === item.id)
      ? cart
          .filter((cartItem) => cartItem.id === item.id)
          .map((item) => item.quantity)[0]
      : 0;

  const updateCart = (item, q) => {
    let newCart = cart.some((cartItem) => cartItem.id === item.id)
      ? cart
          .map((cartItem) =>
            cartItem.id === item.id
              ? {
                  ...item,
                  quantity: cartItem.quantity + parseInt(q)
                }
              : cartItem
          )
          .filter((item) => item.quantity > 0)
      : [...cart, { ...item, quantity: 1 }];

    newCart = validateCartCoupons(newCart);
    localStorage.setItem(
      'cart',
      JSON.stringify({ username: user?.username || '', item: newCart })
    );
    setCart(newCart);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => updateCart(item, -1)}
        disabled={getItemQuantity(item) === 0}
        style={{
          borderRadius: '6px 0px 0px 6px',
          padding: '0px 6px'
        }}
        classes={{ root: clsx(classes.orderIncDecBtnRoot, className) }}>
        -
      </Button>
      <Button
        variant="outlined"
        style={{
          borderRadius: '0px',
          padding: '0px 11px',
          fontWeight: 700,
          color: !className ? 'black' : 'white'
        }}
        classes={{ root: clsx(classes.orderIncDecBtnRoot, className) }}
        disabled>
        {getItemQuantity(item)}
      </Button>
      <Button
        variant="outlined"
        onClick={() => updateCart(item, 1)}
        style={{
          borderRadius: '0px 6px 6px 0px',
          padding: '0px 6px'
        }}
        classes={{ root: clsx(classes.orderIncDecBtnRoot, className) }}>
        +
      </Button>
    </>
  );
};

export default OrderUpDownButtons;
