import React from 'react';
import { Grid, Typography, Chip } from '@material-ui/core';
import MUIRichTextEditor from 'mui-rte';
import { convertToRaw } from 'draft-js';
import SaveIcon from '@material-ui/icons/Save';

const SaveBtn = (props) => (
  <Chip
    label="SAVE"
    icon={<SaveIcon />}
    color="primary"
    onClick={props.onMouseDown}
    style={{ borderRadius: '4px' }}
  />
);

const AboutShop = (props) => {
  const { selectedShop, handleSubmit } = props;

  const handleSave = (data) => {
    const content = convertToRaw(data.getCurrentContent());
    const contentString = JSON.stringify(content);
    if (
      contentString !== selectedShop.about &&
      content.blocks.some((item) => item.text !== '')
    ) {
      handleSubmit({ preventDefault: () => {} }, { about: contentString });
    }
  };

  const a11yProps = () =>
    selectedShop && selectedShop.about !== null && selectedShop.about !== ''
      ? {
          defaultValue: selectedShop.about
        }
      : {};

  return (
    <Grid container style={{ padding: '0.5rem' }}>
      <Grid
        container
        item
        justify="space-between"
        alignItems="center"
        xs={12}
        style={{
          marginBottom: '0.8rem',
          marginLeft: '0.8rem',
          marginTop: '0.5rem'
        }}>
        <Typography variant="h5">
          {selectedShop && selectedShop.name ? selectedShop.name : ''}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginLeft: '1rem', marginRight: '1rem' }}>
        <MUIRichTextEditor
          label="write about your shop..."
          controls={[
            'title',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'highlight',
            'undo',
            'redo',
            'numberList',
            'bulletList',
            'quote',
            'code',
            'clear',
            'custom-save'
          ]}
          customControls={[
            {
              name: 'custom-save',
              component: SaveBtn,
              type: 'callback',
              onClick: handleSave
            }
          ]}
          {...a11yProps()}
        />
      </Grid>
    </Grid>
  );
};

export default AboutShop;
