import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  Button,
  DialogContent
} from '@material-ui/core';
import ItemizeProduct from '../itemize/ItemizeProduct';

export function ItemisedDialog({
  onSuccess = () => {},
  order,
  dialogType,
  setDialogType
}) {
  const [currentOrder, setCurrentOrder] = useState({});
  const [prevTotal, setPrevTotal] = useState(0);

  const handleClose = () => {
    setCurrentOrder(order);
    setDialogType(null);
  };

  const prevTotalChangeHandler = (val) => {
    setPrevTotal(val);
  };

  useEffect(() => {
    setCurrentOrder(order);
    prevTotalChangeHandler(order?.total || 0);
  }, [order]);

  const handleSubmit = () => {
    if (order?.paymentStatus === 'paid' && currentOrder?.total > prevTotal) {
      onSuccess({
        order: { ...currentOrder, paymentStatus: 'partiallyPaid' },
        sts: 'accepted'
      });
    } else {
      onSuccess({ order: currentOrder, sts: 'accepted' });
    }
  };

  return (
    <Dialog
      fullWidth={true}
      open={dialogType === 'accepted'}
      maxWidth="lg"
      onClose={handleClose}>
      <DialogContent>
        <ItemizeProduct order={currentOrder} setOrder={setCurrentOrder} />
      </DialogContent>
      <DialogActions style={{ alignSelf: 'center' }}>
        <Button autoFocus onClick={handleClose} color="primary" align="center">
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          align="center">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ItemisedDialog.propTypes = {
  declineOrder: PropTypes.func,
  handleClose: PropTypes.func
};
