import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ShopPhotos from './ShopPhotos';
import { useHistory } from 'react-router-dom';
import { routes } from 'common/constants';
import UploadedShopPhotos from './UploadedShopPhotos';
import AddIcon from '@material-ui/icons/Add';
import { ArrowBack } from '@material-ui/icons';
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Container,
  Fab,
  Divider,
  IconButton
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none'
  },
  contentContainer: {
    padding: '1rem'
  },
  header: {
    padding: '1rem'
  }
}));

function PhotosUplaod(props) {
  const { selectedShop, setSelectedShop, setDisabled } = props;
  const history = useHistory();
  const [showUploadDropzone, setShowUploadDropzone] = useState(false);
  const classes = useStyles();

  const showUploader = () => {
    setShowUploadDropzone(true);
    history.replace(routes.serviceProvider.shopPhotosAdd);
  };

  const hideUploader = () => {
    setShowUploadDropzone(false);
    history.replace(routes.serviceProvider.shopPhotos);
    setDisabled(true);
  };

  return (
    <Card className={classes.root}>
      <CardContent style={{ padding: '0' }}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.header}>
          <Grid container item xs={10} alignItems="center">
            {showUploadDropzone && (
              <IconButton onClick={hideUploader}>
                <ArrowBack fontSize="small" />
              </IconButton>
            )}
            <CardHeader
              title="Shop Photos and Videos"
              subheader="Shop Related Photos and Videos"
              style={{ padding: '0' }}
            />
          </Grid>
          {showUploadDropzone ? null : (
            <Grid container item xs={2} justify="flex-end" alignItems="center">
              <Fab color="secondary" size="small" onClick={showUploader}>
                <AddIcon />
              </Fab>
            </Grid>
          )}
        </Grid>
        <Divider />

        <Container className={classes.contentContainer}>
          {showUploadDropzone ? (
            <>
              <ShopPhotos
                hideUploader={hideUploader}
                selectedShop={selectedShop}
                setSelectedShop={setSelectedShop}
                setDisabled={setDisabled}
              />
            </>
          ) : (
            <>
              <UploadedShopPhotos
                selectedShop={selectedShop}
                setSelectedShop={setSelectedShop}
              />
            </>
          )}
        </Container>
      </CardContent>
    </Card>
  );
}

export default PhotosUplaod;
