import React, { useState, useEffect } from 'react';
import { SearchBannerModal } from './components';
import { Image, DocumentDeleteDialog } from 'components/organisms';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLoader } from 'layouts/loaderContext';
import { useSnackbar } from 'notistack';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { listSearchBanners } from 'graphql/queries';
import {
  createSearchBanner,
  updateSearchBanner,
  deleteSearchBanner
} from 'graphql/mutations';
import { uploadImage } from 'common/utilFunctions';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1.5rem 1rem'
  },
  bannersContainer: {
    marginTop: '1.5rem'
  },
  bannerRoot: {
    padding: '1rem'
  }
}));

const SearchBanner = ({ className, ...rest }) => {
  const [banners, setBanners] = useState([]);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const classes = useStyles();
  const { setLoading } = useLoader();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    loadBanners();
  }, []);

  const loadBanners = async () => {
    setLoading(true);
    const sBar = enqueueSnackbar('Loading...', {
      variant: 'info',
      persist: true
    });
    try {
      const resp = await API.graphql(
        graphqlOperation(listSearchBanners, { limit: 1000 })
      );
      setBanners(
        resp.data.listSearchBanners?.items.filter((item) => !item._deleted) ||
          []
      );
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        autoHideDuration: 2000
      });
    } finally {
      closeSnackbar(sBar);
      setLoading(false);
    }
  };

  const handleAddBtnClick = () => setShowModal(true);

  const handleModalClose = (event, reason = '') => {
    if (reason && reason === 'backdropClick') return;
    setShowModal(false);
    setSelectedBanner(null);
  };

  const handleEditClick = (item) => () => {
    setSelectedBanner(item);
    setShowModal(true);
  };

  // TODO: move to context
  const handleModalSubmit = async (banner) => {
    if (!banner?.photoURL || !banner?.title) {
      enqueueSnackbar('Enter Valid Image and Title', {
        variant: 'error',
        autoHideDuration: 2000
      });
      return;
    }
    let sBar;
    setLoading(true);
    try {
      const input = { title: banner.title, color: banner.color };
      if (banner?.file) {
        const { type: mimeType } = banner.file;
        input['MIMETypes'] = mimeType;
        const key = await uploadImage(banner.file);
        input['photoURL'] = key;
      }
      if (banner.id) {
        sBar = enqueueSnackbar('Updating Banner...', {
          variant: 'info',
          persist: true
        });
        input['id'] = banner.id;
        input['_version'] = banner._version;
        const resp = await API.graphql(
          graphqlOperation(updateSearchBanner, { input })
        );
        setBanners([
          resp.data.updateSearchBanner,
          ...banners.filter((item) => item.id !== banner.id)
        ]);
        enqueueSnackbar('Banner Updated', {
          variant: 'success',
          autoHideDuration: 2000
        });
        setSelectedBanner(null);
      } else {
        sBar = enqueueSnackbar('Adding Banner...', {
          variant: 'info',
          persist: true
        });
        const resp = await API.graphql(
          graphqlOperation(createSearchBanner, { input })
        );
        setBanners([resp.data.createSearchBanner, ...banners]);
        enqueueSnackbar('Banner Added', {
          variant: 'success',
          autoHideDuration: 2000
        });
      }
      handleModalClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        autoHideDuration: 2000
      });
    } finally {
      closeSnackbar(sBar);
      setLoading(false);
    }
  };

  const handleDeleteClick = (item) => () => {
    setSelectedBanner(item);
    setShowDeleteConfirmModal(true);
  };

  const handleDeleteConfirmModalClose = () => {
    setShowDeleteConfirmModal(false);
    setSelectedBanner(null);
  };

  // TODO: move to context
  const handleConfirmDelete = async () => {
    let sBar;
    setLoading(true);
    try {
      sBar = enqueueSnackbar('Deleting Banner...', {
        variant: 'info',
        persist: true
      });
      const input = {
        id: selectedBanner.id,
        _version: selectedBanner._version
      };
      await API.graphql(graphqlOperation(deleteSearchBanner, { input }));
      Storage.remove(selectedBanner.photoURL);
      setBanners(banners.filter((item) => item.id !== selectedBanner.id));
      enqueueSnackbar('Banner Deleted', {
        variant: 'success',
        autoHideDuration: 2000
      });
      handleDeleteConfirmModalClose();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        autoHideDuration: 2000
      });
    } finally {
      closeSnackbar(sBar);
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container {...rest} className={clsx(classes.root, className)}>
        <Grid container justify="space-between">
          <Typography variant="h3">Home Page Search Banners</Typography>
          {banners.length < 10 ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddBtnClick}>
              Add Banner
            </Button>
          ) : null}
        </Grid>
        <Grid container className={classes.bannersContainer}>
          {banners.map((item, index) => (
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
              className={classes.bannerRoot}
              key={`search-banners-items-${index}`}>
              <Card>
                <CardContent>
                  <Image docKey={item.photoURL} width={'100%'} />
                  <Grid
                    container
                    justify="center"
                    style={{ gap: '0.5rem' }}
                    alignItems="center">
                    <Typography variant="h5" align="center">
                      {item.title}
                    </Typography>
                    <input type="color" value={item.color} disabled />
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid container justify="space-around">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={handleEditClick(item)}>
                      edit
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleDeleteClick(item)}>
                      delete
                    </Button>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <SearchBannerModal
        open={showModal}
        banner={selectedBanner}
        handleClose={handleModalClose}
        handleSubmit={handleModalSubmit}
      />
      <DocumentDeleteDialog
        open={showDeleteConfirmModal}
        handleCancel={handleDeleteConfirmModalClose}
        handleConfirm={handleConfirmDelete}
        confirmText="delete this banner"
        title="Delete Banner"
      />
    </>
  );
};

export default SearchBanner;
