import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Image } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {
    gap: '0.25rem',
    padding: '2rem',
    backgroundColor: '#def1f3',
    [theme.breakpoints.up('md')]: {
      padding: '2rem 5rem 2rem'
    }
  },
  image: {
    height: '11rem',
    width: '16rem'
  }
}));

const ShopVideos = ({ videos = [] }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Typography variant="h3">Our Videos</Typography>
      <Grid container>
        {videos.map((item, index) => (
          <Grid
            item
            lg={4}
            sm={6}
            xs={12}
            key={`shop-videos-items-${index}`}
            container
            justify="center">
            <Image
              docKey={item?.videoURL || ''}
              className={classes.image}
              mimeType={item?.MIMETypes || 'video'}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default ShopVideos;
