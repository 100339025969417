import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState
} from 'react';
import { useLoader } from 'layouts/loaderContext';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { getOrderByUserId } from 'graphql/customQueries';
import { updateOrder } from 'graphql/mutations';
import { getOrderByShopIdDateOIDStatus } from 'graphql/queries';

//TODO : Need to move context outside of view

const OrdersContext = createContext({});

function ordersReducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case 'updateData': {
      return payload || [];
    }
    case 'addData': {
      return [...state, ...payload];
    }
    case 'addOrder': {
      return [...state, payload];
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

const OrdersProvider = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [orders, dispatch] = useReducer(ordersReducer, []);
  const [nextPageToken, setNextPageToken] = useState('');
  const { setLoading } = useLoader();

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getOrders': {
          setLoading(true);
          const {
            newQuery = false,
            shopId,
            status = [],
            orderId = '',
            createdDateRange = ''
          } = action?.payload || {};

          let snackBar;

          try {
            snackBar = enqueueSnackbar('Orders are loading...', {
              variant: 'info',
              persist: true,
              preventDuplicate: true
            });
            const input = {
              shopId,
              status,
              orderId
            };
            if (createdDateRange) input['createdDateRange'] = createdDateRange;
            if (!newQuery) input['nextToken'] = nextPageToken;

            const data = await API.graphql(
              graphqlOperation(getOrderByShopIdDateOIDStatus, input)
            );
            const orders = data.data.getOrderByShopIdDateOIDStatus.items
              .filter((item) => item._deleted !== true)
              .sort(
                (a, b) => b.orderID.split('-')[1] - a.orderID.split('-')[1]
              );
            setNextPageToken(data.data.getOrderByShopIdDateOIDStatus.nextToken);

            if (!newQuery) {
              dispatch({
                type: 'addData',
                payload: orders
              });
            } else {
              dispatch({
                type: 'updateData',
                payload: orders
              });
            }
          } catch (error) {
            console.error(error);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              preventDuplicate: true,
              autoHideDuration: 2500
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'getOrdersByUserId': {
          setLoading(true);
          let snackBar;
          try {
            snackBar = enqueueSnackbar('Orders are loading...', {
              variant: 'info',
              persist: true,
              preventDuplicate: true
            });
            const data = await API.graphql(
              graphqlOperation(getOrderByUserId, {
                userID: action.payload.userId,
                limit: 5000
              })
            );
            const orders = data.data.getOrderByUserId.items
              .filter((item) => item._deleted !== true)
              .sort(
                (a, b) =>
                  new Date(b.createdAt).getTime() -
                  new Date(a.createdAt).getTime()
              );
            dispatch({
              type: 'updateData',
              payload: orders
            });
            return orders;
          } catch (error) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2500,
              preventDuplicate: true
            });
            console.error('something went wrong...', error);
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'updateOrder': {
          setLoading(true);
          let snackBar;
          try {
            const { order, sts, updateMessage = '' } = action.payload;
            snackBar = enqueueSnackbar(updateMessage || 'updating order...', {
              variant: 'info',
              persist: true
            });
            const { data } = await API.graphql(
              graphqlOperation(updateOrder, {
                input: {
                  id: order.id,
                  total: order.total,
                  status: sts || order.status,
                  collectionAndDeliveryFee: order.collectionAndDeliveryFee,
                  orderCancelOrDeclineReason: order.orderCancelOrDeclineReason,
                  collection: order.collection,
                  delivery: order.delivery,
                  orderList: order.orderList,
                  paymentStatus: order.paymentStatus,
                  paidAmount: order.paidAmount,
                  _version: order._version
                }
              })
            );
            const newOrder = data.updateOrder;

            const updatedOrders = orders
              .map((order) =>
                newOrder.id === order.id ? data.updateOrder : order
              )
              .filter(({ _deleted }) => !_deleted);

            dispatch({
              type: 'updateData',
              payload: updatedOrders
            });
          } catch (error) {
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2500,
              preventDuplicate: true
            });
            console.error('something went wrong...', error);
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'sortOrders': {
          let sortedOrders;
          if (
            action.payload.property === 'date' ||
            action.payload.property === 'parent-date'
          ) {
            sortedOrders = [
              ...orders.sort((a, b) =>
                action.payload.direction === 'desc'
                  ? new Date(a.createdAt).getTime() -
                    new Date(b.createdAt).getTime()
                  : new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime()
              )
            ];
          } else if (action.payload.property === 'deliveryDate') {
            sortedOrders = [
              ...orders.sort((a, b) =>
                action.payload.direction === 'desc'
                  ? new Date(a.delivery.date).getTime() -
                    new Date(b.delivery.date).getTime()
                  : new Date(b.delivery.date).getTime() -
                    new Date(a.delivery.date).getTime()
              )
            ];
          } else {
            sortedOrders = [
              ...orders.sort((a, b) =>
                action.payload.direction === 'desc'
                  ? a.orderID.split('-')[1] - b.orderID.split('-')[1]
                  : b.orderID.split('-')[1] - a.orderID.split('-')[1]
              )
            ];
          }
          dispatch({
            type: 'updateData',
            payload: sortedOrders
          });
          break;
        }
        default:
          dispatch(action);
      }
      return;
    },
    [orders, nextPageToken]
  );
  const value = {
    orders,
    isMoreOrdersAvailable: !!nextPageToken,
    dispatch: asyncDispatch
  };

  return (
    <OrdersContext.Provider value={value}>
      {props.children}
    </OrdersContext.Provider>
  );
};

function useOrders() {
  const context = useContext(OrdersContext);
  if (context === undefined || !Object.keys(context).length) {
    throw new Error('useOrders must be used within a OrdersContext');
  }
  return context;
}

export { OrdersProvider, useOrders };
