import React from 'react';
import { OrderDetailContent } from 'components/organisms';
import { Grid, Modal, Backdrop, Fade, Container } from '@material-ui/core';

const OrderDetailsDialog = ({
  dialogType,
  setDialogType,
  order,
  updateOrder,
  showOrderDetails = false,
  setShowOrderDetails = () => {}
}) => {
  const handleClose = () => {
    setDialogType(false);
    setShowOrderDetails(false);
  };

  return order !== undefined ? (
    <Modal
      aria-describedby="message-modal-description"
      aria-labelledby="message-modal-title"
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition
      onClose={handleClose}
      open={showOrderDetails || dialogType === 'orderDetails'}>
      <Container maxWidth="md" style={{ border: 'none' }}>
        <Fade in={showOrderDetails || dialogType === 'orderDetails'}>
          <Grid container spacing={3}>
            <Grid container item justify="flex-end" alignItems="center"></Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{
                backgroundColor: 'white'
              }}>
              {/* order details */}
              <OrderDetailContent
                order={order}
                updateOrder={updateOrder}
                isOrdersModal={true}
                handleClose={handleClose}
              />
            </Grid>
          </Grid>
        </Fade>
      </Container>
    </Modal>
  ) : null;
};

export default OrderDetailsDialog;
