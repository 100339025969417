import React, { useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { disableUser, enableUser } from 'graphql/mutations';
import { useLoader } from 'layouts/loaderContext';
import SalesUserAddForm from './SalesUserAddForm';
import {
  Grid,
  Typography,
  Button,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TablePagination
} from '@material-ui/core';
import SalesUserActivity from './SalesUserActivity';
import { CustomSearchBar } from 'components/organisms';
import { searchStringInItems } from 'common/utilFunctions';

const SalesUsers = ({ salesUsers = [], setSalesUsers = () => {} }) => {
  const [showAddForm, setShowAddForm] = useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [searchBy, setSearchBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { setLoading } = useLoader();

  const handleEnableUser = (username, id) => {
    setLoading(true);
    API.graphql(graphqlOperation(enableUser, { username, id }))
      .then(() =>
        setSalesUsers([
          ...salesUsers.map((user) =>
            user.id === id ? { ...user, status: 'enabled' } : user
          )
        ])
      )
      .catch((e) => console.log('error', e))
      .finally(() => setLoading(false));
  };

  const handleDisableUser = (username, id) => {
    setLoading(true);
    API.graphql(graphqlOperation(disableUser, { username, id }))
      .then(() =>
        setSalesUsers([
          ...salesUsers.map((user) =>
            user.id === id ? { ...user, status: 'disabled' } : user
          )
        ])
      )
      .catch((e) => console.log('error', e))
      .finally(() => setLoading(false));
  };

  const handleNameClick = (user) => (e) => {
    setShowEditForm(true);
    setCurrentUser(user);
  };

  const handlePageChange = (_event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleUpdateUsers = (addUser = false, user) =>
    addUser
      ? setSalesUsers([user, ...salesUsers])
      : setSalesUsers([
          ...salesUsers.map((item) =>
            item.id === user.id ? { ...item, ...user } : item
          )
        ]);

  return (
    <Grid container style={{ gap: '1rem' }}>
      <Grid container justify="space-between">
        <Typography variant="h4">Facility Manager</Typography>
        {!!(showEditForm || showAddForm) ? null : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowAddForm(true)}>
            Add Facility Manager
          </Button>
        )}
      </Grid>

      {!!(showEditForm || showAddForm) ? null : (
        <Grid container justify="flex-start">
          <Grid item xs={12} md={5}>
            <CustomSearchBar
              value={searchBy}
              onChange={(e) => setSearchBy(e.target.value.toLowerCase())}
              placeholder="search by name / email"
              onClear={() => setSearchBy('')}
            />
          </Grid>
        </Grid>
      )}

      <Grid container>
        {showAddForm ? (
          <SalesUserAddForm
            handleCancel={() => {
              setShowAddForm(false);
              setShowEditForm(false);
            }}
            handleUpdateUsers={handleUpdateUsers}
          />
        ) : showEditForm ? (
          <SalesUserActivity
            handleCancel={() => {
              setShowAddForm(false);
              setShowEditForm(false);
            }}
            handleUpdateUsers={handleUpdateUsers}
            edit={showEditForm}
            selectedUser={currentUser}
          />
        ) : (
          <>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="center">Phone No</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salesUsers
                  .filter((item) =>
                    searchStringInItems(searchBy, [
                      item.email,
                      `${item?.title || ''} ${item?.firstName || ''} ${
                        item?.lastName || ''
                      }`
                    ])
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => (
                    <TableRow hover key={`sales-users-table-item-${index}`}>
                      <TableCell>
                        <Button
                          variant="text"
                          style={{ textTransform: 'none' }}
                          color="primary"
                          onClick={handleNameClick(item)}>
                          {`${item?.title || ''} ${item?.firstName || ''} ${
                            item?.lastName || ''
                          }`}
                        </Button>
                      </TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell align="center">{item.phoneNumber}</TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: 'capitalize' }}>
                        {item.status}
                      </TableCell>
                      <TableCell align="center">
                        {item.status === 'enabled' ? (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              handleDisableUser(item.username, item.id)
                            }>
                            disable
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              handleEnableUser(item.username, item.id)
                            }>
                            enable
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={
                salesUsers.filter((item) =>
                  searchStringInItems(searchBy, [
                    item.email,
                    `${item?.title || ''} ${item?.firstName || ''} ${
                      item?.lastName || ''
                    }`
                  ])
                ).length
              }
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default SalesUsers;
