import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Image } from 'components/organisms';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  desc: {
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  }
}));

const Template3 = ({
  content = {
    image: '',
    description: ''
  },
  shopName = ''
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="space-between"
      style={{
        backgroundColor: '#ffb81f',
        padding: '0',
        maxWidth: '73rem',
        minHeight: '200px'
      }}>
      <Grid item xs={7} container alignItems="center" justify="center">
        <Image
          docKey={content?.image || ''}
          loadFromStorage={content?.image?.split('/').at(0) === 'images'}
          height="100%"
          width="100%"
          style={{
            borderRadius: '0 10px 10px 0',
            maxHeight: '412px',
            objectFit: 'cover'
          }}
        />
      </Grid>
      <Grid
        container
        item
        xs={5}
        alignItems="flex-end"
        style={{ padding: '0.5rem' }}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            className={classes.desc}
            style={{
              color: '#fff',
              lineHeight: '2rem',
              letterSpacing: '2px'
            }}>
            {content?.description || ''}
          </Typography>
        </Grid>
        <Grid container item xs={12} alignItems="flex-end">
          <Typography
            variant="h6"
            align="left"
            style={{ padding: '0.8rem 0 0 0' }}>
            <span style={{ fontWeight: '550' }}>Ad</span> &#183; {shopName}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Template3;
