import { API, graphqlOperation } from 'aws-amplify';
import { getCouponsByShop } from 'graphql/queries';
import * as mutations from 'graphql/mutations';

export const loadCouponsByShop = (input) =>
  new Promise((resolve, reject) => {
    API.graphql(graphqlOperation(getCouponsByShop, input))
      .then((data) => resolve(data.data.getCouponsByShop))
      .catch((err) => reject(err));
  });

export const createCoupon = (input) =>
  new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(mutations.createCoupon, {
        input
      })
    )
      .then((data) => resolve(data.data.createCoupon))
      .catch((err) => reject(err));
  });

export const deleteCoupon = (input) =>
  new Promise((resolve, reject) => {
    API.graphql(
      graphqlOperation(mutations.deleteCoupon, {
        input
      })
    )
      .then((data) => resolve(data.data.deleteCoupon))
      .catch((err) => reject(err));
  });
