import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchShop from 'components/SearchShop';
import { useSnackbar } from 'notistack';
import { createAd } from 'graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { uploadImage } from 'common/utilFunctions';
import { useLoader } from 'layouts/loaderContext';
import { updateAd } from 'graphql/mutations';
import { AdTemplates } from 'components';
import {
  priorities,
  formFieldsForMendatoryCheck,
  initialState,
  templateFields,
  templates,
  adPages,
  adTypes
} from './constants';
import { webLinkRegex } from 'common/constants';
import { validateImageAspectRatio } from 'common/validators';

const useStyles = makeStyles((theme) => ({
  content: {
    gap: '1.5rem'
  },
  gap: {
    margin: '1rem 0'
  },
  priorityLabel: {
    color: '#9e9e9e'
  }
}));

const AdsModal = ({
  open = false,
  handleModalClose = () => {},
  setAds = () => {},
  ads = [],
  selectedAd = ''
}) => {
  const [adInput, setAdInput] = useState(initialState);
  const [templateInput, setTemplateInput] = useState([]);
  const { setLoading } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    if (selectedAd) {
      const ad = ads.find((item) => item.id === selectedAd);
      setAdInput(
        Object.fromEntries(
          Object.keys(initialState).map((key) => [key, ad[key] || ''])
        )
      );
      if (ad?.content) {
        const parsedContent = JSON.parse(ad.content);
        setTemplateInput(
          templateFields[ad.template].map((item) => ({
            ...item,
            value: parsedContent[item.name] || item.value
          }))
        );
      }
    }
  }, [selectedAd]);

  const handleSelectChange = (e, newVal) => {
    setAdInput({
      ...adInput,
      shop: newVal
    });
  };

  const handleChange = (e) => {
    const numberCheckRegex = /[^0-9.]/g;
    if (e.target.name === 'amount') {
      e.target.value = e.target.value.replace(numberCheckRegex, '');
    }
    if (e.target.name === 'template') {
      setTemplateInput(templateFields[e.target.value]);
    }
    const newInput = {
      ...adInput,
      [e.target.name]: e.target.value
    };
    if (e.target.name === 'page') {
      newInput.template = '';
      setTemplateInput([]);
    }
    setAdInput(newInput);
  };

  const getTemplateValuesAsObject = (template = []) =>
    Object.fromEntries(
      template
        .map((item) => [item?.name || '', item?.value || ''])
        .filter((item) => item.every((val) => !!val))
    );

  const handleTemplateFieldValueChange = (e) =>
    setTemplateInput(
      templateInput.map((item) =>
        item?.name === e.target.name ? { ...item, value: e.target.value } : item
      )
    );

  const getDuration = () => {
    const fromDate = new Date(adInput.startDate);
    const toDate = new Date(adInput.endDate);
    return (
      `${Math.ceil(
        Math.abs(toDate - fromDate) / (1000 * 60 * 60 * 24)
      )} days` || ''
    );
  };

  const handleImageChange = (e, imageRatio) => {
    const callBack = (res) => {
      if (res) {
        const _file = e.target.files[0];
        if (_file) {
          const reader = new FileReader();
          reader.onload = () => {
            setTemplateInput(
              templateInput.map((item) =>
                item?.name === e.target.name
                  ? { ...item, value: reader.result, file: _file }
                  : item
              )
            );
          };
          reader.readAsDataURL(_file);
        }
      } else {
        enqueueSnackbar(
          `The uploaded image is not matching the desired ratio.`,
          {
            variant: 'error',
            autoHideDuration: 2000
          }
        );
      }
    };
    validateImageAspectRatio(e, imageRatio, callBack);
  };

  const validateDetails = () => {
    if (!adInput.page) {
      return 'please select pages';
    }
    if (!adInput.priority) {
      return 'please select a priority';
    }
    if (adInput.type === 'shop' && !adInput.shop) {
      return 'please select a shop';
    }
    if (!!adInput.redirectLink && !webLinkRegex.test(adInput.redirectLink)) {
      return 'please provide a valid redirect link';
    }
    if (!adInput.startDate || !adInput.endDate) {
      return 'please enter valid dates';
    }
    if (formFieldsForMendatoryCheck.some((field) => !adInput[field])) {
      return 'all fields are mendatory';
    }
  };

  const handleClose = () => {
    setAdInput(initialState);
    setTemplateInput([]);
    handleModalClose();
  };

  const handleSubmit = async (e) => {
    const formErrors = validateDetails();
    if (!!formErrors) {
      enqueueSnackbar(formErrors, {
        variant: 'error',
        autoHideDuration: 3000
      });
      return;
    }
    setLoading(true);
    const { shop, amount, orderedDate, ...rest } = adInput;
    let updatedTemplateInput = [];
    if (templateInput.some((item) => item.file)) {
      const results = await Promise.allSettled(
        templateInput.map(async (item) =>
          item?.file ? { ...item, value: await uploadImage(item.file) } : item
        )
      );
      results.forEach(
        (res) =>
          res.status === 'fulfilled' && updatedTemplateInput.push(res.value)
      );
    } else {
      updatedTemplateInput = templateInput;
    }
    const input = {
      ...rest,
      shopID: shop?.id || null,
      amount: amount || 0,
      content: JSON.stringify(getTemplateValuesAsObject(updatedTemplateInput))
    };
    if (!!orderedDate) {
      input['orderedDate'] = orderedDate;
    }

    if (!selectedAd) {
      API.graphql(graphqlOperation(createAd, { input }))
        .then((data) => {
          enqueueSnackbar('Ad created successfully', {
            variant: 'success',
            autoHideDuration: 2000
          });
          setAds([...ads, data?.data?.createAd]);
          handleClose();
        })
        .catch((e) => {
          console.log('error', e);
          enqueueSnackbar('Something went wrong..!', {
            variant: 'error',
            autoHideDuration: 2000
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      input.id = selectedAd;
      input.isClosed = false;
      input._version =
        ads.find((item) => item.id === selectedAd)?._version || 1;
      API.graphql(graphqlOperation(updateAd, { input }))
        .then((data) => {
          enqueueSnackbar('Ad updated successfully', {
            variant: 'success',
            autoHideDuration: 2000
          });
          setAds([
            data?.data?.updateAd,
            ...ads.filter((ad) => ad?.id !== selectedAd)
          ]);
          handleClose();
        })
        .catch((e) => {
          console.log('error', e);
          enqueueSnackbar('Something went wrong..!', {
            variant: 'error',
            autoHideDuration: 2000
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      onClose={handleClose}
      aria-labelledby="ad-form-dialog-title">
      <DialogTitle id="ad-form-dialog-title">Advertisement</DialogTitle>
      <DialogContent className={classes.content}>
        <DialogContentText>Add/Edit advertisement</DialogContentText>

        {/* type select */}
        <Grid container>
          <FormControl component="fieldset">
            <FormLabel component="legend">Select Ad Type</FormLabel>
            <RadioGroup
              row
              aria-label="type"
              name="type"
              value={adInput.type}
              onChange={handleChange}>
              {adTypes.map((type, index) => (
                <FormControlLabel
                  key={`add-ad-modal-types-${index}`}
                  value={type.value}
                  control={<Radio />}
                  label={type.label}
                  disabled={type.disabled}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* ad redirect link */}
        {adInput.type === 'default' ? (
          <Grid container className={classes.gap}>
            <TextField
              variant="outlined"
              label="Redirect Link"
              size="small"
              error={
                !!adInput.redirectLink &&
                !webLinkRegex.test(adInput.redirectLink)
              }
              helperText={
                !!adInput.redirectLink &&
                !webLinkRegex.test(adInput.redirectLink)
                  ? 'this is not a valid link'
                  : ''
              }
              InputLabelProps={{ shrink: true }}
              name="redirectLink"
              value={adInput.redirectLink || ''}
              fullWidth
              onChange={handleChange}
            />
          </Grid>
        ) : null}

        <SearchShop onChange={handleSelectChange} value={adInput.shop} />
        <Grid container className={classes.gap}>
          <TextField
            type="date"
            variant="outlined"
            label="Select Order date"
            size="small"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              min: new Date().toISOString().split('T').at(0)
            }}
            name="orderedDate"
            fullWidth
            value={adInput.orderedDate}
            onChange={handleChange}
          />
        </Grid>
        <Grid container className={classes.gap}>
          <TextField
            type="date"
            variant="outlined"
            label="Select Start date"
            size="small"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              min: new Date().toISOString().split('T').at(0)
            }}
            name="startDate"
            fullWidth
            value={adInput.startDate}
            onChange={handleChange}
          />
        </Grid>
        {!!adInput.startDate && (
          <Grid container style={{ marginTop: '1rem' }}>
            <TextField
              type="date"
              variant="outlined"
              label="Select End date"
              size="small"
              InputLabelProps={{ shrink: true }}
              inputProps={{
                min: !!adInput.startDate
                  ? new Date(adInput.startDate).toISOString().split('T').at(0)
                  : new Date().toISOString().split('T').at(0)
              }}
              name="endDate"
              value={adInput.endDate}
              fullWidth
              onChange={handleChange}
            />
          </Grid>
        )}
        {!!adInput.startDate && !!adInput.endDate && (
          <Grid container style={{ padding: '0.5rem 0 0' }}>
            <Typography variant="body2">Duration: {getDuration()}</Typography>
          </Grid>
        )}
        <Grid container className={classes.gap}>
          <TextField
            select
            variant="outlined"
            label="Priority"
            size="small"
            InputLabelProps={{ shrink: true }}
            name="priority"
            value={adInput.priority || ''}
            fullWidth
            onChange={handleChange}>
            {priorities.map((item, index) => (
              <MenuItem
                key={`priorities-select-menu-items-${index}`}
                value={item.value}>
                {item.label} &nbsp;
                {item?.labelHelper ? (
                  <em className={classes.priorityLabel}>
                    {`(${item?.labelHelper})`}
                  </em>
                ) : null}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid container className={classes.gap}>
          <TextField
            variant="outlined"
            label="Amount"
            size="small"
            InputLabelProps={{ shrink: true }}
            name="amount"
            value={adInput.amount || ''}
            fullWidth
            onChange={handleChange}
          />
        </Grid>

        {/* page select */}
        <Grid container className={classes.gap}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Select Page</FormLabel>
            <RadioGroup
              row
              aria-label="page"
              name="page"
              value={adInput.page}
              onChange={handleChange}>
              {adPages.map((page, index) => (
                <FormControlLabel
                  key={`add-ad-modal-pages-${index}`}
                  value={page.value}
                  control={<Radio />}
                  label={page.label}
                  disabled={page.disabled}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* template select */}
        {!!adInput.page ? (
          <Grid container className={classes.gap}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Select Template</FormLabel>
              <RadioGroup
                row
                aria-label="template"
                name="template"
                value={adInput.template}
                onChange={handleChange}>
                {templates
                  .filter((item) => item.visibleAt === adInput.page)
                  .map((item, index) => {
                    return (
                      <FormControlLabel
                        key={`add-ad-modal-templates-${index}`}
                        value={item.value}
                        control={<Radio disabled={item.disabled} />}
                        label={item.label}
                      />
                    );
                  })}
              </RadioGroup>
            </FormControl>
          </Grid>
        ) : null}

        {templateInput.length > 0 && (
          <Grid container className={classes.gap}>
            <Grid
              container
              item
              xs={12}
              md={adInput.page === 'list' ? 6 : 12}
              justify="center">
              <AdTemplates
                ad={{
                  ...adInput,
                  content: JSON.stringify(
                    getTemplateValuesAsObject(templateInput)
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={adInput.page === 'list' ? 6 : 12}>
              {/* template fields */}
              {templateInput?.map((item, index) => {
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    className={classes.gap}
                    key={`ad-form-content-field-${index}`}>
                    {item?.type === 'image' ? (
                      <Grid
                        container
                        justify="center"
                        alignItems="center"
                        direction="column">
                        <input
                          accept="image/*"
                          id={`add-product-form-image-upload-${item?.name}`}
                          name={item?.name || ''}
                          onChange={(e) =>
                            handleImageChange(e, item.imageRatio)
                          }
                          type="file"
                          hidden
                        />
                        <label
                          htmlFor={`add-product-form-image-upload-${item?.name}`}>
                          <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            component="span">
                            Upload {item?.name}
                          </Button>
                        </label>

                        <Typography
                          align="center"
                          style={{ paddingTop: '0.5rem' }}>
                          Preferred image ratio
                          <strong> {item.imageRatioText}</strong>
                        </Typography>
                      </Grid>
                    ) : (
                      <TextField
                        variant="outlined"
                        label={item?.label || ''}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: item?.max || 0 }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="body2">
                                {item?.value?.length || 0}/{item?.max || 0}
                              </Typography>
                            </InputAdornment>
                          )
                        }}
                        name={item?.name || ''}
                        value={item?.value || ''}
                        fullWidth
                        onChange={handleTemplateFieldValueChange}
                      />
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdsModal;
