export const getCurrentOpenAds = /* GraphQL */ `
  query GetCurrentOpenAds($page: Pages!) {
    getCurrentOpenAds(page: $page) {
      id
      shopID
      isClosed
      template
      content
      page
      priority
      shop {
        id
        shopID
        name
      }
      type
      redirectLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;

export const shopQueryItems = `{
      id
      name
      googlePlaceId
      postcode
      image
      about
      phoneNumber
      email
      address
      websiteLink
      language
      town
      minPriceForFree
      distanceRange
      range
      shopID
      location {
        lat
        lng
      }
      workHrs{
        closeTime
        openTime
        closeDay
        openDay
      }
      products {
        items {
          id
          shopID
          serviceID
          service {
            name
          }
          itemID
          item {
            name
            image
            description
          }
          price
          image 
          category
          name
          description
          unit
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      reviews {
        items {
          id
          shopID
          userID
          user {
            id
            firstName
            username
            lastName
            picture
          }
          createdAt
          updatedAt
          title
          category1
          category2
          category3
          category4
          category5
          description
          status
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      photos {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        serviceID
        status
      }
      primaryPhoto {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
      }
      videos {
        name
        videoURL
        autoPlay
        thumbURL
        description
        MIMETypes
        status
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
      status
      isLive
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }`;

export const getShopByPostalCode = /* GraphQL */ `
  query GetShopByPostalCode($postalCode: String!, $range: String) {
    getShopByPostalCode(postalCode: $postalCode, range: $range) ${shopQueryItems}
  }
`;

export const getOrderByUserId = /* GraphQL */ `
  query GetOrderByUserId(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByUserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        orderID
        orderList {
          productID
          category
          name
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          quantity
        }
        userID
        status
        collection {
          date
          time
          type
          address
        }
        delivery {
          date
          time
          type
          address
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        orderCancelOrDeclineReason
        total
        paidAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
        }
        payments {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          minPriceForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          status
          isLive
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
