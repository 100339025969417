import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MainLogo from '../../assets/png/Launder-it-logo.png';
import FooterLogo from '../../assets/png/Launder-it-white-logo.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content'
  },
  normal: {
    width: '175px',
    [theme.breakpoints.down('md')]: {
      padding: '10px'
    }
  },
  large: {
    width: '185px'
  },
  small: {
    width: '145px'
  }
}));

const LogoName = ({ light = false, size = 'normal' }) => {
  const classes = useStyles();

  return (
    <Link to="/">
      <Grid container className={classes.root}>
        <img
          src={light ? FooterLogo : MainLogo}
          alt="Launder-it"
          className={classes[size]}
        />
      </Grid>
    </Link>
  );
};

LogoName.propTypes = {
  light: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal', 'large'])
};

export default LogoName;
