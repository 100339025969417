import {
  List,
  ListItem,
  Collapse,
  FormControlLabel,
  Checkbox,
  Typography,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';
import checkedIcon from '../assets/filterCheckedIcon.svg';
import uncheckedIcon from '../assets/filterUncheckedIcon.svg';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { capitalizeStr } from 'common/utilFunctions';

const Filter = ({
  data = { name: '', items: [], id: '', filterName: '' },
  open,
  setOpen,
  shopFilters,
  setShopFilters
}) => {
  const updateShopFilters = (id) => (e) => {
    e.target.checked
      ? data.filterName !== 'services'
        ? setShopFilters({
            ...shopFilters,
            [data.filterName]: [id]
          })
        : setShopFilters({
            ...shopFilters,
            services: [...shopFilters.services, id]
          })
      : data.filterName !== 'services'
      ? setShopFilters({
          ...shopFilters,
          [data.filterName]: shopFilters[data.filterName].filter(
            (item) => item !== id
          )
        })
      : setShopFilters({
          ...shopFilters,
          services: shopFilters.services.filter((item) => item !== id)
        });
  };

  const handleCollapseClick = () =>
    setOpen({ ...open, [data.id]: !open[data.id] });

  return (
    <>
      <ListItem
        style={{ width: '100%' }}
        disableGutters
        button
        onClick={handleCollapseClick}>
        <ListItemText
          primary={
            <Typography
              style={{
                fontSize: '14px',
                fontWeight: '700',
                color: 'black'
              }}>
              {data.name}
            </Typography>
          }
        />
        <ListItemSecondaryAction
          onClick={handleCollapseClick}
          style={{ top: '58%', right: 0 }}>
          {open[data.id] ? <ExpandMore /> : <ChevronRightIcon />}
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={open[data.id]}>
        <List style={{ padding: 0 }}>
          {data.items.map((item, index) => (
            <ListItem
              key={`shop-filters-items-${item}-${index}`}
              disableGutters
              style={{ padding: 0 }}>
              <FormControlLabel
                style={{ marginLeft: '-0.5rem' }}
                checked={shopFilters[data.filterName].some(
                  (id) => item.id === id
                )}
                onChange={updateShopFilters(item.id)}
                label={
                  <Typography
                    style={{
                      marginLeft: '6px',
                      fontSize: '14px',
                      fontWeight: '500',
                      color: 'black'
                    }}>
                    {capitalizeStr(item?.name)}
                  </Typography>
                }
                control={
                  <Checkbox
                    checkedIcon={<img src={checkedIcon} alt="checked" />}
                    icon={<img src={uncheckedIcon} alt="checked" />}
                  />
                }
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default Filter;
