import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Image } from 'components/organisms';
import { graphqlOperation, API } from 'aws-amplify';
import { getGallaryPhotoByServiceID } from 'graphql/queries';
import { useLoader } from 'layouts/loaderContext';
import PhotoGalleryDialog from 'components/organisms/PhotoGalleryDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  autoComplete: {
    margin: theme.spacing(1),
    minWidth: 500
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: 10
  },
  footer: {
    flexGrow: 1,
    padding: 10
  },
  amount: {
    width: 60,
    '& input': {
      padding: '10.5px 8px'
    }
  },
  column: {
    maxWidth: 100
  },
  imgHolder: {
    width: 82,
    height: 82,
    textAlign: 'center',
    border: '1px solid #bbb',
    marginRight: 10,
    position: 'relative'
  },
  image: {
    maxHeight: 80,
    maxWidth: 80,
    transform: 'translateY(-50%)',
    marginTop: '50%'
  },
  imageColumn: {
    width: 50
  },
  iconButton: {
    padding: 10
  },
  imgEditButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    background: 'rgba(255,255,255,.75)',
    borderRadius: 0,
    padding: 0
  },
  alignMiddle: {
    verticalAlign: 'middle'
  },
  tableRow: {
    '& .editBtn': {
      visibility: 'hidden'
    },
    '&:hover .editBtn': {
      visibility: 'visible'
    }
  }
}));

const ProductsRow = ({
  row,
  product,
  products,
  dispatch,
  setDisabled,
  priceHelper
}) => {
  const classes = useStyles();
  const [editName, setEditName] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [imgData, setImgData] = useState(''); // TODO : check for optimize
  const { setLoading } = useLoader();
  const [showDialog, setShowDialog] = useState(false);
  const [previewGalleryImg, setPreviewGalleryImg] = useState([]);
  const [serviceName, setServiceName] = useState('');

  const updateProducts = (obj) => {
    let newProducts = Object.assign({}, products);
    if (product) {
      const updatedProduct = {
        ...product,
        enabled: true,
        modify: true,
        ...obj
      };
      const isValid =
        updatedProduct.price &&
        updatedProduct.price !== 0 &&
        !!updatedProduct.unit;
      newProducts[product.itemID || row.id] = { ...updatedProduct, isValid };
    } else {
      const { name, description, image, id, ...rowValue } = row;
      newProducts[row.id] = {
        price: 0,
        ...rowValue,
        itemID: id,
        isValid: false,
        unit: '',
        enabled: true,
        modify: true,
        ...obj
      };
    }
    dispatch({
      type: 'updateData',
      payload: newProducts
    });
    setDisabled(false);
  };

  const handleFileChange = (event) => {
    const {
      target: { value, files }
    } = event;
    const fileForUpload = files[0];
    updateProducts({ file: fileForUpload });
    readFile(fileForUpload || value);
  };
  const handleFileRemove = (event) => {
    updateProducts({ file: null });
    updateProducts({ image: null });
    setImgData('');
  };

  const readFile = (_file) => {
    if (_file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImgData(reader.result);
      };
      reader.readAsDataURL(_file);
    }
  };

  const saveToProduct = (propName) => {
    const obj = {};
    const value = propName === 'name' ? editName : editDescription;
    if (value.trim()) {
      obj[propName] = value;
    } else if (propName !== 'name') {
      obj[propName] = '';
    }
    updateProducts(obj);

    if (propName === 'name') {
      setEditName('');
    } else {
      setEditDescription('');
    }
  };

  const changeHandler = (event) => {
    updateProducts({ enabled: !(product && product.enabled) });
  };

  const updateUnits = (event) => {
    const {
      target: { value }
    } = event;
    updateProducts({ unit: value });
  };

  const updatePrice = (event) => {
    const replaceRegex = /[^0-9.]/g;
    event.target.value = event.target.value.replace(replaceRegex, '');
    updateProducts({ price: event.target.value });
  };

  const selectItemHandler = (product) => {
    setShowDialog(true);
    setServiceName(product?.name);
    fetchItemPhotos(product?.serviceID);
  };

  const selectImageHandler = (el = '') => {
    !el ? setImgData(null) : setImgData(el.picture.photoURL);
  };

  const onCancelSelectingImg = () => {
    setImgData('');
    setShowDialog(false);
  };

  const onConfirmImage = () => {
    updateProducts({ image: imgData });
    setShowDialog(false);
  };

  const fetchItemPhotos = (serviceId) => {
    if (serviceId) {
      try {
        setLoading(true);
        API.graphql(
          graphqlOperation(getGallaryPhotoByServiceID, {
            serviceID: serviceId
          })
        ).then(({ data }) => {
          const itemImgs = data.getGallaryPhotoByServiceID.items
            .filter((item) => !item._deleted)
            .map((img) => img);
          setPreviewGalleryImg(itemImgs);
          setLoading(false);
        });
      } catch (error) {
        console.log('error', error);
        setLoading(false);
      }
    }
  };

  return (
    <TableRow className={classes.tableRow} key={row.name}>
      <TableCell padding="checkbox">
        <Checkbox
          checked={(product && product.enabled) || false}
          onChange={changeHandler}
        />
      </TableCell>
      <TableCell className={classes.imageColumn} component="th" scope="row">
        <div className={classes.imgHolder}>
          {imgData ? (
            <Image
              docKey={imgData}
              loadFromStorage={imgData.includes('images/')}
              alt="Item"
              className={classes.image}
            />
          ) : product?.image || row.image ? (
            <Image
              docKey={product?.image || row.image}
              alt={product?.name || row.name}
              className={classes.image}
            />
          ) : null}

          {product && product.modify && imgData ? (
            <IconButton
              className={classes.imgEditButton}
              component="span"
              onClick={handleFileRemove}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          ) : (
            <>
              <IconButton
                onClick={() => selectItemHandler(row)}
                className={`${classes.imgEditButton} editBtn`}
                component="span">
                <EditIcon fontSize="small" />
              </IconButton>
            </>
          )}
        </div>
      </TableCell>
      <TableCell component="th" scope="row">
        {editName ? (
          <TextField
            margin="none"
            onChange={(e) => setEditName(e.target.value.trimStart() || ' ')}
            size="small"
            value={editName}
            variant="outlined"
          />
        ) : (
          (product && product.name) || row.name
        )}
        {editName ? (
          <>
            <IconButton
              className={classes.iconButton}
              onClick={() => saveToProduct('name')}>
              <CheckBoxIcon fontSize="small" />
            </IconButton>
            <IconButton
              className={classes.iconButton}
              onClick={() => setEditName('')}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ) : (
          <IconButton
            className={`${classes.iconButton} editBtn`}
            onClick={() => setEditName((product && product.name) || row.name)}>
            <EditIcon fontSize="small" />
          </IconButton>
        )}
      </TableCell>
      <TableCell>
        {editDescription ? (
          <TextareaAutosize
            className={classes.alignMiddle}
            onChange={(e) => {
              setEditDescription(e.target.value.trimStart() || ' ');
            }}
            rowsMin={3}
            value={editDescription}
          />
        ) : (
          (product && product.description) || row.description || 'N/A'
        )}
        {editDescription ? (
          <>
            <IconButton
              className={classes.iconButton}
              onClick={() => saveToProduct('description')}>
              <CheckBoxIcon fontSize="small" />
            </IconButton>
            <IconButton
              className={classes.iconButton}
              onClick={() => setEditDescription('')}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        ) : (
          <IconButton
            className={`${classes.iconButton}  editBtn`}
            onClick={() =>
              setEditDescription(
                (product && product.description) || row.description || ' '
              )
            }>
            <EditIcon fontSize="small" />
          </IconButton>
        )}
      </TableCell>
      <TableCell align="center" className={classes.column}>
        <TextField
          className={classes.amount}
          margin="none"
          onChange={(e) => updatePrice(e)}
          size="small"
          helperText={
            product && product.enabled && product.price === 0 && priceHelper
          }
          error={
            product &&
            product.enabled &&
            priceHelper !== '' &&
            product.price === 0
          }
          disabled={!(product && product.enabled)}
          value={product?.price || row.price || ''}
          variant="outlined"
          required
        />
      </TableCell>
      <TableCell align="center" className={classes.column}>
        <Select
          native
          onChange={updateUnits}
          value={(product && product.unit) || ''}
          variant="outlined"
          required>
          <option value=""></option>
          <option value="perPair">per/pair</option>
          <option value="perKg">per/kg</option>
          <option value="perItem">per/item</option>
          <option value="perLoad">per/load</option>
          <option value="poa">POA</option>
        </Select>
      </TableCell>

      <PhotoGalleryDialog
        open={showDialog}
        onClose={onCancelSelectingImg}
        previewGalleryImg={previewGalleryImg}
        serviceName={serviceName}
        selectImageHandler={selectImageHandler}
        handleFileChange={handleFileChange}
        imgData={imgData}
        setImgData={setImgData}
        onConfirmImage={onConfirmImage}
      />
    </TableRow>
  );
};

export default ProductsRow;
