import React, { useEffect, useState } from 'react';
import { validatePhoneNumber } from 'common/validators';
import { useSnackbar } from 'notistack';
import { useLoader } from 'layouts/loaderContext';
import { API, graphqlOperation } from 'aws-amplify';
import { addNewShopUser, updateUserDetail } from 'graphql/mutations';
import {
  Card,
  CardContent,
  CardHeader,
  TextField,
  Button,
  CardActions,
  Grid,
  MenuItem
} from '@material-ui/core';
import { countries } from 'common/constants';
import { makeStyles } from '@material-ui/core/styles';

const formFields = [
  {
    label: 'First Name',
    name: 'firstName'
  },
  {
    label: 'Last Name',
    name: 'lastName'
  },
  {
    label: 'Email',
    name: 'email'
  },
  {
    label: 'Phone No',
    name: 'phoneNumber',
    helperText: 'Please enter 11 to 13 digit contact number. E.g. 07712345678'
  },
  {
    label: 'Address',
    name: 'address'
  }
];

const initialUserState = {
  title: 'Mr',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  groups: 'sales',
  address: '',
  country: '',
  postalCode: ''
};

const useStyles = makeStyles((theme) => ({
  mdPaddingRight: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '0.625rem'
    }
  }
}));

const SalesUserAddForm = ({
  handleCancel,
  handleUpdateUsers = () => {},
  edit = false,
  selectedUser
}) => {
  const { setLoading } = useLoader();
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [user, setUser] = useState(initialUserState);
  const [disabled, setDisabled] = useState(true);
  const titles = [
    {
      value: 'Mr',
      label: 'Mr'
    },
    {
      value: 'Mrs',
      label: 'Mrs'
    },
    {
      value: 'Miss',
      label: 'Miss'
    },
    {
      value: 'Ms',
      label: 'Ms'
    },
    {
      value: 'Mx',
      label: 'Mx'
    },
    {
      value: 'Sir',
      label: 'Sir'
    },
    {
      value: 'Dr',
      label: 'Dr'
    },
    {
      value: 'Lady',
      label: 'Lady'
    },
    {
      value: 'Lord',
      label: 'Lord'
    }
  ];

  useEffect(() => {
    if (edit) {
      const editableUser = {
        id: selectedUser.id,
        _version: selectedUser._version
      };
      const addressInArray =
        selectedUser?.address?.split(',')?.map((item) => item.trim()) || [];
      Object.keys(initialUserState)
        .filter((attr) => attr !== 'groups')
        .forEach((attr) => {
          editableUser[attr] = selectedUser[attr] || '';
        });
      editableUser.country = addressInArray.pop();
      editableUser.address = addressInArray.join(', ');
      setUser(editableUser);
    }
  }, [edit]);

  const handleChange = (event) => {
    if (event.target.name === 'phoneNumber') {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
    setUser({
      ...user,
      [event.target.name]: event.target.value
    });
    disabled && setDisabled(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!!validatePhoneNumber(user.phoneNumber)) {
      enqueueSnackbar(validatePhoneNumber(user.phoneNumber), {
        variant: 'warning',
        preventDuplicate: true
      });
      return;
    } else if (Object.values(user).some((val) => !val)) {
      enqueueSnackbar(
        `${
          formFields.find((field) => !user[field.name])?.label ||
          (!user.country ? 'Country' : 'Postal Code')
        } is a mandatory field`,
        {
          variant: 'warning',
          preventDuplicate: true
        }
      );
      return;
    }
    let snackBar;
    try {
      setLoading(true);
      snackBar = enqueueSnackbar('Saving User data....', {
        variant: 'info',
        persist: true
      });
      const { country, ...rest } = user;
      const resp = await API.graphql(
        graphqlOperation(addNewShopUser, {
          ...rest,
          address: `${rest.address}, ${country}`,
          dateOfBirth: '1970-01-01'
        })
      );
      handleUpdateUsers(true, resp.data.addNewShopUser);
      enqueueSnackbar('User created successfully', {
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: 1500
      });
      setUser(initialUserState);
      handleCancel();
    } catch (error) {
      enqueueSnackbar(error.errors[0]?.message || 'Something went wrong..!', {
        variant: 'error',
        preventDuplicate: true
      });
      console.error('Error', error);
    } finally {
      closeSnackbar(snackBar);
      setLoading(false);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!!validatePhoneNumber(user.phoneNumber)) {
      enqueueSnackbar(validatePhoneNumber(user.phoneNumber), {
        variant: 'warning',
        preventDuplicate: true
      });
      return;
    } else if (Object.values(user).some((val) => !val)) {
      enqueueSnackbar(
        `${
          formFields.find((field) => !user[field.name])?.label ||
          (!user.country ? 'Country' : 'Postal Code')
        } is a mandatory field`,
        {
          variant: 'warning',
          preventDuplicate: true
        }
      );
      return;
    }
    let snackBar;
    try {
      setLoading(true);
      snackBar = enqueueSnackbar('Updating User data....', {
        variant: 'info',
        persist: true
      });
      const { country, ...rest } = user;
      const updatedUser = {
        ...rest,
        address: `${rest.address}, ${country}`,
        username: rest.email
      };
      const resp = await API.graphql(
        graphqlOperation(updateUserDetail, updatedUser)
      );
      enqueueSnackbar('User updated successfully', {
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: 1500
      });
      handleUpdateUsers(false, updatedUser);
      setUser(initialUserState);
      handleCancel();
    } catch (error) {
      enqueueSnackbar(error.errors[0]?.message || 'Something went wrong..!', {
        variant: 'error',
        preventDuplicate: true
      });
      console.error('Error', error);
    } finally {
      closeSnackbar(snackBar);
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardHeader
        title={!edit ? 'Add User' : 'Edit User'}
        subheader={
          !edit ? 'add new facility manager' : 'update facility manager'
        }
      />
      <form autoComplete="off" noValidate>
        <CardContent>
          <TextField
            label="Select Title"
            fullWidth
            margin="dense"
            select
            SelectProps={{
              native: true
            }}
            InputLabelProps={{ shrink: true }}
            name="title"
            value={user.title}
            onChange={handleChange}
            required
            variant="outlined">
            {titles.map((option, index) => (
              <option
                key={`add-sales-user-form-title-options-item-${index}`}
                value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
          {formFields.map((item, index) => (
            <TextField
              key={`add-sales-user-form-items-${index}`}
              name={item.name}
              value={user[item.name]}
              label={item.label}
              disabled={edit && item.name === 'email'}
              onChange={handleChange}
              helperText={
                item.name === 'phoneNumber'
                  ? validatePhoneNumber(user.phoneNumber)
                  : ''
              }
              error={
                item.name === 'phoneNumber'
                  ? !!validatePhoneNumber(user.phoneNumber)
                  : false
              }
              variant="outlined"
              margin="dense"
              required
              fullWidth
            />
          ))}
          <Grid container justify="space-between">
            <Grid item xs={12} md={6} className={classes.mdPaddingRight}>
              <TextField
                name="postalCode"
                value={user.postalCode}
                label="Postal Code"
                onChange={handleChange}
                variant="outlined"
                margin="dense"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                name="country"
                value={user.country}
                label="Country"
                onChange={handleChange}
                variant="outlined"
                margin="dense"
                required
                fullWidth>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {countries.map((item, index) => (
                  <MenuItem
                    key={`countries-list-item-${index}`}
                    value={`${item.value}`}>
                    {item.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container style={{ gap: '0.625rem' }} justify="flex-end">
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={disabled}
              onClick={edit ? handleUpdate : handleSubmit}>
              Submit
            </Button>
          </Grid>
        </CardActions>
      </form>
    </Card>
  );
};

export default SalesUserAddForm;
