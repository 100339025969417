import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import PaymentIcon from '@material-ui/icons/Payment';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';

const tabs = [
  {
    id: 0,
    name: 'Profile Information',
    icon: <EditOutlinedIcon fontSize="small" />
  },
  {
    id: 1,
    name: 'Notifications',
    icon: <NotificationsNoneOutlinedIcon fontSize="small" />
  },
  {
    id: 2,
    name: 'Payment Methods',
    icon: <PaymentIcon fontSize="small" />
  },
  {
    id: 3,
    name: 'Manage Addresses',
    icon: <LocationOnOutlinedIcon fontSize="small" />
  },
  {
    id: 4,
    name: 'Change Password',
    icon: <HttpsOutlinedIcon fontSize="small" />
  }
];

const addressAttributes = {
  buildingNumber: 'Building No :',
  buildingName: 'Building Name :',
  line1: 'Line 1 :',
  line2: 'Line 2 :',
  postcode: 'Post Code :'
};

export { tabs, addressAttributes };
