import React, { useContext, useState } from 'react';
import {
  Grid,
  Button,
  Container,
  Badge,
  IconButton,
  useMediaQuery
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import SignInButton from 'components/organisms/SignInButton';
import { CartContext } from 'context/cart/cartContext';
import CartMenu from './CartMenu';
import LogoName from './LogoName';
import JoinLinks from './JoinLinks';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#1f6893',
    padding: '0.5rem 0',
    [theme.breakpoints.up('md')]: {
      padding: 0
    }
  },
  container: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '0 0.5rem',
    [theme.breakpoints.up('md')]: {
      maxWidth: '90%',
      padding: 0
    }
  },
  main: {
    gap: '1rem',
    justifyContent: 'center',
    '@media (min-width:360px)': {
      gap: '0',
      justifyContent: 'space-between'
    }
  },
  cartBtn: {
    color: 'rgb(255 255 255/0.7)',
    textTransform: 'none',
    fontSize: '12px',
    minWidth: 0
  },
  knowledgeBtn: {
    fontWeight: 600,
    fontSize: '13px',
    color: 'rgb(255 255 255/0.7)',
    padding: 0,
    paddingRight: '15px',
    borderRight: '1px solid rgb(255 255 255/0.2)',
    borderRadius: 0,
    textTransform: 'none'
  },
  offerNavRightDiv: {
    width: 'fit-content',
    gap: '0.25rem',
    [theme.breakpoints.up('sm')]: {
      gap: '0.5rem'
    }
  },
  cartBadge: {
    backgroundColor: 'white',
    color: 'black',
    height: '13px',
    minWidth: '10px',
    width: '13px',
    fontSize: '10px'
  }
}));

const OfferNav = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const { cart } = useContext(CartContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const handleCartMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid container direction="row" className={classes.main}>
          <Grid
            item
            container
            alignItems="center"
            style={{ width: 'fit-content', padding: 0 }}>
            {md ? (
              <JoinLinks />
            ) : (
              <LogoName light size={md ? 'normal' : 'small'} />
            )}
          </Grid>
          <Grid
            container
            item
            justify="flex-end"
            alignItems="center"
            className={classes.offerNavRightDiv}>
            <SignInButton />
            <>
              {/* can be modified to promote reusability */}
              {sm ? (
                <Button
                  startIcon={
                    <Badge
                      classes={{ badge: classes.cartBadge }}
                      badgeContent={cart.reduce((r, o) => o.quantity + r, 0)}>
                      <ShoppingCartOutlinedIcon fontSize="small" />
                    </Badge>
                  }
                  size="small"
                  onClick={(e) =>
                    history.location.pathname.split('/').at(-1) !==
                      'checkout' && handleCartMenu(e)
                  }
                  className={classes.cartBtn}>
                  Cart
                </Button>
              ) : (
                <IconButton
                  size="small"
                  onClick={(e) =>
                    history.location.pathname.split('/').at(-1) !==
                      'checkout' && handleCartMenu(e)
                  }
                  className={classes.cartBtn}>
                  <Badge
                    classes={{ badge: classes.cartBadge }}
                    badgeContent={cart.reduce((r, o) => o.quantity + r, 0)}>
                    <ShoppingCartOutlinedIcon fontSize="small" />
                  </Badge>
                </IconButton>
              )}

              {cart?.length > 0 && anchorEl !== null && (
                <CartMenu
                  anchorEl={anchorEl}
                  cartItems={cart}
                  setAnchorEl={setAnchorEl}
                />
              )}
            </>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default OfferNav;
