import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Grid,
  Button,
  Chip
} from '@material-ui/core';
import moment from 'moment';
import { AdTemplates } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0.5rem'
  },
  imageContainer: {
    margin: '0 auto',
    overflow: 'hidden'
  },
  image: {
    width: '100%'
  },
  closeBtn: {
    backgroundColor: theme.palette.warning.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.warning.dark
    }
  },
  deleteBtn: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  closedChip: {
    borderRadius: 0,
    width: '100%',
    margin: '0.5rem 0',
    textTransform: 'uppercase',
    backgroundColor: theme.palette.warning.main
  }
}));

const AdsCard = (props) => {
  const {
    className,
    ad,
    handleUpdate = () => () => {},
    handleDelete = () => () => {},
    handleClose = () => () => {},
    setAds = () => {},
    ads = [],
    ...rest
  } = props;
  const classes = useStyles();

  const isCurrentAd =
    new Date(ad?.endDate) >= new Date() &&
    new Date(ad?.startDate) <= new Date() &&
    !ad?.isClosed;

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent style={{ padding: '0.5rem' }}>
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.imageContainer}>
          <AdTemplates
            ad={ad}
            showAsButton={true}
            handleClick={handleUpdate(ad?.id)}
          />
        </Grid>
        {ad?.type === 'default' ? (
          <Grid container justify="center" style={{ marginTop: '0.25rem' }}>
            <Chip color="primary" size="small" label="Default Advertisement" />
          </Grid>
        ) : null}

        {ad?.shop ? (
          <Typography
            align="center"
            variant="h6"
            style={{ paddingTop: '0.5rem' }}>
            {ad?.shop?.name || ''}
          </Typography>
        ) : null}
        <Typography align="center" variant="body2">
          {`${moment(ad?.startDate).format('Do MMM YY') || ''} - ${
            moment(ad?.endDate).format('Do MMM YY') || ''
          }`}
        </Typography>
      </CardContent>
      <CardActions style={{ padding: '0' }}>
        <Grid container justify="space-between">
          <Grid item xs={12} md={12}>
            {ad?.isClosed && (
              <Chip
                color="primary"
                label="closed"
                className={classes.closedChip}
              />
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={12}
            justify="center"
            style={{ gap: '1rem', paddingBottom: '1rem' }}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={handleUpdate(ad?.id)}>
              edit
            </Button>
            {isCurrentAd && (
              <Button
                variant="contained"
                size="small"
                className={classes.closeBtn}
                onClick={handleClose(ad?.id || '', ad?._version || 1)}>
                close
              </Button>
            )}
            <Button
              variant="contained"
              size="small"
              onClick={handleDelete(ad?.id || '', ad?._version || 1)}
              className={classes.deleteBtn}>
              delete
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

AdsCard.propTypes = {
  className: PropTypes.string,
  ad: PropTypes.object.isRequired
};

export default AdsCard;
