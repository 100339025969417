import React, { useState, useEffect } from 'react';
import { showPrice, unitFormatter } from 'common/utilFunctions';
import {
  Typography,
  Box,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  autoComplete: {
    margin: theme.spacing(1),
    minWidth: 500
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: 10
  },
  footer: {
    flexGrow: 1,
    padding: 10
  },
  amount: {
    width: 60,
    '& input': {
      padding: '10.5px 8px'
    }
  },
  column: {
    maxWidth: 100
  },
  imgHolder: {
    width: 82,
    height: 82,
    textAlign: 'center',
    border: '1px solid #bbb',
    marginRight: 10,
    position: 'relative'
  },
  image: {
    maxHeight: 80,
    maxWidth: 80,
    transform: 'translateY(-50%)',
    marginTop: '50%'
  },
  imageColumn: {
    width: 50
  },
  iconButton: {
    padding: 10
  },
  imgEditButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    background: 'rgba(255,255,255,.75)',
    borderRadius: 0,
    padding: 0
  },
  alignMiddle: {
    verticalAlign: 'middle'
  },
  tableRow: {
    '& .editBtn': {
      visibility: 'hidden'
    },
    '&:hover .editBtn': {
      visibility: 'visible'
    }
  },
  noImage: {
    paddingTop: '5px',
    '& p': {
      fontSize: '10px',
      lineHeight: '12px'
    }
  }
}));

const ItemizeRow = ({ row, order, setOrder }) => {
  const classes = useStyles();
  const [curOrder, setCurOrder] = useState({});
  const [quantityNum, setQuantityNum] = useState(0);
  const [open, setOpen] = useState(false);
  const numArr = Array.from(Array(100).keys());

  useEffect(() => {
    const newOrderItem = order.orderList.find(
      (o) => o.productID === row.productID
    );
    setCurOrder(newOrderItem || {});
    setQuantityNum(newOrderItem ? newOrderItem.quantity : 0);
  }, [row, order]);

  const handleQuantityChange = (event) => {
    setQuantityNum(event.target.value);
    setOrder({
      ...order,
      orderList: order.orderList.map((list) =>
        list.productID === row.productID
          ? { ...list, quantity: event.target.value }
          : list
      )
    });
  };

  const checkHandler = (e) =>
    e.target.checked
      ? setOrder({
          ...order,
          orderList: [
            ...order.orderList,
            {
              productID: row.productID,
              description: null,
              discount: null,
              name: row.name,
              price: row.product.price,
              quantity: quantityNum || 1,
              unit: row.product.unit
            }
          ]
        })
      : setOrder({
          ...order,
          orderList: order.orderList.filter(
            (item) => item.productID !== row.productID
          )
        });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <TableRow key={row.name} className={classes.tableRow}>
      <TableCell padding="checkbox">
        <Checkbox
          checked={curOrder.productID !== undefined}
          onChange={checkHandler}
        />
      </TableCell>
      <TableCell className={classes.column}>
        {curOrder.name || row.name || row.product.name}
      </TableCell>
      <TableCell className={classes.column} align="center">
        <Typography>
          {curOrder.description ||
            row.description ||
            row.product.description ||
            'N/A'}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Box
          className={classes.amount}
          style={{
            border: '1px solid #ced4da',
            display: 'flex',
            margin: 'auto',
            paddingLeft: '10px'
          }}>
          <Select
            disableUnderline
            id="name"
            labelId="controlled-open-select-label"
            onChange={handleQuantityChange}
            onClose={handleClose}
            onOpen={handleOpen}
            disabled={curOrder.productID === undefined}
            open={open}
            value={quantityNum || 0}>
            {numArr.map((numb, i) => {
              return (
                <MenuItem key={`numb-${i}`} value={numb}>
                  {numb}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
      </TableCell>

      <TableCell className={classes.column} align="center">
        <Typography>{curOrder?.unit || row.product.unit || ''}</Typography>
      </TableCell>

      <TableCell className={classes.column} align="center">
        <Typography>
          {showPrice(curOrder?.price || row.product.price || 0)}
        </Typography>
      </TableCell>
      <TableCell className={classes.column} align="center">
        <Typography>
          {curOrder.discount
            ? `${showPrice(curOrder.discount || 0)} ${unitFormatter(
                curOrder.unit
              )}`.toLowerCase()
            : curOrder.overallDiscount
            ? `${showPrice(curOrder.overallDiscount)}`
            : 0}
        </Typography>
      </TableCell>
      <TableCell className={classes.column} align="center">
        <Typography>
          {showPrice(
            (quantityNum || 0) * (curOrder?.price || row.product.price || 0) -
              (curOrder.overallDiscount || 0)
          )}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export default ItemizeRow;
