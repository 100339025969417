import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  fields,
  initialValues,
  endAdornments,
  requiredFields,
  notes
} from './constants';
import { useServices } from 'views/Services/serviceContext';
import { useProducts } from 'views/Products/productsContext';
import { useCoupons } from 'context/coupons/couponsContext';
import { useAmplifyAuth } from 'context';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem'
  },
  content: {
    padding: '1rem',
    margin: 0
  },
  form: {
    width: '100%'
  },
  input: {
    padding: '0.5rem'
  },
  notesContainer: {
    margin: '1.5rem 0 0',
    padding: '0.5rem',
    backgroundColor: '#e1f5fe'
  },
  listRoot: {
    padding: 0
  },
  listItem: {
    paddingLeft: '0.5rem',
    paddingBottom: 0
  }
}));

const CouponForm = ({ handleHideForm = () => {} }) => {
  const classes = useStyles();
  const { services, dispatch: servicesDispatch } = useServices();
  const { products, dispatch: productsDispatch } = useProducts();
  const { coupons, dispatch } = useCoupons();
  const [coupon, setCoupon] = useState(initialValues);
  const { enqueueSnackbar } = useSnackbar();
  let {
    state: { user }
  } = useAmplifyAuth();

  useEffect(() => {
    servicesDispatch({ type: 'getService' });
  }, [servicesDispatch]);

  useEffect(() => {
    if (user?.shopId) {
      if (!Object.keys(products).length) {
        productsDispatch({
          type: 'getProductsByShopId',
          payload: { id: user.shopId }
        });
      }
      setCoupon({ ...coupon, shopID: user.shopId });
    }
  }, [user]);

  const handleChange = (e) => {
    const floatRegex = /^[0-9]+\.?[0-9]*$/;
    const numRegex = /^[0-9]+$/;
    const newCoupon = { ...coupon };

    // update discount and condition amounts based on regex check
    if (e.target.name === 'discountAmount') {
      if (
        (coupon.discountUnit === 'noOfItems' &&
          numRegex.test(e.target.value)) ||
        (coupon.discountUnit !== 'noOfItems' && floatRegex.test(e.target.value))
      )
        newCoupon['discountAmount'] = e.target.value;
    } else if (e.target.name === 'conditionAmount') {
      if (
        (coupon.conditionUnit === 'noOfItems' &&
          numRegex.test(e.target.value)) ||
        (coupon.conditionUnit !== 'noOfItems' &&
          floatRegex.test(e.target.value))
      )
        newCoupon['conditionAmount'] = e.target.value;
    } else {
      newCoupon[e.target.name] = e.target.value;
    }

    if (e.target.name === 'discountType') {
      newCoupon['discountUnit'] = '';
      newCoupon['discountAmount'] = '';
    }
    if (e.target.name === 'discountUnit') newCoupon['discountAmount'] = '';

    if (e.target.name === 'conditionUnit') newCoupon['conditionAmount'] = '';
    setCoupon(newCoupon);
  };

  const handleCreateSuccess = () => {
    setCoupon(initialValues);
    handleHideForm();
  };

  const handleSubmit = (e) => {
    // data validation is needed
    e.preventDefault();

    const missingField = Object.entries(requiredFields).find(
      ([key, _]) => !coupon[key]
    );
    if (missingField) {
      enqueueSnackbar(`${missingField[1]} is required`, {
        variant: 'error',
        autoHideDuration: 2500
      });
      return;
    }
    if (coupon.startDateTime >= coupon.endDateTime) {
      enqueueSnackbar('Enter valid dates', {
        variant: 'error',
        autoHideDuration: 2500
      });
      return;
    }
    if (coupon.discountType === 'conditional') {
      if (!coupon.conditionAmount) {
        enqueueSnackbar('Conditional amount is required', {
          variant: 'error',
          autoHideDuration: 2500
        });
        return;
      }
      if (!coupon.conditionUnit) {
        enqueueSnackbar('Conditional unit is required', {
          variant: 'error',
          autoHideDuration: 2500
        });
        return;
      }
    }
    const {
      discountFor,
      serviceID,
      startDateTime,
      endDateTime,
      ...rest
    } = coupon;

    const payloadCoupon = {
      startDateTime: new Date(startDateTime).toISOString(),
      endDateTime: new Date(endDateTime).toISOString(),
      ...rest
    };

    if (payloadCoupon.discountType === 'direct') {
      delete payloadCoupon.conditionUnit;
      delete payloadCoupon.conditionAmount;
    }
    dispatch({
      type: 'createCoupon',
      payload: {
        coupon: payloadCoupon,
        successCallback: handleCreateSuccess
      }
    });
  };

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.content} component={Paper}>
        <form noValidate className={classes.form}>
          <Grid container>
            {/* select discount for shop / item */}
            <Grid container className={classes.input}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="discountFor"
                  name="discountFor"
                  value={coupon.discountFor}
                  onChange={handleChange}>
                  <FormControlLabel
                    value="product"
                    control={<Radio />}
                    label="Discount for Product"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* select product */}
            {coupon.discountFor === 'product' ? (
              <>
                <Grid item xs={12} md={6} className={classes.input}>
                  <TextField
                    select
                    name="serviceID"
                    variant="outlined"
                    size="small"
                    label="Select Service"
                    value={coupon.serviceID}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth>
                    {services.map((item, index) => (
                      <MenuItem
                        value={item?.id || ''}
                        key={`coupons-form-services-items-${index}`}>
                        {item?.name || item.item?.name || ''}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6} className={classes.input}>
                  <TextField
                    select
                    name="productID"
                    variant="outlined"
                    size="small"
                    label="Select Product"
                    value={coupon.productID}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    disabled={!coupon.serviceID}
                    fullWidth>
                    {Object.values(products)
                      .filter(
                        (item) =>
                          item.serviceID === coupon.serviceID &&
                          coupons.every(
                            (_coupon) => _coupon.productID !== item.id
                          )
                      )
                      .map((item, index) => (
                        <MenuItem
                          value={item?.id || ''}
                          key={`coupons-form-products-items-${index}`}>
                          {item?.name || item?.item?.name || ''}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </>
            ) : null}

            {/* title, desc, start & end date & time */}
            {fields.map((item, index) => (
              <Grid
                item
                xs={12}
                md={item.md || 6}
                key={`coupons-form-fields-items-${index}`}
                className={classes.input}>
                <TextField
                  type={item.type || 'text'}
                  name={item.name}
                  label={item.label}
                  value={coupon[item.name]}
                  onChange={handleChange}
                  multiline={item.multiline || false}
                  rows={item.rows || 1}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  inputProps={
                    item.type === 'datetime-local'
                      ? {
                          min: new Date().toISOString().slice(0, 16)
                        }
                      : {}
                  }
                  fullWidth
                />
              </Grid>
            ))}

            {/* max no of uses per user */}
            {/* TODO: backend implementation required */}
            {/* <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classes.input}>
              <Grid item xs={10}>
                <Typography variant="body1">Max. use per / user</Typography>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  name="maxUsesPerUser"
                  variant="outlined"
                  type="number"
                  size="small"
                  value={coupon.maxUsesPerUser}
                  onChange={handleChange}
                  inputProps={{ min: 0 }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Grid>
            </Grid> */}

            {/* discount type select */}
            <Grid container className={classes.input}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Select Discount Type</FormLabel>
                <RadioGroup
                  row
                  aria-label="type"
                  name="discountType"
                  onChange={handleChange}>
                  <FormControlLabel
                    value="direct"
                    control={<Radio />}
                    label="Direct"
                  />
                  <FormControlLabel
                    value="conditional"
                    control={<Radio />}
                    label="Conditional"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* conditional discount condition details */}
            {coupon.discountType === 'conditional' ? (
              <Grid
                container
                justify="space-between"
                alignItems="center"
                className={classes.input}>
                <Grid item xs={8}>
                  <Typography variant="body1">
                    Conditional Amount / Items
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    select
                    name="conditionUnit"
                    variant="outlined"
                    size="small"
                    label="unit"
                    value={coupon.conditionUnit}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth>
                    <MenuItem value="amount">Amount</MenuItem>
                    <MenuItem value="noOfItems">Item</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    name="conditionAmount"
                    variant="outlined"
                    size="small"
                    className={classes.input}
                    value={coupon.conditionAmount}
                    onChange={handleChange}
                    inputProps={{ min: 0 }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="body2">
                            {endAdornments[coupon.conditionUnit] || ''}
                          </Typography>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}

            {/* direct discount details */}
            {coupon.discountType ? (
              <Grid
                container
                justify="space-between"
                alignItems="center"
                className={classes.input}>
                <Grid item xs={8}>
                  <Typography variant="body1">Discount</Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    select
                    name="discountUnit"
                    variant="outlined"
                    size="small"
                    label="unit"
                    value={coupon.discountUnit}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth>
                    <MenuItem value="amount">Amount</MenuItem>
                    <MenuItem value="percentage">Percentage</MenuItem>
                    {coupon.discountType === 'conditional' ? (
                      <MenuItem value="noOfItems">Item</MenuItem>
                    ) : null}
                  </TextField>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    name="discountAmount"
                    variant="outlined"
                    size="small"
                    className={classes.input}
                    value={coupon.discountAmount}
                    onChange={handleChange}
                    inputProps={{ min: 0 }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Typography variant="body2">
                            {endAdornments[coupon.discountUnit] || ''}
                          </Typography>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}

            {/* submit button */}
            <Grid container justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleSubmit}>
                save
              </Button>
            </Grid>

            {/* notes */}
            <Grid
              container
              justify="flex-end"
              component={Paper}
              className={classes.notesContainer}>
              <Grid item xs={12}>
                <Typography variant="h6">Notes</Typography>
                <List className={classes.listRoot}>
                  {notes.map((item, index) => (
                    <ListItem
                      className={classes.listItem}
                      key={`coupons-form-notes-items-${index}`}>
                      <Typography variant="body2">
                        {index + 1}. {item}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default CouponForm;
