import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import {
  Grid,
  Typography,
  CardContent,
  GridList,
  GridListTile,
  FormControlLabel,
  useMediaQuery,
  CardActionArea,
  Box,
  Radio,
  RadioGroup
} from '@material-ui/core';
import noImagePic from 'assets/png/no-image-available.png';
import shopsLoading from 'assets/gif/shopsLoading.gif';
import { getShopTotalRating, showPrice } from '../../../common/utilFunctions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useLoader } from 'layouts/loaderContext';
import { Image } from 'components/organisms';
import { useHistory } from 'react-router-dom';
import { useShops } from 'context/shop/shopsContext';
import StyledRating from 'views/ShopDetail/components/StyledRating';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DistanceIcon from '../assets/Distance.png';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '5.625rem 0'
  },
  favouriteButton: {
    color: theme.palette.favourite.main,
    height: '16px',
    width: '16px'
  },
  card: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '12px 12px 0 0',
    [theme.breakpoints.up('md')]: {
      margin: '0 5px'
    }
  },
  cardName: {
    fontSize: '15px',
    fontWeight: '600',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    verticalAlign: 'top',
    overflow: 'hidden'
  },
  cardContent: {
    padding: '10px 0',
    borderBottom: '3px solid #a3a3a3'
  },
  cardFooter: {
    padding: '10px 0 0 0',
    marginTop: '24px'
  },
  sliderContainer: {
    marginTop: '24px'
  },
  header: {
    fontSize: '24px',
    fontWeight: '800'
  },
  rating: {
    fontSize: '9px',
    color: theme.palette.secondary.main
  },
  ratingCount: {
    fontSize: '9px',
    backgroundColor: '#C4C4C45C',
    borderRadius: '50%',
    height: '23px',
    width: '23px',
    lineHeight: '22px'
  },
  nextBtn: {
    maxHeight: '20px',
    maxWidth: '20px'
  },
  startingPrice: {
    backgroundColor: '#ffc700a8',
    color: theme.palette.primary.secondary,
    padding: '4px 6px',
    fontSize: '10px',
    color: '#194376',
    fontWeight: 600
  },
  onHoldContainer: { position: 'relative' },
  onHoldWrapper: {
    left: '0px',
    position: 'absolute',
    top: '0px',
    height: '100px',
    width: '100px',
    overflow: 'hidden',
    zIndex: '1000'
  },
  onHoldRibbon: {
    left: '-64px',
    position: 'absolute',
    top: '32px',
    height: '16px',
    width: '200px',
    transform: 'rotate(-45deg)',
    textAlign: 'center',
    backgroundColor: theme.palette.warning.main,
    color: '#000',
    fontSize: '11px'
  },
  image: {
    objectFit: 'contain',
    maxWidth: '100%',
    borderRadius: '12px',
    overflow: 'hidden',
    backgroundColor: '#ccc'
  },
  imageCover: {
    position: 'absolute',
    borderRadius: 5,
    top: -9,
    right: -151,
    width: 317,
    height: 198,
    transform: 'rotateY(0deg) rotate(45deg)',
    opacity: 0.3,
    backgroundImage: 'linear-gradient(gray, white)'
  },
  postCode: {
    borderRadius: 2,
    color: 'black',
    gap: '0.25rem'
  },
  shopsList: {
    width: '100%',
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    gap: 10
  },
  shopsListJustify: {
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center'
    }
  },
  distanceRange: {
    color: 'black',
    textAlign: 'left',
    fontSize: '0.7rem',
    backgroundColor: '#f8f8f8'
  },
  shopGrayWrapper: {
    filter: 'grayscale(100%)'
  }
}));

export default function IntroOne() {
  const [currentScrollBtn, setCurrentScrollBtn] = useState(0);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const popularShopsDiv = useRef(null);
  const popularShop1 = useRef(null);
  const { shops = [], dispatch } = useShops();
  const classes = useStyles();
  const { loading } = useLoader();
  const history = useHistory();
  const [filteredShops, setFilteredShops] = useState([]);
  const [cardHeight, setCardHeight] = useState(295);

  useEffect(() => {
    const postalCode = localStorage.getItem('recentPostCode');
    if (postalCode) {
      dispatch({
        type: 'getShopsByPostCode',
        payload: { postalCode }
      });
    } else {
      dispatch({
        type: 'getRandomShops',
        payload: { limit: 1000 }
      });
    }
  }, []);

  const handleScroll = (e) => {
    let lastIndex = filteredShops.length - 1;
    lastIndex = md ? parseInt(lastIndex / 4) * 4 : lastIndex;
    const newIndex = Math.floor(
      popularShopsDiv.current.scrollLeft /
        (popularShop1.current?.offsetWidth + 10)
    );
    const showLastIndex =
      popularShopsDiv.current.offsetWidth +
        popularShopsDiv.current.scrollLeft >=
      popularShopsDiv.current.scrollWidth - 10;
    setCurrentScrollBtn(
      showLastIndex ? lastIndex : newIndex <= lastIndex ? newIndex : lastIndex
    );
  };

  useEffect(() => {
    setFilteredShops(
      shops
        ?.filter(
          (item) =>
            !!item &&
            (item.status === 'onhold' || item.isLive) &&
            item.products?.items.length > 0
        )
        .sort(
          (a, b) =>
            getShopTotalRating(b.reviews.items) -
            getShopTotalRating(a.reviews.items)
        )
        .slice(0, 12) || []
    );
  }, [shops]);

  useEffect(() => {
    filteredShops.length > 0 &&
      popularShopsDiv.current?.addEventListener('scroll', handleScroll, {
        passive: false
      });
    return () =>
      popularShopsDiv.current?.removeEventListener('scroll', handleScroll);
  }, [filteredShops]);

  const layoutUpdateEffect = () => {
    const width = popularShop1.current.offsetWidth;
    const newHeight = width * (9 / 16);
    setCardHeight(newHeight - 5);
  };

  useLayoutEffect(() => {
    if (!popularShop1.current) return;

    layoutUpdateEffect();
    window.addEventListener('resize', layoutUpdateEffect);

    return () => {
      window.removeEventListener('resize', layoutUpdateEffect);
    };
  }, [filteredShops]);

  const scrollDiv = (scrollWidth) => {
    popularShopsDiv.current.scrollBy({
      left: scrollWidth,
      behavior: 'smooth'
    });
  };

  const handleChange = (e) => {
    const scrollWidth =
      (popularShop1.current?.offsetWidth + 10) * e.target.value -
      (popularShopsDiv.current?.scrollLeft || 0);
    scrollDiv(scrollWidth);
    setCurrentScrollBtn(parseInt(e.target.value));
  };

  const handleShopClick = (shop) => () => {
    history.push(
      `/shop/${shop.shopID}/${shop.name.toLowerCase().split(' ').join('-')}`
    );
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <Grid container justify="center">
          <img src={shopsLoading} alt="shops are loading" />
        </Grid>
      ) : shops?.length > 0 ? (
        <>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
            <Typography variant="h3" color="primary" className={classes.header}>
              Popular Shops
            </Typography>
          </Grid>
          <Grid container className={classes.sliderContainer}>
            <Box style={{ width: '90%', margin: '0 auto' }}>
              <Grid container item alignItems="center" xs={12} sm={12}>
                <GridList
                  ref={popularShopsDiv}
                  className={clsx('hide-scrollbar', classes.shopsList, {
                    [classes.shopsListJustify]: filteredShops.length < 4
                  })}>
                  {filteredShops.map((item, index) => {
                    const isClosedToday = !item?.workHrs?.some(
                      (item) => item.openDay === new Date().getDay()
                    );
                    return (
                      <GridListTile
                        xs={12}
                        md={3}
                        ref={popularShop1}
                        key={`popular-shop-introone-items-${index}`}
                        style={{
                          height: `${cardHeight + 115}px`,
                          width: md ? '24.5%' : '100%'
                        }}>
                        <Grid
                          container
                          justify="center"
                          style={{ height: '100%' }}>
                          <Grid
                            key={`home-intro-one-popular-shop=${index}`}
                            className={classes.card}>
                            {item.status === 'onhold' || isClosedToday ? (
                              <Box className={classes.onHoldContainer}>
                                <Box className={classes.onHoldWrapper}>
                                  <Box className={classes.onHoldRibbon}>
                                    {item.status === 'onhold'
                                      ? 'OFFLINE ORDER'
                                      : isClosedToday
                                      ? 'Closed for Today'
                                      : ''}
                                  </Box>
                                </Box>
                              </Box>
                            ) : null}
                            <CardActionArea
                              onClick={handleShopClick(item)}
                              className={
                                isClosedToday ? classes.shopGrayWrapper : ''
                              }>
                              <CardContent
                                style={{
                                  padding: 0,
                                  height: '100%'
                                }}>
                                <Box
                                  style={{
                                    height: '40%',
                                    overflow: 'hidden'
                                  }}>
                                  <Box
                                    style={{
                                      position: 'relative',
                                      overflow: 'hidden'
                                    }}>
                                    <Image
                                      docKey={
                                        item.primaryPhoto?.photoURL ||
                                        item.photos?.find(
                                          (photo) =>
                                            photo?.status === 'approved'
                                        )?.photoURL ||
                                        noImagePic
                                      }
                                      width="100%"
                                      loadFromStorage={
                                        item?.photos?.some(
                                          (photo) =>
                                            photo?.status === 'approved'
                                        ) || false
                                      }
                                      className={classes.image}
                                      style={{
                                        height: `${cardHeight}px`
                                      }}
                                    />

                                    {item?.photos?.some(
                                      (photo) =>
                                        photo?.status === 'approved' &&
                                        photo?.MIMETypes.includes('image')
                                    ) &&
                                      !loading && (
                                        <Box className={classes.imageCover} />
                                      )}
                                  </Box>
                                </Box>
                                <Grid
                                  container
                                  justify="space-between"
                                  className={classes.cardContent}>
                                  <Grid
                                    container
                                    alignItems="flex-end"
                                    className={classes.postCode}>
                                    <img src={DistanceIcon} />

                                    <Typography
                                      className={classes.distanceRange}
                                      align="center">
                                      {item.range
                                        ? item.range > 152
                                          ? (item.range / 1609).toFixed(2) +
                                            ' mi'
                                          : Math.ceil(item.range * 3.281) +
                                            ' ft'
                                        : item.postcode}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    item
                                    justify="space-between"
                                    style={{ marginBottom: '0.625rem' }}>
                                    <Grid
                                      container
                                      item
                                      xs={6}
                                      justify="flex-start"
                                      alignItems="center"
                                      style={{ paddingTop: 4, minHeight: 45 }}>
                                      <Typography
                                        variant="h5"
                                        color="secondary"
                                        title={item.name || ''}
                                        className={classes.cardName}>
                                        {item.name || ''}
                                        {item.reviews.items.length > 0 && (
                                          <Grid
                                            container
                                            item
                                            className={classes.rating}>
                                            <StyledRating
                                              name="size-small"
                                              value={parseFloat(
                                                getShopTotalRating(
                                                  item.reviews.items
                                                )
                                              )}
                                              readOnly
                                              size={'small'}
                                              isSmallText={true}
                                              isText={false}
                                            />
                                          </Grid>
                                        )}
                                      </Typography>
                                    </Grid>

                                    <Grid
                                      container
                                      item
                                      xs={4}
                                      alignItems="center"
                                      justify="flex-end">
                                      <Typography
                                        variant="body2"
                                        className={classes.startingPrice}>
                                        {`Starting at ${showPrice(
                                          Math.min(
                                            ...item.products?.items
                                              .filter(
                                                (product) =>
                                                  product.price &&
                                                  !product?._deleted
                                              )
                                              .map((product) => product.price)
                                          )
                                        )}`}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </CardActionArea>
                          </Grid>
                        </Grid>
                      </GridListTile>
                    );
                  })}
                </GridList>
              </Grid>
            </Box>
          </Grid>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center">
            <RadioGroup row value={currentScrollBtn} onChange={handleChange}>
              {filteredShops?.map((item, i) => {
                if (md ? i % 4 === 0 : true) {
                  return (
                    <FormControlLabel
                      value={i}
                      key={`popular-shops-radio-group-item-${item.id}`}
                      control={
                        <Radio
                          disableRipple
                          size="medium"
                          style={{ padding: 0, paddingTop: '10px' }}
                          checkedIcon={
                            <FiberManualRecordIcon
                              style={{
                                fontSize: 15,
                                color: '#007AFF',
                                padding: 0
                              }}
                            />
                          }
                          icon={
                            <FiberManualRecordIcon
                              style={{
                                fontSize: 15,
                                color: '#7391BC',
                                padding: 0
                              }}
                            />
                          }
                        />
                      }
                    />
                  );
                } else {
                  return null;
                }
              })}
            </RadioGroup>
          </Grid>
        </>
      ) : null}
    </div>
  );
}
