import React, { useEffect } from 'react';
import { CouponForm, CouponHeader, CouponTable } from './components';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { useCoupons } from 'context/coupons/couponsContext';
import { useAmplifyAuth } from 'context';
import { routes } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem'
  }
}));

const Coupons = () => {
  const classes = useStyles();
  const { dispatch } = useCoupons();
  let { actionType = '' } = useParams();
  const {
    state: { user }
  } = useAmplifyAuth();
  const history = useHistory();

  const handleHideForm = () => history.replace(routes.serviceProvider.coupons);

  const handleShowForm = () => history.push('/service-provider/coupons/add');

  useEffect(() => {
    if (user?.shopId) {
      dispatch({ type: 'getCouponsByShop', payload: { shopID: user?.shopId } });
    }
  }, [user]);

  return (
    <Grid container className={classes.root}>
      <CouponHeader
        handleHideForm={handleHideForm}
        handleShowForm={handleShowForm}
      />
      {actionType === 'add' ? (
        <CouponForm handleHideForm={handleHideForm} />
      ) : (
        <CouponTable />
      )}
    </Grid>
  );
};

export default Coupons;
