import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState
} from 'react';
import { useLoader } from '../../layouts/loaderContext';
import { API, graphqlOperation } from 'aws-amplify';
import mutations from '../Products/productMutations';
import { useSnackbar } from 'notistack';
import { useServices } from 'views/Services/serviceContext';
import omit from 'lodash/omit';
import {
  uploadImage,
  getCustomShopProductName,
  compressImage
} from 'common/utilFunctions';

/** Utils */
// const uploadImage = async (file, name) => {
//   const extension = file.name.split('.')[1];
//   const { type: mimeType } = file;
//   let key = `images/${uuid()}`;
//   if (name) {
//     key = `${key}-${name.replace(/ /g, '_')}`;
//   }
//   key = `${key}.${extension}`;
//   const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`;

//   try {
//     await Storage.put(key, file, {
//       contentType: mimeType
//     });
//     return url;
//   } catch (err) {
//     return null;
//   }
// };

const ProductsContext = createContext({});

function productsReducer(state, action) {
  switch (action.type) {
    case 'updateData': {
      return action.payload || {};
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const loadProducts = (shopID) =>
  new Promise((resolve) => {
    API.graphql(
      graphqlOperation(
        `
        query GetShop($id: ID!) {
          getShop(id: $id) {
            id
            name
            products {
              items {
                id
                price
                unit
                name
                description
                itemID
                serviceID
                item {
                  id
                  name
                  description
                  image
                }
                image
                _version
                _deleted
              }
            }
          }
        }
      `,
        { id: shopID }
      )
    ).then((data) => {
      //TODO error catch
      resolve(data?.data?.getShop);
    });
  });

const saveProducts = async (products, shopID) => {
  let modifiedProducts = [];
  for (const [, value] of Object.entries(products)) {
    if (value.modify) {
      modifiedProducts.push({ ...value, shopID });
    }
  }
  let imageUploadData = [];
  await Promise.allSettled(
    modifiedProducts.map((item) => {
      if (item.file) {
        return new Promise(async (resolve) => {
          const compressedFile = await compressImage(item.file);
          uploadImage(compressedFile).then((data) =>
            resolve({ id: item.itemID, data })
          );
        });
      }
      return {};
    })
  ).then((results) => {
    results.forEach(({ value = {} }) => {
      imageUploadData[value.id] = value.data;
    });
  });

  const data = await Promise.allSettled(
    modifiedProducts.map((item) => {
      return new Promise((resolve) => {
        if (item.file) {
          item.image = imageUploadData[item.itemID];
        }
        if (item.id) {
          //const { modify, file, _deleted, enabled, ...input } = item;
          const input = omit(item, [
            'modify',
            'file',
            '_deleted',
            'enabled',
            'isValid',
            'item'
          ]);
          if (item.enabled) {
            API.graphql(graphqlOperation(mutations.updateProduct, { input }))
              .then((data) => resolve(data))
              .catch((err) => {});
          } else {
            API.graphql(
              graphqlOperation(mutations.deleteProduct, {
                input: { id: input.id, _version: input._version }
              })
            )
              .then((data) => resolve(data))
              .catch((err) => {});
          }
        } else {
          //const { modify, file, _deleted, _version, enabled, ...input } = item;
          const input = omit(item, [
            'modify',
            'file',
            '_deleted',
            'enabled',
            'isValid',
            '_version'
          ]);
          if (item.enabled) {
            API.graphql(
              graphqlOperation(mutations.createProduct, { input })
            ).then((data) => resolve(data));
          } else {
            resolve({});
          }
        }
      });
    })
  ).then((results) => {
    let newProducts = Object.assign({}, products);
    results.forEach(({ value: { data = {} } = {} }) => {
      const respData =
        data.createProduct || data.updateProduct || data.deleteProduct;
      if (respData && respData._deleted) {
        delete newProducts[respData.itemID];
      } else if (respData) {
        const isValid =
          respData.price && !(!respData.unit || respData.unit === 'na');
        newProducts[
          respData?.itemID || getCustomShopProductName(respData.name)
        ] = {
          ...respData,
          enabled: true,
          modify: false,
          isValid
        };
      }
    });
    return newProducts;
  });

  return data;
};

const ProductsProvider = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [products, dispatch] = useReducer(productsReducer, {});
  let currShop = null;
  const { services } = useServices();
  const [selectedShop, setSelectedShop] = useState(null);
  const { setLoading } = useLoader();

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getProductsByShopId': {
          setLoading(true);
          let resProducts = {};
          const { payload } = action;
          const { id: shopID } = payload;
          if (currShop !== shopID) {
            const data = await loadProducts(shopID);
            currShop = shopID;
            setSelectedShop({ ...payload, name: data.name });
            const items = data?.products.items;
            setLoading(false);
            items.map((item) => {
              if (!item._deleted) {
                const isValid =
                  item.price && !(!item.unit || item.unit === 'na');
                const productKey =
                  item.itemID || getCustomShopProductName(item.name);
                resProducts[productKey] = {
                  ...item,
                  enabled: true,
                  modify: false,
                  isValid
                };
              }
            });
            dispatch({
              type: 'updateData',
              payload: resProducts
            });
            return resProducts;
          }
          break;
        }
        case 'getProductsWithService': {
          setLoading(true);
          let serviceWithProduct = [];
          services.forEach((service) => {
            const {
              items: { items = [], ...restItems },
              id,
              ...rest
            } = service;
            const newItems = [
              ...items
                .filter((item) => products[item.id] !== undefined)
                .map((item) => {
                  let product = products[item.id] || {};
                  return { ...item, product, productID: product.id };
                }),
              ...Object.entries(products)
                .filter(
                  ([key, val]) =>
                    key.includes('shopProduct') && val.serviceID === id
                )
                .map(([key, val]) => ({
                  name: '',
                  description: '',
                  image: '',
                  id: key,
                  serviceID: id,
                  product: val,
                  productID: val?.id || ''
                }))
            ];

            newItems.length > 0 &&
              serviceWithProduct.push({
                ...rest,
                items: {
                  ...restItems,
                  items: newItems
                }
              });
          });
          setLoading(false);
          return serviceWithProduct;
        }
        case 'saveProducts': {
          setLoading(true);
          const sBar = enqueueSnackbar('Saving Products ....', {
            variant: 'info',
            persist: true
          });
          try {
            const data = await saveProducts(products, selectedShop.id);
            dispatch({
              type: 'updateData',
              payload: data
            });
            enqueueSnackbar('Products saved successfully', {
              variant: 'success',
              preventDuplicate: true
            });
          } catch (error) {
            enqueueSnackbar('Something went wrong', {
              variant: 'error',
              preventDuplicate: true
            });
            console.log('error', error);
          } finally {
            closeSnackbar(sBar);
            setLoading(false);
          }
          break;
        }
        case 'addProduct': {
          setLoading(true);
          try {
            const newProduct = {
              ...action.payload,
              enabled: true,
              modify: false,
              isValid: false
            };
            dispatch({
              type: 'updateData',
              payload: {
                [getCustomShopProductName(action.payload.name)]: newProduct,
                ...products
              }
            });
            enqueueSnackbar('Product added...', {
              variant: 'success',
              preventDuplicate: true,
              autoHideDuration: 1500
            });
          } catch (error) {
            enqueueSnackbar('Something went wrong', {
              variant: 'error',
              preventDuplicate: true
            });
            console.log('error', error);
          } finally {
            setLoading(false);
          }
          break;
        }
        case 'resetProducts': {
          setLoading(true);
          try {
            dispatch({
              type: 'updateData',
              payload: action.payload
            });
          } catch (error) {
            enqueueSnackbar('Something went wrong', {
              variant: 'error',
              preventDuplicate: true
            });
            console.log('error', error);
          } finally {
            setLoading(false);
          }
          break;
        }
        default:
          dispatch(action);
      }
    },
    [products, services]
  );
  const value = {
    products,
    dispatch: asyncDispatch,
    selectedShop
  };

  return (
    <ProductsContext.Provider value={value}>
      {props.children}
    </ProductsContext.Provider>
  );
};

function useProducts() {
  const context = useContext(ProductsContext);
  if (context === undefined || !Object.keys(context).length) {
    throw new Error('useProducts must be used within a ProductContext');
  }
  return context;
}

export { ProductsProvider, useProducts };
