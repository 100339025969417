import React from 'react';
import { Grid, IconButton, Link, useMediaQuery } from '@material-ui/core';
import LogoName from '../../components/organisms/LogoName';
import FacebookIcon from '../../assets/png/Facebook.png';
import TwiterIcon from '../../assets/png/Twitter.png';
import InstagramIcon from '../../assets/png/Instagram.png';
import YoutubeIcon from '../../assets/png/Youtube.png';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { googleFormLinks, socialSiteLinks } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    backgroundColor: '#194376'
  },
  container: {
    width: '100%',
    padding: '1.5rem 0',
    [theme.breakpoints.up('md')]: {
      width: '90%'
    }
  },
  socialMediaIcon: {
    padding: 0,
    margin: '0.4rem 0.2rem 0'
  },
  collapse: {
    paddingLeft: '2rem'
  },
  collapseListItem: {
    width: '100%',
    borderBottom: '1px solid rgb(196 196 196/0.1)'
  },
  newsItemBody: {
    color: '#7391BC',
    fontSize: '13px',
    fontWeight: 'bold',
    letterSpacing: '0.015em'
  },
  linkFont: {
    letterSpacing: '0.01em',
    fontWeight: 500,
    cursor: 'pointer',
    padding: '0 0.5rem',
    color: theme.palette.yellow.main || '#EDD242',
    borderRight: '2px solid rgba(196, 196, 196, 0.7)',
    marginRight: '0.5rem'
  }
}));

export default function Footer() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.container}>
        <Grid
          item
          xs={12}
          md={5}
          container
          alignItems="flex-end"
          justify={md ? 'flex-start' : 'center'}
          style={{ marginBottom: md ? 0 : '1rem', gap: '0.5rem' }}>
          <LogoName light size="large" />
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          container
          alignItems="center"
          justify={md ? 'flex-end' : 'center'}
          style={{ marginTop: md ? 0 : '1rem' }}>
          <Link
            href={googleFormLinks.joinUs}
            target="_blank"
            rel="noreferrer"
            className={classes.linkFont}>
            Join Us
          </Link>
          <Link
            href={socialSiteLinks.facebook}
            target="_blank"
            rel="noreferrer"
            className={classes.socialMediaIcon}>
            <img src={FacebookIcon} alt="FacebookIcon" />
          </Link>
          <Link
            // href="#"
            // target="_blank"
            rel="noreferrer"
            className={classes.socialMediaIcon}>
            <img src={TwiterIcon} alt="TwiterIcon" />
          </Link>
          <Link
            href={socialSiteLinks.instagram}
            target="_blank"
            rel="noreferrer"
            className={classes.socialMediaIcon}>
            <img src={InstagramIcon} alt="InstagramIcon" />
          </Link>
          <Link
            // href="#"
            // target="_blank"
            rel="noreferrer"
            className={classes.socialMediaIcon}>
            <img src={YoutubeIcon} alt="YoutubeIcon" />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
}
