import React, { useReducer, useState, useEffect, createContext } from 'react';
import { graphqlOperation, API, Auth, Hub } from 'aws-amplify';
import { byUserEmail } from '../../graphql/queries';
import { updateUser } from '../../graphql/mutations';
import amplifyAuthReducer from './amplifyAuthReducer';

const validGroups = [
  'admin',
  'laundryAdmin',
  'sales',
  'laundryDriver',
  'laundrySales',
  'laundryStaff',
  'customer'
];

const loadUserDetail = (email) =>
  new Promise((resolve) => {
    API.graphql(graphqlOperation(byUserEmail, { email: email }))
      .then((data) => {
        resolve(data.data.byUserEmail.items[0]);
      })
      .catch((err) => console.log('error', err));
  });

const AmplifyAuthContext = createContext({});

function AmplifyAuthProvider({ children }) {
  // const [state, dispatch] = useReducer(amplifyAuthReducer, { count: 0 })
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  /// const value = { state, dispatch }
  const initialState = {
    isLoading: false,
    isError: false,
    user: {},
    isAdmin: false,
    isLaundryAdmin: false,
    isLaundryStaff: false,
    isSales: false
  };
  const [state, dispatch] = useReducer(amplifyAuthReducer, initialState);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const fetchUserData = async () => {
    try {
      const data = await Auth.currentAuthenticatedUser();
      const { attributes } = data;
      const groupsFromCognito =
        data?.signInUserSession?.accessToken?.payload['cognito:groups'] || [];
      const groups = groupsFromCognito.filter((g) => validGroups.includes(g));
      let [, shopId] =
        (attributes['custom:shopId'] &&
          attributes['custom:shopId'].split('--')) ||
        []; // TODO will be removed
      if (!shopId) {
        shopId = attributes['custom:shopId'];
      }
      const shopLabel = attributes['custom:shopLabel'];
      // user data from cognito user pool
      const userData = {
        firstName: attributes.given_name || attributes['custom:firstName'],
        lastName: attributes.family_name || attributes['custom:lastName'],
        phoneNumber: attributes['custom:phoneNumber'],
        defaultAddress: attributes['custom:defaultAddress'],
        shopId: shopId,
        shopLabel: shopLabel,
        title: attributes['custom:title'],
        email: attributes['email'],
        sub: attributes['sub'],
        username: data.username,
        groups
      };
      dispatch({
        type: 'FETCH_USER_DATA_SUCCESS',
        payload: { user: userData }
      });
      if (data) {
        let userData = await loadUserDetail(data.attributes.email);
        if (!userData.userId) {
          const input = {
            id: userData.id,
            userId: data.attributes.sub || '',
            groups: groups
          };
          try {
            userData = await API.graphql(
              graphqlOperation(updateUser, { input })
            );
          } catch (e) {
            console.error('Error', e);
          }
        }
        dispatch({
          type: 'FETCH_USER_DATA_SUCCESS',
          payload: { user: userData }
        });
      }
    } catch (error) {
      dispatch({ type: 'FETCH_USER_DATA_FAILURE' });
    }
  };

  const onAuthEvent = (payload) => {
    switch (payload.event) {
      case 'signIn':
        setTriggerFetch(true);
        console.log('signed in');
        break;
      case 'customOAuthState':
        if (payload.data) {
          const data = JSON.parse(payload.data);
          if (data.rURL) {
            window.location.replace(data.rURL);
          }
        }
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    Hub.listen('auth', (data) => {
      const { payload } = data;
      onAuthEvent(payload);
    });
    fetchUserData();
    return () => {
      Hub.remove('auth');
    };
  }, [triggerFetch]);

  const _updateUser = async (data) => {
    const user = await Auth.currentAuthenticatedUser();
    const resp = await Auth.updateUserAttributes(user, data);
    fetchUserData();
  };

  const _refreshUser = () => {
    fetchUserData();
  };

  const handleSignOut = async () => {
    try {
      console.log('signed out');
      await Auth.signOut();
      setTriggerFetch(false);
      dispatch({ type: 'RESET_USER_DATA' });
    } catch (error) {
      console.error('Error signing out user ', error);
    }
  };

  const value = { state, _updateUser, handleSignOut, _refreshUser };

  return (
    <AmplifyAuthContext.Provider value={value}>
      {children}
    </AmplifyAuthContext.Provider>
  );
}

function useAmplifyAuth() {
  const context = React.useContext(AmplifyAuthContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a AmplifyAuthProvider');
  }
  return context;
}

export { AmplifyAuthProvider, useAmplifyAuth };
