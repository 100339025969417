import React from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Rating } from '@material-ui/lab';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2)
  },
  cardcontent: {
    height: '98px',
    padding: 2,
    '&:last-child': {
      paddingBottom: 0
    },
    display: 'flex',
    alignContent: 'space-between'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    margin: theme.spacing(1)
  },
  card: {
    width: '274px',
    height: '251px',
    boxShadow: 'none'
  },
  media: {
    height: 0,
    paddingTop: '56.25%' // 16:9
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cardText: {
    backgroundColor: 'green'
  },
  icon: {
    color: theme.palette.subtitleColor,
    padding: theme.spacing(0, 1)
  },
  typography: {
    color: theme.palette.subtitleColor
  },
  errorMessenger: {
    padding: theme.spacing(2, 5)
  }
}));
const ShopDetails = props => {
  const { userFilterItems, shops = [], postCode } = props;
  const classes = useStyles();
  const history = useHistory();
  const bannerImg = '/images/cardMedia.jpg';

  const Shops = props => {
    const { displayItem } = props;
    return (
      <Grid
        container
        spacing={7}
      >
        {displayItem.length === 0 ? (
          <div className={classes.errorMessenger}>
            <Typography>Sorry,No record Found</Typography>
          </div>
        ) : (
          displayItem.map(item => (
            <Grid
              item
              key={item.shopID}
              md={4}
            >
              <Card
                border={0}
                className={classes.card}
                key={item.shopID}
              >
                <CardActionArea
                  onClick={() => history.push(`/shop/${item.shopID}`)}
                >
                  <CardMedia
                    className={classes.media}
                    image={bannerImg}
                    title="serviceImg"
                  />
                  <CardContent className={classes.cardcontent}>
                    <Grid container>
                      <Grid
                        item
                        md={12}
                      >
                        <Typography variant="h5">{item.name}</Typography>
                        <Typography variant="caption">
                          {item.address},{item.town},{item.postcode}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={12}
                      >
                        <div className={classes.cardFooter}>
                          <Rating
                            name="unique-rating"
                            size="small"
                          />
                          <Typography
                            className={classes.typography}
                            paragraph={false}
                          >
                            {item.distance > 152 ? 
                              (item.distance / 1609).toFixed(2)+'mi' : 
                              Math.ceil(item.distance * 3.281)+ 'ft'}
                            <DirectionsRunIcon
                              className={classes.icon}
                              size="small"
                            />
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    );
  };
  if (userFilterItems.length !== 0) {
    const filterItem = shops
      .map(shop => ({
        ...shop,
        products: shop.products.items.filter(product => {
          return userFilterItems.includes(product.name || product.item?.name);
        })
      }))
      .filter(shop => shop.products.length !== 0)
      .sort((a, b) => a.Distance - b.Distance);
    return <Shops
      displayItem={filterItem}
      postCode={postCode}
           />;
  } else {
    if (typeof shops === 'object' && shops.length > 0) {
      const filterItem = shops.filter(shop => shop.products.items.length !== 0);
      return <Shops
        displayItem={filterItem}
        postCode={postCode}
             />;
    } else {
      return null;
    }
  }
};

export default ShopDetails;
