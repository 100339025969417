import React, { useState, useEffect } from 'react';
import ShopFilters from './ShopFilters';
import ShopsCarousel from './ShopsCarousel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Drawer,
  useMediaQuery,
  Button,
  CircularProgress,
  Typography
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import filterIcon from '../assets/filterIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '3rem',
    [theme.breakpoints.up('lg')]: {
      padding: '3rem 0',
      maxWidth: '90%'
    },
    overflowX: 'hidden'
  },
  title: {
    fontWeight: 'Bold',
    margin: theme.spacing(4, 0)
  },
  divider: {
    color: theme.palette.divider
  },
  content: {
    height: 'fit-content',
    padding: 0,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '1.5rem'
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '3.5rem'
    }
  },
  clearAll: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  searchBox: {
    borderRadius: '10px'
  },
  searchIcon: {
    color: theme.palette.grey[400]
  },
  toggleDrawerBtn: {
    position: 'fixed',
    top: '50%',
    right: 0,
    backgroundColor: theme.palette.yellow.main
  },
  saveBtn: {
    color: 'black',
    jbackgroundColor: theme.palette.yellow.main
  }
}));

const ShopsViews = (props) => {
  const {
    categories = [],
    shops = [],
    shopFilters,
    setShopFilters,
    unfilteredCategories,
    isShopsLoading = false,
    resetFilters = () => {},
    showResetFiltersBtn = false
  } = props;
  const [servicesWithShops, setServicesWithShops] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchName, setSearchName] = useState('');
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const classes = useStyles();

  const closeDrawer = () => setOpenDrawer(false);

  useEffect(
    () =>
      setServicesWithShops(
        categories.map((category) => ({
          ...category,
          shops: shops.filter(
            (shop) =>
              [
                ...shop.products?.items?.filter(
                  (item) => item.serviceID === category.id
                )
              ].length !== 0
          )
        }))
      ),
    [categories, shops]
  );

  const filteredVisibleShops = servicesWithShops.every(
    (servicesWithShop) => servicesWithShop.shops?.length < 1
  );

  return (
    <>
      <Grid container justify="space-between" className={classes.root}>
        {!lg ? (
          <IconButton
            onClick={() => setOpenDrawer(true)}
            className={classes.toggleDrawerBtn}>
            <img src={filterIcon} alt="toggle-filter-button" />
          </IconButton>
        ) : (
          <Grid item xs={2}>
            <ShopFilters
              services={unfilteredCategories}
              shopFilters={shopFilters}
              setShopFilters={setShopFilters}
              resetFilters={resetFilters}
              showResetFiltersBtn={showResetFiltersBtn}
            />
          </Grid>
        )}
        {isShopsLoading ? (
          <Grid
            container
            justify="center"
            alignItems="center"
            item
            xs={12}
            lg={9}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid
            item
            container
            alignItems="flex-start"
            justify="flex-start"
            xs={12}
            lg={10}
            className={classes.content}>
            <Grid item container justify="flex-end">
              <Grid item xs={12} lg={4}>
                <TextField
                  style={{ marginBottom: '2.5rem' }}
                  variant="outlined"
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                  fullWidth
                  InputProps={{
                    classes: { root: classes.searchBox },
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon className={classes.searchIcon} />
                      </InputAdornment>
                    )
                  }}
                  placeholder="Shop Name"
                />
              </Grid>
            </Grid>
            {!filteredVisibleShops ? (
              servicesWithShops
                .filter((item) => item.shops?.length > 0)
                .map((service, index) => (
                  <Grid
                    container
                    alignItems="flex-start"
                    key={`shops-carousels-shops-search-results-items-${index}`}>
                    <Grid container item xs={12}>
                      <ShopsCarousel
                        service={service}
                        searchName={searchName}
                        className={classes.shops}
                      />
                    </Grid>
                  </Grid>
                ))
            ) : (
              <Grid item container justify="center" alignItems="center" xs={12}>
                <Typography align="center" variant="h3">
                  Register your interest so that we can be there shortly...
                </Typography>
              </Grid>
            )}
            {/* <CategoryList showAsCard={true} /> */}
          </Grid>
        )}
      </Grid>
      <Drawer anchor="right" open={openDrawer} onClose={closeDrawer}>
        <div style={{ width: '100vw' }} role="presentation">
          <ShopFilters
            services={unfilteredCategories}
            shopFilters={shopFilters}
            setShopFilters={setShopFilters}
          />
          <Grid
            container
            justify="center"
            style={{ gap: '22px', paddingBottom: '22px' }}>
            <Button size="large" variant="outlined" onClick={closeDrawer}>
              Cancel
            </Button>
            <Button
              size="large"
              variant="contained"
              onClick={closeDrawer}
              className={classes.saveBtn}>
              Save
            </Button>
          </Grid>
        </div>
      </Drawer>
    </>
  );
};

export default ShopsViews;
