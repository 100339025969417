import { API, graphqlOperation } from 'aws-amplify';
import { listShops, listShopsWithProductByID } from './shopsQueries';
import { updateShop } from 'graphql/mutations';

export const loadShops = (limit = 100, nextToken = '') =>
  new Promise((resolve) => {
    API.graphql(graphqlOperation(listShops(limit, nextToken))).then((data) => {
      //TODO error catch
      const shops = data.data.listShops.items.filter(
        (item) => item._deleted !== true && item.status !== 'rejected'
      );
      const nextToken = data.data.listShops.nextToken;
      resolve({ nextToken, shops });
    });
  });

export const loadShopsWithProductByID = ({ shopID }) =>
  new Promise((resolve) => {
    API.graphql(graphqlOperation(listShopsWithProductByID(shopID))).then(
      (data) => {
        if (data?.data?.listShops?.items?.length > 0) {
          resolve(data.data.listShops.items[0]);
        } else {
          resolve('NO_DATA');
        }
      }
    );
  });

export const updateShopData = (input) =>
  new Promise((resolve, reject) =>
    API.graphql(
      graphqlOperation(updateShop, {
        input
      })
    )
      .then((data) => resolve(data.data.updateShop))
      .catch((err) => reject(err))
  );
