import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useShops } from 'context/shop/shopsContext';
import { useAmplifyAuth } from 'context';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `${theme.spacing(1)}px 0px`
  }
}));

const SearchShop = (props) => {
  const { onChange = () => {}, value } = props;
  const classes = useStyles();
  const { shops = [], dispatch } = useShops();
  let {
    state: { user, isSales }
  } = useAmplifyAuth();

  useEffect(() => {
    const payload = { limit: 5000, newQuery: true };
    if (isSales) {
      payload.salesUser = user?.id;
    }
    dispatch({ type: 'getShops', payload });
  }, [user]);

  return (
    <Autocomplete
      id="select-shop"
      disableClearable
      value={value}
      options={['', ...shops]}
      fullWidth
      className={classes.root}
      getOptionSelected={(option, value) => option?.id === value?.id}
      getOptionLabel={(option) =>
        !!option
          ? `${option?.shopID || ''} - ${option?.name || ''} - ${
              option?.postcode || ''
            }`
          : ''
      }
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          size="small"
          label="Select Shop"
          variant="outlined"
        />
      )}
    />
  );
};

SearchShop.propTypes = {
  onChange: PropTypes.func
};

export default SearchShop;
