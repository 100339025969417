import React from 'react';
import { CardActions, Button } from '@material-ui/core';
import { routes } from 'common/constants';
import { useHistory } from 'react-router-dom';
import { useAmplifyAuth } from 'context';

const FormActionButton = ({
  isAddNewPage,
  selectedShop,
  resetHandler,
  handleSubmit,
  buttonAction,
  pageType,
  setDeleteDialog
}) => {
  const history = useHistory();
  const {
    state: { isAdmin = false }
  } = useAmplifyAuth();
  return (
    <CardActions>
      {isAddNewPage ? (
        <React.Fragment>
          <Button
            color="primary"
            disabled={!selectedShop}
            type="submit"
            variant="contained"
            size="small">
            Add Shop details
          </Button>
          <Button
            color="secondary"
            disabled={!selectedShop}
            variant="contained"
            size="small"
            onClick={resetHandler}>
            Reset
          </Button>
        </React.Fragment>
      ) : (
        <>
          <Button
            color="primary"
            onClick={(e) => {
              handleSubmit(e, buttonAction.SAVE);
            }}
            variant="contained">
            Save
          </Button>
          {pageType !== 'shop' && (
            <Button
              color="primary"
              onClick={(e) => {
                handleSubmit(e, buttonAction.SAVE_AND_CLOSE);
              }}
              variant="outlined">
              Save and Close
            </Button>
          )}
          <Button
            color="primary"
            onClick={() => {
              if (pageType == 'shop') {
                history.push('/');
              } else {
                history.push(routes.admin.shops);
              }
            }}
            variant="outlined">
            Cancel
          </Button>
          {isAdmin &&
            pageType !== 'shop' &&
            selectedShop?.status !== 'rejected' && (
              <Button
                color="primary"
                onClick={() => {
                  setDeleteDialog(true);
                }}
                variant="outlined">
                Delete
              </Button>
            )}
        </>
      )}
    </CardActions>
  );
};

export default FormActionButton;
