export const listServices = /* GraphQL */ `
  query ListServices {
    listServices(limit: 1000) {
      items {
        items {
          items {
            id
            name
            image
            description
            serviceID
            _deleted
            _version
          }
        }
        id
        name
        description
        orderIndex
        type
        _deleted
        _version
      }
    }
  }
`;

export default { listServices };
