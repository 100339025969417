import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'common/constants';
import { API, graphqlOperation } from 'aws-amplify';
import { updateShop } from 'graphql/mutations';
import { makeStyles } from '@material-ui/core/styles';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import AssignmentIcon from '@material-ui/icons/Assignment';
import MapIcon from '@material-ui/icons/Map';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Paper
} from '@material-ui/core';
import { SubSideNavBar } from 'components/organisms';
import { Context } from '../../../context/shopDetail/shopContext';
import { useSnackbar } from 'notistack';
import { useLoader } from '../../../layouts/loaderContext';
import { useShops } from 'context/shop/shopsContext';
import ShopDetails from './ShopDetails';
import CollectionAndDeliveryHours from './CollectionAndDeliveryHours';
import DropPickupInstructions from './DropPickupInstructions';
import DocumentsUpload from './DocumentsUpload';
import ShopWorkingHours from './ShopWorkingHours';
import AboutShop from './AboutShop';
import { Provider } from '../../../context/shopDetail/shopContext';
import PhotosUplaod from './PhotosUpload';
import AddressOnGoogle from './AddressOnGoogle';
import { getLatLngByPostCode } from 'graphql/queries';
import { shopFormFields } from 'common/constants';
import { shopFieldvalidation } from 'common/validators';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#eeeeee',
    overflowY: 'hidden',
    padding: '0',
    margin: '0'
  },
  leftGrid: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#c2c2c2',
    backgroundColor: 'white',
    overflowY: 'hidden'
  },
  scrollContainer: {
    maxHeight: '80vh',
    minHeight: '80vh',
    overflow: 'scroll',
    width: '100%',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem'
  }
}));

const fields = [
  {
    icon: <SettingsOutlinedIcon />,
    title: 'Shop Details',
    description: 'Shop Name and Contact Details',
    Component: ShopDetails,
    id: 1,
    route: routes.serviceProvider.shopDetail
  },
  {
    icon: <VerifiedUserOutlinedIcon />,
    title: 'Working Hours',
    description: 'Information about Shop Working Hours',
    Component: ShopWorkingHours,
    id: 2,
    route: routes.serviceProvider.shopWorkingHours
  },
  {
    icon: <NotificationsActiveOutlinedIcon />,
    title: 'Collection and Delivery Hours',
    description: 'Information about Shop Collection and Delivery Hours',
    Component: CollectionAndDeliveryHours,
    id: 3,
    route: routes.serviceProvider.shopCollectionDeliveryHours
  },
  {
    icon: <InsertDriveFileOutlinedIcon />,
    title: 'Drop & Pickup Instructions',
    description: 'Shop Related Drop & Pickup Instructions',
    Component: DropPickupInstructions,
    id: 8,
    route: routes.serviceProvider.shopInstructions
  },
  {
    icon: <ReceiptOutlinedIcon />,
    title: 'Documents',
    description: 'Shop Related Documents',
    Component: DocumentsUpload,
    id: 4,
    route: routes.serviceProvider.shopDocuments
  },
  {
    icon: <ArtTrackIcon />,
    title: 'Shop Photos & Videos',
    description: 'Shop Photos and Videos',
    Component: PhotosUplaod,
    id: 5,
    route: routes.serviceProvider.shopPhotos
  },
  {
    icon: <MapIcon />,
    title: 'Find my shop on Google Maps',
    description: 'Shop location on Google Maps',
    Component: AddressOnGoogle,
    id: 6,
    route: routes.serviceProvider.shopMap
  },
  {
    icon: <AssignmentIcon />,
    title: 'About the Shop',
    Component: AboutShop,
    id: 7,
    route: routes.serviceProvider.shopAbout
  }
];

const GetActiveComponent = (props) => {
  let { Component } = fields?.find(
    (menu) => menu.id === props.activecomponentid
  );
  return <Component {...props} />;
};

const SubSidebarNav = (props) => {
  const history = useHistory();
  const [activeComponent, setActiveComponent] = useState(1);
  const classes = useStyles();
  const { shopId, pageType } = props;
  const { selectedShop, setSelectedShop } = useContext(Context);
  const { dispatch } = useShops();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setLoading } = useLoader();
  const [enablePriceText, setEnablePriceText] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState({});
  const [inititalShop, setInititalShop] = useState({});
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    history.replace(routes.serviceProvider.shopDetail);
  }, []);

  useEffect(() => {
    let mounted = true;

    const addShop = async (id) => {
      if (!id) {
        mounted && setSelectedShop({});
        return;
      }
      const data = await dispatch({
        type: 'getSelectedShop',
        payload: { shopId: id }
      });
      if (!data) {
        if (pageType == 'shop') {
          history.push('/');
        } else {
          history.push(routes.admin.shops);
        }
      } else {
        mounted && setSelectedShop(data);
        mounted && setInititalShop(data);
      }
    };
    addShop(shopId);

    return () => {
      mounted = false;
    };
  }, [shopId]);

  useEffect(() => {
    let mounted = true;
    !!selectedShop?.minPriceForFree && mounted && setEnablePriceText(true);

    return () => {
      mounted = false;
    };
  }, [selectedShop]);

  const handleChange = (event, newValue) => {
    setDisabled(false);
    if (event.target.name === 'phoneNumber') {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
    const value =
      event.target.name === 'country'
        ? event.target.value
        : event.target.name === 'town'
        ? event.target.value
        : newValue || event.target.value;
    setSelectedShop({
      ...selectedShop,
      [event.target.name]: value
    });
    shopFieldvalidation({
      ...selectedShop,
      [event.target.name]: value
    });
  };

  const handleSubmit = async (event, extras = {}) => {
    event.preventDefault();
    const _validate = shopFormFields.every(({ label, property }) => {
      if (!selectedShop[property]) {
        enqueueSnackbar(label + ' is a mandatory field', {
          variant: 'warning',
          preventDuplicate: true
        });
      }
      // need to use later
      // else if (formErrors[property]) {
      //   enqueueSnackbar(formErrors[property], {
      //     variant: 'warning',
      //     preventDuplicate: true
      //   });
      // }
      return !!selectedShop[property];
      // && !formErrors[property];
    });

    if (!_validate) {
      return;
    }

    const {
      place_id: googlePlaceId,
      name,
      contactName,
      address,
      postcode,
      town,
      country,
      email,
      phoneNumber,
      location,
      workHrs,
      collectionHrs = [],
      deliveryHrs = [],
      minPriceForFree,
      distanceRange,
      language,
      websiteLink = '',
      about,
      standardDeliveryFee = 0,
      enableAsapDelivery = false,
      AsapDeliveryFee = 0,
      collectionInstructions = '',
      deliveryInstructions = ''
    } = { ...selectedShop, ...extras };
    if (!workHrs) {
      enqueueSnackbar('Shop Open Hours is a mandatory field', {
        variant: 'warning',
        preventDuplicate: true
      });
      return;
    }
    if (!location?.lat || !location.lng) {
      enqueueSnackbar('Please select location for shop', {
        variant: 'warning',
        preventDuplicate: true
      });
      return;
    }

    if (collectionHrs.length > 0 || deliveryHrs.length > 0) {
      if (!distanceRange) {
        enqueueSnackbar(
          '"Collection and delivery distance range" is a mandatory field',
          { variant: 'warning', preventDuplicate: true }
        );
        return;
      }
      if (!standardDeliveryFee) {
        enqueueSnackbar(
          '"Standard collection or delivery fee" is a mandatory field',
          { variant: 'warning', preventDuplicate: true }
        );
        return;
      }
      if (enablePriceText && !minPriceForFree) {
        enqueueSnackbar(
          '"Free collection and delivery for order amount more than" is a mandatory field',
          { variant: 'warning', preventDuplicate: true }
        );
        return;
      }
    }

    if (enableAsapDelivery && !AsapDeliveryFee) {
      enqueueSnackbar(
        '"ASAP collection and delivery price" is a mandatory field',
        { variant: 'warning', preventDuplicate: true }
      );
      return;
    }

    const workingDays = workHrs.map((day) => day.openDay);
    const workingDaysCollectionHrs = collectionHrs.filter((colHr) =>
      workingDays.some((day) => colHr.openDay === day)
    );
    const workingDaysDeliveryHrs = deliveryHrs.filter((delHr) =>
      workingDays.some((day) => delHr.openDay === day)
    );

    try {
      const payload = {
        input: {
          googlePlaceId,
          name,
          contactName,
          address,
          postcode,
          town,
          country,
          email,
          phoneNumber,
          location,
          workHrs,
          collectionHrs: workingDaysCollectionHrs,
          deliveryHrs: workingDaysDeliveryHrs,
          distanceRange: distanceRange ? parseFloat(distanceRange) : null,
          minPriceForFree: minPriceForFree ? parseFloat(minPriceForFree) : null,
          standardDeliveryFee,
          enableAsapDelivery,
          AsapDeliveryFee,
          language,
          websiteLink,
          about,
          collectionInstructions,
          deliveryInstructions
        }
      };

      if (inititalShop.collectionHrs === payload.input.collectionHrs) {
        delete payload.input.collectionHrs;
      }
      if (inititalShop.deliveryHrs === payload.input.deliveryHrs) {
        delete payload.input.deliveryHrs;
      }
      if (inititalShop.workHrs === payload.input.workHrs) {
        delete payload.input.workHrs;
      }
      if (inititalShop.language === payload.input.language) {
        delete payload.input.language;
      }

      // TODO: combine API with graphql
      if (selectedShop.id) {
        const { _version, id } = selectedShop;
        payload.input._version = _version;
        payload.input.id = id;
        const sBar = enqueueSnackbar('Updating Shop data...', {
          variant: 'info',
          persist: true
        });
        setLoading(true);
        const result = await API.graphql(graphqlOperation(updateShop, payload));
        closeSnackbar(sBar);
        enqueueSnackbar('Saved Shop successfully', {
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 1000
        });
        setLoading(false);
        dispatch({
          type: 'updateShop',
          payload: result.data.updateShop
        });
        setInititalShop(result.data.updateShop);
        setSelectedShop(result.data.updateShop);
        setDisabled(true);
      }
    } catch (e) {
      console.error('Error: ', e);
    }
  };

  const handleNumberChange = (e) => {
    setDisabled(false);
    const re = /^[0-9]+\.?[0-9]*$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setSelectedShop({
        ...selectedShop,
        [e.target.name]: e.target.value === '' ? 0 : e.target.value
      });
    }
  };

  const handlePostCodeOnBlur = () => {
    // need to update the lat and lng in selectedShop by using the new postcode
    API.graphql(
      graphqlOperation(getLatLngByPostCode, {
        postalCode: selectedShop.postcode
      })
    )
      .then((data) => {
        data?.data?.getLatLngByPostCode &&
          setSelectedShop({
            ...selectedShop,
            location: {
              lat: data.data.getLatLngByPostCode.lat,
              lng: data.data.getLatLngByPostCode.lng
            }
          });
      })
      .catch((e) => console.error('postcode data not found', e));
  };

  const onCancel = () => {
    setDisabled(true);
    setSelectedShop(inititalShop);
    setEnablePriceText(false);
    setFormErrors({});
  };

  const handleActiveFormSection = (id) => {
    setActiveComponent(id);
    const { route } = fields.find((field) => field.id === id);
    history.replace(route);
  };

  return (
    <Provider>
      <Grid container className={classes.root}>
        <Grid className={classes.leftGrid} item md={3} xs={12}>
          <SubSideNavBar
            currentItem={fields.find((item) => item.id === activeComponent)}>
            <List style={{ padding: 0 }}>
              {fields.map((item) => {
                return (
                  <ListItem
                    component={Paper}
                    key={item.id}
                    button
                    disabled={!disabled}
                    onClick={() => handleActiveFormSection(item.id)}
                    selected={activeComponent === item.id}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText
                      primary={item.title}
                      secondary={item.description || ''}
                    />
                  </ListItem>
                );
              })}
            </List>
          </SubSideNavBar>
        </Grid>
        <Grid item md={9} xs={12}>
          <PerfectScrollbar className={classes.scrollContainer}>
            <Paper style={{ height: 'fit-content', minHeight: '100%' }}>
              <GetActiveComponent
                activecomponentid={activeComponent}
                pageType={pageType}
                shopId={shopId}
                handleChange={handleChange}
                validation={shopFieldvalidation}
                handleNumberChange={handleNumberChange}
                selectedShop={selectedShop}
                setSelectedShop={setSelectedShop}
                handlePostCodeOnBlur={handlePostCodeOnBlur}
                formErrors={formErrors}
                onCancel={onCancel}
                handleSubmit={handleSubmit}
                disabled={disabled}
                enablePriceText={enablePriceText}
                setEnablePriceText={setEnablePriceText}
                fields={shopFormFields}
                setDisabled={setDisabled}
              />
            </Paper>
          </PerfectScrollbar>
        </Grid>
      </Grid>
    </Provider>
  );
};

export default SubSidebarNav;
