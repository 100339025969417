import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState
} from 'react';
import { useSnackbar } from 'notistack';
import { useLoader } from 'layouts/loaderContext';
import * as couponsAPIs from './couponsAPIs';

const CURR_TIME = new Date().toISOString();
const CouponsContext = createContext({});

const couponsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'updateData': {
      return payload || [];
    }
    case 'addData': {
      return [...payload, ...state];
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

const CouponsProvider = (props) => {
  const [coupons, dispatch] = useReducer(couponsReducer, []);
  const [selectedShop, setSelectedShop] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setLoading } = useLoader();

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getCouponsByShop': {
          const { shopID = '' } = action?.payload || {};
          if (shopID === selectedShop) return;
          setLoading(true);
          const snackBar = enqueueSnackbar('Loading Coupons...', {
            variant: 'info',
            persist: true
          });
          setSelectedShop(shopID);
          try {
            const resp = await couponsAPIs.loadCouponsByShop({
              shopID,
              limit: 5000
            });
            const data =
              resp.items?.filter(
                (item) =>
                  !item._deleted &&
                  item?.startDateTime < CURR_TIME &&
                  CURR_TIME < item?.endDateTime
              ) || [];
            dispatch({
              type: 'updateData',
              payload: data
            });
          } catch (error) {
            console.error('Something went wrong... ', error);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        case 'createCoupon': {
          setLoading(true);
          const { coupon, successCallback = () => {} } = action.payload;
          try {
            const data = await couponsAPIs.createCoupon(coupon);
            dispatch({
              type: 'addData',
              payload: [data]
            });
            enqueueSnackbar('Coupon Saved', {
              variant: 'success',
              autoHideDuration: 2500
            });
            successCallback();
          } catch (error) {
            console.error('Something went wrong... ', error);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
          }
          break;
        }
        case 'deleteCoupon': {
          setLoading(true);
          const { couponID = '' } = action.payload;
          const currCoupon = coupons.find((item) => item.id === couponID);
          if (!currCoupon) return;
          try {
            await couponsAPIs.deleteCoupon({
              id: currCoupon.id,
              _version: currCoupon._version
            });
            const updatedCoupons = coupons.filter(
              (item) => item.id !== couponID
            );
            dispatch({
              type: 'updateData',
              payload: updatedCoupons
            });
            enqueueSnackbar('Coupon Deleted', {
              variant: 'success',
              autoHideDuration: 2500
            });
          } catch (error) {
            console.error('Something went wrong... ', error);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
          }
          break;
        }
        default:
          dispatch(action);
      }
    },
    [coupons]
  );

  const value = {
    coupons,
    dispatch: asyncDispatch
  };

  return (
    <CouponsContext.Provider value={value}>
      {props.children}
    </CouponsContext.Provider>
  );
};

const useCoupons = () => {
  const context = useContext(CouponsContext);
  if (!(context && Object.keys(context).length)) {
    throw new Error('useCoupons must be used within a CouponsContext');
  }
  return context;
};

export { useCoupons, CouponsProvider };
