import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import {
  Password,
  AccountProfile,
  Notifications,
  Appearence,
  SideBar
} from './components';
import { useAmplifyAuth } from '../../context';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#E5E5E5',
    overflow: 'hidden',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&:-webkit-scrollbar': {
      display: 'none'
    }
  },
  contentContainer: {
    marginLeft: '1rem',
    marginTop: '0.2rem',
    marginRight: '1rem',
    paddingRight: '0.2rem'
  },
  scrollContent: {
    overflowY: 'scroll',
    maxHeight: '82vh',
    minHeight: '82vh',
    width: '100%'
  }
}));

const ServiceSettings = () => {
  const [currentTab, setCurrentTab] = useState('profile');
  const classes = useStyles();
  const {
    state: { user }
  } = useAmplifyAuth();

  return (
    user && (
      <Grid container className={classes.root}>
        <Grid container item xs={12} md={3}>
          <SideBar setCurrentTab={setCurrentTab} />
        </Grid>
        <Grid container item xs={12} md={9}>
          <PerfectScrollbar
            className={clsx(classes.scrollContent, classes.contentContainer)}>
            {currentTab === 'profile' ? (
              <AccountProfile />
            ) : currentTab === 'password' ? (
              <Password />
            ) : currentTab === 'notifications' ? (
              <Notifications />
            ) : currentTab === 'appearence' ? (
              <Appearence />
            ) : (
              <>{currentTab}</>
            )}
          </PerfectScrollbar>
        </Grid>
      </Grid>
    )
  );
};

export default ServiceSettings;
