import { API, graphqlOperation } from 'aws-amplify';
import { listServices } from './serviceQueries';
import mutations from './serviceMutations';
import { uploadImage, compressImage } from 'common/utilFunctions';

/* Service  APIs */
const loadService = () =>
  new Promise((resolve) => {
    API.graphql(graphqlOperation(listServices)).then((data) => {
      //TODO error catch
      const services = data.data.listServices.items.filter(
        (item) => item._deleted !== true
      );
      const filteredServices = services
        .map((item) => {
          const items =
            item.items.items &&
            item.items.items.filter((item) => item._deleted !== true);
          return { ...item, items: { items } };
        })
        .sort((a, b) => (a.orderIndex || 0) - (b.orderIndex || 0));
      resolve(filteredServices);
    });
  });

const addNewService = (service) =>
  new Promise((resolve) => {
    const payload = {
      input: service
    };
    API.graphql(
      graphqlOperation(mutations.createService, payload)
    ).then((data) => resolve(data));
  });

const updateNewService = (service) =>
  new Promise((resolve) => {
    const payload = {
      input: service
    };
    API.graphql(
      graphqlOperation(mutations.updateService, payload)
    ).then((data) => resolve(data));
  });

/* Item  APIs */
const addNewItem = (input) =>
  new Promise((resolve) => {
    const payload = {
      input
    };
    API.graphql(graphqlOperation(mutations.createItem, payload)).then((data) =>
      resolve(data)
    );
  });

const performDelete = ({ type, payload }) =>
  new Promise((resolve) => {
    const { id, _version, moduleType } = payload;
    API.graphql(
      graphqlOperation(mutations[type + moduleType], {
        input: { id, _version }
      })
    ).then((data) => resolve(data));
  });

const performUpdate = async ({ type, payload }, file) => {
  const { _deleted, moduleType, ...inputData } = payload;

  let input = inputData;
  if (file && moduleType == 'Item') {
    const compressedFile = await compressImage(file);
    const imageUrl = await uploadImage(compressedFile);
    if (imageUrl) {
      input.image = imageUrl;
    }
  }

  const data = await API.graphql(
    graphqlOperation(mutations[type + moduleType], { input })
  );
  return data;
};

export {
  loadService,
  addNewService,
  addNewItem,
  performDelete,
  performUpdate,
  updateNewService
};
