import React from 'react';
import { Address } from 'components/organisms';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Modal, Paper, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  modalScroll: {
    overflowY: 'scroll',
    maxHeight: '90vh',
    paddingRight: '15px'
  },
  modalBox: {
    padding: '0 20px',
    marginTop: '20px',
    maxWidth: '90%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '50%'
    }
  }
}));

const AddressModal = ({
  open = false,
  handleClose = () => {},
  updateAddressList = () => {},
  showAddNewAddressForm = false,
  hideAddNewAddressForm = () => {},
  selectedAddress = null
}) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="address-details-modal-title"
      aria-describedby="address-details-modal-description">
      <Grid container justify="center" alignItems="center">
        <Paper className={classes.modalBox}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ margin: '10px 0' }}>
            <Typography variant="h4">
              {showAddNewAddressForm ? 'Add Address' : 'Address Details'}{' '}
            </Typography>
            <IconButton onClick={handleClose} style={{ padding: 0 }}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <PerfectScrollbar className={classes.modalScroll}>
            {showAddNewAddressForm ? (
              <Address
                mode={3}
                onCancel={hideAddNewAddressForm}
                updateAddressList={updateAddressList}
              />
            ) : (
              <Address
                mode={1}
                data={selectedAddress}
                updateAddressList={updateAddressList}
              />
            )}
          </PerfectScrollbar>
        </Paper>
      </Grid>
    </Modal>
  );
};

export default AddressModal;
