import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, Grid, Tabs, Tab, Paper } from '@material-ui/core';
import { getCustomShopProductName } from 'common/utilFunctions';
import { ProductsToolbar, ProductsTable, AddProduct } from './components';
import SearchShop from 'components/SearchShop';
import { useServices } from '../Services/serviceContext';
import { useProducts } from './productsContext';
import { useAmplifyAuth } from 'context';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  autoComplete: {
    margin: theme.spacing(1),
    minWidth: 500
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: 10
  },
  footer: {
    flexGrow: 1,
    padding: 10
  },
  tabs: {
    border: '1px solid #DDDDDD',
    borderBottom: 'none',
    marginRight: 5
  },
  wrapper: {
    display: 'table'
  },
  checkbox: {
    paddingLeft: 0
  }
}));

const initialNewProduct = {
  service: '',
  name: '',
  description: ''
};

const ProductList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [disabled, setDisabled] = useState(true);
  const { services, dispatch: dispatchService } = useServices();
  const { products, selectedShop, dispatch } = useProducts();
  const [initialProducts, setInitialProducts] = useState({});
  const [showAddProductDialog, setShowAddProductDialog] = useState(false);
  const [newProduct, setNewProduct] = useState(initialNewProduct);
  const [items, setItems] = useState([]);

  let {
    state: { user, isAdmin, isSales }
  } = useAmplifyAuth();

  useEffect(() => {
    dispatchService({ type: 'getService' });
  }, [dispatchService]);

  useEffect(() => {
    let mounted = true;
    if (user && user.shopId) {
      dispatch({
        type: 'getProductsByShopId',
        payload: { id: user.shopId }
      }).then((data) => mounted && setInitialProducts(data));
    }

    return () => {
      mounted = false;
    };
  }, [user]);

  useEffect(() => {
    if (products) {
      updateItems();
    }
  }, [value, services, Object.keys(products).length]);

  const updateItems = () => {
    const itemsUnordered = services[value]?.items?.items || [];
    const newProductItems = Object.entries(products)
      .filter(
        ([key, val]) =>
          key.includes('shopProduct') && val.serviceID === services[value]?.id
      )
      .map(([key, val]) => ({
        name: '',
        description: '',
        image: '',
        id: key,
        serviceID: services[value]?.id
      }));
    const oldItemIds = items
      .map((item) => item.id)
      .filter((id) => !id.includes('shopProduct'));
    const oldShopProductItemIds = items
      .map((item) => item.id)
      .filter((id) => id.includes('shopProduct'));
    if (
      !(
        oldItemIds.length === itemsUnordered.length &&
        itemsUnordered.every((item) => oldItemIds.includes(item.id)) &&
        oldShopProductItemIds.length === newProductItems.length
      )
    ) {
      setItems([
        ...newProductItems,
        ...itemsUnordered.filter((item) => !!products[item.id]),
        ...itemsUnordered.filter((item) => !products[item.id])
      ]);
    }
  };

  const handleChange = (event, newValue) => setValue(newValue);

  const handleSelectChange = (event, newValue) => {
    if (newValue?.id) {
      dispatch({
        type: 'getProductsByShopId',
        payload: newValue
      }).then((data) => {
        setInitialProducts(data);
      });
    }
  };

  const addProduct = () => {
    const { service, ...rest } = newProduct;
    const data = {
      ...rest,
      serviceID: newProduct.service?.id || '',
      shopID: user.shopId
    };
    dispatch({
      type: 'addProduct',
      payload: data
    });
    setShowAddProductDialog(false);
    setDisabled(false);
    setNewProduct(initialNewProduct);
  };

  const selectAllItems = (event, t) => {
    setDisabled(false);
    const checkValue = event.target.checked;
    let newProducts = Object.assign({}, products);

    // service items
    const items = services[value]?.items?.items || [];
    items.forEach((item) => {
      const product = products[item.id];
      if (product) {
        newProducts[product.itemID] = {
          ...product,
          enabled: checkValue,
          modify: true
        };
      } else {
        const { name, description, image, id, ...rowValue } = item;
        newProducts[item.id] = {
          price: 0,
          ...rowValue,
          itemID: id,
          enabled: checkValue,
          modify: true
        };
      }
    });

    // shop specific products
    const shopProducts = Object.values(newProducts).filter(
      (item) => !item.itemID && item.serviceID === (services[value]?.id || '')
    );
    shopProducts.forEach((item) => {
      if (item.name)
        newProducts[getCustomShopProductName(item.name)] = {
          ...item,
          enabled: checkValue,
          modify: true
        };
    });

    dispatch({
      type: 'updateData',
      payload: newProducts
    });
  };

  const handleAddBtnClick = () => {
    setNewProduct({ ...newProduct, service: services[value] });
    setShowAddProductDialog(true);
  };

  return (
    <>
      <div className={classes.root}>
        <ProductsToolbar
          title={
            isAdmin || isSales || !selectedShop
              ? 'Add/Edit Products and Pricing'
              : `${selectedShop.name} - Add/Edit Products and Pricing`
          }
        />
        <div className={classes.content}>
          <Paper className={classes.paper}>
            <Grid
              container
              item
              xs={12}
              justify="flex-end"
              style={{ marginBottom: '1rem' }}>
              <>
                {!(isAdmin || isSales) ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddBtnClick}>
                    Add New Item
                  </Button>
                ) : (
                  <SearchShop
                    onChange={handleSelectChange}
                    value={selectedShop}
                  />
                )}
              </>
            </Grid>

            {!!selectedShop && (
              <Grid container>
                <Grid container>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto">
                    {services.map(
                      (
                        { id, name, items: { items = [] } = { items: [] } },
                        key
                      ) => {
                        const shopProducts = Object.values(products).filter(
                          (item) => !item.itemID && item.serviceID === id
                        );
                        const newCheckAllItem =
                          items.some((item) => products[item.id]?.enabled) ||
                          shopProducts?.some((prod) =>
                            prod.name
                              ? products[getCustomShopProductName(prod.name)]
                                  ?.enabled
                              : false
                          );
                        const showTab = [...items, ...shopProducts].length > 0;

                        return showTab ? (
                          <Tab
                            key={key}
                            value={key}
                            classes={{
                              root: classes.tabs,
                              wrapper: classes.wrapper
                            }}
                            label={
                              <>
                                <Checkbox
                                  checked={newCheckAllItem}
                                  disabled={value !== key}
                                  inputProps={{
                                    'aria-label': 'select all items'
                                  }}
                                  onClick={selectAllItems}
                                  className={classes.checkbox}
                                />
                                <span>{name}</span>
                              </>
                            }
                          />
                        ) : null;
                      }
                    )}
                  </Tabs>
                </Grid>
                <Grid container>
                  <ProductsTable
                    services={services}
                    dispatch={dispatch}
                    products={products}
                    disabled={disabled}
                    setDisabled={setDisabled}
                    initialProducts={initialProducts}
                    updateInitialProducts={() => setInitialProducts(products)}
                    items={items}
                  />
                </Grid>
              </Grid>
            )}
          </Paper>
        </div>
      </div>
      <AddProduct
        open={showAddProductDialog}
        handleClose={() => setShowAddProductDialog(false)}
        services={services}
        values={newProduct}
        addProducts={addProduct}
        setValues={setNewProduct}
      />
    </>
  );
};

export default ProductList;
