import React, { useCallback } from 'react';
import { UploadedFilesListItem } from 'components/organisms';
import {
  Grid,
  Typography,
  Button,
  TextField,
  MenuItem
} from '@material-ui/core';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {},
  dragDropBox: {
    minHeight: '15rem',
    width: '100%',
    border: '2px dashed #C4C4C4',
    borderRadius: '0.4rem'
  }
}));

const DocumentUploadDropzone = ({
  onDrop,
  selectedFiles,
  onClose,
  handleChange
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const onDropRejected = useCallback(
    rejectedFiles =>
      rejectedFiles.length > 1 &&
      enqueueSnackbar('select only one file at a time', {
        variant: 'error',
        autoHideDuration: 1500
      }),
    []
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    accept: ['image/*', 'application/pdf']
  });

  return (
    <Grid container style={{ marginBottom: '2rem' }}>
      <Grid container item xs={12} justify="center" alignItems="center">
        <Grid container item justify="center" {...getRootProps()} xs={12}>
          <input {...getInputProps()} />
          <Grid
            container
            item
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.dragDropBox}
            xs={12}>
            {isDragActive ? (
              <Typography variant="h6">Drop the files here...</Typography>
            ) : (
              <>
                <CloudUploadOutlinedIcon fontSize="large" />
                <Typography variant="h6" style={{ marginTop: '1rem' }}>
                  Drag & Drop Files
                </Typography>
                <br />
                <br />
                <Button color="primary" onClick={open}>
                  Choose Files
                </Button>
              </>
            )}
          </Grid>
          <Grid container item justify="flex-start">
            <Typography
              variant="body1"
              style={{ color: ' #C4C4C4', padding: '0.5rem' }}>
              Accepted File Types: PDF, JPG and PNG.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {selectedFiles && selectedFiles.length > 0 && (
        <Grid container style={{ gap: '0.625rem' }}>
          {selectedFiles.map((file, index) => (
            <Grid container key={`selected-files-${index}`}>
              <UploadedFilesListItem
                fileName={file.file?.name || ''}
                onClose={() => onClose(index)}
              />
              <Grid
                container
                justify="space-between"
                item
                xs={12}
                style={{ marginTop: '0.5rem' }}>
                <Grid item xs={6} style={{ paddingRight: '0.625rem' }}>
                  <TextField
                    value={file.documentType || ''}
                    onChange={handleChange(index)}
                    name="documentType"
                    variant="outlined"
                    label="Document type"
                    select
                    margin="dense"
                    size="small"
                    fullWidth
                    required
                    InputLabelProps={{ shrink: true }}>
                    <MenuItem value="Id Proof">ID Proof</MenuItem>
                    <MenuItem value="Address Proof">Address Proof</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={file.name || ''}
                    onChange={handleChange(index)}
                    fullWidth
                    label="Document name"
                    margin="dense"
                    name="name"
                    required
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={file.description || ''}
                  onChange={handleChange(index)}
                  fullWidth
                  label="Description"
                  margin="dense"
                  name="description"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default DocumentUploadDropzone;
