import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Chip,
  Button,
  IconButton
} from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import { getPaymentByOrderId } from 'graphql/queries';
import { useLoader } from 'layouts/loaderContext';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import {
  showPrice,
  getOrderTotal,
  unitFormatter,
  getFormattedName,
  getOrderDiscountTotal,
  getCollectionAndDeliveryFee,
  getCollectionAndDeliveryFeeText
} from 'common/utilFunctions';
import OrderDocumentPDF from './OrderDocumentPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useHistory } from 'react-router-dom';
import PaymentStatusTag from 'components/molecules/PaymentStatusTag';
import {
  inWords,
  capitalizeStr,
  showPartialPaymentRecord
} from 'common/utilFunctions';

const useStyles = makeStyles((theme) => ({
  backgroundColor: {
    backgroundColor: '#D5EEFF'
  },
  table: {
    minWidth: 700
  },
  verticalAlign: {
    verticalAlign: 'middle'
  },
  statusChipSec: {
    marginTop: '1rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: '0'
    }
  },
  center: {
    textAlign: 'center'
  },
  cancelReasonBox: {
    padding: '0.5rem',
    backgroundColor: theme.palette.warning.light,
    borderRadius: '0.28rem',
    marginTop: '0.5rem'
  },
  asapBanner: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    padding: '0.2rem 0.5rem',
    borderRadius: '0.25rem'
  },
  discountBreakdown: {
    fontSize: '0.625rem',
    padding: '0 1rem'
  },
  overallDiscount: {
    padding: '0 1rem 1rem'
  }
}));
const OrderDetailContent = ({
  order,
  handleClose = () => {},
  updateOrder = () => {},
  isOrdersModal = false,
  showBackButton = false
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [partiallyPaidRecord, setPartiallyPaidRecord] = useState([]);
  const [prevTotal, setPrevTotal] = useState(0);
  const { setLoading } = useLoader();

  useEffect(() => {
    setPrevTotal(order?.total);
  }, []);

  const getPartialPaymentsRecord = async (id) => {
    setLoading(true);
    if (id) {
      const response = await API.graphql(
        graphqlOperation(getPaymentByOrderId, {
          orderID: id
        })
      );
      const data = await response.data.getPaymentByOrderId.items;
      setPartiallyPaidRecord(
        data
          .map((el) => ({
            amount: el.amount,
            date: el.createdAt,
            lastChanged: el._lastChangedAt
          }))
          .sort((a, b) => a.lastChanged - b.lastChanged)
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    getPartialPaymentsRecord(order?.id);
  }, [order]);

  let balance = order?.total;

  return order?.id ? (
    <Grid container>
      <Paper
        style={{
          width: '100%',
          margin: '0.4rem 0.5rem',
          padding: '0.5rem'
        }}>
        <Grid container item justify="space-between" alignItems="center">
          <Grid item sm={8} xs={12} container alignItems="center">
            {showBackButton ? (
              <IconButton size="small" onClick={() => history.goBack()}>
                <ArrowBackIcon fontSize="small" />
              </IconButton>
            ) : null}
            <Typography
              variant="h5"
              style={{
                margin: '0 0.5rem'
              }}>
              {order.orderID}
            </Typography>
            <Typography>
              ( ordered on
              {moment(order.createdAt).format(' MMMM Do YYYY')}
              {' at '}
              {moment(order.createdAt).format('hh:mm a')} )
            </Typography>
          </Grid>
          <Grid
            item
            sm={4}
            xs={12}
            container
            justify="flex-end"
            className={classes.statusChipSec}>
            <Chip
              color="primary"
              style={{
                backgroundColor: order.status
                  ? order.status === 'declined'
                    ? 'red'
                    : order.status === 'cancel'
                    ? 'orange'
                    : 'green'
                  : 'green',
                fontWeight: '600',
                textTransform: 'capitalize',
                marginRight: '0.5rem'
              }}
              label={order.status}
            />

            <PDFDownloadLink
              document={
                <OrderDocumentPDF order={order} payment={partiallyPaidRecord} />
              }
              fileName={`order-${order?.orderID || ''}`}>
              {({ loading, error }) =>
                !(loading || error) ? (
                  <Button variant="contained" color="secondary" size="small">
                    Print Order
                  </Button>
                ) : null
              }
            </PDFDownloadLink>
          </Grid>
        </Grid>
        {order.orderCancelOrDeclineReason && (
          <Grid container className={classes.cancelReasonBox}>
            <Typography>
              <span style={{ fontWeight: 450 }}>
                Reason for
                {order.status === 'declined' ? ' decline: ' : ' cancellation: '}
              </span>
              {order.orderCancelOrDeclineReason}
            </Typography>
          </Grid>
        )}
      </Paper>
      <Divider />
      <Paper
        style={{
          width: '100%',
          margin: '1rem 0.5rem',
          padding: '0.5rem'
        }}>
        <PerfectScrollbar style={{ maxHeight: '65vh', paddingRight: '1rem' }}>
          <Grid
            container
            justify="space-between"
            style={{ marginBottom: '1rem' }}>
            <Grid
              container
              item
              justify="flex-start"
              alignItems="flex-start"
              xs={12}
              md={6}>
              <Typography>
                Contact Name : {getFormattedName(order?.userDetail)}
                <br />
                <br />
                Email address : {order?.userDetail?.email}
                <br />
                <br />
                Phone number : {order?.userDetail?.phoneNumber}
                {order?.collection?.date ? (
                  <>
                    <br />
                    <br />
                    Collection date :
                    {moment(order.collection.date).format(' MMMM Do YYYY')}
                    {' between '}
                    {order.collection.time}
                  </>
                ) : null}
              </Typography>
              {order?.collection?.type === 'asap' &&
                order?.delivery?.type === 'asap' && (
                  <Grid
                    container
                    item
                    xs={12}
                    justify="flex-start"
                    alignItems="center"
                    style={{
                      marginTop: '1rem'
                    }}>
                    <Typography className={classes.asapBanner}>
                      This is an <strong> ASAP </strong> order
                    </Typography>
                  </Grid>
                )}
            </Grid>
            {isOrdersModal ? (
              <Grid
                container
                direction="column"
                item
                xs={12}
                md={5}
                style={{ gap: '1rem' }}
                alignItems="center">
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={1}>
                    <LocationOnIcon fontSize="inherit" />
                  </Grid>
                  <Grid item xs={11}>
                    {order?.collection?.address ? (
                      <Typography>
                        Collection Address :<br />
                        {JSON.parse(order?.collection?.address)['line1']}
                        {', '}
                        {JSON.parse(order?.collection?.address)['town']}
                      </Typography>
                    ) : (
                      <Typography>
                        The order will be dropped off by the customer.
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid container justify="center" alignItems="center">
                  <Grid item xs={1}>
                    <LocationOnIcon fontSize="inherit" />
                  </Grid>
                  <Grid item xs={11}>
                    {order?.delivery?.address ? (
                      <Typography>
                        Delivery Address :<br />
                        {JSON.parse(order?.delivery?.address)['line1']}
                        {', '}
                        {JSON.parse(order?.delivery?.address)['town']}
                      </Typography>
                    ) : (
                      <Typography>
                        The order will be picked up by the customer.
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container item xs={12} md={5} justify="flex-end">
                {order?.shop && (
                  <Typography>
                    <span style={{ fontWeight: 'bolder' }}>
                      {order?.shop?.name}
                    </span>
                    <br />
                    {order?.shop?.address}
                    {`, ${order?.shop?.postcode || ''}`.toUpperCase()}
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="order-items-table">
              <TableHead>
                <TableRow className={classes.backgroundColor}>
                  <TableCell align="left">ITEM NAME</TableCell>
                  <TableCell align="center">ITEM DESCRIPTION</TableCell>
                  <TableCell align="center">QUANTITY</TableCell>
                  <TableCell align="center">PRICE</TableCell>
                  <TableCell align="center">DISCOUNT</TableCell>
                  <TableCell align="right">TOTAL</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.orderList.length > 0 &&
                  order.orderList.map((item, index) => (
                    <TableRow
                      key={`${item.orderID}-order-details-items-${index}`}>
                      <TableCell align="left">{item.name}</TableCell>
                      <TableCell align="center">
                        {item.description !== null ? item.description : 'N/A'}
                      </TableCell>
                      <TableCell align="center">{item.quantity}</TableCell>
                      <TableCell align="center">
                        {`${showPrice(item.price || 0)} ${unitFormatter(
                          item.unit
                        )}`.toLowerCase()}
                      </TableCell>
                      <TableCell align="center">
                        {item.discount
                          ? `${showPrice(item.discount || 0)} ${unitFormatter(
                              item.unit
                            )}`.toLowerCase()
                          : item.overallDiscount
                          ? `${showPrice(item.overallDiscount)}`
                          : 0}
                      </TableCell>
                      <TableCell align="right">
                        {showPrice(
                          item.price * item.quantity -
                            (item.overallDiscount || 0)
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow>
                  <TableCell
                    rowSpan={7 + partiallyPaidRecord?.length}
                    colSpan={3}
                    style={{ textAlign: 'center' }}>
                    <PaymentStatusTag order={order} prevTotal={prevTotal} />
                  </TableCell>
                  <TableCell colSpan={2}>Subtotal</TableCell>
                  <TableCell align="right">
                    {showPrice(getOrderTotal(order) || 0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Tax</TableCell>
                  <TableCell align="right">{showPrice(0)}</TableCell>
                </TableRow>
                {order.collection.type === 'asap' ? (
                  <TableRow>
                    <TableCell colSpan={2}>ASAP Fee</TableCell>
                    <TableCell align="right">
                      {showPrice(order?.shop?.AsapDeliveryFee || 0)}
                    </TableCell>
                  </TableRow>
                ) : null}
                <TableRow>
                  <TableCell colSpan={2}>
                    {getCollectionAndDeliveryFeeText(order)}
                  </TableCell>
                  <TableCell align="right">
                    {getCollectionAndDeliveryFee(order)}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.backgroundColor}>
                  <TableCell colSpan={2}>Total : In Cash</TableCell>
                  <TableCell align="right">
                    {showPrice(order.total || 0)}
                  </TableCell>
                </TableRow>
                {partiallyPaidRecord?.length > 0 &&
                  (showPartialPaymentRecord(
                    partiallyPaidRecord,
                    order.total
                  ) ? (
                    <>
                      {partiallyPaidRecord.map((el, i) => {
                        balance -= el.amount;

                        return (
                          <TableRow key={i}>
                            <TableCell colSpan={2}>
                              Partial Cash Payment on :&nbsp;
                              {moment(el.date).format('DD/MM/YYYY, h:mm a')}
                              <br />
                              {balance <= 0
                                ? 'Total Amount Due:'
                                : 'Balance Due:'}
                            </TableCell>
                            <TableCell align="right">
                              {showPrice(el.amount)}
                              <br />
                              {showPrice(balance)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell colSpan={2}>
                        Cash Payment on :&nbsp;
                        {moment(partiallyPaidRecord[0].date).format(
                          'DD/MM/YYYY, h:mm a'
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {showPrice(partiallyPaidRecord[0].amount)}
                      </TableCell>
                    </TableRow>
                  ))}
                {getOrderDiscountTotal(order) ? (
                  <TableRow>
                    <TableCell
                      colSpan={3}
                      className={classes.discountBreakdown}>
                      <strong>Note:-</strong> Direct Discount:{'  '}
                      {showPrice(
                        getOrderDiscountTotal({
                          orderList:
                            order.orderList?.filter((item) =>
                              item?.discountCoupon
                                ? JSON.parse(item?.discountCoupon)
                                    .discountType === 'direct'
                                : false
                            ) || []
                        })
                      )}
                      , Discount from Coupons:{'  '}
                      {showPrice(
                        getOrderDiscountTotal({
                          orderList:
                            order.orderList?.filter((item) =>
                              item?.discountCoupon
                                ? JSON.parse(item?.discountCoupon)
                                    .discountType === 'conditional'
                                : false
                            ) || []
                        })
                      )}
                      , Overall Discount: {'  '}
                      {showPrice(getOrderDiscountTotal(order))}
                    </TableCell>
                  </TableRow>
                ) : null}
              </TableBody>
            </Table>
            {order.paymentStatus === 'paid' && (
              <Typography
                align="center"
                style={{ padding: '0.5rem', fontWeight: 'bold' }}>
                A Cash Payment of {capitalizeStr(inWords(order?.total))} is
                Made.
              </Typography>
            )}
          </TableContainer>
        </PerfectScrollbar>
      </Paper>
      <Paper className={classes.backgroundColor} style={{ width: '100%' }}>
        <Grid container justify="space-between" style={{ padding: '1rem' }}>
          <Grid container item xs={12} md={8}>
            {order?.delivery?.time && (
              <>
                <LocalShippingOutlinedIcon />
                <Typography style={{ marginLeft: '1rem' }}>
                  {'Delivery Date :'}
                  {moment(order.delivery.date).format(' MMMM Do YYYY')}
                  {' between '}
                  {order.delivery.time}
                </Typography>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            container
            justify="flex-end"
            style={{ gap: '0.75rem' }}>
            {isOrdersModal && order?.status === 'newOrder' ? (
              <>
                <Button
                  color="primary"
                  onClick={() => updateOrder(order, 'itemized')}
                  variant="contained"
                  size="small">
                  Accept
                </Button>
                <Button
                  color="secondary"
                  onClick={() => updateOrder(order, 'declined')}
                  variant="contained"
                  size="small">
                  Decline
                </Button>
              </>
            ) : null}
            {isOrdersModal ? (
              <Button
                color="secondary"
                onClick={handleClose}
                variant="contained"
                size="small">
                Close
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  ) : null;
};

export default OrderDetailContent;
