import React, { useEffect, useState } from 'react';
import { Container, Grid, TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { NavigationBreadcrumbs } from 'components/organisms';
import { makeStyles } from '@material-ui/core/styles';
import Header from './components/Header';
import ChatContactBox from './components/ChatContactBox';
import ChatMessageBox from './components/ChatMessageBox';
import { useOrders } from 'context/order/ordersContext';
import { useAmplifyAuth } from 'context';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#E5E5E5',
    borderTop: '1px solid #0000001A'
  },
  searchBox: {
    borderRadius: '8px'
  },
  searchIcon: {
    color: theme.palette.grey[400]
  }
}));

const Chat = () => {
  const { orders, dispatch } = useOrders();
  const {
    state: { user }
  } = useAmplifyAuth();
  const [orderActive, setOrderActive] = useState();
  const classes = useStyles();
  useEffect(() => {
    const { shopId, shopID } = user;
    if (shopId || shopID) {
      dispatch({
        type: 'getOrdersByUserId',
        payload: {
          userId: user.id
        }
      });
    }
  }, [user]);
  useEffect(() => {
    if (orders.length) {
      setOrderActive(orders[0]);
    }
  }, [orders]);

  return (
    <div className={classes.container}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <NavigationBreadcrumbs currentLink="Chat" />
          </Grid>
          <Grid item xs={12}>
            <Header content="Chat" />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              style={{ marginBottom: '20px', backgroundColor: '#fff' }}
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon className={classes.searchIcon} />
                  </InputAdornment>
                )
              }}
              placeholder="Search"
            />

            <ChatContactBox setOrderActive={setOrderActive} />
          </Grid>

          <Grid item xs={12} md={8}>
            {orderActive && <ChatMessageBox orderActive={orderActive} />}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Chat;
