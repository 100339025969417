import React, { useState, useRef, useEffect } from 'react';
import {
  Grid,
  GridList,
  GridListTile,
  Radio,
  FormControlLabel,
  RadioGroup,
  useMediaQuery
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import QuotesIcon from '../assets/Qoutes.png';
import TESTIMONIAL_DATA from '../constants/TestimonialContent';

const useStyles = makeStyles((theme) => ({
  tile: {
    overflow: 'visible'
  },
  leftPadding: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '5em'
    }
  },
  rightMargin: {
    [theme.breakpoints.up('md')]: {
      marginRight: '5em'
    }
  }
}));

const SubFooter = () => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const smd = useMediaQuery(theme.breakpoints.down('sm'));
  const [tmolIndex, setTmolIndex] = useState(1);
  const testimonialsDiv = useRef(null);
  const testimonial1Div = useRef(null);

  const maxContentChar = smd ? 200 : 300;

  function handleScroll(e) {
    const newIndex =
      Math.floor(
        testimonialsDiv.current.scrollLeft /
          (testimonial1Div.current?.offsetWidth || 1)
      ) + 1;
    const showLastIndex =
      testimonialsDiv.current.offsetWidth +
        testimonialsDiv.current.scrollLeft >=
      testimonialsDiv.current.scrollWidth - 10;
    setTmolIndex(showLastIndex ? 5 : newIndex <= 5 ? newIndex : 5);
  }

  useEffect(() => {
    testimonialsDiv.current &&
      testimonialsDiv.current.addEventListener('scroll', handleScroll, {
        passive: false
      });

    return () =>
      testimonialsDiv.current?.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollDiv = (scrollWidth) => {
    testimonialsDiv.current.scrollBy({
      left: scrollWidth,
      behavior: 'smooth'
    });
  };

  const handleChange = (e) => {
    const scrollWidth =
      (testimonial1Div.current?.offsetWidth || 500) * (e.target.value - 1) -
      (testimonialsDiv.current?.scrollLeft || 0);
    scrollDiv(scrollWidth);
    setTmolIndex(parseInt(e.target.value));
  };

  return (
    <section className="tmol" style={{ marginTop: '5rem' }}>
      <div className={clsx(classes.leftPadding, 'tmol__container')}>
        <Grid
          container
          className="tmol__info"
          alignItems="center"
          justify="center">
          <Grid item>
            <p className="tmol__smallTitle" style={{ fontSize: '18px' }}>
              Testimonials
            </p>
            <h2
              className="tmol__title"
              style={{ fontSize: '24px', marginTop: '1.5rem' }}>
              What our customer says about us...
            </h2>
          </Grid>
          {/* <div className="tmol__counts">
            <p className="tmol__count">1,200+</p>
            <p className="tmol__countDes">Happy customers</p>
          </div> */}
        </Grid>
        <GridList
          ref={testimonialsDiv}
          className="hide-scrollbar tmol__lists mySwiperTmol"
          style={{
            flexWrap: 'nowrap',
            transform: 'translateZ(0)'
          }}>
          {TESTIMONIAL_DATA?.map((item, i) => {
            const contentData =
              item?.data?.length > maxContentChar
                ? item.data.substring(0, maxContentChar).concat('...')
                : item?.data;

            return (
              <GridListTile
                ref={testimonial1Div}
                key={`testimonials-list-item-${i}`}
                classes={{ tile: classes.tile }}
                className={item.id === 5 ? classes.rightMargin : ''}
                style={{
                  height: 'fit-content',
                  width: md ? 550 : '100%',
                  paddingLeft: md ? '40px' : 0,
                  paddingBottom: md ? '33px' : 0,
                  paddingTop: !md ? '40px' : 0
                }}>
                <div className="tmol__list" style={{ borderRadius: '12px' }}>
                  <div className="tmol__listProfile">
                    <img
                      src={item.image}
                      alt="profile"
                      className="tmol__listProfileImg"
                    />
                  </div>

                  <p className="tmol__listDes">{contentData}</p>
                  <div className="tmol__listBottom">
                    <p className="tmol__author">
                      {item.name} / <span>{item.user}</span>
                    </p>
                    <img src={QuotesIcon} alt="qoutes" />
                  </div>
                </div>
              </GridListTile>
            );
          })}
        </GridList>
      </div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{
          position: 'relative',
          left: md ? '36%' : '40%',
          top: '0%',
          paddingBottom: '80px'
        }}>
        <RadioGroup row value={tmolIndex} onChange={handleChange}>
          {TESTIMONIAL_DATA?.map((item, i) => (
            <FormControlLabel
              value={item.id}
              key={`subfooter-radio-group-item-${item.id}`}
              control={
                <Radio
                  disableRipple
                  size="medium"
                  style={{ padding: 0, paddingTop: '10px' }}
                  checkedIcon={
                    <FiberManualRecordIcon
                      style={{ fontSize: 15, color: '#007AFF', padding: 0 }}
                    />
                  }
                  icon={
                    <FiberManualRecordIcon
                      style={{ fontSize: 15, color: '#7391BC', padding: 0 }}
                    />
                  }
                />
              }
            />
          ))}
        </RadioGroup>
      </Grid>
    </section>
  );
};

export default SubFooter;
