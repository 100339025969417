import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useAmplifyAuth } from 'context';
import { Profile, SidebarNav } from './components';
import PropTypes from 'prop-types';
import { routes } from 'common/constants';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Drawer } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalLaundryServiceIcon from '@material-ui/icons/LocalLaundryService';
import StoreIcon from '@material-ui/icons/Store';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  }
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, ...rest } = props;
  const {
    state: { user }
  } = useAmplifyAuth();

  const classes = useStyles();

  const allPages = [
    {
      title: 'Dashboard',
      href: routes.admin.dashboard,
      icon: <DashboardIcon />,
      userGroup: ['admin']
    },
    {
      title: 'Dashboard',
      href: routes.sales.dashboard,
      icon: <DashboardIcon />,
      userGroup: ['sales']
    },
    {
      title: 'Shops',
      href: routes.admin.shops,
      icon: <StoreIcon />,
      userGroup: ['admin']
    },
    {
      title: 'Shops',
      href: routes.sales.shops,
      icon: <StoreIcon />,
      userGroup: ['sales']
    },
    {
      title: 'Shop',
      href: routes.serviceProvider.shopDetail,
      icon: <StoreIcon />,
      userGroup: ['laundryAdmin']
    },
    {
      title: 'Services',
      href: routes.admin.services,
      icon: <LocalLaundryServiceIcon />,
      userGroup: ['admin']
    },
    {
      title: 'Photo Library',
      href: routes.admin.photoLibrary,
      icon: <PhotoLibraryIcon />,
      userGroup: ['admin']
    },
    {
      title: 'Products & Pricing',
      href: routes.admin.products,
      icon: <ShoppingBasketIcon />,
      userGroup: ['admin']
    },
    {
      title: 'Products & Pricing',
      href: routes.sales.products,
      icon: <ShoppingBasketIcon />,
      userGroup: ['sales']
    },
    {
      title: 'Products & Pricing',
      href: routes.serviceProvider.productsPricing,
      icon: <ShoppingBasketIcon />,
      userGroup: ['laundryAdmin']
    },
    {
      title: 'Coupons',
      href: routes.serviceProvider.coupons,
      icon: <LocalOfferIcon />,
      userGroup: ['laundryAdmin']
    },
    {
      title: 'Orders',
      href: routes.serviceProvider.ordersNew,
      icon: <ShoppingBasketIcon />,
      userGroup: ['laundryAdmin', 'laundryStaff']
    },
    {
      title: 'Ad manager',
      href: routes.admin.ads,
      icon: <TextFieldsIcon />,
      userGroup: ['admin']
    },
    {
      title: 'Sales & Marketing',
      href: routes.admin.salesMarketing,
      icon: <AttachMoneyIcon />,
      userGroup: ['admin']
    },
    {
      title: 'Shop Users',
      href: routes.admin.users,
      icon: <PeopleIcon />,
      userGroup: ['admin']
    },

    {
      title: 'Customers',
      href: routes.admin.customers,
      icon: <PeopleIcon />,
      userGroup: ['admin']
    },
    {
      title: 'Redirect Records',
      href: routes.admin.insights,
      icon: <AssessmentIcon />,
      userGroup: ['admin']
    },
    {
      title: 'Search Banners',
      href: routes.admin.searchBanner,
      icon: <ViewCarouselIcon />,
      userGroup: ['admin']
    },
    {
      title: 'Manage Staff',
      href: routes.serviceProvider.manageStaff,
      icon: <SupervisedUserCircleOutlinedIcon />,
      userGroup: ['laundryAdmin']
    },
    {
      title: 'Settings',
      href: routes.admin.settings,
      icon: <SettingsIcon />,
      userGroup: ['admin']
    },
    {
      title: 'Settings',
      href: routes.sales.settings,
      icon: <SettingsIcon />,
      userGroup: ['sales']
    },
    {
      title: 'Settings',
      href: routes.serviceProvider.settingsProfile,
      icon: <SettingsIcon />,
      userGroup: ['laundryAdmin', 'laundryStaff']
    }
  ];

  const pages = useCallback(() => {
    return user && user.groups
      ? allPages.filter(({ userGroup }) => userGroup.includes(user.groups[0]))
      : [];
  }, [user]);

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages()} />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
