import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useOrders } from 'context/order/ordersContext';
import {
  Card,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableSortLabel,
  TableFooter,
  Grid,
  Avatar,
  Badge,
  IconButton,
  Link
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MessageDialog from '../../Messages/MessageDialog';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import EditIcon from '@material-ui/icons/Edit';
import PaymentUpdateDialog from './dialogs/PaymentUpdateDialog';
import {
  showPrice,
  unitFormatter,
  getFormattedName
} from 'common/utilFunctions';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'auto'
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  searchInput: {
    margin: theme.spacing(2),
    marginLeft: 0,
    maxWidth: 500
  },
  orderIDColumn: {
    cursor: 'pointer',
    fontSize: '12px'
  }
}));

const OrdersTable = (props) => {
  const {
    className,
    status,
    stage,
    updateOrder,
    showMessages,
    setShowOrderDetails = () => {},
    loadOrders = () => {},
    ...rest
  } = props;
  const { orders, isMoreOrdersAvailable, dispatch } = useOrders();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('orderID');
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [currentOrder, setCurrentOrder] = useState();
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const handlePageChange = (_event, page) => {
    isMoreOrdersAvailable && loadOrders(true, false);
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleShowMesssages = (order) => {
    setCurrentOrder(order);
    setShowMessageDialog(true);
  };

  const handlePaymentClick = (order) => () => {
    setCurrentOrder(order);
    setShowPaymentModal(true);
  };

  const filteredOrders = orders.filter(({ status: s }) =>
    !!status ? s === status : true
  );

  // for sorting by property
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    dispatch({
      type: 'sortOrders',
      payload: { property, direction: newOrder }
    });
  };

  return (
    <>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  style={{ minWidth: 152, fontSize: '12px' }}>
                  NAME &amp; CONTACT
                </TableCell>
                <TableCell
                  align="center"
                  style={{ minWidth: 150, fontSize: '12px' }}>
                  <TableSortLabel
                    active={orderBy === 'orderID'}
                    direction={orderBy === 'orderID' ? order : 'asc'}
                    onClick={(e) => handleRequestSort(e, 'orderID')}>
                    &nbsp; &nbsp; &nbsp; &nbsp; ORDER NO.
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  align="center"
                  style={{ minWidth: 170, fontSize: '12px' }}>
                  <TableSortLabel
                    active={orderBy === 'date'}
                    direction={orderBy === 'date' ? order : 'asc'}
                    onClick={(e) => handleRequestSort(e, 'date')}>
                    &nbsp; &nbsp; &nbsp; &nbsp; DATE OF ORDER
                  </TableSortLabel>
                </TableCell>

                {stage === 9 ? (
                  <TableCell align="center">DELIVERED ON</TableCell>
                ) : (
                  <TableCell
                    align="center"
                    style={{ minWidth: 160, fontSize: '12px' }}>
                    <TableSortLabel
                      active={orderBy === 'deliveryDate'}
                      onClick={(e) => handleRequestSort(e, 'deliveryDate')}
                      direction={orderBy === 'deliveryDate' ? order : 'asc'}>
                      &nbsp; &nbsp; &nbsp; EST. DELIVERY
                    </TableSortLabel>
                  </TableCell>
                )}

                {(stage === 7 || stage === 8 || stage === 9) && (
                  <TableCell align="center" style={{ fontSize: '12px' }}>
                    PRICE
                  </TableCell>
                )}

                {(stage === 1 ||
                  stage === 3 ||
                  stage === 4 ||
                  stage === 5 ||
                  stage === 6) && (
                  <TableCell align="left" style={{ fontSize: '12px' }}>
                    PAYMENT
                  </TableCell>
                )}

                {stage === 7 || stage === 8 ? (
                  <TableCell align="center" style={{ fontSize: '12px' }}>
                    CONTACT
                  </TableCell>
                ) : stage === 9 ? null : (
                  <TableCell></TableCell>
                )}

                {stage === 7 || stage === 8 || stage === 9 ? (
                  <TableCell align="left" style={{ fontSize: '12px' }}>
                    {' '}
                    &nbsp;STATUS
                  </TableCell>
                ) : (
                  <TableCell></TableCell>
                )}

                {stage === 9 && (
                  <TableCell align="center" style={{ fontSize: '12px' }}>
                    CUSTOMER RATING
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            {filteredOrders.length > 0 ? (
              <>
                <TableBody>
                  {filteredOrders
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((order, index) => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={order.id}>
                        <TableCell align="left">
                          <Grid container item style={{ minWidth: 150 }}>
                            {showMessages &&
                            ((index === 1 && stage === 3) ||
                              (index === 0 && stage === 1)) ? (
                              <Badge
                                className={classes.avatar}
                                badgeContent={
                                  <IconButton
                                    size="small"
                                    onClick={() => handleShowMesssages(order)}
                                    style={{ backgroundColor: '#C2E7FF' }}>
                                    <MailOutlineRoundedIcon
                                      style={{ fontSize: '14px' }}
                                    />
                                  </IconButton>
                                }>
                                <Avatar />
                              </Badge>
                            ) : (
                              <Avatar className={classes.avatar}></Avatar>
                            )}
                            <Typography
                              variant="body1"
                              style={{ fontSize: '12px' }}>
                              {getFormattedName(order.userDetail)}
                              <br />
                              {order.userDetail?.phoneNumber}
                            </Typography>
                          </Grid>
                        </TableCell>

                        <TableCell align="center">
                          <Link
                            className={classes.orderIDColumn}
                            style={{
                              color:
                                order?.collection?.type === 'asap'
                                  ? 'red'
                                  : 'blue'
                            }}
                            onClick={() => {
                              setShowOrderDetails(true);
                              updateOrder(order, 'orderDetails');
                            }}>
                            {order.orderID}
                          </Link>
                        </TableCell>

                        <TableCell align="center">
                          <Typography
                            variant="body1"
                            style={{ fontSize: '12px' }}>
                            {moment(order.createdAt).format('DD/MM/YYYY')}
                          </Typography>
                        </TableCell>

                        <TableCell align="center" style={{ fontSize: '12px' }}>
                          {order.delivery?.date
                            ? moment(order.delivery.date).format('DD/MM/YYYY')
                            : 'N/A'}
                        </TableCell>

                        {(stage === 7 || stage === 8 || stage === 9) && (
                          <TableCell
                            align="center"
                            style={{ fontSize: '12px' }}>
                            {stage === 8 ? 'N/A' : showPrice(order?.total || 0)}
                          </TableCell>
                        )}

                        {(stage === 1 ||
                          stage === 3 ||
                          stage === 4 ||
                          stage === 5 ||
                          stage === 6) && (
                          <TableCell
                            align="left"
                            style={{
                              color:
                                order.paymentStatus === 'paid'
                                  ? 'green'
                                  : 'black',
                              fontSize: '12px'
                            }}>
                            {order.paymentStatus === 'paid' ? (
                              `Paid ${showPrice(order?.total || 0)}`
                            ) : (
                              <>
                                {unitFormatter(order.paymentStatus) || 'Unpaid'}
                                <IconButton onClick={handlePaymentClick(order)}>
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              </>
                            )}
                          </TableCell>
                        )}

                        {stage === 9 ? null : (
                          <TableCell
                            align="center"
                            style={{ fontSize: '12px' }}>
                            {stage === 6 ? (
                              <Button variant="contained">
                                Contact Driver
                              </Button>
                            ) : (
                              <Button
                                onClick={() => handleShowMesssages(order)}
                                endIcon={
                                  <MailOutlineRoundedIcon fontSize="small" />
                                }
                                variant="contained"
                                size="small">
                                Message
                              </Button>
                            )}
                          </TableCell>
                        )}

                        {/* status column */}
                        <TableCell align="left" style={{ fontSize: '12px' }}>
                          {stage === 1 && (
                            <Grid
                              container
                              item
                              justify="flex-start"
                              spacing={2}
                              style={{ rowGap: '1rem' }}>
                              <Button
                                color="primary"
                                onClick={() => updateOrder(order, 'itemized')}
                                variant="contained"
                                size="small"
                                style={{ marginRight: '0.5rem' }}>
                                Accept
                              </Button>
                              <Button
                                color="secondary"
                                size="small"
                                onClick={() => updateOrder(order, 'declined')}
                                variant="contained">
                                Decline
                              </Button>
                            </Grid>
                          )}

                          {stage === 2 && (
                            <Button
                              color="primary"
                              onClick={() => updateOrder(order, 'accepted')}
                              variant="contained"
                              size="small">
                              Ready For Item Pricing
                            </Button>
                          )}

                          {stage === 3 && (
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={() => updateOrder(order, 'inProgress')}
                              size="small">
                              Ready For Wash
                            </Button>
                          )}

                          {stage === 4 && (
                            <Grid
                              container
                              item
                              justify="space-between"
                              alignItems="center">
                              <Typography>In progress</Typography>
                              <Button
                                color="primary"
                                onClick={() =>
                                  updateOrder(order, 'readyForDelivery')
                                }
                                variant="contained"
                                size="small">
                                Move To Delivery
                              </Button>
                            </Grid>
                          )}

                          {stage === 5 && (
                            <Button
                              color="primary"
                              variant="contained"
                              size="small"
                              onClick={() => updateOrder(order, 'delivered')}>
                              Ready For Delivery
                            </Button>
                          )}

                          {stage === 6 && (
                            <Grid
                              container
                              item
                              justify="space-between"
                              alignItems="center">
                              <Typography>Delivered</Typography>
                              <Button
                                color="primary"
                                variant="contained"
                                size="small">
                                Edit
                              </Button>
                            </Grid>
                          )}

                          {stage === 7 && order.status}

                          {stage === 8 && (
                            <Grid
                              container
                              alignItems="center"
                              justify="space-evenly"
                              style={{
                                backgroundColor: 'blue',
                                padding: '0.2rem',
                                borderRadius: '0.2rem'
                              }}>
                              <Typography
                                style={{
                                  color: 'white'
                                }}
                                align="center">
                                Declined
                              </Typography>
                              <HighlightOffIcon
                                fontSize="small"
                                style={{
                                  color: 'white'
                                }}
                              />
                            </Grid>
                          )}

                          {stage === 9 && 'delivered'}
                        </TableCell>

                        {stage === 9 && (
                          <TableCell align="center">
                            <Grid>
                              <Rating value={5} readOnly />
                            </Grid>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      count={isMoreOrdersAvailable ? -1 : filteredOrders.length}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[5, 10, 25]}
                    />
                  </TableRow>
                </TableFooter>
              </>
            ) : (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6}>
                    <Typography variant="h6" align="center">
                      No Records Found!!!
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </CardContent>
      </Card>
      <MessageDialog
        open={showMessageDialog}
        setOpen={setShowMessageDialog}
        order={currentOrder}
      />
      <PaymentUpdateDialog
        order={currentOrder}
        open={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
      />
    </>
  );
};

OrdersTable.propTypes = {
  className: PropTypes.string
};

export default OrdersTable;
