import React, { useState, useEffect, useRef } from 'react';
import TinySlider from 'tiny-slider-react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  LinearProgress,
  Grid,
  IconButton,
  useMediaQuery
} from '@material-ui/core';
import { ShopsViews, CategoryList } from './components';
import { API, graphqlOperation } from 'aws-amplify';
import { getCurrentOpenAds } from 'graphql/customQueries';
import { useServices } from 'views/Services/serviceContext';
import { useShops } from 'context/shop/shopsContext';
import { useHistory, useParams } from 'react-router-dom';
import { initialFilters, tinySliderSettings, AD_PER_SLIDE } from './constants';
import { useLoader } from 'layouts/loaderContext';
import { AdTemplates } from 'components';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import clsx from 'clsx';
import 'tiny-slider/dist/tiny-slider.css';
import './assets/carousel-styles.css';
import { NavigationBreadcrumbs } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {},
  box: {
    height: 200,
    backgroundColor: theme.palette.captionBox
  },
  typography: {
    color: theme.palette.white
  },
  caption: {
    padding: theme.spacing(5, 20)
  },
  subCaption: {
    padding: theme.spacing(5, 20)
  },
  selectGroup: {
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    width: '133px',
    height: '45px',
    margin: theme.spacing(1),
    borderRadius: '5em',
    backgroundColor: theme.palette.select,
    color: theme.palette.black
  },
  offerShopsContainer: {
    backgroundColor: theme.palette.primary.main,
    padding: '25px 10px'
  },
  scrollBtn: {
    maxHeight: '26px',
    maxWidth: '26px',
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.white,
      color: theme.palette.primary.main
    }
  },
  navigationBreadcrumbWrapper: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '0 3rem',
    [theme.breakpoints.up('md')]: {
      padding: 0,
      maxWidth: '90%'
    }
  }
}));

const curTime = new Date().getHours() * 100 + new Date().getMinutes(); // current time in working hours time format

const ShopList = ({ className }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { loading } = useLoader();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const currentViewWidth = md ? 'md' : sm ? 'sm' : 'xs';
  const sliderRef = useRef(null);
  let { postCode } = useParams();
  const history = useHistory();
  const [shopFilters, setShopFilters] = useState(initialFilters);
  const [showResetFiltersBtn, setShowResetFiltersBtn] = useState(false);
  const [isShopsLoading, setIsShopsLoading] = useState(false);
  const { services = [], dispatch: dispatchService } = useServices();
  const [ads, setAds] = useState([]);
  const { shops = [], dispatch } = useShops();
  let filteredShops = shops?.filter((shop) => !!shop) || [];
  if (shopFilters.shops.length > 0)
    filteredShops = filteredShops.filter(
      (shop) =>
        shop.status === 'onhold' ||
        (shop.isLive &&
          shop?.workHrs.some(
            (day) =>
              day.openDay === new Date().getDay() &&
              day.openTime < curTime &&
              (!day.closeTime || day.closeTime > curTime)
          ))
    );

  const serviceOnClick = (serviceID) =>
    setShopFilters({
      ...shopFilters,
      services: serviceID === 'all' ? [] : [serviceID]
    });

  useEffect(() => {
    if (services?.length === 0) {
      dispatchService({ type: 'getService' });
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: 'getShopsByPostCode',
      payload: {
        postalCode: postCode,
        // range: shopFilters.distance?.at(0) || 2,
        showSnackbar: true,
        setExtraLoading: setIsShopsLoading
      }
    });
  }, [shopFilters.distance, postCode]);

  useEffect(() => {
    let mounted = true;

    API.graphql(graphqlOperation(getCurrentOpenAds, { page: 'list' }))
      .then((data) => {
        const filteredShops = shops?.filter((shop) => !!shop) || [];
        const ads = data.data.getCurrentOpenAds?.filter(
          (item) => !item._deleted
        );
        const defaultAds =
          ads?.filter((item) => item?.type === 'default') || [];
        const shopAds =
          ads
            ?.filter((item) =>
              filteredShops.some(
                (shop) => item?.type === 'shop' && shop?.id === item?.shopID
              )
            )
            .sort((a, b) => (a?.priority > b?.priority ? 1 : -1)) || [];

        mounted &&
          setAds(
            filteredShops?.length > 0 ? [...shopAds, ...defaultAds] : defaultAds
          );
      })
      .catch((e) => console.error(e));

    return () => {
      mounted = false;
    };
  }, [shops]);

  useEffect(() => {
    let mounted = true;
    if (shopFilters.services.length > 0 || shopFilters.shops.length > 0)
      mounted && setShowResetFiltersBtn(true);
    else mounted && setShowResetFiltersBtn(false);
    return () => {
      mounted = false;
    };
  }, [shopFilters]);

  const resetFilters = () => {
    setShopFilters(initialFilters);
    setShowResetFiltersBtn(false);
  };

  const onGoTo = (dir) => sliderRef.current.slider.goTo(dir);

  useEffect(() => {
    let mounted = true;
    const serviceId = history.location.search.split('=').at(1);
    if (serviceId) {
      mounted &&
        setShopFilters({
          ...shopFilters,
          services: [...shopFilters.services, serviceId]
        });
    }
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container style={{ borderTop: '1px solid #0000001a' }}>
        <Grid item xs={12} className={classes.navigationBreadcrumbWrapper}>
          <NavigationBreadcrumbs
            currentLink="Shop List"
            style={{
              margin: '10px 0 0',
              padding: '0 0 10px 0'
            }}
          />
        </Grid>
      </Grid>
      {!(loading || isShopsLoading) ? (
        <>
          {/* {filteredShops?.length > 0 ? (
            <CategoryList
              categories={services}
              shops={filteredShops}
              onClick={serviceOnClick}
            />
          ) : null} */}
          {ads.length > 0 && (
            <Grid container className={classes.offerShopsContainer}>
              <Grid
                container
                item
                justify="flex-start"
                alignItems="center"
                style={{ width: 40 }}>
                <IconButton
                  onClick={() => onGoTo('prev')}
                  className={classes.scrollBtn}>
                  <ArrowBackIosIcon
                    fontSize="small"
                    style={{ fontSize: '10px' }}
                  />
                </IconButton>
              </Grid>
              <Grid
                container
                item
                justify="center"
                alignItems="center"
                style={{
                  maxWidth: 'calc(100% - 80px)',
                  overflow: 'hidden',
                  textAlign: 'center'
                }}>
                <TinySlider settings={tinySliderSettings} ref={sliderRef}>
                  {[...Array(ads.length).keys()]
                    .filter(
                      (item) =>
                        item % AD_PER_SLIDE[currentViewWidth].count === 0
                    )
                    .map((index) => (
                      <div
                        style={{
                          opacity: 1
                        }}
                        key={`shops-list-ads-slides-${index}`}
                        className="tns-lazy-img">
                        <Grid container justify="space-around">
                          {AD_PER_SLIDE[currentViewWidth].indexArr
                            .map((item) => {
                              const newIndex = index + item;
                              return newIndex >= ads.length
                                ? ads[newIndex - ads.length]
                                : ads[newIndex];
                            })
                            .filter((item) => item)
                            .map((ad, adIndex) => (
                              <AdTemplates
                                key={`shops-list-ads-items-${index + adIndex}`}
                                ad={ad}
                                showAsButton={true}
                              />
                            ))}
                        </Grid>
                      </div>
                    ))}
                </TinySlider>
              </Grid>
              <Grid
                container
                item
                justify="flex-end"
                alignItems="center"
                style={{ width: 40 }}>
                <IconButton
                  onClick={() => onGoTo('next')}
                  className={classes.scrollBtn}>
                  <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
                </IconButton>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <LinearProgress />
      )}

      {loading ? (
        <LinearProgress />
      ) : (
        <ShopsViews
          categories={
            shopFilters.services.length > 0
              ? services?.filter((service) =>
                  shopFilters.services.some((id) => id === service.id)
                )
              : services
          }
          unfilteredCategories={services}
          shops={filteredShops}
          shopFilters={shopFilters}
          setShopFilters={setShopFilters}
          isShopsLoading={loading || isShopsLoading}
          resetFilters={resetFilters}
          showResetFiltersBtn={showResetFiltersBtn}
        />
      )}
    </div>
  );
};

export default ShopList;
