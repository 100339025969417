import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import userFlowTheme from '../../theme/HomeTheme';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import {
  Navbar,
  OfferNav,
  HomeFooter,
  Copyright
} from '../../components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%'
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    minHeight: 'calc(100vh - 255px)',
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100vh - 265px)'
    }
  }
}));

const NonLoggedIn = (props) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={userFlowTheme}>
      <div className={clsx(classes.root)}>
        <OfferNav />
        <Navbar />
        <main className={classes.content}>{props.children}</main>
        <HomeFooter />
        <Copyright />
      </div>
    </ThemeProvider>
  );
};

NonLoggedIn.propTypes = {
  children: PropTypes.node
};

export default NonLoggedIn;
