import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAmplifyAuth } from 'context';
import { CircularProgress } from '@material-ui/core';
import '@aws-amplify/ui/dist/style.css';
import { Authenticator} from 'aws-amplify-react';



class AuthComponent extends React.Component {
  handleStateChange = state => {
    console.log(state);
    if (state === 'signedIn') {
      this.props.onUserSignIn();
    }
  };
  render() {
    console.log(this.props);
    return (
      <Authenticator onStateChange={this.handleAuthStateChange} />
    );
  }
}

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, restrictedTo, ...rest } = props;
  let { state: { user } } = useAmplifyAuth();
  const [authState, setAuthState] = useState({
    isLoggedIn: false
  })


  const handleUserSignIn = (state) => {
    if (state === 'signedIn') {
      setAuthState({ isLoggedIn: true })
    }
    else {
      setAuthState({ isLoggedIn: false })
    }
  };

  const ProtectedRoute = ({ render: C, props: childProps, ...rest }) => (
    <Route
      {...rest}
      render={rProps =>
        childProps.isLoggedIn ? (
          <C
            {...rProps}
            {...childProps}
          />
        ) : (
          <Redirect
            to={`/auth?redirect=${rProps.location.pathname}${rProps.location.search
            }`}
          />
        )
      }
    />
  );


  const authProps = {
    isLoggedIn: authState.isLoggedIn,
    onUserSignIn: handleUserSignIn
  };

  return (
    <Route
      {...rest}
      render={props => {
        if (restrictedTo) {
          /*if (user && user.groups) {
            const { userGroup, fallbackPath } = restrictedTo;
            const { groups = [] } = user || {};
            if (!groups.includes(userGroup)) {
              return <Redirect to={{ pathname: fallbackPath || '', state: { from: props.location } }} />
            } else {
              return <Layout>
                <Component {...props} />
              </Layout>
            }
          }
          // display loader while loading user data in userContext*/
          return <ProtectedRoute
            exact
            path={props.path}
            props={props}
            render={Component}
                 />

          //return <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />;
        } else {
          return <Layout>
            <Component {...props} />
          </Layout>
        }
      }}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default RouteWithLayout;
