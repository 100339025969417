import React, { useState, useEffect } from 'react';
import { routes } from 'common/constants';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import MailOutlineRoundedIcon from '@material-ui/icons/MailOutlineRounded';
import { Link, useParams } from 'react-router-dom';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Typography,
  CardActions,
  Container,
  Paper,
  TableSortLabel
} from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { showPrice, unitFormatter } from '../../common/utilFunctions';
import moment from 'moment';
import { CustomSearchBar, Image } from 'components/organisms';
import Pagination from '@material-ui/lab/Pagination';
import { useOrders } from 'context/order/ordersContext';
import { getUser } from 'graphql/queries';
import { useLoader } from 'layouts/loaderContext';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650
  },
  profileImg: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    marginRight: '1rem'
  }
}));

function CustomerDetails() {
  let { userId } = useParams();
  const classes = useStyles();
  const [searchBy, setSearchBy] = useState('');
  const [customerDetails, setCustomerDetails] = useState();
  const [page, setPage] = useState(1);
  const [sortDirection, setSortDirection] = useState('asc');
  const { orders, dispatch } = useOrders();
  const { loading } = useLoader();

  const itemsPerPage = 10;
  const noOfPages = orders && Math.ceil(orders.length / itemsPerPage);

  const paginationHandler = (event, value) => {
    setPage(value);
  };

  const onSearchChange = (event) => {
    setPage(1);
    setSearchBy(event.target.value);
  };

  useEffect(() => {
    if (userId) {
      API.graphql(graphqlOperation(getUser, { id: userId }))
        .then((res) => {
          setCustomerDetails(res.data.getUser);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: 'getOrdersByUserId',
      payload: {
        userId
      }
    });
  }, [userId]);

  useEffect(() => {
    dispatch({
      type: 'sortOrders',
      payload: {
        property: 'orderId',
        direction: sortDirection
      }
    });
  }, [sortDirection]);

  const filterSearchOrder = ({ orderID = '' }) => {
    return orderID?.toLowerCase().includes(searchBy.toLowerCase());
  };

  const handleRequestSort = (event) => {
    setSortDirection((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
  };

  return (
    <Container style={{ marginLeft: '0.5rem', marginRight: '0.5rem' }}>
      <Grid
        container
        justify="space-between"
        style={{ padding: '0.5rem', marginBottom: '0.5rem' }}>
        <Grid container item justify="flex-start" xs={2} alignItems="center">
          <Link to={routes.admin.customers}>
            <ArrowBackIcon />
          </Link>
        </Grid>
        <Grid item container md={3} xs={12} justify="flex-end">
          <CustomSearchBar
            value={searchBy}
            onChange={onSearchChange}
            placeholder="Search Order"
            onClear={() => setSearchBy('')}
          />
        </Grid>
      </Grid>

      <Card
        style={{
          marginBottom: '1rem',
          paddingTop: '0.5rem',
          height: '9rem'
        }}>
        <CardContent>
          <Grid
            container
            justify="space-between"
            style={{ marginLeft: '1.5rem', marginBottom: '1rem' }}>
            <Grid
              container
              item
              xs={4}
              spacing={5}
              justify="flex-start"
              alignItems="center"
              direction="row">
              <Image
                docKey={customerDetails?.picture}
                loadFromStorage={
                  customerDetails?.picture?.split('/').at(0) === 'images'
                }
                className={classes.profileImg}
              />
              <Typography>
                {`${customerDetails?.firstName} ${customerDetails?.lastName}`}
                <br />
                {`${customerDetails?.phoneNumber}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            item
            xs={12}
            style={{ padding: '1rem' }}>
            <Grid container item xs={6}>
              {customerDetails?.createdAt ? (
                <Typography variant="body1">
                  {`Joined on:
                   ${moment(customerDetails?.createdAt).format(
                     'MMMM Do YYYY'
                   )}`}
                </Typography>
              ) : null}
            </Grid>

            <Grid container item spacing={4} xs={6} justify="flex-end">
              <IconButton color="primary">
                <a href={`tel:${customerDetails?.phoneNumber}`}>
                  <CallRoundedIcon />
                </a>
              </IconButton>
              <IconButton color="primary">
                <a href={`mailTo:${customerDetails?.email}`}>
                  <MailOutlineRoundedIcon />
                </a>
              </IconButton>
            </Grid>
          </Grid>
        </CardActions>
      </Card>

      {orders.length < 1 && !loading ? (
        <Typography align="center" variant="body1" style={{ fontSize: 18 }}>
          The user didn't place any order yet.
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="user-orders-table">
            <TableHead>
              <TableRow style={{ backgroundColor: '#F6F6F6' }}>
                <TableCell>
                  <TableSortLabel
                    active={true}
                    direction={sortDirection}
                    onClick={handleRequestSort}>
                    ORDER NO.
                  </TableSortLabel>
                </TableCell>
                <TableCell align="left">DATE ORDERED</TableCell>
                <TableCell align="left">SHOP NAME</TableCell>
                <TableCell align="left">ORDER DELIVERED</TableCell>
                <TableCell align="left">PRICE</TableCell>
                <TableCell align="left">STATUS</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders
                ?.filter(filterSearchOrder)
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((order, index) => (
                  <TableRow
                    key={`user-order-table-row-${order.id}`}
                    style={{
                      backgroundColor: index % 2 !== 0 ? '#F6F6F6' : '#FFFEFE'
                    }}>
                    <TableCell component="th" scope="row">
                      <Link
                        to={`/admin/customers/${order.userID}/orders/${order.orderID}`}>
                        {order.orderID}
                      </Link>
                    </TableCell>
                    <TableCell align="left">
                      {moment(order.createdAt).format('MMMM Do YYYY')}
                    </TableCell>
                    <TableCell align="left">{order.shop?.name}</TableCell>
                    <TableCell align="left">
                      {moment(order.delivery?.date).format('MMMM Do YYYY')}
                    </TableCell>
                    <TableCell align="left">
                      {showPrice(order?.total || 0)}
                    </TableCell>
                    <TableCell align="left" style={{ color: '#3F51B5' }}>
                      {unitFormatter(order?.status)}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Grid
        container
        item
        xs={12}
        justify="center"
        style={{ marginTop: '10px' }}>
        <Pagination
          count={noOfPages}
          page={page}
          onChange={paginationHandler}
          defaultPage={1}
          color="secondary"
          shape="rounded"
        />
      </Grid>
    </Container>
  );
}

export default CustomerDetails;
