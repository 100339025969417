import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState
} from 'react';
import { useSnackbar } from 'notistack';
import { useLoader } from 'layouts/loaderContext';
import * as redirectAPIs from './redirectAPIs';

const RedirectsContext = createContext({});

const redirectsReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'updateData': {
      return payload || [];
    }
    case 'addData': {
      return [...state, ...payload];
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

const RedirectsProvider = (props) => {
  const [redirects, dispatch] = useReducer(redirectsReducer, []);
  // const [nextPageToken, setNextPageToken] = useState('');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedShopID, setSelectedShopID] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const { setLoading } = useLoader();

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getRedirects': {
          const { shopID, limit = 5000, newQuery = false, date = '' } =
            action?.payload || {};
          if (shopID === selectedShopID && date === selectedDate) return;
          setLoading(true);
          const snackBar = enqueueSnackbar('Loading...', {
            variant: 'info',
            persist: true
          });
          setSelectedShopID(shopID);
          setSelectedDate(date);
          const input = {
            shopID,
            limit,
            sortDirection: 'DESC'
          };
          if (!!date) {
            input.createdAt = { beginsWith: date };
          }
          try {
            const resp = await redirectAPIs.loadRedirectsByShopID(input);
            const data = resp.items?.filter((item) => !item._deleted) || [];
            if (!newQuery) {
              dispatch({
                type: 'addData',
                payload: data
              });
            } else {
              dispatch({
                type: 'updateData',
                payload: data
              });
            }
          } catch (err) {
            console.error('Something went wrong... ', err);
            enqueueSnackbar('Something went wrong...', {
              variant: 'error',
              autoHideDuration: 2000
            });
          } finally {
            setLoading(false);
            closeSnackbar(snackBar);
          }
          break;
        }
        default:
          dispatch(action);
      }
    },
    [redirects]
  );

  const value = {
    redirects,
    selectedShopID,
    selectedDate,
    dispatch: asyncDispatch
  };

  return (
    <RedirectsContext.Provider value={value}>
      {props.children}
    </RedirectsContext.Provider>
  );
};

const useRedirects = () => {
  const context = useContext(RedirectsContext);
  if (!(context && Object.keys(context).length)) {
    throw new Error('useRedirects must be used within a RedirectsContext');
  }
  return context;
};

export { useRedirects, RedirectsProvider };
