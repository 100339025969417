import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  fontColorForAddress: {
    color: '#454545'
  }
}));

const AddressCard = ({ heading = '', address }) => {
  const classes = useStyles();
  const {
    buildingName = '',
    buildingNumber = '',
    country = '',
    district = '',
    line1 = '',
    line2 = '',
    phoneNumber = '',
    postcode = '',
    town = ''
  } = address && JSON.parse(address.address);

  return (
    <Grid container direction="column">
      <Typography variant="h4">{heading}</Typography>
      <Grid
        container
        direction="column"
        style={{
          border: '1px solid #979797',
          borderRadius: '11px',
          padding: '16px',
          marginTop: '16px'
        }}>
        <Typography variant="subtitle2" className={classes.fontColorForAddress}>
          {line1}
        </Typography>
        <Typography variant="subtitle2" className={classes.fontColorForAddress}>
          {line2}
        </Typography>
        <Typography
          variant="subtitle2"
          className={
            classes.fontColorForAddress
          }>{`${buildingNumber}, ${buildingName}`}</Typography>
        <Typography
          variant="subtitle2"
          className={
            classes.fontColorForAddress
          }>{`${district}, ${postcode}`}</Typography>
        <Typography variant="subtitle2" className={classes.fontColorForAddress}>
          {town}
        </Typography>
        <Typography variant="subtitle2" className={classes.fontColorForAddress}>
          {country}
        </Typography>
        <Typography variant="subtitle2" className={classes.fontColorForAddress}>
          {phoneNumber}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AddressCard;
