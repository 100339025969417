export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      shopID
      serviceID
      itemID
      category
      name
      description
      discount
      discountDescription
      unit
      tags
      price
      image
      _version
      _deleted
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      shopID
      serviceID
      itemID
      category
      name
      description
      discount
      discountDescription
      unit
      tags
      price
      image
      _version
      _deleted
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      shopID
      serviceID
      itemID
      category
      name
      description
      discount
      discountDescription
      unit
      tags
      price
      image
      _version
      _deleted
    }
  }
`;


export default { createProduct, updateProduct, deleteProduct }