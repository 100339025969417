import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import { routes } from 'common/constants';
import SearchShop from 'components/SearchShop';
import { Link } from 'react-router-dom';
import { CustomSearchBar } from 'components/organisms';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginLeft: theme.spacing(1)
  }
}));

const UsersToolbar = (props) => {
  const {
    value,
    handleChange,
    handleClear,
    handleShopChange,
    className,
    selectedShop,
    ...rest
  } = props;
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container justify="space-between">
        <Typography variant="h3">Shop Users</Typography>
        <Link to={routes.admin.addUser}>
          <Button color="primary" variant="contained">
            Add user
          </Button>
        </Link>
      </Grid>
      <Grid container>
        <Grid item xs={6} container alignItems="center">
          <SearchShop onChange={handleShopChange} value={selectedShop} />
        </Grid>
        <Grid item xs={6} container alignItems="center">
          <CustomSearchBar
            className={classes.searchInput}
            value={value}
            onChange={handleChange}
            placeholder="Search by Name / Email / Phone"
            onClear={handleClear}
          />
        </Grid>
      </Grid>
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
