import React, { useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Typography,
  Paper,
  ListItem,
  List,
  IconButton,
  FormControlLabel,
  Switch,
  Collapse
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CustomListItem from './CustomListItem';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TocIcon from '@material-ui/icons/Toc';
import { useServices } from '../serviceContext';

const initialService = {
  name: '',
  orderIndex: 0,
  type: 'domestic'
};

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: '1rem' },
  inputContainer: {
    padding: '0.25rem'
  },
  buttonsContainer: {
    padding: '0.25rem'
  },
  heading: {
    fontSize: '1.25rem',
    padding: '0.25rem 0',
    marginLeft: '0.5rem',
    '& > span': {
      fontSize: '0.9rem',
      color: '#999'
    }
  },
  subHeading: {
    fontSize: '0.75rem',
    color: '#aaa',
    '& > span': {
      fontSize: '1rem',
      color: '#000'
    }
  }
}));

const listStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  disabled: {
    color: 'black',
    fontSize: '1.25rem'
  },
  iconButton: {
    padding: 0
  },
  divider: {
    height: 28,
    margin: 4
  },
  listItem: {
    padding: '0 5px'
  }
}));

export default function ServicesList({
  service = {},
  hideNewService = () => {},
  handleDragStart = () => {},
  handleDragEnter = () => {},
  handleDragEnd = () => {},
  isDomesticDrag = false,
  isCommercialDrag = false
}) {
  const classes = useStyles();
  const listClasses = listStyles();
  const [currentService, setCurrentService] = useState(initialService);
  const [editing, setEditing] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const { setEditData, dispatch } = useServices();

  useEffect(() => {
    if (service?.name) {
      setCurrentService({
        ...service,
        orderIndex: service.orderIndex || 0,
        type: service.type || 'domestic'
      });
      setEditing(false);
    } else {
      setCurrentService(initialService);
      setEditing(true);
    }
  }, [service?.id]);

  const handleChange = (e) =>
    e.target.name === 'type'
      ? setCurrentService({
          ...currentService,
          [e.target.name]: e.target.checked ? 'commercial' : 'domestic'
        })
      : setCurrentService({
          ...currentService,
          [e.target.name]: e.target.value
        });

  const handleSubmit = (e) => {
    e.stopPropagation();
    if (currentService.name && currentService.orderIndex >= 0) {
      if (currentService.id) {
        dispatch({
          type: 'updateNewService',
          payload: currentService
        }).then(() => setEditing(false));
      } else {
        dispatch({ type: 'addNewService', payload: currentService }).then(
          hideNewService
        );
      }
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    setEditData({
      name: service.name,
      description: service.description,
      id: service.id,
      _version: service._version,
      moduleType: 'Service'
    });
  };

  const handleClose = (e) => {
    e.stopPropagation();
    if (service?.name) {
      setCurrentService({
        ...service,
        orderIndex: service.orderIndex || 0,
        type: service.type || 'domestic'
      });
    }
    setEditing(false);
    hideNewService();
  };

  const saveItem = (name) => {
    dispatch({
      type: 'addNewItem',
      payload: {
        name: name,
        serviceID: service.id
      }
    }).then(() => {
      setNewItem(false);
    });
  };

  const handleAddItem = (e) => {
    e.stopPropagation();
    setShowDropDown(true);
    setNewItem(true);
  };

  const handleEditService = (e) => {
    e.stopPropagation();
    setEditing(true);
  };
  const isDrag =
    service.type === 'commercial' ? isCommercialDrag : isDomesticDrag;

  return (
    <Grid item xs={12} className={classes.root}>
      <List className={listClasses.root} component={Paper}>
        <ListItem
          draggable={isDrag}
          onDragStart={handleDragStart}
          onDragEnter={handleDragEnter}
          onDragEnd={handleDragEnd}
          onDragOver={(e) => e.preventDefault()}
          className={listClasses.listItem}
          component="div"
          button
          onClick={() => {
            if (!isDrag) setShowDropDown(!showDropDown);
          }}>
          <Grid container alignItems="center">
            {editing ? (
              <Grid container justify="space-between" alignItems="center">
                <Grid item xs={12} sm={5} className={classes.inputContainer}>
                  <TextField
                    placeholder="Enter Service name"
                    inputProps={{ 'aria-label': 'enter service name' }}
                    value={currentService.name}
                    name="name"
                    onChange={handleChange}
                    size="small"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} sm={2} className={classes.inputContainer}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={currentService.type === 'commercial'}
                        onChange={handleChange}
                        name="type"
                        color="primary"
                      />
                    }
                    label={currentService.type}
                    labelPlacement="bottom"
                  />
                </Grid>
                <Grid
                  item
                  xs={4}
                  sm={3}
                  className={classes.buttonsContainer}
                  container
                  justify="space-around">
                  <IconButton
                    title="Save"
                    className={listClasses.iconButton}
                    onClick={handleSubmit}>
                    <CheckBoxIcon />
                  </IconButton>
                  <IconButton
                    title="Cancel"
                    className={listClasses.iconButton}
                    onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ) : (
              <Grid container item justify="space-between" alignItems="center">
                {isDrag && <TocIcon />}
                <Grid item xs={7} className={classes.inputContainer}>
                  <Typography className={classes.heading}>
                    {currentService.name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  className={classes.buttonsContainer}
                  container
                  justify="space-around">
                  <IconButton
                    title="Add item"
                    className={listClasses.iconButton}
                    onClick={handleAddItem}
                    disabled={isDrag}>
                    <AddBoxRoundedIcon />
                  </IconButton>
                  <IconButton
                    title="Edit item"
                    className={listClasses.iconButton}
                    onClick={handleEditService}
                    disabled={isDrag}>
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    title="Delete item"
                    className={listClasses.iconButton}
                    onClick={handleDelete}
                    disabled={isDrag}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        </ListItem>

        {newItem && (
          <CustomListItem
            editable={true}
            setNewItem={setNewItem}
            saveItem={saveItem}
          />
        )}

        {service?.items?.items?.length > 0 ? (
          <Collapse in={showDropDown}>
            {service.items.items.map((item, i) => (
              <CustomListItem item={item} key={i} />
            ))}
          </Collapse>
        ) : null}
      </List>
    </Grid>
  );
}
