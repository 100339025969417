import { React } from 'react';

const amplifyAuthReducer = (state, { type, payload } = {}) => {
  switch (type) {
    case 'FETCH_USER_DATA_SUCCESS': {
      const { user } = payload || {};
      let _user = {};
      Object.keys(user).forEach((key) => {
        if (user[key]) {
          _user[key] = user[key];
          //TODO : keep this because we getting user from two sources, need to remove this in future
          if (key === 'shopID') {
            _user['shopId'] = user[key];
          }
        }
      });
      const groups = payload?.user?.groups || state?.user?.groups;
      return {
        ...state,
        isLoading: false,
        isError: false,
        isAdmin: groups?.includes('admin'),
        isSales: groups?.includes('sales'),
        isLaundryAdmin: groups?.includes('laundryAdmin'),
        isLaundryStaff: groups?.includes('laundryStaff'),
        user: { ...state.user, ..._user }
      };
    }
    case 'FETCH_USER_DATA_FAILURE':
      return { ...state, isLoading: false, isError: true };
    case 'RESET_USER_DATA':
      return { ...state, user: null };
    default:
      throw new Error();
  }
};

export default amplifyAuthReducer;
