const LOCATIONS_DATA = [
  {
    lat: 51.522648109047694,
    lng: -0.12565440000016526,
    area: 'Holborn',
    text: 'wc1b3dg',
    postalCode: 'wc1b3dg'
  },
  {
    lat: 51.60492603471709,
    lng: -0.06469429426879203,
    area: 'Tottenham',
    text: 'N170BX',
    postalCode: 'N170BX'
  },
  {
    lat: 51.5802538950181,
    lng: 0.004053376185258184,
    area: 'Wanstead',
    text: 'E111NR',
    postalCode: 'E111NR'
  },
  {
    lat: 51.54301754284518,
    lng: -0.04766462955913131,
    area: 'Hackney',
    text: 'E97LQ',
    postalCode: 'E97LQ'
  },
  {
    lat: 51.54262309200557,
    lng: 0.029402225500916018,
    area: 'Plaistow',
    text: 'E78HU',
    postalCode: 'E78HU'
  },
  {
    lat: 51.517839396632134,
    lng: -0.039553303986811526,
    area: 'Limehouse',
    text: 'E13NN',
    postalCode: 'E13NN'
  },
  {
    lat: 51.50908270850392,
    lng: 0.05049754543518133,
    area: 'London city airport',
    text: 'E162PX',
    postalCode: 'E162PX'
  },
  {
    lat: 51.475718027800724,
    lng: 0.038987537017095356,
    area: 'Shooters Hill Rd',
    text: 'SE184LX',
    postalCode: 'SE184LX'
  },
  {
    lat: 51.478044280068,
    lng: -0.023738746954473058,
    area: 'Deptford',
    text: 'SE84RJ',
    postalCode: 'SE84RJ'
  },
  {
    lat: 51.462682494116905,
    lng: -0.08457842142202776,
    area: 'Dog Kennel Hill Estate',
    text: 'SE228BB',
    postalCode: 'SE228BB'
  },
  {
    lat: 51.48484040021677,
    lng: -0.09313923478935734,
    area: 'Walworth',
    text: 'SE172AX',
    postalCode: 'SE172AX'
  },
  {
    lat: 51.47944990048652,
    lng: -0.1581838477012801,
    area: 'Battersea',
    text: 'SW114NJ',
    postalCode: 'SW114NJ'
  },
  {
    lat: 51.5059591558456,
    lng: -0.18774795754248627,
    area: 'Kensington',
    text: 'W84PX',
    postalCode: 'W84PX'
  },
  {
    lat: 51.47785536979775,
    lng: -0.20153009763988516,
    area: 'Fulham',
    text: 'SW65NT',
    postalCode: 'SW65NT'
  },
  {
    lat: 51.50489937796175,
    lng: -0.2575263151370396,
    area: 'Bedford Park',
    text: 'W37QE',
    postalCode: 'W37QE'
  },
  {
    lat: 51.54874699932716,
    lng: -0.22148013495353885,
    area: 'Willesden',
    text: 'NW24RA',
    postalCode: 'NW24RA'
  },
  {
    lat: 51.56008461857739,
    lng: -0.15958531596433384,
    area: 'Camden Town',
    text: 'NW51QR',
    postalCode: 'NW51QR'
  },
  {
    lat: 51.596425514194614,
    lng: -0.1295506290076864,
    area: 'Wood Green',
    text: 'N227AY',
    postalCode: 'N227AY'
  },
  {
    lat: 51.5548434795637,
    lng: -0.09016225930817345,
    area: 'Newington',
    text: 'N52RT',
    postalCode: 'N52RT'
  },
  {
    lat: 51.63099332024943,
    lng: -0.17529366712055613,
    area: 'Whetstone',
    text: 'N209HS',
    postalCode: 'N209HS'
  },
  {
    lat: 51.617009788629346,
    lng: -0.005935006669523614,
    area: 'Chingford Hatch',
    text: 'E49EY',
    postalCode: 'E49EY'
  },
  {
    lat: 51.599787493080406,
    lng: 0.10179457597141582,
    area: 'Aldborough Hatch',
    text: 'IG63HX',
    postalCode: 'IG63HX'
  },
  {
    lat: 51.552777493099555,
    lng: 0.11320162514958157,
    area: 'Dagenham',
    text: 'RM82DB',
    postalCode: 'RM82DB'
  },
  {
    lat: 51.48165386338466,
    lng: 0.13221337702986066,
    area: 'Belvedere',
    text: 'SE20DW',
    postalCode: 'SE20DW'
  },
  {
    lat: 51.423799821302104,
    lng: -0.034469982254181464,
    area: 'Beckenham',
    text: 'SE265BA',
    postalCode: 'SE265BA'
  },
  {
    lat: 51.43598234706926,
    lng: 0.107952429869063,
    area: 'Sidcup',
    text: 'DA159AG',
    postalCode: 'DA159AG'
  },
  {
    lat: 51.580678521117086,
    lng: -0.34033212091434506,
    area: 'Harrow',
    text: 'HA11PD',
    postalCode: 'HA11PD'
  },
  {
    lat: 51.516268008960964,
    lng: -0.402647784228036,
    area: 'Southall',
    text: 'UB40JG',
    postalCode: 'UB40JG'
  },
  {
    lat: 51.41228673498997,
    lng: -0.30112057306458295,
    area: 'Kingston upon Thames',
    text: 'KT11SW',
    postalCode: 'KT11SW'
  },
  {
    lat: 51.403217813245654,
    lng: -0.16655270919586126,
    area: 'Mitcham',
    text: 'CR43ND',
    postalCode: 'CR43ND'
  }
];

export default LOCATIONS_DATA;
