import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import UploadedShopDocuments from '../../../LaundryShop/components/UploadedShopDocuments';
import UploadedShopPhotos from '../../../LaundryShop/components/UploadedShopPhotos';
import { API, graphqlOperation } from 'aws-amplify';
import { createShop, disableUser, updateShop } from 'graphql/mutations';
import ShopDeleteDialog from './ShopDeleteDialog';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import { Context } from '../../../../context/shopDetail/shopContext';
import PropTypes from 'prop-types';
import Map from './map';
import { routes } from 'common/constants';
import WorkingHours from './WorkingHours';
import { useSnackbar } from 'notistack';
import { useLoader } from '../../../../layouts/loaderContext';
import { useShops } from 'context/shop/shopsContext';
import { useHistory, useParams } from 'react-router-dom';
import { useAmplifyAuth } from 'context';
import {
  getLatLngByPostCode,
  searchShops,
  getAllShopUsers
} from 'graphql/queries';
import moment from 'moment';
import ShopDetails from 'views/LaundryShop/components/ShopDetails';
import CollectionAndDeliveryHours from 'views/LaundryShop/components/CollectionAndDeliveryHours';
import FormActionButton from './FormActionButton';
import { shopFormFields } from 'common/constants';
import { shopFieldvalidation } from 'common/validators';

const buttonAction = {
  SUBMIT: 0,
  SAVE: 1,
  SAVE_AND_CLOSE: 2
};

const EntryFormTabPanel = (props) => {
  let { actionType, shopId } = useParams();
  const history = useHistory();
  const { shops } = useShops();
  const isAddNewPage = actionType === 'add';
  const {
    className,
    google,
    value,
    index,
    shopId: _shopId,
    pageType,
    showNote = false,
    ...rest
  } = props;
  if (_shopId) {
    shopId = _shopId;
  }
  const {
    selectedShop,
    setSelectedShop,
    availableShops,
    setAvailableShops
  } = useContext(Context);
  const { dispatch } = useShops();
  const {
    state: { user, isAdmin = false }
  } = useAmplifyAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setLoading } = useLoader();
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [enablePriceText, setEnablePriceText] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (isAddNewPage || !shopId) {
      setSelectedShop({});
      return;
    }
    dispatch({
      type: 'getSelectedShop',
      payload: { shopId }
    }).then((data) => {
      if (!data) {
        if (pageType === 'shop') {
          history.push('/');
        } else {
          history.push(routes.admin.shops);
        }
      } else {
        setSelectedShop(data);
      }
    });
  }, [shopId]);

  useEffect(() => {
    !!selectedShop?.minPriceForFree && setEnablePriceText(true);
  }, [selectedShop]);

  const handleChange = (event, newValue) => {
    if (event.target.name === 'phoneNumber') {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
    if (event.target.name === 'status') {
      const value = event.target.value;
      value !== 'active'
        ? setSelectedShop({
            ...selectedShop,
            [event.target.name]: value,
            isLive: false
          })
        : setSelectedShop({
            ...selectedShop,
            [event.target.name]: value
          });
    } else {
      const value =
        event.target.name === 'country'
          ? event.target.value
          : event.target.name === 'town'
          ? event.target.value
          : newValue || event.target.value;
      setSelectedShop({
        ...selectedShop,
        [event.target.name]: value
      });
      shopFieldvalidation(
        {
          ...selectedShop,
          [event.target.name]: value
        },
        setFormErrors
      );
    }
  };

  const useStyles = makeStyles(() => ({
    root: {},
    style: {
      width: '50vw',
      height: '75vh',
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    timeSelect: {
      margin: '15px 10px'
    },
    languageHeader: {
      paddingBottom: 0
    },
    smallInput: {
      width: 80,
      marginLeft: '10px'
    },
    mediumInput: {
      width: 100,
      marginLeft: '10px'
    },
    dollarSign: {
      '&:before': {
        content: '"£"',
        position: 'absolute',
        top: 11,
        left: 10,
        fontSize: 14
      },
      '& input': {
        paddingLeft: 20
      }
    }
  }));

  const classes = useStyles();

  const deleteShopHandler = async () => {
    const { _version, id } = selectedShop;
    const payload = {
      input: {
        _version,
        id,
        status: 'rejected',
        isLive: false
      }
    };
    const sBar = enqueueSnackbar(
      "Deactivating shop and disabling all it's users...",
      {
        variant: 'info',
        persist: true
      }
    );
    setLoading(true);
    try {
      const getUsersResponse = await API.graphql(
        graphqlOperation(getAllShopUsers, {
          shopId: selectedShop.id
        })
      );
      const usersToDisable = getUsersResponse.data?.getAllShopUsers || [];
      const disabledUsers = usersToDisable.map(
        async (user) =>
          await API.graphql(
            graphqlOperation(disableUser, {
              username: user.Username,
              id: user.Attributes.find((item) => item.Name === 'sub')?.Value
            })
          )
      );
      await Promise.all(disabledUsers);
      console.log(`total users disabled: ${disabledUsers.length || 0}`);
      const result = await API.graphql(graphqlOperation(updateShop, payload));
      enqueueSnackbar('Deleted Shop successfully', {
        variant: 'success',
        preventDuplicate: true,
        autoHideDuration: 1000
      });
      closeSnackbar(sBar);
      setLoading(false);
      dispatch({
        type: 'updateShop',
        payload: result.data.updateShop
      });
      history.push(routes.admin.shops);
    } catch (error) {
      closeSnackbar(sBar);
      setLoading(false);
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 1500
      });
      console.error('error', error);
    }
  };

  const handleSubmit = async (event, actionType = buttonAction.SUBMIT) => {
    event.preventDefault();

    const _validate = shopFormFields.every(({ label, property }) => {
      if (!selectedShop[property]) {
        enqueueSnackbar(label + ' is a mandatory field', {
          variant: 'warning',
          preventDuplicate: true
        });
      }
      return !!selectedShop[property];
    });
    if (!_validate) {
      return;
    }

    const {
      place_id: googlePlaceId,
      name,
      contactName,
      address,
      postcode,
      town,
      country,
      email,
      phoneNumber,
      location,
      workHrs,
      collectionHrs = [],
      deliveryHrs = [],
      minPriceForFree,
      distanceRange,
      language,
      standardDeliveryFee = 0,
      enableAsapDelivery = false,
      AsapDeliveryFee = 0,
      isLive,
      websiteLink = '',
      status
    } = selectedShop;
    let sBar;

    if (!workHrs) {
      enqueueSnackbar('Shop Open Hours is a mandatory field', {
        variant: 'warning',
        preventDuplicate: true
      });
      return;
    }
    if (!location?.lat || !location.lng) {
      enqueueSnackbar('Please select location for shop', {
        variant: 'warning',
        preventDuplicate: true
      });
      return;
    }

    if (collectionHrs.length > 0 || deliveryHrs.length > 0) {
      if (!distanceRange) {
        enqueueSnackbar(
          '"Collection and delivery distance range" is a mandatory field',
          { variant: 'warning', preventDuplicate: true }
        );
        return;
      }
      if (!standardDeliveryFee) {
        enqueueSnackbar(
          '"Standard collection or delivery fee" is a mandatory field',
          { variant: 'warning', preventDuplicate: true }
        );
        return;
      }
      if (enablePriceText && !minPriceForFree) {
        enqueueSnackbar(
          '"Free collection and delivery for order amount more than" is a mandatory field',
          { variant: 'warning', preventDuplicate: true }
        );
        return;
      }
    }

    if (enableAsapDelivery && !AsapDeliveryFee) {
      enqueueSnackbar(
        '"ASAP collection and delivery price" is a mandatory field',
        { variant: 'warning', preventDuplicate: true }
      );
      return;
    }

    try {
      const payload = {
        input: {
          googlePlaceId,
          name,
          contactName,
          address,
          postcode,
          town,
          country,
          email,
          phoneNumber,
          location,
          workHrs,
          collectionHrs,
          deliveryHrs,
          distanceRange: distanceRange ? parseFloat(distanceRange) : null,
          minPriceForFree: minPriceForFree ? parseFloat(minPriceForFree) : null,
          language,
          standardDeliveryFee,
          enableAsapDelivery,
          AsapDeliveryFee,
          isLive,
          websiteLink,
          status: status || 'pending'
        }
      };

      setLoading(true);

      // TODO: combine API with graphql
      if (selectedShop.id) {
        const { _version, id } = selectedShop;
        payload.input._version = _version;
        payload.input.id = id;
        sBar = enqueueSnackbar('Updating Shop data...', {
          variant: 'info',
          persist: true
        });
        const result = await API.graphql(graphqlOperation(updateShop, payload));
        closeSnackbar(sBar);
        enqueueSnackbar('Updated shop successfully', {
          variant: 'success',
          preventDuplicate: true,
          autoHideDuration: 1000
        });
        setLoading(false);
        dispatch({
          type: 'updateShop',
          payload: result.data.updateShop
        });
        setSelectedShop(result.data.updateShop);
        if (actionType === buttonAction.SAVE_AND_CLOSE) {
          history.push(routes.admin.shops);
        }
      } else {
        let shouldAddShop = true;

        // check if shop is already added
        const resp = await API.graphql(
          graphqlOperation(searchShops, { searchString: payload.input.name })
        );
        const shopsWithSameName = resp.data.searchShops.items || [];
        const isShopAlreadyExist = [...shops, ...shopsWithSameName].some(
          (shop) =>
            // checking shop google place id
            shop?.googlePlaceId === payload.input.googlePlaceId ||
            // checking shop name
            (shop?.name?.split(' ').join('').toLowerCase() ===
              selectedShop.name?.split(' ').join('').toLowerCase() &&
              // checking shop postcode
              shop?.postcode?.split(' ').join('').toLowerCase() ===
                selectedShop.postcode?.split(' ').join('').toLowerCase() &&
              // checking shop phone number last 10 digits
              shop?.phoneNumber?.split(' ').join('').slice(-10) ===
                selectedShop.phoneNumber?.split(' ').join('').slice(-10) &&
              // checking shop email
              shop?.email?.trim().toLowerCase() ===
                selectedShop.email?.trim().toLowerCase())
        );
        if (isShopAlreadyExist) {
          enqueueSnackbar('Shop already exists...', {
            variant: 'error',
            preventDuplicate: true,
            autoHideDuration: 3000
          });
          shouldAddShop = false;
        }

        // adding shop
        if (shouldAddShop) {
          sBar = enqueueSnackbar('Saving Shop data...', {
            variant: 'info',
            persist: true
          });
          payload.input.createdBy = user.sub;
          payload.input.createdAt = new Date();
          const result = await API.graphql(
            graphqlOperation(createShop, payload)
          );
          closeSnackbar(sBar);
          const shopId = result.data.createShop.id;
          const sBar_1 = enqueueSnackbar('Saving shop location data...', {
            variant: 'info',
            persist: true
          });
          await API.post('laundryapi', '/postData', {
            body: {
              shopID: shopId,
              businessName: selectedShop.name,
              lat: selectedShop.location.lat,
              lng: selectedShop.location.lng
            }
          });
          dispatch({
            type: 'addShop',
            payload: result.data.createShop
          });
          setAvailableShops(
            availableShops.filter((e) => e.place_id !== selectedShop.place_id)
          );
          setSelectedShop({});
          setLoading(false);
          closeSnackbar(sBar_1);
          enqueueSnackbar('Saved Shop successfully', {
            variant: 'success',
            preventDuplicate: true,
            autoHideDuration: 1000
          });
        }
      }
    } catch (e) {
      console.error('Error: ', e);
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 1500
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDialogClose = () => setDeleteDialog(false);

  const resetHandler = () => setSelectedShop({});

  const handlePostCodeOnBlur = () => {
    // need to update the lat and lng in selectedShop by using the new postcode
    API.graphql(
      graphqlOperation(getLatLngByPostCode, {
        postalCode: selectedShop.postcode
      })
    )
      .then((data) => {
        setSelectedShop({
          ...selectedShop,
          location: {
            lat: data.data.getLatLngByPostCode.lat,
            lng: data.data.getLatLngByPostCode.lng
          }
        });
      })
      .catch((e) => console.error('postcode data not found', e));
  };

  const handleNumberChange = (e) => {
    const re = /^[0-9]+\.?[0-9]*$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setSelectedShop({
        ...selectedShop,
        [e.target.name]: e.target.value === '' ? 0 : e.target.value
      });
    }
  };

  return (
    <Card
      {...rest}
      aria-labelledby={`simple-tab-${index}`}
      className={clsx(classes.root, className)}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel">
      <form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {selectedShop && (
          <CardHeader
            action={
              <FormActionButton
                isAddNewPage={isAddNewPage}
                selectedShop={selectedShop}
                resetHandler={resetHandler}
                handleSubmit={handleSubmit}
                buttonAction={buttonAction}
                pageType={pageType}
                setDeleteDialog={setDeleteDialog}
              />
            }
            subheader="The information about the shop."
            title={
              selectedShop.name
                ? `${selectedShop.name} - Shop Details`
                : 'Shop Details'
            }
          />
        )}
        <Divider />
        {isAddNewPage && showNote ? (
          <>
            <Grid
              container
              style={{ backgroundColor: '#ffb74d', padding: '1rem' }}>
              <Typography variant="body1">
                <strong>Note:</strong> add non listed shops manually.
              </Typography>
            </Grid>
            <Divider />
          </>
        ) : null}
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Status"
                margin="dense"
                name={'status'}
                select
                disabled={!isAdmin}
                onChange={handleChange}
                required
                value={
                  selectedShop && selectedShop.status
                    ? selectedShop.status !== 'rejected'
                      ? selectedShop.status
                      : ''
                    : 'pending'
                }
                variant="outlined">
                <MenuItem value="pending">Pending</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="deactivate">Deactivate</MenuItem>
                <MenuItem value="suspended">Suspended</MenuItem>
                <MenuItem value="onhold">On Hold</MenuItem>
              </TextField>
            </Grid>
            <Grid item md={6} xs={12} container alignItems="center">
              <FormControlLabel
                label={
                  selectedShop && selectedShop.isLive ? 'Online' : 'Offline'
                }
                control={
                  <Switch
                    disabled={
                      selectedShop && selectedShop.status
                        ? selectedShop.status !== 'active'
                        : true
                    }
                    checked={
                      selectedShop && selectedShop.isLive
                        ? selectedShop.isLive
                        : false
                    }
                    onChange={handleChange}
                    color="primary"
                    name="isLive"
                    inputProps={{ 'aria-label': 'shop-isLive-switch' }}
                  />
                }
              />
            </Grid>
            <ShopDetails
              fields={shopFormFields}
              formErrors={formErrors}
              selectedShop={selectedShop}
              handleChange={handleChange}
              handlePostCodeOnBlur={handlePostCodeOnBlur}
              handleSubmit={handleSubmit}
            />
          </Grid>
        </CardContent>
        <Divider />
        <WorkingHours
          sorryMsg="Closed"
          subheader="The information about the shop working days."
          title="Shop Open Hours"
          type="workHrs"
          hours={selectedShop ? selectedShop['workHrs'] : []}
          selectedShop={selectedShop}
          setSelectedShop={setSelectedShop}
        />
        <Divider />
        <CardHeader
          subheader="Pin for your business location."
          title="Mark my shop in Google Map"
        />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Map state={selectedShop} update={setSelectedShop} />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {selectedShop && (
          <CollectionAndDeliveryHours
            selectedShop={selectedShop}
            setSelectedShop={setSelectedShop}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            enablePriceText={enablePriceText}
            setEnablePriceText={setEnablePriceText}
            handleNumberChange={handleNumberChange}
          />
        )}
        {!isAddNewPage && (
          <>
            <Divider />
            <CardHeader title="Documents" subheader="Approve shop documents" />
            <Divider />
            <CardContent style={{ padding: '0 1rem' }}>
              <UploadedShopDocuments
                selectedShop={selectedShop}
                setSelectedShop={setSelectedShop}
              />
            </CardContent>
            <Divider />
            <CardHeader
              title="Photos & Videos"
              subheader="Approve shop photos & videos"
            />
            <Divider />
            <CardContent style={{ padding: '0 1rem' }}>
              <UploadedShopPhotos
                selectedShop={selectedShop}
                setSelectedShop={setSelectedShop}
              />
            </CardContent>
          </>
        )}
        <FormActionButton
          isAddNewPage={isAddNewPage}
          selectedShop={selectedShop}
          resetHandler={resetHandler}
          handleSubmit={handleSubmit}
          buttonAction={buttonAction}
          pageType={pageType}
          setDeleteDialog={setDeleteDialog}
        />
      </form>
      {!isAddNewPage ? (
        <Typography variant="body2" style={{ padding: '0.1rem 0.5rem' }}>
          consent letter
          {selectedShop?.consentLetterCount
            ? ` generated ${selectedShop.consentLetterCount} time${
                selectedShop.consentLetterCount > 1 ? 's' : ''
              }, last on ${moment(selectedShop.consentLetterLastDate).format(
                'DD/MM/YYYY'
              )}`
            : ' not yet generated'}
        </Typography>
      ) : null}

      <ShopDeleteDialog
        open={deleteDialog}
        handleClose={handleDeleteDialogClose}
        handleSuccess={deleteShopHandler}
      />
    </Card>
  );
};
EntryFormTabPanel.propTypes = {
  children: PropTypes.node
};

export default EntryFormTabPanel;
