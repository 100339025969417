const createService = /* GraphQL */ `
  mutation CreateService(
    $input: CreateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    createService(input: $input, condition: $condition) {
      id
      name
      description
      orderIndex
      type
      items {
        items {
          id
          serviceID
          name
          description
          _version
          _deleted
        }
      }
      _version
      _deleted
    }
  }
`;

const updateService = /* GraphQL */ `
  mutation UpdateService(
    $input: UpdateServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    updateService(input: $input, condition: $condition) {
      id
      name
      description
      orderIndex
      type
      items {
        items {
          id
          serviceID
          name
          description
          _version
          _deleted
        }
      }
      _version
      _deleted
    }
  }
`;

const deleteService = /* GraphQL */ `
  mutation DeleteService(
    $input: DeleteServiceInput!
    $condition: ModelServiceConditionInput
  ) {
    deleteService(input: $input, condition: $condition) {
      id
      _version
      _deleted
    }
  }
`;

const createItem = /* GraphQL */ `
  mutation CreateItem(
    $input: CreateItemInput!
    $condition: ModelItemConditionInput
  ) {
    createItem(input: $input, condition: $condition) {
      id
      serviceID
      name
      image
      description
      _version
      _deleted
    }
  }
`;

const updateItem = /* GraphQL */ `
  mutation UpdateItem(
    $input: UpdateItemInput!
    $condition: ModelItemConditionInput
  ) {
    updateItem(input: $input, condition: $condition) {
      id
      serviceID
      name
      image
      description
      _version
      _deleted
    }
  }
`;

const deleteItem = /* GraphQL */ `
  mutation DeleteItem(
    $input: DeleteItemInput!
    $condition: ModelItemConditionInput
  ) {
    deleteItem(input: $input, condition: $condition) {
      id
      serviceID
      _version
      _deleted
    }
  }
`;

export default {
  updateService,
  updateItem,
  deleteItem,
  deleteService,
  createItem,
  createService
};
