import React, { useCallback, useEffect, useRef } from 'react';
import { I18n, Auth } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { useHistory } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import LogoName from '../../components/organisms/LogoName';
import { Grid, makeStyles } from '@material-ui/core';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

I18n.putVocabulariesForLanguage('en', {
  'Sign In': 'Login', // Tab header
  'Sign in': 'LOGIN' // Button label
});

const useStyles = makeStyles(() => ({
  root: {
    margin: '0 auto',
    padding: '2rem 0 3rem',
    minHeight: '450px'
  }
}));

const Login = () => {
  const history = useHistory();
  const classes = useStyles();
  const captchaRef = useRef();
  const redirectUrl = history.location.search.split('redirect=')[1] || '/home';
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('recaptcha not yet available');
      return '';
    }
    const token = await executeRecaptcha();
    return token;
  }, [executeRecaptcha]);

  useEffect(() => {
    localStorage.setItem('redirectUrl', redirectUrl);
    handleReCaptchaVerify().then((token) => {
      captchaRef.current = token;
    });
  }, [handleReCaptchaVerify]);

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      className={classes.root}>
      <Grid item style={{ marginBottom: '40px' }}>
        <LogoName />
      </Grid>
      <Authenticator
        loginMechanisms={['email']}
        signUpAttributes={[
          'custom:firstName',
          'custom:lastName',
          'custom:phoneNumber'
        ]}
        formFields={{
          signUp: {
            email: {
              order: 1
            },
            'custom:firstName': {
              order: 2,
              placeholder: 'First Name'
            },
            'custom:lastName': {
              order: 3,
              placeholder: 'Last Name'
            },
            'custom:phoneNumber': {
              order: 4,
              placeholder: 'Phone Number'
            },
            password: {
              order: 5
            },
            confirm_password: {
              order: 6
            }
          }
        }}
        services={{
          handleSignUp: async (formData) =>
            await Auth.signUp({
              ...formData,
              validationData: {
                recaptchaToken: captchaRef.current
              }
            }),
          handleSignIn: async (formData) =>
            Auth.signIn({
              ...formData,
              username: formData.username.trim().toLowerCase()
            }).then((user) => {
              history.replace(redirectUrl);
              return user;
            }),
          handleConfirmSignUp: async ({ username, code }) =>
            Auth.confirmSignUp(username, code).then(() =>
              history.replace('/login')
            ),
          handleForgotPasswordSubmit: async ({ username, code, password }) =>
            Auth.forgotPasswordSubmit(username, code, password).then(() =>
              history.replace(redirectUrl)
            )
        }}
      />
    </Grid>
  );
};

export default Login;
