import React, { Fragment } from 'react';
import moment from 'moment';
import consentLetterHeaderImg from '../../assets/png/consentLetterHeaderImg.jpg';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center'
  },
  section: {
    position: 'relative',
    margin: 50,
    flexGrow: 1,
    fontSize: '10px',
    lineHeight: '1.8'
  },
  logo: {
    width: '110%',
    marginBottom: 10
  },
  logoContainer: {
    marginLeft: '-20px',
    marginTop: '-80px'
  },
  pageNum: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    position: 'absolute',
    right: 0,
    bottom: -90
  },
  marginLargeBottom: {
    marginBottom: 20
  },
  marginMediumBottom: {
    marginBottom: 15
  },
  marginSmallBottom: {
    marginBottom: 10
  },
  formLineHeight: {
    lineHeight: '2'
  },
  welcomeText: {
    marginTop: 20
  },
  textCenter: {
    position: 'relative',
    textAlign: 'center'
  },
  fontColor: {
    color: '#144272'
  },
  underLineStyle: {
    textDecoration: 'underline'
  },
  borderBottomLine: {
    borderBottom: '5px solid #3C79F5',
    position: 'absolute',
    bottom: -55,
    height: 400,
    width: '100%',
    clear: 'both'
  },
  boldText: {
    fontSize: 11
  },
  whiteBox: {
    position: 'absolute',
    transform: 'rotate(45deg)',
    left: 14,
    top: -60,
    width: 60,
    height: 60,
    textAlign: 'cneter',
    backgroundColor: 'white'
  },
  websiteLink: {
    position: 'absolute',
    bottom: -45,
    left: '43%'
  }
});

const GenerateConsentLetterPDF = ({ shops = [] }) => {
  const todayDate = moment().format('Do MMMM YYYY');
  return (
    <Document>
      {shops.map((shop, index) => (
        <Fragment key={`shop-consent-letters-pages-${shop.id}`}>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <View style={styles.logoContainer}>
                <Image src={consentLetterHeaderImg} style={styles.logo} />
              </View>

              <View>
                <View style={styles.marginMediumBottom}>
                  <Text style={styles.boldText}>Director/Owner</Text>
                </View>
                <View style={styles.marginMediumBottom}>
                  <Text>{shop.name}</Text>
                  <Text>{shop.address}</Text>
                  <Text>
                    {shop.town},{shop.postcode}
                  </Text>
                  <Text>{shop.country}</Text>
                </View>
                <View style={styles.marginMediumBottom}>
                  <Text>{todayDate}</Text>
                </View>
              </View>

              <View>
                <View style={styles.marginSmallBottom}>
                  <Text>Dear Sir/Madam,</Text>
                </View>
                <View style={styles.marginSmallBottom}>
                  <Text>
                    Sub:{' '}
                    <Text style={styles.boldText}>
                      Promoting services on our platform
                    </Text>
                  </Text>
                </View>
                <View style={styles.marginSmallBottom}>
                  <Text>We trust that this letter finds you well.</Text>
                </View>
                <View style={styles.marginMediumBottom}>
                  <Text>
                    We have been in the dry-cleaning business for over 20 years
                    and in that period, we have come to understand the industry
                    well in order that we can serve our customers better.
                    Situated in the heart of East London we have been fortunate
                    to provide a service to a diverse range of customers and
                    recognised a big gap in the market which we plan to develop.
                    We are proposing to launch a new company/service called{' '}
                    <a
                      href="https://www.launder-it.co.uk/"
                      style={[styles.fontColor, styles.underLineStyle]}>
                      launder-it.co.uk
                    </a>
                    . which will allow us to set up a platform on which dry
                    cleaning businesses/laundry/tailoring/key cutting/shoe
                    repair like yourselves can advertise and promote your
                    company`s services. Our site will endorse and actively
                    promote your business to attract those customers looking for
                    a bespoke laundry service. Through a link on our site, they
                    can access your company`s website and be able to select and
                    pay for their desired laundry service. All you will need to
                    do is provide the requested service and get paid.
                  </Text>
                </View>
                <View style={styles.marginMediumBottom}>
                  <Text>
                    For a period of 6 months, launder-it.co.uk will offer this
                    promotion free of charge if you join by 31/03/2023, should
                    this be of interest to you. If you would like to take
                    advantage of this free promotion, we ask that you provide
                    the following information:
                  </Text>
                </View>
                <View>
                  <View style={styles.marginSmallBottom}>
                    <Text>
                      &bull; Company`s name and
                      address:______________________________________________________________
                    </Text>
                  </View>
                  <View style={styles.marginSmallBottom}>
                    <Text>
                      &bull; Mobile and landline telephone
                      numbers:_______________________________________________________
                    </Text>
                  </View>
                  <View style={styles.marginSmallBottom}>
                    <Text>
                      &bull; Authorising Manager`s name and contact
                      details:________________________________________________
                    </Text>
                  </View>
                  <View style={styles.marginSmallBottom}>
                    <Text>
                      &bull; Authorising Manager`s Signature with
                      Date:____________________________________________________
                    </Text>
                  </View>
                </View>
              </View>
              <Text
                style={[
                  styles.fontColor,
                  styles.underLineStyle,
                  styles.websiteLink
                ]}>
                <a href="https://www.launder-it.co.uk/">launder-it.co.uk</a>
              </Text>
              <View style={styles.borderBottomLine}></View>
              <View style={styles.pageNum}>
                <Text style={{ textAlign: 'center' }}>1</Text>
              </View>
            </View>
          </Page>

          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <View style={styles.logoContainer}>
                <Image src={consentLetterHeaderImg} style={styles.logo} />
              </View>
              <View style={styles.marginLargeBottom}>
                <Text>
                  We do hope that you will take advantage of this exclusive
                  offer and allow us to help you grow your business. We would
                  like your concern to use your photos and videos in our
                  website, advertisement and social media. Once we receive
                  confirmation that you would like to go ahead with the offer,
                  we will send you login detail to complete to indicate that you
                  are happy to proceed and agree to our terms and conditions.
                </Text>
                <Text>We look forward to hearing from you.</Text>
              </View>

              <View style={styles.marginLargeBottom}>
                <Text>Yours faithfully,</Text>
                <Text>Launder-it.co.uk</Text>
                <Text>
                  Ph:{' '}
                  <Text style={[styles.fontColor, styles.boldText]}>
                    {' '}
                    44(0) 7771038880
                  </Text>
                </Text>
              </View>

              <View>
                <View style={styles.marginSmallBottom}>
                  <Text>You can add if you have more than one Business.</Text>
                </View>
                <View>
                  <View style={styles.formLineHeight}>
                    <Text>
                      Business name:
                      -------------------------------------------------------------------------------------------------------------------------------
                      ----------------------------------------------------------------------------------------------------------------------------------------------------
                    </Text>
                    <Text>
                      Contact
                      detail:---------------------------------------------------------------------------------------------------------------------------------
                    </Text>
                    <Text>
                      Authorising Manager`s name and contact
                      details:-----------------------------------------------------------------------------------
                      -----------------------------------------------------------------------------------------------------------------------------------------------------
                    </Text>
                  </View>
                  <View style={styles.formLineHeight}>
                    <Text>
                      Business name:
                      -------------------------------------------------------------------------------------------------------------------------------
                      ----------------------------------------------------------------------------------------------------------------------------------------------------
                    </Text>
                    <Text>
                      Contact
                      detail:---------------------------------------------------------------------------------------------------------------------------------
                    </Text>
                    <Text>
                      Authorising Manager`s name and contact
                      details:-----------------------------------------------------------------------------------
                      -----------------------------------------------------------------------------------------------------------------------------------------------------
                    </Text>
                  </View>
                  <View>
                    <View style={styles.marginSmallBottom}>
                      <Text>
                        If you would like to refer any other business. Please
                        fill the details
                      </Text>
                    </View>
                    <View style={styles.formLineHeight}>
                      <Text>
                        Business name:
                        -------------------------------------------------------------------------------------------------------------------------------
                        ----------------------------------------------------------------------------------------------------------------------------------------------------
                      </Text>
                      <Text>
                        Contact
                        detail:---------------------------------------------------------------------------------------------------------------------------------
                      </Text>
                      <Text>
                        Authorising Manager`s name and contact
                        details:-----------------------------------------------------------------------------------
                        -----------------------------------------------------------------------------------------------------------------------------------------------------
                      </Text>
                    </View>
                  </View>
                </View>
              </View>

              <View style={styles.textCenter}>
                <Text style={[styles.welcomeText, styles.boldText]}>
                  ------------------------------ welcome aboard
                  -----------------------------
                </Text>
                <Text
                  style={[
                    styles.fontColor,
                    styles.underLineStyle,
                    styles.websiteLink
                  ]}>
                  <a href="https://www.launder-it.co.uk/">launder-it.co.uk</a>
                </Text>
              </View>
              <View style={styles.borderBottomLine}></View>
              <View style={styles.pageNum}>
                <Text>2</Text>
              </View>
            </View>
          </Page>
        </Fragment>
      ))}
    </Document>
  );
};

export default GenerateConsentLetterPDF;
