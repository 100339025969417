import React, { useState } from 'react';
import { DocumentDeleteDialog } from 'components/organisms';
import { Button, Grid, List, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCoupons } from 'context/coupons/couponsContext';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem 0 0'
  },
  deleteBtn: {
    height: 'fit-content',
    color: 'white',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light
    }
  },
  listRoot: {
    width: '100%'
  }
}));

const CouponTable = () => {
  const classes = useStyles();
  const { coupons = [], dispatch } = useCoupons();
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState('');

  const handleDelete = (id) => () => {
    setSelectedCoupon(id);
    setShowDeleteConfirmDialog(true);
  };

  const handleModalClose = () => setShowDeleteConfirmDialog(false);

  const handleDeleteConfirm = () => {
    dispatch({
      type: 'deleteCoupon',
      payload: { couponID: selectedCoupon }
    });
    handleModalClose();
  };

  return (
    <Grid container className={classes.root}>
      <List className={classes.listRoot}>
        {coupons.map((item, index) => (
          <ListItem key={`coupons-table-items-${index}`}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant="h6">{item.title || ''}</Typography>
                <Typography variant="subtitle2">
                  {item.description || ''}
                </Typography>
              </Grid>
              <Grid item xs={2} container justify="flex-end">
                <Button
                  size="small"
                  variant="contained"
                  className={classes.deleteBtn}
                  onClick={handleDelete(item.id)}>
                  Delete Coupon
                </Button>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
      <DocumentDeleteDialog
        open={showDeleteConfirmDialog}
        handleCancel={handleModalClose}
        handleConfirm={handleDeleteConfirm}
        confirmText="delete this coupon"
      />
    </Grid>
  );
};

export default CouponTable;
