import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LogoName from 'components/organisms/LogoName';
import { useHistory, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useAmplifyAuth } from 'context';

const useStyles = makeStyles(() => ({
  root: {
    padding: '2rem 0',
    margin: '0 auto',
    maxWidth: 'min(90%, 25rem)',
    minHeight: '28rem'
  },
  card: {
    border: '1px solid grey',
    borderRadius: '0.25rem',
    padding: '1rem 2rem'
  },
  form: {
    width: '100%'
  },
  formChild: {
    gap: '1rem'
  },
  heading: {
    gap: '0.25rem',
    marginBottom: '1rem'
  }
}));

const VerifyEmail = (props) => {
  const { onUserSignIn = () => {} } = props;
  const classes = useStyles();
  const [code, setCode] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  let { _updateUser: updateUser } = useAmplifyAuth();
  const { search } = useLocation();
  const redirectURL = search.split('redirect=').at(1) || '/home';

  useEffect(() => {
    Auth.verifyCurrentUserAttribute('email')
      .then(() => console.log('verification code sent to user email'))
      .catch((err) => {
        console.error('Something went wrong', err);
        enqueueSnackbar('Attempt limit exceeded, please try after some time.', {
          autoHideDuration: 2500,
          variant: 'error',
          preventDuplicate: true
        });
        history.replace('/home');
      });
  }, []);

  const handleChange = (e) => setCode(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (code.length < 4) {
        enqueueSnackbar('Code cannot be less than 4 characters', {
          autoHideDuration: 2000,
          variant: 'error',
          preventDuplicate: true
        });
        return;
      }
      await Auth.verifyCurrentUserAttributeSubmit('email', code);
      enqueueSnackbar('Email verifed successfully', {
        autoHideDuration: 2000,
        variant: 'success',
        preventDuplicate: true
      });
      onUserSignIn(true);
      history.replace(redirectURL);
      updateUser({ email_verified: true });
    } catch (error) {
      console.error('something went wrong', error);
      enqueueSnackbar('Wrong Code!!! Try again...', {
        autoHideDuration: 2000,
        variant: 'error',
        preventDuplicate: true
      });
    }
  };

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.root}>
      <Grid container justify="center">
        <LogoName />
      </Grid>
      <Grid container justify="center" className={classes.card}>
        <form autoComplete="off" className={classes.form}>
          <Grid container justify="center" className={classes.formChild}>
            <Grid container justify="center" className={classes.heading}>
              <Typography variant="h2">Verify Your Email</Typography>
              <Typography variant="body1">
                Please check your email for the Verification Code
              </Typography>
            </Grid>
            <TextField
              variant="outlined"
              label="Enter the code here"
              onChange={handleChange}
              inputProps={{ minLength: 4, maxLength: 8 }}
              fullWidth
              required
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              onClick={handleSubmit}>
              SUBMIT
            </Button>
            <Typography variant="body1">
              Note: If you don't verify your email you won't be able to recover
              your account if you ever forget your password.
            </Typography>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default VerifyEmail;
