import React, { useContext } from 'react';
import { CartContext } from 'context/cart/cartContext';
import { useAmplifyAuth } from 'context';
import OrderUpDownButtons from './OrderUpDownButtons';
import {
  Grid,
  Card,
  Typography,
  IconButton,
  CardContent,
  Button,
  Link
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import {
  showPrice,
  unitFormatter,
  getOrderTotal,
  checkCouponCondition,
  calculatePriceWithDiscount
} from 'common/utilFunctions';
import { useLoader } from 'layouts/loaderContext';
import { API, graphqlOperation } from 'aws-amplify';
import { createAnOrder, updateUserDetail } from 'graphql/mutations';
import { validateCartCoupons } from 'common/validators';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CartCoupons from './CartCoupons';
import { omit } from 'lodash';

const useStyles = makeStyles((theme) => ({
  orderSubContainer: {
    height: 'fit-content',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '25px'
    }
  },
  orderSumCard: {
    borderRadius: '8px',
    width: '100%',
    border: `1px solid ${theme.palette.grey.main}`,
    boxShadow: 'none'
  },
  orderSumCardContent: {
    padding: '20px'
  },
  totalPrice: {
    marginRight: '30px'
  },
  checkoutBtn: {
    backgroundColor: theme.palette.blue.main,
    color: 'white',
    textTransform: 'uppercase'
  },
  closeBtn: {
    color: theme.palette.grey.main
  },
  totalText: {
    fontWeight: 850,
    color: '#444444'
  },
  greyText: {
    color: '#545454'
  },
  discountedPrice: {
    color: '#F82A2A'
  },
  originalCrossedPrice: {
    textDecoration: 'line-through',
    fontSize: '0.5rem'
  }
}));

const CartCard = ({
  btnText = 'checkout',
  isDefaultAddressChange,
  shop = { name: '', shopID: '' },
  order: {
    typeOfOrder = '',
    collectionMethod = '',
    deliveryMethod = '',
    collectionAddress = '',
    deliveryAddress = '',
    setStatus = () => {},
    collectionTime = '',
    collectionSlot = '',
    deliverySlot = '',
    deliveryTime = '',
    standardDeliveryFee = '',
    minPriceForFree = '',
    asapDeliveryFee = '',
    paymentOptions = ''
  } = {
    typeOfOrder: '',
    collectionMethod: '',
    deliveryMethod: '',
    collectionAddress: '',
    deliveryAddress: '',
    setStatus: () => {},
    collectionTime: '',
    collectionSlot: '',
    deliverySlot: '',
    deliveryTime: '',
    standardDeliveryFee: '',
    minPriceForFree: '',
    asapDeliveryFee: '',
    paymentOptions: ''
  }
}) => {
  const { cart, setCart } = useContext(CartContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setLoading } = useLoader();
  const history = useHistory();
  const classes = useStyles();
  const subTotal = getOrderTotal({ orderList: cart });
  let total = subTotal;
  let collectionAndDeliveryFee = 0;
  const {
    state: { user }
  } = useAmplifyAuth();

  if (typeOfOrder === 'ASAP') {
    total = total + asapDeliveryFee;
    collectionAndDeliveryFee = collectionAndDeliveryFee + asapDeliveryFee;
  }
  if (
    collectionMethod === 'Collection' &&
    (subTotal < minPriceForFree || !minPriceForFree)
  ) {
    total = total + standardDeliveryFee;
    collectionAndDeliveryFee = collectionAndDeliveryFee + standardDeliveryFee;
  }
  if (
    deliveryMethod === 'Delivery' &&
    (subTotal < minPriceForFree || !minPriceForFree)
  ) {
    total = total + standardDeliveryFee;
    collectionAndDeliveryFee = collectionAndDeliveryFee + standardDeliveryFee;
  }

  const handleAddMoreItems = () => {
    const previousPath = history.location.pathname.split('/');
    const lastEl = previousPath.pop();
    lastEl === 'checkout'
      ? history.replace(previousPath.join('/'))
      : history.replace('/home');
  };

  const removeItemFromCart = (item) => {
    const newCart = validateCartCoupons(
      cart.filter((cartItem) => item.id !== cartItem.id)
    );
    localStorage.setItem(
      'cart',
      JSON.stringify({ username: user.username, item: newCart })
    );
    setCart(newCart);
  };

  const defaultAddressHandler = async (id) => {
    const snackBar = enqueueSnackbar('Updating default address', {
      variant: 'info',
      persist: true,
      preventDuplicate: true
    });
    try {
      const resp = await API.graphql(
        graphqlOperation(updateUserDetail, { defaultAddress: id })
      );
      console.log('address update response', resp);
    } catch (error) {
      console.error('Something went wrong', error);
    } finally {
      closeSnackbar(snackBar);
    }
  };

  const handleApplyCouponClick = (coupon) => () => {
    const couponProduct = cart.find((item) => item.id === coupon.productID);
    const conditionMet = checkCouponCondition(
      coupon.conditionUnit,
      coupon.conditionAmount,
      cart,
      coupon.productID
    );

    if (!conditionMet) {
      enqueueSnackbar('Not Applicable', {
        variant: 'error',
        autoHideDuration: 2000
      });
      return;
    }

    couponProduct.discountCouponID = coupon.id;
    couponProduct.discountCoupon = coupon;
    if (
      coupon.discountUnit === 'amount' ||
      coupon.discountUnit === 'percentage'
    ) {
      const discountedPrice = calculatePriceWithDiscount(
        couponProduct.price,
        coupon.discountAmount,
        coupon.discountUnit
      );
      couponProduct.discount = couponProduct.price - discountedPrice;
      couponProduct.price = discountedPrice;
    } else if (coupon.discountUnit === 'noOfItems') {
      couponProduct.overallDiscount = couponProduct.price;
    }

    const newCart = cart.map((item) =>
      item.id === couponProduct.id ? couponProduct : item
    );
    localStorage.setItem(
      'cart',
      JSON.stringify({ username: user.username, item: newCart })
    );
    setCart(newCart);
  };

  const onOrderPlace = async () => {
    if (!user?.id) {
      setStatus('FAILURE');
      return;
    }
    if (cart.length > 0) {
      setLoading(true);
      let shopID;
      const orderList = cart.map((product) => {
        const { item, shopID: _shopID } = product;
        if (_shopID) {
          shopID = _shopID;
        }
        const _product = {
          category: product.category || item?.category,
          name: product.name || item?.name,
          description: product.description || item?.description,
          discount: product.discount || 0,
          discountCoupon: product.discountCoupon
            ? JSON.stringify({
                ...omit(product.discountCoupon, [
                  'updatedAt',
                  '_deleted',
                  '_lastChangedAt',
                  '_version'
                ])
              })
            : '',
          discountCouponID: product.discountCouponID || '',
          discountDescription:
            product.discountDescription || item?.discountDescription,
          overallDiscount: product.overallDiscount || 0,
          unit: product.unit || item?.unit,
          price: product.price || item?.price || 1,
          productID: product.id,
          quantity: product.quantity
        };
        return _product;
      });

      let collection = {
        type: typeOfOrder === 'ASAP' ? 'asap' : 'time'
      };
      let delivery = {
        type: typeOfOrder === 'ASAP' ? 'asap' : 'time'
      };

      if (collectionMethod !== 'Drop') {
        const _collAddress = omit(collectionAddress, [
          '_deleted',
          '_lastChangedAt',
          '_version',
          'user',
          'userID',
          'updatedAt',
          'createdAt'
        ]);
        const filteredCollAddress = Object.fromEntries(
          Object.entries(_collAddress).filter(([, v]) => !!v)
        );
        collection.address = filteredCollAddress;

        if (typeOfOrder === 'Schedule') {
          collection.date = collectionTime.format('YYYY-MM-DDThh:mm:ss.sssZ');
          collection.time = collectionSlot;
        }
      }

      if (deliveryMethod !== 'Pickup') {
        const _delAddress = omit(deliveryAddress, [
          '_deleted',
          '_lastChangedAt',
          '_version',
          'user',
          'userID',
          'updatedAt',
          'createdAt'
        ]);
        const filteredDelAddress = Object.fromEntries(
          Object.entries(_delAddress).filter(([, v]) => !!v)
        );
        delivery.address = filteredDelAddress;

        if (typeOfOrder === 'Schedule') {
          delivery.date = deliveryTime.format('YYYY-MM-DDThh:mm:ss.sssZ');
          delivery.time = deliverySlot;
        }
      }

      if (isDefaultAddressChange.isChange) {
        if (isDefaultAddressChange.option === 'collection') {
          defaultAddressHandler(collectionAddress.id);
        } else if (isDefaultAddressChange.option === 'delivery') {
          defaultAddressHandler(deliveryAddress.id);
        }
      }

      const payload = {
        shopID,
        orderList: JSON.stringify(orderList),
        collection: JSON.stringify(collection),
        delivery: JSON.stringify(delivery),
        total,
        collectionAndDeliveryFee,
        currentShopFees: JSON.stringify({
          standardDeliveryFee,
          AsapDeliveryFee: asapDeliveryFee,
          minPriceForFree
        })
      };
      API.graphql(graphqlOperation(createAnOrder, payload))
        .then((resp) => {
          localStorage.removeItem('cart');
          setStatus('SUCCESS');
          setCart([]);
          setLoading(false);
        })
        .catch(() => {
          setStatus('FAILURE');
          setLoading(false);
        });
    }
  };

  return (
    <Grid container justify="flex-end" className={classes.orderSubContainer}>
      <Card className={classes.orderSumCard}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ padding: '13px', backgroundColor: '#454545' }}>
          <Typography
            variant="h5"
            align="center"
            style={{ color: 'white', fontSize: '18px', fontWeight: '700' }}>
            Order Summary
          </Typography>
        </Grid>
        <CardContent className={classes.orderSumCardContent}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{
              paddingBottom: '15px',
              borderBottom: '1px solid #D2D8DD'
            }}>
            {cart.length > 0 ? (
              cart?.map((item, index) => (
                <Grid
                  key={`cart-items-ready-for-checkout-${index}`}
                  container
                  justify="space-between"
                  alignItems="center"
                  style={{ marginBottom: '10px' }}>
                  <Grid
                    item
                    xs={4}
                    md={3}
                    lg={6}
                    style={{ paddingRight: '5px' }}>
                    <Typography variant="h6">
                      {item.name || item.item?.name}
                    </Typography>
                    <Typography variant="body1" className={classes.greyText}>
                      <span
                        className={
                          item.discount ? classes.originalCrossedPrice : ''
                        }>
                        {`${showPrice(
                          item.discount
                            ? item.price + item.discount
                            : item.price
                        )}`}
                      </span>
                      <span className={classes.discountedPrice}>
                        {item.discount ? ` ${showPrice(item.price)}` : ''}
                      </span>
                      {` ${unitFormatter(item.unit, '/').toLowerCase()}`}
                      <span className={classes.discountedPrice}>
                        {item.overallDiscount
                          ? ` (-${showPrice(item.overallDiscount)})`
                          : ''}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={8}
                    sm={4}
                    md={9}
                    lg={6}
                    alignItems="center"
                    justify="space-between">
                    <Grid container item xs={6} justify="center">
                      <OrderUpDownButtons item={item} />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      container
                      justify="flex-end"
                      style={{ gap: '0.625rem' }}>
                      <Typography variant="h6">
                        {showPrice(
                          item.price * item.quantity -
                            (item.overallDiscount || 0)
                        )}
                      </Typography>
                      <IconButton
                        onClick={() => removeItemFromCart(item)}
                        style={{ padding: '0' }}>
                        <CloseIcon
                          fontSize="small"
                          className={classes.closeBtn}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Grid
                container
                item
                xs={12}
                justify="center"
                style={{ marginBottom: '10px' }}>
                <Typography variant="h4">Cart is Empty</Typography>
              </Grid>
            )}

            {subTotal > 0 && (
              <Grid
                item
                xs={12}
                container
                justify="space-between"
                style={{ marginTop: '1.5rem' }}>
                <Typography variant="h6">Sub Total</Typography>
                <Typography variant="h6" className={classes.totalPrice}>
                  {showPrice(subTotal)}
                </Typography>
              </Grid>
            )}

            {/* coupons */}
            <CartCoupons
              handleApplyCouponClick={handleApplyCouponClick}
              showAppliedOnly={btnText !== 'checkout'}
            />

            {typeOfOrder === 'ASAP' && (
              <Grid
                item
                xs={12}
                container
                justify="space-between"
                style={{ margin: '8px 0px 4px 0px' }}>
                <Typography variant="h6">ASAP fee</Typography>
                <Typography variant="h6" className={classes.totalPrice}>
                  {showPrice(asapDeliveryFee)}
                </Typography>
              </Grid>
            )}

            {(subTotal < minPriceForFree || !minPriceForFree) &&
              (deliveryMethod === 'Delivery' ||
                collectionMethod === 'Collection') && (
                <Grid
                  item
                  xs={12}
                  container
                  justify="space-between"
                  style={{ margin: '0.25rem 0 0.75rem' }}>
                  <Typography variant="h6">
                    {`${collectionMethod === 'Collection' ? 'Collection' : ''}${
                      deliveryMethod === 'Delivery' &&
                      collectionMethod === 'Collection'
                        ? ' & '
                        : ''
                    }${deliveryMethod === 'Delivery' ? 'Delivery' : ''} fee`}
                  </Typography>
                  <Typography variant="h6" className={classes.totalPrice}>
                    {showPrice(
                      standardDeliveryFee *
                        (deliveryMethod === 'Delivery' &&
                        collectionMethod === 'Collection'
                          ? 2
                          : 1)
                    )}
                  </Typography>
                </Grid>
              )}

            <Grid item xs={12} container justify="space-between">
              <Typography
                variant="h5"
                className={classes.totalText}
                style={{ marginTop: '20px' }}>
                Total
              </Typography>
              <Typography
                variant="h5"
                className={classes.totalText}
                style={{ marginTop: '20px', marginRight: '30px' }}>
                {showPrice(total)}
              </Typography>
            </Grid>
          </Grid>

          <Grid container style={{ paddingTop: '20px' }}>
            {btnText === 'checkout' ? (
              cart.length === 0 ? (
                <Button
                  fullWidth
                  disabled
                  size="large"
                  variant="contained"
                  className={classes.checkoutBtn}>
                  {btnText}
                </Button>
              ) : (
                <RouterLink
                  to={`/shop/${shop.shopID}/${shop.name
                    .toLowerCase()
                    .split(' ')
                    .join('-')}/checkout`}
                  style={{ width: '100%' }}>
                  <Button
                    fullWidth
                    size="large"
                    disabled={cart.length === 0}
                    variant="contained"
                    className={classes.checkoutBtn}>
                    {btnText}
                  </Button>
                </RouterLink>
              )
            ) : btnText === 'place order' ? (
              <Grid
                container
                alignItems="center"
                direction="column"
                style={{ gap: '0.2rem' }}>
                <Button
                  fullWidth
                  disabled={
                    (collectionMethod === 'Collection'
                      ? !collectionAddress ||
                        (!(typeOfOrder === 'ASAP') &&
                          (!collectionTime || !collectionSlot))
                      : !collectionMethod) ||
                    (deliveryMethod === 'Delivery'
                      ? !deliveryAddress ||
                        (!(typeOfOrder === 'ASAP') &&
                          (!deliverySlot || !deliveryTime))
                      : !deliveryMethod) ||
                    !paymentOptions ||
                    cart.length === 0
                  }
                  onClick={onOrderPlace}
                  variant="contained"
                  className={classes.checkoutBtn}>
                  {btnText}
                </Button>
                <Link
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleAddMoreItems()}>
                  Add more items
                </Link>
              </Grid>
            ) : null}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CartCard;
