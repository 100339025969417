import React from 'react';
import IntroOne from './components/IntroOne';
import Search from './components/Search';
import Banner from './components/Banner';
import SubFooter from './components/SubFooter';
import Cookies from './components/Cookies';
import { CategoryList } from 'views/ShopList/components';
import './styles.css';

export default function App() {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <Search />
      <CategoryList />
      <IntroOne />
      <Banner />
      <SubFooter />
      <Cookies />
    </div>
  );
}
