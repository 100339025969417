import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Typography } from '@material-ui/core';
import { useAmplifyAuth } from 'context';
import { getStorageUrl } from 'common/utilFunctions';
import { routes } from 'common/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = (props) => {
  const [picture, setPicture] = useState('');
  const { className, ...rest } = props;
  const {
    state: { user, isAdmin = false, isLaundryAdmin = false, isSales = false }
  } = useAmplifyAuth();
  const classes = useStyles();

  useEffect(() => {
    let isMounted = true;
    user &&
      getStorageUrl(user.picture).then((picture) =>
        isMounted ? setPicture(picture) : null
      );
    return () => (isMounted = false);
  }, [user]);

  return (
    user && (
      <div {...rest} className={clsx(classes.root, className)}>
        <Avatar
          alt={user.firstName}
          className={classes.avatar}
          component={RouterLink}
          src={picture}
          to={
            isAdmin
              ? routes.admin.settings
              : isSales
              ? routes.sales.settings
              : isLaundryAdmin
              ? routes.serviceProvider.settings
              : routes.customer.home
          }
        />
        <Typography className={classes.name} variant="h4">
          {user.firstName} {user.lastName}
        </Typography>
        <Typography variant="body2"> ({user.username})</Typography>
      </div>
    )
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
