import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import ShopDocumentsIdAddress from './ShopDocumentsIdAddress';
import { useAmplifyAuth } from 'context';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { updateShop } from 'graphql/mutations';
import { useShops } from 'context/shop/shopsContext';
import { useLoader } from 'layouts/loaderContext';
import { getFileUploadKey } from 'common/utilFunctions';

Storage.configure({ level: 'protected' });

const useStyles = makeStyles(() => ({
  root: {
    padding: '1rem 0'
  }
}));

function UploadedShopPhotos(props) {
  const { selectedShop, setSelectedShop } = props;
  const [documents, setDocuments] = useState([]);
  const { dispatch } = useShops();
  const { setLoading } = useLoader();
  const classes = useStyles();
  let {
    state: { isAdmin }
  } = useAmplifyAuth();

  useEffect(() => setDocuments(selectedShop?.documents || []), [selectedShop]);

  const handleDelete = async (url = '') => {
    Storage.remove(
      selectedShop.documents?.find((doc) => doc?.documentURL === url)
        ?.documentURL
    );
    const input = {
      id: selectedShop.id,
      _version: selectedShop._version,
      documents:
        selectedShop.documents?.filter((item) => item?.documentURL !== url) ||
        []
    };
    setLoading(true);
    try {
      const result = await API.graphql(graphqlOperation(updateShop, { input }));
      dispatch({
        type: 'updateShop',
        payload: result.data.updateShop
      });
      setSelectedShop(result.data.updateShop);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (url = '', status) => {
    const input = {
      id: selectedShop.id,
      _version: selectedShop._version,
      documents:
        selectedShop.documents
          ?.filter((item) => item)
          .map((item) => ({
            ...item,
            status: item.documentURL === url ? status : item.status
          })) || []
    };
    setLoading(true);
    try {
      const result = await API.graphql(graphqlOperation(updateShop, { input }));
      dispatch({
        type: 'updateShop',
        payload: result.data.updateShop
      });
      setSelectedShop(result.data.updateShop);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const handleReUpload = async (url = '', file) => {
    setLoading(true);
    const oldDoc = documents.find((item) => item.documentURL === url);
    Storage.remove(oldDoc?.documentURL);
    const { type: mimeType } = file;
    const key = getFileUploadKey(file, 'documents');
    try {
      const resp = await Storage.put(key, file, {
        contentType: mimeType
      });
      const input = {
        id: selectedShop.id,
        _version: selectedShop._version,
        documents: [
          ...selectedShop.documents?.filter((item) => item.documentURL !== url),
          {
            ...oldDoc,
            documentURL: key,
            MIMETypes: mimeType
          }
        ]
      };
      const result = await API.graphql(graphqlOperation(updateShop, { input }));
      dispatch({
        type: 'updateShop',
        payload: result.data.updateShop
      });
      setSelectedShop(result.data.updateShop);
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box className={classes.root}>
      <ShopDocumentsIdAddress
        data={documents.filter((el) => el && el.documentType === 'Id Proof')}
        heading="Identity Proof Documents"
        handleUpdate={handleUpdate}
        handleReUpload={handleReUpload}
        handleDelete={handleDelete}
      />
      <ShopDocumentsIdAddress
        data={documents.filter(
          (el) => el && el.documentType === 'Address Proof'
        )}
        heading="Address Proof Documents"
        handleUpdate={handleUpdate}
        handleReUpload={handleReUpload}
        handleDelete={handleDelete}
      />
    </Box>
  );
}

export default UploadedShopPhotos;
