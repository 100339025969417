import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import ReviewComp from './components/ReviewComp';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: '0 30px',
    borderTop: '1px solid #0000001A',
    [theme.breakpoints.up('md')]: {
      padding: '0 120px'
    }
  }
}));

const Review = () => {
  const classes = useStyles();
  const { shopName } = useParams();

  return (
    <Grid container justify="center" className={classes.root}>
      <ReviewComp shopName={shopName} />
    </Grid>
  );
};

export default Review;
