import React, { useState } from 'react';
import { capitalizeStr } from 'common/utilFunctions';
import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  Tooltip,
  useMediaQuery,
  CardContent,
  Button,
  Box
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ShopCardContent from './ShopCardContent';
import { ShopWrapper, ShopListWrapper } from '../HOCs';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    height: 'fit-content',
    boxShadow: 'none',
    border: 'none',
    position: 'relative'
  },
  viewBtn: {
    fontSize: '12px',
    color: '#030045',
    fontWeight: '700'
  },
  scrollBtn: {
    maxHeight: '26px',
    maxWidth: '26px',
    boxShadow: '0px 8px 18px rgb(45 69 157 / 16%)',
    margin: '0 5px',
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.white,
      color: theme.palette.primary.main
    }
  },
  listContainer: {
    margin: '1.25rem 0 2.5rem'
  },
  cardContent: {
    padding: 0
  },
  list: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    gap: '1.25rem'
  },
  shopWrapper: {
    [theme.breakpoints.up('lg')]: {
      padding: '0.625rem 0'
    }
  },
  shopGrayWrapper: {
    filter: 'grayscale(100%)'
  },
  onHoldWrapper: {
    left: '0px',
    position: 'absolute',
    top: '0px',
    height: '100px',
    width: '100px',
    overflow: 'hidden',
    zIndex: 1
  },
  onHoldRibbon: {
    left: '-64px',
    position: 'absolute',
    top: '32px',
    height: '16px',
    width: '200px',
    transform: 'rotate(-45deg)',
    textAlign: 'center',
    backgroundColor: theme.palette.warning.light,
    color: '#000',
    fontSize: '11px'
  }
}));

const shouldEnableShop = (item) => item.status === 'onhold' || item.isLive;

function getSortedShops(shops) {
  const shopsSortedByRange = shops.sort((a, b) => a.range - b.range); //sorting the shops by range

  const onlineShops = shopsSortedByRange.filter(
    (item) => item.status === 'active' && item.isLive
  );
  const offlineShops = shopsSortedByRange.filter(
    (item) => item.status === 'onhold'
  );
  const closeShops = shopsSortedByRange.filter(
    (item) => item.status === 'active' && !item.isLive
  );

  return [...onlineShops, ...offlineShops, ...closeShops];
}

const ShopsCarousel = ({
  service = { name: '', id: '' },
  className,
  searchName
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [showAll, setShowAll] = useState(false);
  const theme = useTheme();
  const [lgUp, lgDown] = [
    useMediaQuery(theme.breakpoints.up('lg')),
    useMediaQuery(theme.breakpoints.down('lg'))
  ];
  const lg = lgUp && lgDown;
  const xl = useMediaQuery(theme.breakpoints.up('xl'));
  const filteredShops = service.shops?.filter((shop) =>
    shop.name.toLowerCase().includes(searchName.toLowerCase())
  );
  const showShowMoreBtn =
    (xl && service.shops?.length > 6) || (lg && service.shops?.length > 4);
  const slicingEndIndex = !showAll ? (xl ? 6 : lg ? 4 : undefined) : undefined;

  const handleShopClick = (shop) => () => {
    // setSelectedShop(shop);
    history.push(
      `/shop/${shop.shopID}/${shop.name.toLowerCase().split(' ').join('-')}`
    );
  };

  const handleSwitchShowAll = () => setShowAll(!showAll);

  return (
    <Grid container className={clsx(className, classes.root)}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item lg={8} xs={12}>
          <Typography variant="h3" color="secondary">
            {capitalizeStr(service.name)}
          </Typography>
        </Grid>
        {showShowMoreBtn ? (
          <Grid
            item
            container
            xs={12}
            lg={4}
            justify="flex-end"
            alignItems="center">
            <Button
              className={classes.viewBtn}
              onClick={handleSwitchShowAll}
              endIcon={showAll ? <ExpandLess /> : <ExpandMore />}>
              Show {showAll ? 'Less' : 'More'}
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Grid container alignItems="center" className={classes.listContainer}>
        {filteredShops.length > 0 ? (
          <ShopListWrapper
            lg={lgUp}
            className={clsx(classes.list, 'hide-scrollbar')}>
            {getSortedShops(filteredShops)
              .slice(0, slicingEndIndex)
              .map((item, index) => {
                const isClosedToday = !item?.workHrs?.some(
                  (item) => item.openDay === new Date().getDay()
                );
                const showInGrayscale =
                  isClosedToday || (item.status === 'active' && !item.isLive);
                return (
                  <ShopWrapper
                    key={`popular-shop-search-page-items-${index}`}
                    className={classes.shopWrapper}
                    lg={lgUp}>
                    <Grid container justify="center">
                      <Card className={classes.card}>
                        {item.status === 'onhold' || isClosedToday ? (
                          <Box className={classes.onHoldWrapper}>
                            <Box className={classes.onHoldRibbon}>
                              {item.status === 'onhold'
                                ? 'OFFLINE ORDER'
                                : isClosedToday
                                ? 'Closed for Today'
                                : ''}
                            </Box>
                          </Box>
                        ) : null}
                        <CardActionArea
                          className={
                            showInGrayscale ? classes.shopGrayWrapper : ''
                          }
                          disabled={!shouldEnableShop(item)}
                          onClick={handleShopClick(item)}>
                          {item.status === 'onhold' || isClosedToday ? (
                            <Tooltip
                              title={
                                item.status === 'onhold'
                                  ? 'currently this shop is not taking online orders'
                                  : 'this shop is closed for today'
                              }
                              placement="top"
                              arrow>
                              <CardContent className={classes.cardContent}>
                                <ShopCardContent
                                  item={item}
                                  serviceID={service.id}
                                />
                              </CardContent>
                            </Tooltip>
                          ) : (
                            <CardContent className={classes.cardContent}>
                              <ShopCardContent
                                item={item}
                                serviceID={service.id}
                              />
                            </CardContent>
                          )}
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </ShopWrapper>
                );
              })}
          </ShopListWrapper>
        ) : (
          <Typography align="center" variant="h4">
            Sorry, No Record Found
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ShopsCarousel;
