import React, { useEffect, useRef, useState } from 'react';
import {
  TextField,
  Avatar,
  Grid,
  Typography,
  IconButton,
  Modal,
  Fab,
  Backdrop,
  Fade,
  Box,
  Container,
  Link,
  Paper,
  Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AttachmentOutlinedIcon from '@material-ui/icons/AttachmentOutlined';
import SendIcon from '@material-ui/icons/Send';
import { getMessageByOrderID } from 'graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import { useAmplifyAuth } from 'context';
import { createMessage } from 'graphql/mutations';
import { onCreateMessage } from 'graphql/subscriptions';

const useStyles = makeStyles((theme) => ({
  msgReceived: {
    marginRight: 'auto',
    padding: '0.5rem 1rem',
    marginBottom: '5px',
    backgroundColor: '#eee',
    borderRadius: '15px 15px 15px 0px',
    position: 'relative'
  },
  msgSent: {
    marginLeft: 'auto',
    padding: '0.5rem 1rem',
    marginBottom: '5px',
    backgroundColor: '#EAFCFE',
    borderRadius: '15px 15px 0 15px',
    position: 'relative'
  }
}));

const MessageDialog = ({ open, setOpen, order = {} }) => {
  const {
    state: { user }
  } = useAmplifyAuth();
  const classes = useStyles();
  const [newMessage, setNewMessage] = useState('');
  const [userMessage, setUserMessage] = useState([]);
  const orderValue = useRef(null);

  const handleClose = () => {
    setNewMessage('');
    setOpen(false);
  };

  const sendHandler = async () => {
    setNewMessage('');
    const data = await API.graphql(
      graphqlOperation(createMessage, {
        input: {
          sender: user.id || user.sub,
          orderID: order.id,
          message: newMessage,
          status: 'sent'
        }
      })
    );
  };
  // useEffect(() => {
  //   if (order.id) {
  //     orderValue.current = order.id
  //     API.graphql(
  //       graphqlOperation(getMessageByOrderID, {
  //         orderID: order.id
  //       })
  //     ).then(({ data }) => {
  //       const msgs = data?.getMessageByOrderID?.items.map(msg => ({
  //         ...msg,
  //         text: msg.message
  //       })).sort((a,b) => new Date(a.createdAt) - new Date(b.createdAt));
  //       setUserMessage(msgs);
  //     });
  //   }
  // }, [order]);
  // useEffect(() => {
  //   // Subscribe to creation of message
  //   API.graphql(graphqlOperation(onCreateMessage)).subscribe({
  //     next: ({ provider, value }) => {
  //       const newMsg = value?.data?.onCreateMessage;
  //       if (newMsg?.orderID === orderValue.current) {
  //         setUserMessage(userMessage => [...userMessage, { ...newMsg,text:newMsg.message }]);
  //       }
  //     },
  //     error: error => console.warn(error)
  //   });
  // }, []);

  return (
    <Modal
      aria-describedby="message-modal-description"
      aria-labelledby="message-modal-title"
      disableEscapeKeyDown
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      closeAfterTransition
      onClose={handleClose}
      open={open}>
      <Container
        maxWidth="md"
        style={{
          paddingTop: '5rem',
          paddingBottom: '5rem',
          paddingLeft: '10rem',
          paddingRight: '10rem'
        }}>
        <Fade in={open}>
          <Grid container spacing={3}>
            {/* close button */}
            <Grid container item justify="flex-end" alignItems="center">
              <Fab
                style={{
                  marginRight: '-4rem',
                  marginBottom: '-1.5rem',
                  height: '2.8rem',
                  width: '2.8rem'
                }}
                color="primary"
                onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </Fab>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{
                backgroundColor: 'white'
              }}>
              {/* header */}
              <Grid
                container
                justify="space-between"
                alignItems="center"
                component={Box}
                style={{
                  backgroundColor: '#4094EA',
                  paddingTop: '1.5rem',
                  paddingBottom: '1.5rem',
                  paddingLeft: '2rem',
                  paddingRight: '2rem'
                }}>
                <Grid
                  container
                  item
                  xs={12}
                  md={8}
                  justify="flex-start"
                  spacing={4}
                  alignItems="center"
                  style={{ marginLeft: '0.5rem' }}>
                  <Grid container item xs={2} justify="center">
                    <Avatar />
                  </Grid>
                  <Grid
                    container
                    item
                    justify="space-around"
                    direction="column"
                    alignItems="flex-start"
                    xs={6}>
                    <Typography variant="body1" style={{ color: 'white' }}>
                      {order.userDetail?.firstName} {order.userDetail?.lastName}
                    </Typography>
                    <Link style={{ fontSize: '12px', color: 'white' }}>
                      {order.orderID || ''}
                    </Link>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={4}
                  justify="space-between"
                  direction="column"
                  spacing={3}>
                  <Typography style={{ color: 'white' }}>
                    {order.updatedBy}
                  </Typography>
                  <Typography style={{ color: 'white' }}>
                    Phone : {order.userDetail?.phoneNumber}
                  </Typography>
                </Grid>
              </Grid>

              {/* content */}
              <Grid
                container
                component={Paper}
                direction="column"
                justify="space-between"
                style={{
                  minHeight: '50vh'
                }}>
                <Grid
                  container
                  item
                  justify="space-between"
                  spacing={2}
                  alignItems="flex-end"
                  style={{
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    paddingLeft: '2.5rem',
                    paddingRight: '2.5rem'
                  }}>
                  {userMessage.map((msg, i) => (
                    <Grid
                      container
                      item
                      xs={8}
                      direction="column"
                      className={
                        (user.id || user.sub) === msg.sender
                          ? classes.msgSent
                          : classes.msgReceived
                      }>
                      <Grid
                        container
                        item
                        component={Box}
                        direction="column"
                        justify="space-between"
                        alignItems="center">
                        <Grid container item justify="flex-start" xs={12}>
                          <Typography variant="subtitle1">
                            {msg.text}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          item
                          justify="flex-end"
                          style={{
                            position: 'absolute',
                            bottom: 5,
                            right: 5,
                            zIndex: 999
                          }}
                          xs={12}>
                          <Typography variant="body2">1:33pm</Typography>
                        </Grid>
                      </Grid>
                      {/* <Typography variant="body2">
                      message read at 2:30pm
                    </Typography>*/}
                    </Grid>
                  ))}
                </Grid>
                <Grid container justify="space-between"></Grid>
              </Grid>

              {/* actions */}
              <Grid
                container
                justify="space-between"
                alignItems="center"
                style={{
                  paddingLeft: '1.5rem',
                  paddingRight: '1rem',
                  paddingTop: '0.5rem',
                  paddingBottom: '0.5rem'
                }}>
                <Grid item xs={11}>
                  <TextField
                    id="message-text"
                    fullWidth
                    placeholder="Enter Message"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                  />
                </Grid>
                <Grid container item xs={1} justify="space-evenly">
                  <Button onClick={sendHandler}>
                    <SendIcon
                      style={{
                        color: '#fff',
                        backgroundColor: '#194376',
                        height: '2.5rem',
                        width: '2.5rem',
                        borderRadius: '50%',
                        padding: '6px'
                      }}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </Container>
    </Modal>
  );
};

export default MessageDialog;
