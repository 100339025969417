import React from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const CustomSearchBar = (props) => {
  const {
    className = '',
    value,
    onChange = () => {},
    onClear = () => {},
    onSearch,
    placeholder,
    size = 'small',
    ...rest
  } = props;

  return (
    <TextField
      {...rest}
      fullWidth
      id="custom-search-bar"
      value={value}
      size={size}
      onChange={onChange}
      onKeyDown={onChange}
      label="Search"
      placeholder={placeholder}
      className={className}
      variant="outlined"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value ? (
              <IconButton
                size="small"
                onClick={onClear}
                aria-label="custom-search-bar-icon-button"
                edge="end">
                <CloseIcon fontSize="small" />
              </IconButton>
            ) : (
              <SearchIcon fontSize="small" />
            )}
            {!!value && onSearch ? (
              <IconButton
                size="small"
                disabled={!value}
                onClick={onSearch}
                aria-label="custom-search-bar-search-icon-button"
                edge="end">
                <SearchIcon fontSize="small" />
              </IconButton>
            ) : null}
          </InputAdornment>
        )
      }}
    />
  );
};

export default CustomSearchBar;
