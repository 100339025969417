import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { validatePhoneNumber } from '../../../../common/validators';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  Typography
} from '@material-ui/core';
import { useLoader } from '../../../../layouts/loaderContext';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(theme => ({
  root: { minWidth: '100%' },
  saveButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.text.primary
  },
  cancelButton: {
    color: theme.palette.text.secondary
  }
}));

const Password = props => {
  const getInitialState = () => ({
    password: '',
    oldPassword: '',
    confirmPassword: '',
    email: '',
    phone: ''
  });
  const { className, ...rest } = props;
  const classes = useStyles();
  const [password, setPassword] = useState(getInitialState());
  const [showPassword, setShowPassword] = useState({
    password: false,
    oldPassword: false,
    confirmPassword: false
  });
  const [formErrors, setFormErrors] = useState({ phone: '' });
  const { setLoading } = useLoader();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [enableActions, setEnableActions] = useState(false);

  const resetState = () => {
    setPassword(getInitialState());
  };

  function validateForm() {
    return (
      password.oldPassword.length > 0 &&
      password.password.length > 0 &&
      password.password === password.confirmPassword
    );
  }

  const handleChange = event => {
    setEnableActions(true);
    if (event.target.name === 'phone') {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
      setFormErrors({
        ...formErrors,
        phone: validatePhoneNumber(event.target.value)
      });
    }
    setPassword({
      ...password,
      [event.target.name]: event.target.value
    });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  async function handleChangeClick(event) {
    event.preventDefault();
    let sBar = enqueueSnackbar('Updating password...', {
      variant: 'info',
      preventDuplicate: true
    });

    try {
      setLoading(true);
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        password.oldPassword,
        password.password
      );
      closeSnackbar(sBar);
      setLoading(false);
      sBar = enqueueSnackbar('Password Updated ...', {
        variant: 'sucess',
        preventDuplicate: true
      });
      setEnableActions(false);
      resetState();
    } catch (error) {
      closeSnackbar(sBar);
      sBar = enqueueSnackbar(error.message, {
        variant: 'error',
        preventDuplicate: true
      });
      setLoading(false);
    }
  }

  const onCancel = () => {
    resetState();
    setEnableActions(false);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form onSubmit={handleChangeClick}>
        <CardHeader title="Change Your Password" />
        <CardContent>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Current Password"
              name="oldPassword"
              variant="outlined"
              value={password.oldPassword}
              onChange={handleChange}
              type={showPassword.oldPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          oldPassword: !showPassword.oldPassword
                        })
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword.oldPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="New Password"
              name="password"
              style={{ marginTop: '1rem' }}
              variant="outlined"
              value={password.password}
              onChange={handleChange}
              type={showPassword.password ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword.password
                        })
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword.password ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              required
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              label="Confirm Password"
              name="confirmPassword"
              style={{ marginTop: '1rem' }}
              variant="outlined"
              value={password.confirmPassword}
              onChange={handleChange}
              required
              type={showPassword.confirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword.confirmPassword
                        })
                      }
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword.confirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </CardContent>
        <Divider />
        {/* <CardHeader title="Account Recovery" /> */}
        <CardContent>
          <Grid container spacing={3}>
            {/* <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                variant="outlined"
                value={password.email}
                onChange={handleChange}
                required
              />
            </Grid> */}
            {/* <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phone"
                type="text"
                variant="outlined"
                inputProps={{ maxLength: 13 }}
                value={password.phone}
                onChange={handleChange}
                error={password.phone.length < 11 && password.phone.length > 0}
                helperText={formErrors.phone}
                required
              />
            </Grid> */}
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            xs={12}>
            <Button
              className={classes.cancelButton}
              variant="outlined"
              disabled={!enableActions}
              onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className={classes.saveButton}
              variant="contained"
              disabled={!(enableActions && validateForm())}
              type="submit">
              Save
            </Button>
          </Grid>
        </CardActions>
      </form>
    </Card>
  );
};

Password.propTypes = {
  className: PropTypes.string
};

export default Password;
