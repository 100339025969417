import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Tabs,
  Tab,
  Card,
  useMediaQuery,
  Chip
} from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import {
  showPrice,
  capitalizeStr,
  unitFormatter,
  calculatePriceWithDiscount
} from 'common/utilFunctions';
import OrderUpDownButtons from './OrderUpDownButtons';
import CartCard from './CartCard';
import Image from './Image';
import { useCoupons } from 'context/coupons/couponsContext';
import { CartContext } from 'context/cart/cartContext';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '30px 30px',
    backgroundColor: '#F9FAFB',
    [theme.breakpoints.up('md')]: {
      padding: '40px 5rem 4rem'
    }
  },
  catListContainer: {
    marginTop: '25px',
    padding: '10px 30px 18px 30px',
    padding: '10px 30px 18px 30px',
    boxShadow: '2px 4px 3px 0px #0000001A',
    clipPath: 'inset(0px 0px -15px 0px);',
    [theme.breakpoints.up('md')]: {
      padding: '10px 5rem 18px 5rem'
    }
  },
  tabIndicator: {
    display: 'none'
  },
  tabsRoot: { gap: '10px' },
  selectedItemBtns: {
    backgroundColor: theme.palette.blue.main,
    color: 'white',
    borderColor: 'white !important',
    '&:hover': {
      backgroundColor: theme.palette.blue.secondary
    }
  },
  serviceHeading: {
    fontSize: '22px'
  },
  itemCard: {
    padding: '14px',
    width: '100%',
    borderRadius: '7px',
    boxShadow: '0px 0px 3px 0px #00000033',
    border: 'none',
    marginRight: '1rem'
  },
  greyText: {
    color: '#777'
  },
  discountedPrice: {
    color: '#F82A2A'
  },
  oldPrice: {
    textDecoration: 'line-through',
    fontSize: '0.75rem'
  },
  offerChip: {
    marginLeft: '1rem',
    height: '1rem',
    padding: '0 0.75rem',
    backgroundColor: '#FF8B8B',
    borderColor: '#FE3F3F',
    fontSize: '0.5rem',
    color: 'white'
  },
  itemName: {
    lineHeight: '0.5rem'
  }
}));

const StyledTab = withStyles((theme) => ({
  root: {
    borderRadius: '22px',
    backgroundColor: '#F2F2F2',
    textTransform: 'capitalize',
    fontSize: '12px',
    maxHeight: '30px',
    padding: '8px 15px',
    fontWeight: '800'
  },
  selected: {
    backgroundColor: theme.palette.blue.main,
    color: 'white'
  }
}))((props) => <Tab {...props} />);

const Items = ({ category, dense = false }) => {
  const { cart } = useContext(CartContext);
  const classes = useStyles();
  const { coupons = [] } = useCoupons();

  const getItemQuantity = (item) =>
    cart.some((cartItem) => cartItem.id === item.id)
      ? cart
          .filter((cartItem) => cartItem.id === item.id)
          .map((item) => item.quantity)[0]
      : 0;

  return (
    <Grid container style={{ height: 'fit-content' }}>
      <Grid item xs={12}>
        <Typography variant="h3" className={classes.serviceHeading}>
          {capitalizeStr(category[0])}
        </Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        style={{ gap: '1.5rem 0', margin: '12px 0 18px 0' }}>
        {category[1]
          .filter(
            (item) =>
              !item._deleted && (item.name || (item.item && item.item.name))
          )
          .map((item, index) => {
            const currentCoupon = coupons.find(
              (coupon) =>
                coupon.discountType === 'direct' && coupon.productID === item.id
            );
            let discountedPrice;
            if (currentCoupon)
              discountedPrice = calculatePriceWithDiscount(
                item.price,
                currentCoupon.discountAmount,
                currentCoupon.discountUnit
              );
            return (
              <Grid
                container
                justify="center"
                item
                xs={12}
                md={dense ? 4 : 6}
                key={`shop-detail-shop-items-item-${index}`}>
                <Card className={classes.itemCard}>
                  <Grid
                    container
                    justify="space-between"
                    style={{ height: '100%' }}>
                    <Grid item xs={9} container justify="space-between">
                      <Grid
                        container
                        alignItems="center"
                        style={{ marginBottom: '5px' }}>
                        <Typography variant="h5" className={classes.itemName}>
                          {item?.name || item.item?.name || ''}{' '}
                        </Typography>
                        {currentCoupon ? (
                          <Chip
                            variant="outlined"
                            size="small"
                            className={classes.offerChip}
                            label={`${
                              currentCoupon.discountUnit === 'percentage'
                                ? `${currentCoupon.discountAmount}%`
                                : showPrice(currentCoupon.discountAmount)
                            } off`}
                          />
                        ) : null}
                      </Grid>
                      <Grid container justify="space-between">
                        <Typography
                          variant="caption"
                          className={classes.greyText}>
                          {item?.description || item.item?.description || ''}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        style={{ gap: '0.325rem', marginTop: '5px' }}>
                        <Typography
                          variant="h5"
                          className={currentCoupon ? classes.oldPrice : ''}>
                          {showPrice(item.price)}
                        </Typography>
                        {currentCoupon ? (
                          <Typography
                            variant="h5"
                            className={classes.discountedPrice}>
                            {showPrice(discountedPrice)}
                          </Typography>
                        ) : null}
                        <Typography
                          variant="caption"
                          style={{
                            textTransform: 'lowercase'
                          }}
                          className={classes.greyText}>
                          {unitFormatter(item.unit, '/')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={3}
                      alignItems="center"
                      justify="flex-end">
                      {!!(item?.image || item?.item?.image) && (
                        <Grid
                          item
                          xs={12}
                          container
                          justify="flex-end"
                          style={{ marginBottom: '8px' }}>
                          <Image
                            docKey={item?.image || item?.item?.image}
                            height={50}
                            width={72}
                          />
                        </Grid>
                      )}
                      {!dense && (
                        <Grid
                          container
                          justify="flex-end"
                          alignItems="flex-start"
                          item
                          xs={12}>
                          <OrderUpDownButtons
                            item={{
                              ...item,
                              price: discountedPrice || item.price,
                              discount: item.price - discountedPrice || 0,
                              discountCouponID: currentCoupon?.id || '',
                              discountCoupon: currentCoupon || ''
                            }}
                            className={
                              getItemQuantity(item) > 0
                                ? classes.selectedItemBtns
                                : ''
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};

export default function ItemDisplayer(props) {
  const { currentShop } = props;
  const { cart, setCart } = useContext(CartContext);
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const enableCart = currentShop.isLive;
  const { products = {} } = currentShop;
  const categoryList = {
    ...products?.items?.reduce((r, a) => {
      if (a.service?.name) {
        r[a.service.name.trim()] = [...(r[a.service.name.trim()] || []), a];
      }
      return r;
    }, {})
  };
  const [currentTab, setCurrentTab] = useState('all');

  useEffect(() => {
    if (cart?.length > 0) {
      if (cart[0].shopID !== currentShop.id) {
        localStorage.removeItem('cart');
        setCart([]);
      }
    }
  }, [currentShop]);

  return (
    <Grid container>
      <Grid container alignItems="center" className={classes.catListContainer}>
        <Grid item xs={12} style={{ marginBottom: md ? 0 : '10px' }}>
          <Tabs
            classes={{
              flexContainer: classes.tabsRoot,
              indicator: classes.tabIndicator
            }}
            variant="scrollable"
            value={currentTab}
            onChange={(e, newVal) => setCurrentTab(newVal)}
            scrollButtons="auto">
            <StyledTab
              label="All"
              value="all"
              key={`user-flow-shop-detail-tabs-item-all`}
            />
            {Object.keys(categoryList).map((item, index) => (
              <StyledTab
                label={capitalizeStr(item)}
                value={item}
                key={`user-flow-shop-detail-tabs-item-${index}`}
              />
            ))}
          </Tabs>
        </Grid>
      </Grid>
      <Grid container className={classes.root}>
        {/* category items */}
        <Grid container item xs={12} lg={enableCart ? 8 : 12}>
          {Object.entries(categoryList)
            .filter((item) => currentTab === 'all' || item[0] === currentTab)
            .map((item, index) => (
              <Items
                key={`shop-details-items-to-shop-from-items-${index}`}
                category={item}
                dense={!enableCart}
              />
            ))}
        </Grid>

        {/* order checkout */}
        {enableCart && (
          <Grid item xs={12} lg={4}>
            <CartCard shop={currentShop} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

{
  /*
  SearchBar for future use
  <Grid item xs={12} md={3}>
          <TextField
            variant="outlined"
            label="Search"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={classes.searchIcon} />
                </InputAdornment>
              )
            }}
            fullWidth
          />
        </Grid> */
}
