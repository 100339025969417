import React, { useState, useContext } from 'react';
import { validatePhoneNumber } from '../../../../../../common/validators';
import { useSnackbar } from 'notistack';
import { useLoader } from 'layouts/loaderContext';
import { graphqlOperation, API } from 'aws-amplify';
import {
  addNewShopUser,
  updateUserDetail
} from '../../../../../../graphql/mutations';
import { data as availableLanguage } from '../../../../../Shops/components/ShopDetails/languages.json';
import { countries } from 'common/constants';
import { StaffIdContext } from '../../../../../../context/StaffIdContext';
import { maxInputDateForDOB } from 'common/constants';
import {
  Grid,
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel,
  TextField,
  Avatar,
  CardContent,
  CardActions,
  Divider,
  Button,
  MenuItem,
  Checkbox,
  IconButton,
  Paper,
  Modal
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { useAmplifyAuth } from '../../../../../../context';
import { useEffect } from 'react';
import clsx from 'clsx';
import { unitFormatter } from 'common/utilFunctions';
import { Address, Image } from 'components/organisms';
import { uploadImage } from 'common/utilFunctions';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CloseIcon from '@material-ui/icons/Close';

const checkedIcon = <CheckBoxIcon fontSize="small" />;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  avatar: {
    height: 90,
    width: 100
  },
  saveButton: {
    marginLeft: theme.spacing(2)
  },
  input: {
    display: 'none'
  },
  radioBtn: {
    marginLeft: '0.2rem',
    padding: '0 1rem 0 0.5rem',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '0.4rem'
  },
  radioGroup: {
    gap: '1.5rem'
  },
  edit: {
    padding: '0.25rem 0'
  },
  modalScroll: {
    overflowY: 'scroll',
    maxHeight: '90vh',
    paddingRight: '15px'
  },
  modalPaper: {
    padding: '0.5rem 1rem'
  }
}));

const StaffDetailsForm = ({
  setCurrentTab = () => {},
  currentStaff,
  edit = false,
  setCurrentStaff = () => {}
}) => {
  const { setStaffId } = useContext(StaffIdContext);
  const {
    state: { user, isLaundryAdmin = false }
  } = useAmplifyAuth();
  const getInitialState = {
    title: '',
    dateOfBirth: '',
    email: '',
    address: '',
    phoneNumber: '',
    postalCode: '',
    language: [],
    nationality: '',
    firstName: '',
    lastName: '',
    shopId: user.shopId || '',
    groups: '',
    picture: '',
    status: 'pending'
  };
  const [staffInfo, setStaffInfo] = useState({ ...getInitialState });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { setLoading } = useLoader();
  const [imageFile, setImageFile] = useState(null);
  const [selectedStaffTitle, setSelectedStaffTitle] = useState('');
  const [formErrors, setFormErrors] = useState({
    phoneNumber: false,
    title: false,
    email: false,
    dateOfBirth: false,
    address: false,
    language: false,
    nationality: false,
    postalCode: false,
    groups: false,
    firstName: false,
    lastName: false
  });
  const [enableActions, setEnableActions] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [checkBoxValue, setCheckBoxValue] = useState({
    check1: false,
    check2: false
  });
  const classes = useStyles();
  const staffTitles = [
    {
      title: 'Staff',
      value: 'laundryStaff'
    },
    {
      title: 'Admin',
      value: 'laundryAdmin'
    }
  ];

  useEffect(() => {
    if (edit) {
      setCheckBoxValue({
        check1: true,
        check2: true
      });
      setSelectedStaffTitle(
        staffTitles.find((item) => currentStaff?.groups?.includes(item.value))
          ?.value || ''
      );
      const addressInArray =
        currentStaff?.address?.split(',')?.map((item) => item.trim()) || [];
      const nationality = addressInArray.pop();
      setStaffInfo({
        ...currentStaff,
        address: addressInArray.join(' '),
        nationality,
        picture:
          currentStaff.picture?.split('/').at(0) === 'images'
            ? currentStaff.picture
            : ''
      });
    }
  }, [edit, currentStaff]);

  const handleImageChange = (event) => {
    const _file = event.target.files[0] || event.target.value;
    if (_file) {
      setImageFile(_file);
      const reader = new FileReader();
      reader.onload = () => {
        setStaffInfo({
          ...staffInfo,
          picture: reader.result
        });
        setEnableActions(true);
      };
      reader.readAsDataURL(_file);
    }
  };

  const handleChange = (event, newVal) => {
    setEnableActions(true);
    if (event.target.name === 'phoneNumber') {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
      setFormErrors({
        ...formErrors,
        phoneNumber: validatePhoneNumber(event.target.value)
      });
    }
    setStaffInfo({
      ...staffInfo,
      [event.target.name]:
        event.target.name === 'language' ? newVal : event.target.value
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      Object.keys(getInitialState)
        .filter((key) => key !== 'shopId' && key !== 'picture')
        .some((key) => !staffInfo[key]) ||
      !staffInfo.language.length
    ) {
      enqueueSnackbar(
        `${unitFormatter(
          Object.keys(getInitialState).find((key) => !staffInfo[key]) ||
            'Language'
        )} is a mandatory field.`,
        {
          variant: 'warning',
          preventDuplicate: true
        }
      );
      return;
    }

    let sBar;
    try {
      sBar = enqueueSnackbar('Saving Staff details...', {
        variant: 'info',
        preventDuplicate: true
      });
      setLoading(true);
      let key = '';
      if (imageFile) {
        key = await uploadImage(imageFile);
      }
      const { address, nationality, ...other } = staffInfo;
      const newStaffInfo = {
        ...other,
        address: `${address}, ${nationality}`,
        picture: key
      };

      if (edit) {
        const data = {
          ...Object.fromEntries(
            Object.keys(getInitialState)
              .filter((key) => key !== 'shopId' && key !== 'groups')
              .map((key) => [key, newStaffInfo[key]])
          ),
          id: currentStaff.id,
          username: currentStaff.email
        };
        await API.graphql(graphqlOperation(updateUserDetail, data));
        enqueueSnackbar('User updated successfully', {
          variant: 'success',
          autoHideDuration: 2000,
          preventDuplicate: true
        });
        setCurrentStaff(newStaffInfo);
        setEnableActions(false);
      } else {
        const data = await API.graphql(
          graphqlOperation(addNewShopUser, newStaffInfo)
        );
        setStaffId(data.data.addNewShopUser.id);
        enqueueSnackbar('User created successfully', {
          variant: 'success',
          autoHideDuration: 2000,
          preventDuplicate: true
        });
        setCurrentTab('docs');
      }
    } catch (e) {
      enqueueSnackbar(e.errors?.at(0)?.message || 'Something went wrong..!', {
        variant: 'error',
        preventDuplicate: true
      });
      console.error('Error', e);
    } finally {
      closeSnackbar(sBar);
      setLoading(false);
    }
  };

  const onCancel = () => {
    if (edit) {
      const addressInArray =
        currentStaff?.address?.split(',')?.map((item) => item.trim()) || [];
      const nationality = addressInArray.pop();
      setStaffInfo({
        ...currentStaff,
        address: addressInArray.join(' '),
        nationality,
        picture:
          currentStaff.picture?.split('/').at(0) === 'images'
            ? currentStaff.picture
            : ''
      });
    } else {
      setStaffInfo(getInitialState);
      setSelectedStaffTitle('');
    }
    setEnableActions(false);
    setImageFile(null);
  };

  const handleModalClose = () => setShowAddressModal(false);

  const handleAddAddress = (data) => {
    const {
      country = '',
      postcode = '',
      line1 = '',
      line2 = '',
      line3 = '',
      line4 = '',
      town = '',
      town_or_city = '',
      county = ''
    } = data;
    setStaffInfo({
      ...staffInfo,
      nationality: country,
      postalCode: postcode,
      address: [line1, line2, line3, line4, town, town_or_city, county]
        .filter((item) => !!item)
        .join(', ')
    });
    setEnableActions(true);
    setShowAddressModal(false);
  };

  const handleCheckBox = (name = '') => (e) =>
    setCheckBoxValue({ ...checkBoxValue, [name]: e.target.checked });

  return (
    <>
      <CardContent className={clsx(classes.root, edit && classes.edit)}>
        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Grid item xs={12}>
                <Typography variant="body1" style={{ marginBottom: '10px' }}>
                  &nbsp;Choose staff role
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  row
                  aria-label="staff-title-radios"
                  value={selectedStaffTitle}
                  className={classes.radioGroup}
                  onChange={(e) => setSelectedStaffTitle(e.target.value)}
                  name="staff-title-radios">
                  {staffTitles.map((item, index) => (
                    <Grid
                      item
                      key={`staff-title-select-role-${index + 1}`}
                      style={{ padding: '0 4px' }}>
                      <FormControlLabel
                        value={item.value}
                        name="groups"
                        onChange={handleChange}
                        style={{
                          borderColor:
                            selectedStaffTitle === item.value
                              ? 'blue'
                              : 'rgb(0 0 0 / 20%)'
                        }}
                        className={classes.radioBtn}
                        control={<Radio color="primary" disabled={edit} />}
                        label={
                          <Grid style={{ marginLeft: '0.5rem' }}>
                            <Typography
                              variant="body1"
                              color={
                                selectedStaffTitle === item.value
                                  ? 'primary'
                                  : 'initial'
                              }>
                              {item.title}
                            </Typography>
                          </Grid>
                        }
                      />
                    </Grid>
                  ))}
                </RadioGroup>
              </Grid>
            </Grid>
            {isLaundryAdmin && edit ? (
              <Grid item xs={12} md={6} container alignItems="flex-end">
                <TextField
                  fullWidth
                  label="Status"
                  margin="dense"
                  name={'status'}
                  select
                  disabled={isLaundryAdmin && staffInfo.status === 'pending'}
                  onChange={handleChange}
                  required
                  value={staffInfo.status}
                  variant="outlined">
                  <MenuItem value="pending" disabled>
                    Pending
                  </MenuItem>
                  <MenuItem value="enabled">Enabled</MenuItem>
                  <MenuItem value="disabled">Disabled</MenuItem>
                  <MenuItem value="deceased">Deceased</MenuItem>
                </TextField>
              </Grid>
            ) : null}
          </Grid>

          {!!selectedStaffTitle && (
            <>
              <Grid
                container
                style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                <Typography variant="h5">Staff Personal Information</Typography>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <TextField
                    value={staffInfo.title || ''}
                    name="title"
                    variant="outlined"
                    onChange={handleChange}
                    label="Title"
                    select
                    size="small"
                    placeholder="choose"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    error={formErrors.title}
                    required>
                    <MenuItem value="Mr">Mr</MenuItem>
                    <MenuItem value="Ms">Ms</MenuItem>
                    <MenuItem value="Mrs">Mrs</MenuItem>
                  </TextField>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    value={staffInfo.firstName || ''}
                    name="firstName"
                    variant="outlined"
                    onChange={handleChange}
                    label="First name"
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    value={staffInfo.lastName || ''}
                    name="lastName"
                    variant="outlined"
                    onChange={handleChange}
                    label="Last name"
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    value={staffInfo.email || ''}
                    variant="outlined"
                    name="email"
                    onChange={handleChange}
                    label="Email Address"
                    size="small"
                    type="email"
                    fullWidth
                    disabled={edit}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    value={staffInfo.dateOfBirth || ''}
                    variant="outlined"
                    name="dateOfBirth"
                    onChange={handleChange}
                    label="Birth Date"
                    size="small"
                    type="date"
                    fullWidth
                    inputProps={{
                      max: maxInputDateForDOB
                    }}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    value={staffInfo.phoneNumber || ''}
                    name="phoneNumber"
                    onChange={handleChange}
                    variant="outlined"
                    label="Phone Number"
                    type="text"
                    size="small"
                    fullWidth
                    error={!!formErrors.phoneNumber}
                    helperText={formErrors.phoneNumber}
                    inputProps={{ maxLength: 13 }}
                    InputLabelProps={{ shrink: true }}
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justify="space-between"
                style={{
                  marginTop: '2rem'
                }}>
                <Grid
                  container
                  item
                  direction="column"
                  xs={12}
                  md={2}
                  justify="flex-start"
                  alignItems="center">
                  {staffInfo.picture?.split('/').at(0) === 'images' ? (
                    <Image
                      alt="staff-image"
                      className={classes.avatar}
                      docKey={staffInfo.picture}
                    />
                  ) : (
                    <Avatar
                      className={classes.avatar}
                      src={staffInfo.picture || ''}
                    />
                  )}
                  <input
                    accept="image/*"
                    name="image"
                    className={classes.input}
                    id="contained-button-file"
                    multiple
                    onChange={handleImageChange}
                    type="file"
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      color="primary"
                      component="span"
                      style={{ padding: '6px 0', textTransform: 'capitalize' }}>
                      {staffInfo.picture ? 'Change Image' : 'Upload Image'}
                    </Button>
                  </label>
                </Grid>
                <Grid container item xs={12} md={10} spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      onChange={(e, newVal) =>
                        handleChange({ target: { name: 'language' } }, newVal)
                      }
                      size="small"
                      value={staffInfo.language || []}
                      getOptionLabel={(option) => option}
                      multiple
                      name="language"
                      options={availableLanguage}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="language"
                          label="Language"
                          variant="outlined"
                        />
                      )}
                      renderOption={(option, { selected }) => (
                        <>
                          <Checkbox
                            checked={selected}
                            checkedIcon={checkedIcon}
                            icon={icon}
                          />
                          {option}
                        </>
                      )}
                    />
                  </Grid>
                  {!(
                    staffInfo.postalCode ||
                    staffInfo.address ||
                    staffInfo.nationality
                  ) ? (
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setShowAddressModal(true)}
                        fullWidth>
                        Add address
                      </Button>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={12} md={4}>
                        <TextField
                          required
                          value={staffInfo.postalCode || ''}
                          name="postalCode"
                          onChange={handleChange}
                          variant="outlined"
                          label="Postal code"
                          size="small"
                          placeholder=""
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <TextField
                          value={staffInfo.address || ''}
                          name="address"
                          variant="outlined"
                          onChange={handleChange}
                          label="Address"
                          size="small"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <TextField
                          value={staffInfo.nationality || ''}
                          name="nationality"
                          variant="outlined"
                          label="Nationality"
                          onChange={handleChange}
                          select
                          size="small"
                          placeholder="choose"
                          fullWidth
                          required
                          InputLabelProps={{ shrink: true }}>
                          <MenuItem value="">
                            <i>None</i>
                          </MenuItem>
                          {countries.map((country) => (
                            <MenuItem
                              value={country.value}
                              key={`add-new-staff-form-countries-values-${country.code}`}>
                              {country.value}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              {edit ? null : (
                <Grid
                  container
                  style={{
                    marginTop: '1rem'
                  }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      value={checkBoxValue.check1}
                      onChange={handleCheckBox('check1')}
                      label="This staff is properly verified by us."
                      control={<Checkbox />}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      value={checkBoxValue.check2}
                      onChange={handleCheckBox('check2')}
                      label="I have ID and address proof of the staff with me."
                      control={<Checkbox />}
                    />
                  </Grid>
                </Grid>
              )}

              <Divider />
              <CardActions>
                <Grid
                  container
                  item
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  xs={12}>
                  <Button
                    onClick={onCancel}
                    className={classes.cancelButton}
                    variant="outlined"
                    disabled={!enableActions}>
                    Cancel
                  </Button>
                  <Button
                    className={classes.saveButton}
                    variant="contained"
                    color="primary"
                    disabled={
                      Object.values(checkBoxValue).some((item) => !item) ||
                      !enableActions
                    }
                    type="submit">
                    {`Save${edit ? '' : ' & Next'}`}
                  </Button>
                </Grid>
              </CardActions>
            </>
          )}
        </form>
      </CardContent>
      <Modal
        open={showAddressModal}
        onClose={handleModalClose}
        aria-labelledby="add-address-modal-title"
        aria-describedby="add-address-modal-description">
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ marginTop: '3rem' }}>
          <Paper className={classes.modalPaper}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              style={{ margin: '10px 0' }}>
              <Typography variant="h4">Add Address</Typography>
              <IconButton onClick={handleModalClose} style={{ padding: 0 }}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <PerfectScrollbar className={classes.modalScroll}>
              <Address
                mode={4}
                onCancel={handleModalClose}
                updateAddressList={handleAddAddress}
              />
            </PerfectScrollbar>
          </Paper>
        </Grid>
      </Modal>
    </>
  );
};

export default StaffDetailsForm;
