/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getShopUsers = /* GraphQL */ `
  query GetShopUsers {
    getShopUsers {
      UserCreateDate
      UserLastModifiedDate
      Enabled
      Attributes {
        Name
        Value
      }
      Username
      UserStatus
    }
  }
`;
export const getAllShopUsers = /* GraphQL */ `
  query GetAllShopUsers($shopId: String!) {
    getAllShopUsers(shopId: $shopId) {
      UserCreateDate
      UserLastModifiedDate
      Enabled
      Attributes {
        Name
        Value
      }
      Username
      UserStatus
    }
  }
`;
export const getSalesUsers = /* GraphQL */ `
  query GetSalesUsers {
    getSalesUsers {
      id
      groups
      picture
      username
      userId
      firstName
      lastName
      title
      defaultAddress
      email
      address
      postalCode
      phoneNumber
      createdAt
      shopID
      status
      language
      dateOfBirth
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers {
    getCustomers {
      UserCreateDate
      UserLastModifiedDate
      Enabled
      Attributes {
        Name
        Value
      }
      Username
      UserStatus
    }
  }
`;
export const getShopWithoutRangeByPostalCode = /* GraphQL */ `
  query GetShopWithoutRangeByPostalCode($postalCode: String!, $range: String) {
    getShopWithoutRangeByPostalCode(postalCode: $postalCode, range: $range) {
      id
      name
      googlePlaceId
      contactName
      address
      postcode
      town
      country
      email
      phoneNumber
      location {
        lat
        lng
      }
      workHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      collectionHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      deliveryHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      image
      minPriceForFree
      distanceRange
      standardDeliveryFee
      AsapDeliveryFee
      language
      range
      shopID
      about
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
      }
      photos {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
      }
      primaryPhoto {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
      }
      videos {
        name
        videoURL
        autoPlay
        thumbURL
        description
        MIMETypes
        status
      }
      status
      isLive
      consentLetterCount
      consentLetterLastDate
      enableAsapDelivery
      collectionInstructions
      deliveryInstructions
      websiteLink
      createdBy
      updatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          description
          discount
          discountDescription
          unit
          tags
          price
          image
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      reviews {
        items {
          id
          shopID
          userID
          title
          category1
          category2
          category3
          category4
          category5
          description
          createdAt
          updatedAt
          status
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const getLatLngByPostCode = /* GraphQL */ `
  query GetLatLngByPostCode($postalCode: String!) {
    getLatLngByPostCode(postalCode: $postalCode) {
      lat
      lng
    }
  }
`;
export const getOrderByShopIdDateOIDStatus = /* GraphQL */ `
  query GetOrderByShopIdDateOIDStatus(
    $shopId: String!
    $orderId: String
    $status: [OrderStatus]
    $createdDateRange: DateRange
    $nextToken: String
  ) {
    getOrderByShopIdDateOIDStatus(
      shopId: $shopId
      orderId: $orderId
      status: $status
      createdDateRange: $createdDateRange
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        orderID
        orderList {
          productID
          category
          name
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          quantity
        }
        userID
        status
        collection {
          date
          time
          type
          address
        }
        delivery {
          date
          time
          type
          address
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        orderCancelOrDeclineReason
        total
        paidAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
        }
        payments {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
    }
  }
`;
export const syncOrders = /* GraphQL */ `
  query SyncOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        orderID
        orderList {
          productID
          category
          name
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          quantity
        }
        userID
        status
        collection {
          date
          time
          type
          address
        }
        delivery {
          date
          time
          type
          address
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        orderCancelOrDeclineReason
        total
        paidAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
        }
        payments {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      shopID
      orderID
      orderList {
        productID
        category
        name
        description
        discount
        discountCoupon
        discountCouponID
        discountDescription
        overallDiscount
        unit
        price
        quantity
      }
      userID
      status
      collection {
        date
        time
        type
        address
        driver {
          userID
        }
      }
      delivery {
        date
        time
        type
        address
        driver {
          userID
        }
      }
      createdAt
      updatedAt
      updatedBy
      paymentStatus
      orderCancelOrDeclineReason
      total
      paidAmount
      coupons
      collectionAndDeliveryFee
      currentShopFees {
        standardDeliveryFee
        AsapDeliveryFee
        minPriceForFree
      }
      payments {
        items {
          id
          orderID
          shopID
          userID
          amount
          createdAt
          createdBy
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
      userDetail {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopID
        orderID
        orderList {
          productID
          category
          name
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          quantity
        }
        userID
        status
        collection {
          date
          time
          type
          address
        }
        delivery {
          date
          time
          type
          address
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        orderCancelOrDeclineReason
        total
        paidAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
        }
        payments {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrderHistories = /* GraphQL */ `
  query SyncOrderHistories(
    $filter: ModelOrderHistoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrderHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderID
        order {
          id
          shopID
          orderID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          orderCancelOrDeclineReason
          total
          paidAmount
          coupons
          collectionAndDeliveryFee
          _version
          _deleted
          _lastChangedAt
        }
        userID
        status
        changeLog
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrderHistory = /* GraphQL */ `
  query GetOrderHistory($id: ID!) {
    getOrderHistory(id: $id) {
      id
      orderID
      order {
        id
        shopID
        orderID
        orderList {
          productID
          category
          name
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          quantity
        }
        userID
        status
        collection {
          date
          time
          type
          address
        }
        delivery {
          date
          time
          type
          address
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        orderCancelOrDeclineReason
        total
        paidAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
        }
        payments {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      userID
      status
      changeLog
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listOrderHistorys = /* GraphQL */ `
  query ListOrderHistorys(
    $filter: ModelOrderHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderID
        order {
          id
          shopID
          orderID
          userID
          status
          createdAt
          updatedAt
          updatedBy
          paymentStatus
          orderCancelOrDeclineReason
          total
          paidAmount
          coupons
          collectionAndDeliveryFee
          _version
          _deleted
          _lastChangedAt
        }
        userID
        status
        changeLog
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCounters = /* GraphQL */ `
  query SyncCounters(
    $filter: ModelCountersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCounters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        counterName
        currentValue
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCounters = /* GraphQL */ `
  query GetCounters($id: ID!) {
    getCounters(id: $id) {
      id
      counterName
      currentValue
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCounterss = /* GraphQL */ `
  query ListCounterss(
    $filter: ModelCountersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCounterss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        counterName
        currentValue
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMessages = /* GraphQL */ `
  query SyncMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        sender
        orderID
        message
        status
        readAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      sender
      orderID
      message
      status
      readAt
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sender
        orderID
        message
        status
        readAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPayments = /* GraphQL */ `
  query SyncPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        orderID
        shopID
        userID
        amount
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      orderID
      shopID
      userID
      amount
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orderID
        shopID
        userID
        amount
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses($params: QueryListAddressesParamsInput!) {
    listAddresses(params: $params) {
      addresses
      postcode
      latitude
      longitude
    }
  }
`;
export const getOrderByShopId = /* GraphQL */ `
  query GetOrderByShopId(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByShopId(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        orderID
        orderList {
          productID
          category
          name
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          quantity
        }
        userID
        status
        collection {
          date
          time
          type
          address
        }
        delivery {
          date
          time
          type
          address
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        orderCancelOrDeclineReason
        total
        paidAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
        }
        payments {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrderByUserId = /* GraphQL */ `
  query GetOrderByUserId(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOrderByUserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        orderID
        orderList {
          productID
          category
          name
          description
          discount
          discountCoupon
          discountCouponID
          discountDescription
          overallDiscount
          unit
          price
          quantity
        }
        userID
        status
        collection {
          date
          time
          type
          address
        }
        delivery {
          date
          time
          type
          address
        }
        createdAt
        updatedAt
        updatedBy
        paymentStatus
        orderCancelOrDeclineReason
        total
        paidAmount
        coupons
        collectionAndDeliveryFee
        currentShopFees {
          standardDeliveryFee
          AsapDeliveryFee
          minPriceForFree
        }
        payments {
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        userDetail {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getMessageByOrderID = /* GraphQL */ `
  query GetMessageByOrderID(
    $orderID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMessageByOrderID(
      orderID: $orderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sender
        orderID
        message
        status
        readAt
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPaymentByOrderId = /* GraphQL */ `
  query GetPaymentByOrderId(
    $orderID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPaymentByOrderId(
      orderID: $orderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        shopID
        userID
        amount
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPaymentByShopId = /* GraphQL */ `
  query GetPaymentByShopId(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPaymentByShopId(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        shopID
        userID
        amount
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPaymentByUserId = /* GraphQL */ `
  query GetPaymentByUserId(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPaymentByUserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderID
        shopID
        userID
        amount
        createdAt
        createdBy
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getService = /* GraphQL */ `
  query GetService($id: ID!) {
    getService(id: $id) {
      id
      name
      description
      orderIndex
      type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      items {
        items {
          id
          serviceID
          name
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          description
          discount
          discountDescription
          unit
          tags
          price
          image
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      photoGallaryImages {
        items {
          id
          serviceID
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listServices = /* GraphQL */ `
  query ListServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        photoGallaryImages {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncServices = /* GraphQL */ `
  query SyncServices(
    $filter: ModelServiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncServices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        photoGallaryImages {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getItem = /* GraphQL */ `
  query GetItem($id: ID!) {
    getItem(id: $id) {
      id
      serviceID
      name
      description
      image
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        photoGallaryImages {
          nextToken
          startedAt
        }
      }
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          description
          discount
          discountDescription
          unit
          tags
          price
          image
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listItems = /* GraphQL */ `
  query ListItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceID
        name
        description
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        products {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncItems = /* GraphQL */ `
  query SyncItems(
    $filter: ModelItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        serviceID
        name
        description
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        products {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      id
      name
      googlePlaceId
      contactName
      address
      postcode
      town
      country
      email
      phoneNumber
      location {
        lat
        lng
      }
      workHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      collectionHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      deliveryHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      image
      minPriceForFree
      distanceRange
      standardDeliveryFee
      AsapDeliveryFee
      language
      range
      shopID
      about
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
      }
      photos {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
      }
      primaryPhoto {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
      }
      videos {
        name
        videoURL
        autoPlay
        thumbURL
        description
        MIMETypes
        status
      }
      status
      isLive
      consentLetterCount
      consentLetterLastDate
      enableAsapDelivery
      collectionInstructions
      deliveryInstructions
      websiteLink
      createdBy
      updatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          description
          discount
          discountDescription
          unit
          tags
          price
          image
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      reviews {
        items {
          id
          shopID
          userID
          title
          category1
          category2
          category3
          category4
          category5
          description
          createdAt
          updatedAt
          status
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getShopCreatedBy = /* GraphQL */ `
  query GetShopCreatedBy(
    $createdBy: String
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getShopCreatedBy(
      createdBy: $createdBy
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getShopByStatusCreatedDate = /* GraphQL */ `
  query GetShopByStatusCreatedDate(
    $status: ShopStatus
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getShopByStatusCreatedDate(
      status: $status
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncShops = /* GraphQL */ `
  query SyncShops(
    $filter: ModelShopFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncShops(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const searchShops = /* GraphQL */ `
  query SearchShops(
    $searchString: String
    $status: ShopStatus
    $createdBy: String
    $createdDateRange: DateRange
    $nextToken: String
  ) {
    searchShops(
      searchString: $searchString
      status: $status
      createdBy: $createdBy
      createdDateRange: $createdDateRange
      nextToken: $nextToken
    ) {
      items {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
      nextToken
    }
  }
`;
export const getShopByPostalCode = /* GraphQL */ `
  query GetShopByPostalCode($postalCode: String!, $range: String) {
    getShopByPostalCode(postalCode: $postalCode, range: $range) {
      id
      name
      googlePlaceId
      contactName
      address
      postcode
      town
      country
      email
      phoneNumber
      location {
        lat
        lng
      }
      workHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      collectionHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      deliveryHrs {
        closeDay
        openDay
        closeTime
        openTime
      }
      image
      minPriceForFree
      distanceRange
      standardDeliveryFee
      AsapDeliveryFee
      language
      range
      shopID
      about
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
      }
      photos {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
      }
      primaryPhoto {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
        serviceID
      }
      videos {
        name
        videoURL
        autoPlay
        thumbURL
        description
        MIMETypes
        status
      }
      status
      isLive
      consentLetterCount
      consentLetterLastDate
      enableAsapDelivery
      collectionInstructions
      deliveryInstructions
      websiteLink
      createdBy
      updatedBy
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      products {
        items {
          id
          shopID
          serviceID
          itemID
          category
          name
          description
          discount
          discountDescription
          unit
          tags
          price
          image
          editors
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      reviews {
        items {
          id
          shopID
          userID
          title
          category1
          category2
          category3
          category4
          category5
          description
          createdAt
          updatedAt
          status
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const getCurrentOpenAds = /* GraphQL */ `
  query GetCurrentOpenAds($page: Pages!) {
    getCurrentOpenAds(page: $page) {
      id
      shopID
      startDate
      endDate
      isClosed
      template
      content
      amount
      page
      priority
      orderedDate
      type
      redirectLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const getRandomBanner = /* GraphQL */ `
  query GetRandomBanner {
    getRandomBanner {
      id
      photoURL
      image
      MIMETypes
      title
      color
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      shopID
      serviceID
      itemID
      category
      name
      description
      discount
      discountDescription
      unit
      tags
      price
      image
      editors
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      service {
        id
        name
        description
        orderIndex
        type
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        items {
          nextToken
          startedAt
        }
        products {
          nextToken
          startedAt
        }
        photoGallaryImages {
          nextToken
          startedAt
        }
      }
      item {
        id
        serviceID
        name
        description
        image
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        products {
          nextToken
          startedAt
        }
      }
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
      coupons {
        items {
          id
          shopID
          productID
          title
          description
          startDateTime
          endDateTime
          maxUsesPerUser
          discountType
          discountAmount
          discountUnit
          conditionAmount
          conditionUnit
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopID
        serviceID
        itemID
        category
        name
        description
        discount
        discountDescription
        unit
        tags
        price
        image
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        item {
          id
          serviceID
          name
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProducts = /* GraphQL */ `
  query SyncProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        serviceID
        itemID
        category
        name
        description
        discount
        discountDescription
        unit
        tags
        price
        image
        editors
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        service {
          id
          name
          description
          orderIndex
          type
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        item {
          id
          serviceID
          name
          description
          image
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      groups
      picture
      username
      userId
      firstName
      lastName
      title
      defaultAddress
      email
      address
      postalCode
      phoneNumber
      createdAt
      shopID
      status
      language
      dateOfBirth
      documents {
        name
        documentType
        documentURL
        description
        MIMETypes
        status
      }
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUserByShop = /* GraphQL */ `
  query GetUserByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const byUserEmail = /* GraphQL */ `
  query ByUserEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUserEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      userID
      postcode
      buildingName
      buildingNumber
      line1
      line2
      town
      county
      district
      country
      phoneNumber
      contactName
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const listAddresss = /* GraphQL */ `
  query ListAddresss(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        postcode
        buildingName
        buildingNumber
        line1
        line2
        town
        county
        district
        country
        phoneNumber
        contactName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAddresses = /* GraphQL */ `
  query SyncAddresses(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAddresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        postcode
        buildingName
        buildingNumber
        line1
        line2
        town
        county
        district
        country
        phoneNumber
        contactName
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getCustomerReview = /* GraphQL */ `
  query GetCustomerReview($id: ID!) {
    getCustomerReview(id: $id) {
      id
      shopID
      userID
      title
      category1
      category2
      category3
      category4
      category5
      description
      createdAt
      updatedAt
      status
      _version
      _deleted
      _lastChangedAt
      user {
        id
        groups
        picture
        username
        userId
        firstName
        lastName
        title
        defaultAddress
        email
        address
        postalCode
        phoneNumber
        createdAt
        shopID
        status
        language
        dateOfBirth
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const listCustomerReviews = /* GraphQL */ `
  query ListCustomerReviews(
    $filter: ModelCustomerReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopID
        userID
        title
        category1
        category2
        category3
        category4
        category5
        description
        createdAt
        updatedAt
        status
        _version
        _deleted
        _lastChangedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCustomerReviews = /* GraphQL */ `
  query SyncCustomerReviews(
    $filter: ModelCustomerReviewFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomerReviews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        userID
        title
        category1
        category2
        category3
        category4
        category5
        description
        createdAt
        updatedAt
        status
        _version
        _deleted
        _lastChangedAt
        user {
          id
          groups
          picture
          username
          userId
          firstName
          lastName
          title
          defaultAddress
          email
          address
          postalCode
          phoneNumber
          createdAt
          shopID
          status
          language
          dateOfBirth
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getAd = /* GraphQL */ `
  query GetAd($id: ID!) {
    getAd(id: $id) {
      id
      shopID
      startDate
      endDate
      isClosed
      template
      content
      amount
      page
      priority
      orderedDate
      type
      redirectLink
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
      shop {
        id
        name
        googlePlaceId
        contactName
        address
        postcode
        town
        country
        email
        phoneNumber
        location {
          lat
          lng
        }
        workHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        collectionHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        deliveryHrs {
          closeDay
          openDay
          closeTime
          openTime
        }
        image
        minPriceForFree
        distanceRange
        standardDeliveryFee
        AsapDeliveryFee
        language
        range
        shopID
        about
        documents {
          name
          documentType
          documentURL
          description
          MIMETypes
          status
        }
        photos {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        primaryPhoto {
          name
          photoURL
          thumbURL
          description
          MIMETypes
          status
          serviceID
        }
        videos {
          name
          videoURL
          autoPlay
          thumbURL
          description
          MIMETypes
          status
        }
        status
        isLive
        consentLetterCount
        consentLetterLastDate
        enableAsapDelivery
        collectionInstructions
        deliveryInstructions
        websiteLink
        createdBy
        updatedBy
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        products {
          nextToken
          startedAt
        }
        users {
          nextToken
          startedAt
        }
        reviews {
          nextToken
          startedAt
        }
        coupons {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const listAds = /* GraphQL */ `
  query ListAds($filter: ModelAdFilterInput, $limit: Int, $nextToken: String) {
    listAds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopID
        startDate
        endDate
        isClosed
        template
        content
        amount
        page
        priority
        orderedDate
        type
        redirectLink
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getAdByPage = /* GraphQL */ `
  query GetAdByPage(
    $page: Pages
    $sortDirection: ModelSortDirection
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAdByPage(
      page: $page
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        startDate
        endDate
        isClosed
        template
        content
        amount
        page
        priority
        orderedDate
        type
        redirectLink
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAds = /* GraphQL */ `
  query SyncAds(
    $filter: ModelAdFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        startDate
        endDate
        isClosed
        template
        content
        amount
        page
        priority
        orderedDate
        type
        redirectLink
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
        shop {
          id
          name
          googlePlaceId
          contactName
          address
          postcode
          town
          country
          email
          phoneNumber
          image
          minPriceForFree
          distanceRange
          standardDeliveryFee
          AsapDeliveryFee
          language
          range
          shopID
          about
          status
          isLive
          consentLetterCount
          consentLetterLastDate
          enableAsapDelivery
          collectionInstructions
          deliveryInstructions
          websiteLink
          createdBy
          updatedBy
          createdAt
          _version
          _deleted
          _lastChangedAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const getGallaryPhoto = /* GraphQL */ `
  query GetGallaryPhoto($id: ID!) {
    getGallaryPhoto(id: $id) {
      id
      serviceID
      picture {
        photoURL
        thumbURL
        MIMETypes
      }
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listGallaryPhotos = /* GraphQL */ `
  query ListGallaryPhotos(
    $filter: ModelGallaryPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGallaryPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        serviceID
        picture {
          photoURL
          thumbURL
          MIMETypes
        }
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGallaryPhotoByServiceID = /* GraphQL */ `
  query GetGallaryPhotoByServiceID(
    $serviceID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelGallaryPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGallaryPhotoByServiceID(
      serviceID: $serviceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        serviceID
        picture {
          photoURL
          thumbURL
          MIMETypes
        }
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGallaryPhotos = /* GraphQL */ `
  query SyncGallaryPhotos(
    $filter: ModelGallaryPhotoFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGallaryPhotos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        serviceID
        picture {
          photoURL
          thumbURL
          MIMETypes
        }
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRedirect = /* GraphQL */ `
  query GetRedirect($id: ID!) {
    getRedirect(id: $id) {
      id
      shopID
      userID
      event
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listRedirects = /* GraphQL */ `
  query ListRedirects(
    $filter: ModelRedirectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRedirects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopID
        userID
        event
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRedirectByShopID = /* GraphQL */ `
  query GetRedirectByShopID(
    $shopID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRedirectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getRedirectByShopID(
      shopID: $shopID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        userID
        event
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRedirects = /* GraphQL */ `
  query SyncRedirects(
    $filter: ModelRedirectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRedirects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        userID
        event
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSearchBanner = /* GraphQL */ `
  query GetSearchBanner($id: ID!) {
    getSearchBanner(id: $id) {
      id
      photoURL
      image
      MIMETypes
      title
      color
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listSearchBanners = /* GraphQL */ `
  query ListSearchBanners(
    $filter: ModelSearchBannerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSearchBanners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        photoURL
        image
        MIMETypes
        title
        color
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSearchBanners = /* GraphQL */ `
  query SyncSearchBanners(
    $filter: ModelSearchBannerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSearchBanners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        photoURL
        image
        MIMETypes
        title
        color
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCoupon = /* GraphQL */ `
  query GetCoupon($id: ID!) {
    getCoupon(id: $id) {
      id
      shopID
      productID
      title
      description
      startDateTime
      endDateTime
      maxUsesPerUser
      discountType
      discountAmount
      discountUnit
      conditionAmount
      conditionUnit
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listCoupons = /* GraphQL */ `
  query ListCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shopID
        productID
        title
        description
        startDateTime
        endDateTime
        maxUsesPerUser
        discountType
        discountAmount
        discountUnit
        conditionAmount
        conditionUnit
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCouponsByShop = /* GraphQL */ `
  query GetCouponsByShop(
    $shopID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCouponsByShop(
      shopID: $shopID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        shopID
        productID
        title
        description
        startDateTime
        endDateTime
        maxUsesPerUser
        discountType
        discountAmount
        discountUnit
        conditionAmount
        conditionUnit
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCoupons = /* GraphQL */ `
  query SyncCoupons(
    $filter: ModelCouponFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCoupons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        shopID
        productID
        title
        description
        startDateTime
        endDateTime
        maxUsesPerUser
        discountType
        discountAmount
        discountUnit
        conditionAmount
        conditionUnit
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
