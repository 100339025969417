import MaleAvatar from '../assets/MaleAvt.png';
import FemaleAvatar from '../assets/FemaleAvt.png';

const TESTIMONIAL_DATA = [
  {
    id: 1,
    name: 'Robert Williams',
    user: 'Customer',
    data:
      'I used Five Star Dry Cleaners for my laundry service, After using launder-it.co.uk website I was so happy that I can get dry cleaning, alteration, ironing, shoe repair, etc. Means half of my life can be sorted under one roof, above of all they have option of pick up & drop off as well.',
    image: MaleAvatar
  },
  {
    id: 2,
    name: 'Olivia Ansley',
    user: 'Customer',
    data:
      'By using Launder-It website, I can find the most local laundry service near my resident which helps to control the environment and local business at the same time, I was actually shocked to find out how many options I have in less than a mile.',
    image: FemaleAvatar
  },
  {
    id: 3,
    name: 'Muhammad Amir',
    user: 'Customer',
    data:
      'Me being lazy, was looking for an option to dry clean my work blazer, found N1 Laundrette, was initially unsure as never tried before. But honestly it is pretty straightforward, one time pick up & delivery. No issues. Did what I expected, Will definitely use again.',
    image: MaleAvatar
  },
  {
    id: 4,
    name: 'Mia  Wilson',
    user: 'Customer',
    data:
      'Love the quick service of Five Star Dry Cleaners. Just WOW. Excellent service, it is easy & simple to find out the presorted service which I need to do. Order process so easy and understandable. It impress me to know that free pick up & delivery which also on committed time.',
    image: FemaleAvatar
  },
  {
    id: 5,
    name: 'Dhruvan 	Chari',
    user: 'Customer',
    data:
      'Happy with the way website works, Have used the Stratford Dry Cleaners and Laundry twice now and so far it is been fantastic. No delays with the pick up and drop off of bags, no missing or damaged items. I am very pleased with Stratford Dry Cleaners and Laundry altogether.',
    image: MaleAvatar
  }
];

export default TESTIMONIAL_DATA;
