import React, { useEffect, useState } from 'react';
import OrderTimeEditBar from '../OrderTimeEditBar';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  Button,
  Select,
  DialogContent,
  MenuItem,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { getFormattedName } from 'common/utilFunctions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

export function ConfirmOrderDialog({
  onSuccess = () => {},
  dialogType,
  setDialogType,
  order
}) {
  const classes = useStyles();
  const [state, setState] = useState({
    addressType: 'house',
    driver: 'driver'
  });
  const [pickUpTime, setPickUpTime] = useState('');
  const [deliveryTime, setDeliveryTime] = useState('');
  const [pickUpDate, setPickUpDate] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const currentAddress =
    dialogType === 'itemized'
      ? JSON.parse(order?.collection?.address || '{}')
      : JSON.parse(order?.delivery?.address || '{}');

  useEffect(() => {
    setPickUpDate(order?.collection?.date || '');
    setPickUpTime(order?.collection?.time || '');
    setDeliveryDate(order?.delivery?.date || '');
    setDeliveryTime(order?.delivery?.time || '');
  }, [order]);

  const handleClose = () => {
    setDialogType(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value
    });
  };

  const handleSubmit = () => {
    if (dialogType === 'itemized' && !!order?.collection?.address) {
      if (!pickUpDate || !pickUpTime) {
        enqueueSnackbar('Please provide valid collection date and time', {
          variant: 'warning',
          preventDuplicate: true
        });
        return;
      }
    }
    if (dialogType === 'delivered' && !!order?.delivery?.address) {
      if (!deliveryDate || !deliveryTime) {
        enqueueSnackbar('Please provide valid delivery date and time', {
          variant: 'warning',
          preventDuplicate: true
        });
        return;
      }
    }

    const updatedOrder = {
      ...order,
      collection: {
        ...order?.collection,
        time: pickUpTime,
        date: new Date(pickUpDate)
      },
      delivery: {
        ...order?.delivery,
        time: deliveryTime,
        date: new Date(deliveryDate)
      }
    };
    // dialogType === 'itemized' || dialogType === 'delivered'; dialogType would be next status;
    onSuccess({ order: updatedOrder, sts: dialogType });
  };

  return order && order.id ? (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      onClose={handleClose}
      open={dialogType === 'itemized' || dialogType === 'delivered'}>
      <DialogTitle id="form-dialog-title">
        <Grid container justify="flex-start">
          <Typography variant="h6">
            Order{' '}
            {dialogType === 'itemized'
              ? 'Collection'
              : dialogType === 'delivered'
              ? 'Delivery'
              : null}
            &nbsp; &nbsp;
          </Typography>
          <Typography color="primary">#{order && order.orderID}</Typography>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ height: 'fit-content', overflow: 'hidden' }}>
        <Grid container spacing={3} justify="center" alignItems="center">
          <OrderTimeEditBar
            heading="Pick Up"
            date={pickUpDate}
            time={pickUpTime}
            setDate={setPickUpDate}
            setTime={setPickUpTime}
          />
          <OrderTimeEditBar
            heading="Delivery"
            date={deliveryDate}
            time={deliveryTime}
            setDate={setDeliveryDate}
            setTime={setDeliveryTime}
          />

          <Grid item xs={8} container alignItems="flex-end">
            <Grid container spacing={3} alignItems="flex-end">
              <Grid container item xs={12} direction="column">
                <Typography>
                  Contact Name: {getFormattedName(order?.userDetail)}
                </Typography>
                <Typography
                  style={{
                    marginTop: '0.5rem',
                    marginBottom: '0.5rem'
                  }}>
                  Email address: {order?.userDetail?.email}
                </Typography>
                <Typography>
                  Phone number : {order?.userDetail?.phoneNumber}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {dialogType === 'itemized' && (
                  <Typography variant="h6">Collection Address </Typography>
                )}
                {dialogType === 'delivered' && (
                  <Typography variant="h6">Delivery Address </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  textDecoration: 'underline',
                  paddingTop: '0',
                  gap: '0.5rem'
                }}>
                {!order?.collection?.address ? (
                  <Typography variant="body1">
                    Order will be dropped off by customer <br />
                  </Typography>
                ) : null}
                {!order?.delivery?.address ? (
                  <Typography variant="body1">
                    Order will be picked up by customer <br />
                  </Typography>
                ) : null}
              </Grid>
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    className="nameField"
                    id="filled-basic"
                    value={currentAddress?.postcode || ''}
                    style={{ minWidth: '550px' }}
                    size="small"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="search-filters-staff"
                            edge="start">
                            <LocationOnIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className="nameField"
                    id="filled-basic"
                    value={`${currentAddress?.line1 || ''} ${
                      currentAddress?.line2 || ''
                    } ${currentAddress?.district || ''}`}
                    variant="outlined"
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className="nameField"
                    id="filled-basic"
                    value="Business Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={state.addressType}
                    onChange={handleChange}
                    label="House"
                    style={{ minWidth: '550px' }}
                    defaultValue="house">
                    <MenuItem value="house">House</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item>
              <FormControl
                variant="outlined"
                style={{ minWidth: '100%' }}
                className={classes.formControl}>
                <InputLabel htmlFor="driver">Driver</InputLabel>
                <Select
                  native
                  required
                  value={state.driver}
                  onChange={handleChange}
                  label="driver"
                  inputProps={{
                    name: 'driver',
                    id: 'driver'
                  }}>
                  <option aria-label="None" value="" />
                  <option value="driver1">Driver 1</option>
                  <option value="driver2">Driver 2</option>
                </Select>
              </FormControl>
            </Grid>
            <br />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Message to driver"
              multiline
              rows={12}
              rowsMax={26}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          Close
        </Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}

ConfirmOrderDialog.propTypes = {
  declineOrder: PropTypes.func,
  handleClose: PropTypes.func
};
