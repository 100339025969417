import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useAdTemplateStyles } from '../templateStyles';
import TemplateFooter from './TemplateFooter';
import clsx from 'clsx';

const Template2 = ({
  content = {
    heading: '',
    subHeading1: '',
    subHeading2: '',
    body1: '',
    body2: ''
  },
  shopName = ''
}) => {
  const classes = useAdTemplateStyles();

  return (
    <Grid
      container
      component={Paper}
      className={clsx(classes.card, classes.shopListAdCard)}
      style={{ padding: '0.1rem 0.2rem' }}>
      <Grid item container justify="center">
        <Typography
          variant="h4"
          align="center"
          style={{ color: '#2a45dc', padding: '0.5rem 0', fontWeight: '500' }}>
          {content.heading}
        </Typography>
      </Grid>
      <Grid item container justify="space-evenly" alignItems="flex-start">
        <Grid item xs={6} md={6} style={{ padding: '5px' }}>
          <Typography variant="h6" align="left" style={{ color: '#2a45dc' }}>
            {content.subHeading1}
          </Typography>
          <Typography variant="body2" align="justify" style={{ color: '#000' }}>
            {content.body1}
          </Typography>
        </Grid>
        <Grid item xs={6} md={6} style={{ padding: '5px' }}>
          <Typography variant="h6" align="left" style={{ color: '#2a45dc' }}>
            {content.subHeading2}
          </Typography>
          <Typography variant="body2" align="justify" style={{ color: '#000' }}>
            {content.body2}
          </Typography>
        </Grid>
      </Grid>
      <TemplateFooter shopName={shopName} />
    </Grid>
  );
};

export default Template2;
