import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  TextField,
  Typography,
  IconButton
} from '@material-ui/core';
import StyledRating from './StyledRating';
import CloseIcon from '@material-ui/icons/Close';

const AddReviewDialog = ({
  open,
  handleCancel,
  handleSubmit,
  review,
  handleChange,
  ratingCategories,
  ...other
}) => {
  return (
    <Dialog
      maxWidth="md"
      aria-labelledby="add-review-dialog-title"
      open={open}
      {...other}>
      <DialogTitle disableTypography id="add-review-dialog-title">
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h3">Add Review</Typography>
          <IconButton onClick={handleCancel}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {ratingCategories.map((item, index) => (
          <Grid
            item
            xs={12}
            key={`shop-add-ratings-categories-submit-items-${index}`}
            style={{ margin: '0.5rem 0 0.75rem' }}>
            <Typography variant="h5" style={{ marginBottom: '0.3rem' }}>
              {item.label}
            </Typography>
            <StyledRating
              name={item.name}
              value={review[item.name]}
              onChange={handleChange}
            />
          </Grid>
        ))}
        <TextField
          variant="outlined"
          label="Title"
          placeholder="title"
          fullWidth
          name="title"
          value={review.title}
          onChange={handleChange}
          inputProps={{
            maxLength: 250
          }}
          style={{ margin: '0.625rem 0' }}
        />
        <TextField
          variant="outlined"
          placeholder="write your review"
          label="Description"
          name="description"
          value={review.description}
          onChange={handleChange}
          multiline
          inputProps={{
            maxLength: 1500
          }}
          rows={4}
          fullWidth
          style={{ margin: '0.625rem 0' }}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          CANCEL
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddReviewDialog;
