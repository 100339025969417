export const initialValues = {
  discountFor: 'product',
  title: '',
  description: '',
  startDateTime: '',
  endDateTime: '',
  discountType: '',
  discountUnit: '',
  discountAmount: '',
  conditionUnit: '',
  conditionAmount: '',
  maxUsesPerUser: 0,
  serviceID: '',
  productID: ''
};

export const endAdornments = {
  amount: '£',
  percentage: '%',
  noOfItems: 'items'
};

export const fields = [
  {
    name: 'title',
    label: 'Title',
    md: 12
  },
  {
    name: 'description',
    label: 'Description',
    md: 12,
    multiline: true,
    rows: 3
  },
  {
    name: 'startDateTime',
    label: 'Start Date & Time',
    type: 'datetime-local'
  },
  {
    name: 'endDateTime',
    label: 'End Date & Time',
    type: 'datetime-local'
  }
];

export const requiredFields = {
  title: 'Title',
  description: 'Description',
  startDateTime: 'Start Date & Time',
  endDateTime: 'End Date & Time',
  discountType: 'Discount Type',
  discountUnit: 'Discount Unit',
  discountAmount: 'Discount Amount',
  productID: 'Product'
};

export const notes = [
  <>
    Please provide a proper <strong>Description</strong>
  </>,
  <>
    Only <strong>Title</strong> and <strong>Description</strong> will be shown
    to Customers
  </>,
  <>
    <strong>Direct</strong> coupons will be applied automatically and
    <strong> Conditional</strong> coupons can be applied by the customers
  </>
];
