import React, { useState } from 'react';
import {
  Typography,
  Button,
  Grid,
  Collapse,
  Stepper,
  Step,
  StepLabel,
  Chip,
  useMediaQuery,
  Box,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  showPrice,
  getTotalQuantity,
  unitFormatter,
  inWords,
  capitalizeStr,
  getCollectionAndDeliveryFee,
  getCollectionAndDeliveryFeeText,
  getOrderDiscountTotal
} from 'common/utilFunctions';
import {
  OrderStatusConnector,
  OrderStatusConnectorIcon,
  OrderStatusConnectorVertical,
  AddressCard
} from '../components';
import moment from 'moment';
import { useLoader } from 'layouts/loaderContext';
import { API, graphqlOperation } from 'aws-amplify';
import { getPaymentByOrderId } from 'graphql/queries';
import clsx from 'clsx';

const THREE_MINUTES_IN_MILLISECONDS = 180000;

const useStyles = makeStyles((theme) => ({
  ordersCard: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.grey.secondary,
    marginBottom: '10px'
  },
  ordersCardAnimation: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.grey.secondary,
    marginBottom: '10px',
    animation: `$opac 1.5s ${theme.transitions.easing.easeInOut}`
  },
  ordersCardTopRoot: {
    position: 'relative',
    borderLeft: `8px solid ${theme.palette.secondary.main}`,
    borderRadius: '8px'
  },
  ordersCardTopSection: {
    padding: '0 35px 20px 35px',
    marginTop: '1rem'
  },
  orderPaymentStatusChip: {
    backgroundColor: '#4caf50',
    color: 'white',
    margin: '0.5rem 0 0',
    padding: '0 0.5rem',
    borderRadius: '1rem 0 0 1rem'
  },
  orderCancelStatusChip: {
    backgroundColor: '#ff6100d4',
    color: 'white',
    margin: '0.5rem 0 0',
    padding: '0 0.5rem',
    borderRadius: '1rem 0 0 1rem'
  },
  orderDeclineStatusChip: {
    backgroundColor: '#ff0000b0',
    color: 'white',
    margin: '0.5rem 0 0',
    padding: '0 0.5rem',
    borderRadius: '1rem 0 0 1rem'
  },
  orderCardText: {
    lineHeight: '28px',
    fontWeight: '500'
  },
  orderCardText2: {
    fontWeight: '400',
    lineHeight: '28px'
  },
  orderDetailItem: {
    borderBottom: '0.3px solid rgba(119, 119, 119, 0.5)',
    padding: '10px 0 8px',
    '& > td': {
      borderBottom: '0.3px solid rgba(119, 119, 119, 0.5)'
    }
  },
  orderDetailHeading2: {
    borderBottom: '3px solid #DFDFDF'
  },
  cancelBtn: {
    borderColor: 'red',
    color: 'red'
  },
  '@keyframes opac': {
    '0%': {
      backgroundColor: 'white'
    },
    '50%': {
      backgroundColor: 'orange'
    },
    '100%': {
      backgroundColor: 'white'
    }
  },
  orderTypeBadge: {
    position: 'absolute',
    backgroundColor: '#194376',
    color: 'white',
    margin: '0.5rem 0 0 0',
    padding: '0 0.5rem',
    borderRadius: '0 1rem 1rem 0'
  },
  dropPickStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #979797',
    borderRadius: '11px',
    padding: '16px',
    marginTop: '16px',
    height: '180px'
  },
  orderCancelReasonCard: {
    backgroundColor: '#ff000040',
    padding: '0.5rem',
    marginBottom: '1rem',
    fontWeight: '500'
  },
  tableCellRoot: {
    padding: 0
  },
  tableHeadingCellText: {
    fontWeight: 700
  },
  tableHeadingEndcellText: {
    color: '#979797'
  },
  tableContainer: {
    marginTop: '3rem'
  },
  tableHeadingCell: {
    paddingBottom: '0.625rem'
  },
  tableBodyCell: {
    padding: '0.625rem 0 0.5rem'
  },
  discountBreakdown: {
    fontSize: '0.625rem',
    paddingTop: '0.25rem'
  }
}));

const steps = [
  {
    id: 0,
    label: 'New Order',
    name: 'newOrder'
  },
  {
    id: 1,
    label: 'Itemized',
    name: 'itemized'
  },
  {
    id: 2,
    label: 'Accepted',
    name: 'accepted'
  },
  {
    id: 3,
    label: 'In Progress',
    name: 'inProgress'
  },
  {
    id: 4,
    label: 'Ready for Delivery',
    name: 'readyForDelivery'
  },
  {
    id: 5,
    label: 'Delivered',
    name: 'delivered'
  }
];

const OrderCard = ({
  order,
  handleCancelClick = () => {},
  showCancelButton = false
}) => {
  let balance = order.total;
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const { setLoading } = useLoader();
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const [partiallyPaidRecord, setPartiallyPaidRecord] = useState([]);

  const getCurrentSteps = (o) =>
    !(o.status === 'declined' || o.status === 'cancel')
      ? steps
      : [
          steps[0],
          {
            id: 1,
            label: o.status,
            name: o.status
          }
        ];

  const getPartialPaymentsRecord = async () => {
    setLoading(true);
    if (order?.id) {
      const response = await API.graphql(
        graphqlOperation(getPaymentByOrderId, {
          orderID: order?.id
        })
      );
      const data = await response.data.getPaymentByOrderId.items;
      const partiallyPaidAmounts = (data) => {
        const recordWithDates = data
          .map((el) => ({
            amount: el.amount,
            date: el.createdAt,
            lastChanged: el._lastChangedAt
          }))
          .sort((a, b) => a.lastChanged - b.lastChanged);
        setPartiallyPaidRecord(recordWithDates);
      };
      partiallyPaidAmounts(data);
    }
    setLoading(false);
  };

  const handleViewDetailsClick = () => {
    setShowOrderDetail(true);
    getPartialPaymentsRecord();
  };

  return (
    <Grid
      container
      justify="space-between"
      className={
        new Date(order.createdAt).getTime() + THREE_MINUTES_IN_MILLISECONDS >=
        new Date().getTime()
          ? classes.ordersCardAnimation
          : classes.ordersCard
      }>
      <Grid container className={classes.ordersCardTopRoot}>
        {order.collection?.type === 'asap' ? (
          <Grid container justify="flex-start">
            <Chip
              size="small"
              label="ASAP"
              className={classes.orderTypeBadge}
            />
          </Grid>
        ) : (
          <Grid container justify="flex-start">
            <Chip
              size="small"
              label="Standard"
              className={classes.orderTypeBadge}
            />
          </Grid>
        )}

        {order.paymentStatus === 'paid' && (
          <Grid container justify="flex-end" style={{ marginBottom: '5px' }}>
            <Chip
              size="small"
              label="Paid"
              className={classes.orderPaymentStatusChip}
              style={{ marginBottom: '-10px' }}
            />
          </Grid>
        )}

        {order.paymentStatus === 'partiallyPaid' && (
          <Grid container justify="flex-end" style={{ marginBottom: '5px' }}>
            <Chip
              size="small"
              label={`Partially ${showPrice(order.paidAmount || 0)} paid`}
              className={classes.orderPaymentStatusChip}
              style={{ marginBottom: '-10px' }}
            />
          </Grid>
        )}

        {order.status === 'delivered' && (
          <Grid container justify="flex-end">
            <Chip
              size="small"
              label="Delivered"
              className={classes.orderPaymentStatusChip}
              style={{ marginBottom: '-10px' }}
            />
          </Grid>
        )}
        {order.status === 'cancel' && (
          <Grid container justify="flex-end">
            <Chip
              size="small"
              label="Cancelled "
              className={classes.orderCancelStatusChip}
              style={{ marginBottom: '-10px' }}
            />
          </Grid>
        )}
        {order.status === 'declined' && (
          <Grid container justify="flex-end">
            <Chip
              size="small"
              label="Declined "
              className={classes.orderDeclineStatusChip}
              style={{ marginBottom: '-10px' }}
            />
          </Grid>
        )}
        <Grid
          container
          justify="space-between"
          style={{
            paddingTop: order.paymentStatus === 'paid' ? '0' : '15px'
          }}
          className={classes.ordersCardTopSection}>
          <Grid container item xs={12} sm={8} md={6}>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText}>
                  Order ID :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText2}>
                  {order.orderID || 'N/A'}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText}>
                  Shop Name :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText2}>
                  {order?.shop?.name || ''}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText}>
                  Order Placed on :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText2}>
                  {moment(order.createdAt).format('MMMM Do YYYY') || ''}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText}>
                  Collection Date &amp; Time :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText2}>
                  {order.collection.date
                    ? `${moment(order.collection.date).format('MMMM Do YYYY')}`
                    : 'Pending'}
                </Typography>
                {order.collection.time ? (
                  <Typography variant="body1">
                    ({order.collection.time})
                  </Typography>
                ) : null}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText}>
                  Delivery Date &amp; Time :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText2}>
                  {order.delivery.date
                    ? `${moment(order.delivery.date).format('MMMM Do YYYY')}`
                    : 'Pending'}
                </Typography>
                {order.delivery.time ? (
                  <Typography variant="body1">
                    ({order.delivery.time})
                  </Typography>
                ) : null}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText}>
                  Total Amount :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" className={classes.orderCardText2}>
                  {showPrice(order.total || 0)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            direction="column"
            xs={12}
            md={3}
            style={{ gap: '20px' }}
            justify="center"
            alignItems="flex-end">
            {showCancelButton ? (
              <Button
                variant="outlined"
                fullWidth
                disabled={
                  !(order.status === 'newOrder' || order.status === 'itemized')
                }
                className={classes.cancelBtn}
                onClick={handleCancelClick(order)}>
                CANCEL
              </Button>
            ) : null}
            {showOrderDetail ? (
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setShowOrderDetail(false)}>
                VIEW LESS
              </Button>
            ) : (
              <Button
                variant="outlined"
                fullWidth
                onClick={handleViewDetailsClick}>
                VIEW DETAILS
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Collapse
        in={showOrderDetail}
        style={{ width: '100%', padding: '0px 35px' }}>
        <Typography
          variant="h4"
          style={{ fontWeight: '700', marginTop: '25px' }}>
          Order details
        </Typography>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{
            fontSize: '15px',
            marginBottom: '30px'
          }}>
          Order #{order.orderID || 'N/A'} was placed on{' '}
          {moment(order.createdAt).format('MMMM Do YYYY') || ''}.
        </Typography>
        <Stepper
          alternativeLabel={md}
          activeStep={
            getCurrentSteps(order).find((step) => step.name === order.status)
              ?.id || 0
          }
          orientation={md ? 'horizontal' : 'vertical'}
          style={{ padding: 0, margin: '1rem 0' }}
          connector={
            md ? <OrderStatusConnector /> : <OrderStatusConnectorVertical />
          }>
          {getCurrentSteps(order).map((item) => (
            <Step key={`order-track-steps-items-${item.id}`}>
              <StepLabel
                style={{
                  textTransform: 'capitalize'
                }}
                StepIconComponent={OrderStatusConnectorIcon}>
                {item.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {(order.status === 'declined' || order.status === 'cancel') && (
          <Grid
            container
            item
            justify="center"
            className={classes.orderCancelReasonCard}>
            {order.orderCancelOrDeclineReason}
          </Grid>
        )}
        <Grid container className={classes.tableContainer}>
          <Table>
            <TableHead style={{ backgroundColor: '#fff' }}>
              <TableRow className={classes.orderDetailHeading2}>
                <TableCell
                  classes={{ root: classes.tableCellRoot }}
                  className={classes.tableHeadingCell}>
                  <Typography
                    variant="subtitle1"
                    className={classes.tableHeadingCellText}>
                    Product
                  </Typography>
                </TableCell>
                <TableCell
                  classes={{ root: classes.tableCellRoot }}
                  className={classes.tableHeadingCell}
                  align="center">
                  <Typography
                    variant="subtitle1"
                    className={classes.tableHeadingCellText}>
                    Quantity
                  </Typography>
                </TableCell>
                <TableCell
                  classes={{ root: classes.tableCellRoot }}
                  className={classes.tableHeadingCell}
                  align="center">
                  <Typography
                    variant="subtitle1"
                    className={classes.tableHeadingCellText}>
                    Rate
                  </Typography>
                </TableCell>
                <TableCell
                  classes={{ root: classes.tableCellRoot }}
                  className={classes.tableHeadingCell}
                  align="center">
                  <Typography
                    variant="subtitle1"
                    className={classes.tableHeadingCellText}>
                    Discount
                  </Typography>
                </TableCell>
                <TableCell
                  classes={{ root: classes.tableCellRoot }}
                  className={classes.tableHeadingCell}
                  align="right">
                  <Typography
                    variant="subtitle1"
                    className={clsx(
                      classes.tableHeadingCellText,
                      classes.tableHeadingEndcellText
                    )}>
                    Amount
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {order?.orderList?.map((item, index) => (
                <TableRow
                  className={classes.orderDetailItem}
                  key={`my-orders-order-detail-items-${index}`}>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    className={classes.tableBodyCell}>
                    <Typography variant="h6">{`${item.name || ''}`}</Typography>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    className={classes.tableBodyCell}
                    align="center">
                    <Typography variant="h6">
                      {`x ${item.quantity || 0}`}
                    </Typography>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    className={classes.tableBodyCell}
                    align="center">
                    <Typography variant="h6">
                      {`${showPrice(item.price)} ${unitFormatter(
                        item.unit,
                        '/'
                      ).toLowerCase()}`}
                    </Typography>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    className={classes.tableBodyCell}
                    align="center">
                    <Typography variant="h6">
                      {item.discount
                        ? `${showPrice(item.discount || 0)} ${unitFormatter(
                            item.unit
                          )}`.toLowerCase()
                        : item.overallDiscount
                        ? `${showPrice(item.overallDiscount)}`
                        : 0}
                    </Typography>
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellRoot }}
                    className={classes.tableBodyCell}
                    align="right">
                    <Typography variant="h6">
                      {showPrice(
                        item.price * item.quantity - (item.overallDiscount || 0)
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid container justify="space-between"></Grid>
        {order.collection.type == 'asap' ? (
          <Grid
            container
            className={classes.orderDetailItem}
            justify="space-between">
            <Typography variant="h6">ASAP Fee:</Typography>
            <Typography variant="h6">
              {showPrice(order?.shop?.AsapDeliveryFee)}
            </Typography>
          </Grid>
        ) : (
          ''
        )}
        <Grid
          container
          className={classes.orderDetailItem}
          justify="space-between">
          <Typography variant="h6">
            {getCollectionAndDeliveryFeeText(order)}
          </Typography>
          <Typography variant="h6">
            {getCollectionAndDeliveryFee(order)}
          </Typography>
        </Grid>
        <Grid
          container
          justify="space-between"
          className={classes.orderDetailItem}>
          <Typography
            color="secondary"
            variant="h6"
            style={{ fontWeight: '800' }}>
            {`Total: (x ${getTotalQuantity(order)})`}
          </Typography>
          <Typography
            color="secondary"
            variant="h6"
            style={{ fontWeight: '800' }}>
            {showPrice(order.total || 0)}
          </Typography>
        </Grid>
        <Grid container className={classes.discountBreakdown}>
          <strong>Note:-</strong>
          &nbsp; Direct Discount:{'  '}
          {showPrice(
            getOrderDiscountTotal({
              orderList:
                order.orderList?.filter((item) =>
                  item?.discountCoupon
                    ? JSON.parse(item?.discountCoupon).discountType === 'direct'
                    : false
                ) || []
            })
          )}
          , Discount from Coupons:{'  '}
          {showPrice(
            getOrderDiscountTotal({
              orderList:
                order.orderList?.filter((item) =>
                  item?.discountCoupon
                    ? JSON.parse(item?.discountCoupon).discountType ===
                      'conditional'
                    : false
                ) || []
            })
          )}
          , Overall Discount:{'  '}
          {showPrice(getOrderDiscountTotal(order))}
        </Grid>

        {partiallyPaidRecord?.length > 0 && (
          <Grid>
            {partiallyPaidRecord?.map((el, i) => {
              balance -= el.amount;
              return (
                <Grid
                  container
                  className={classes.orderDetailItem}
                  justify="space-between"
                  key={i}>
                  <Typography variant="h6">
                    Partial Cash Payment on&nbsp;
                    {moment(el.createdAt).format('DD/MM/YYYY, h:mm a')}
                  </Typography>
                  <Typography key={i} variant="h6">
                    {showPrice(el.amount)}
                  </Typography>
                  <Grid container justify="space-between">
                    <Typography variant="h6">
                      {balance <= 0 ? 'Total Amount Due:' : 'Balance Due:'}
                    </Typography>
                    <Typography variant="h6">
                      <strong>{showPrice(balance)}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        )}

        {order.paymentStatus === 'paid' && (
          <Typography
            align="center"
            style={{
              padding: '0.5rem',
              fontWeight: 'bold'
            }}
            variant="h6">
            A Cash Payment of {capitalizeStr(inWords(order?.total))} is Made.
          </Typography>
        )}

        <Grid
          container
          justify="center"
          alignItems="flex-end"
          style={{
            marginBottom: '40px',
            marginTop: '21px'
          }}
          spacing={4}>
          <Grid item md={6} xs={12}>
            {!!order.collection.address ? (
              <AddressCard
                address={order.collection}
                heading="Collection Address"
              />
            ) : (
              <Box>
                <Typography
                  align="center"
                  variant="h5"
                  className={classes.dropPickStyles}>
                  The order will be dropped off by the customer.
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item md={6} xs={12}>
            {!!order.delivery.address ? (
              <AddressCard
                address={order.delivery}
                heading="Delivery Address"
              />
            ) : (
              <Box>
                <Typography
                  align="center"
                  variant="h5"
                  className={classes.dropPickStyles}>
                  The order will be picked up by the customer.
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
};

export default OrderCard;
