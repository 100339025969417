import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Marker } from 'components/molecules';

const Map = (props) => {
  const {
    state,
    update,
    updateActions = () => {},
    disableClick = false,
    defaultZoom = 19
  } = props;

  const defaultProps = {
    center: {
      lat: 51.53314,
      lng: -0.057032
    },
    zoom: defaultZoom
  };

  const storeLocation = {
    lat: state?.location?.lat || 51.53314,
    lng: state?.location?.lng || -0.057032
  };

  const _onClick = (obj) => {
    obj.event.persist();
    updateActions();
    update({
      ...state,
      location: {
        lat: obj.lat,
        lng: obj.lng
      }
    });
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '40vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyCZRcF1TsHJKaYYeUkJ4BXhLTNyzs63Ges' }}
        center={storeLocation}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onClick={disableClick ? () => {} : _onClick}>
        <Marker
          lat={state?.location?.lat || ''}
          lng={state?.location?.lng || ''}
          text={state?.name}
        />
      </GoogleMapReact>
    </div>
  );
};

export default Map;
