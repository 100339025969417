import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MUIRichTextEditor from 'mui-rte';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2rem',
    backgroundColor: '#def1f3',
    gap: '0.25rem',
    [theme.breakpoints.up('md')]: {
      padding: '2.5rem 5rem'
    }
  },
  heading: {},
  editor: {
    color: 'black',
    fontStyle: 'italic'
  }
}));

const AboutShop = ({ currentShop }) => {
  const classes = useStyles();

  const a11yProps = () =>
    currentShop && !!currentShop.about
      ? {
          defaultValue: currentShop.about
        }
      : {};

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="h3" className={classes.heading}>
          About Shop
        </Typography>
      </Grid>
      {currentShop.about && (
        <Grid item xs={12}>
          <MUIRichTextEditor
            classes={{ editor: classes.editor }}
            readOnly
            toolbar={false}
            {...a11yProps()}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default AboutShop;
