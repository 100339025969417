import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  AppBar,
  Badge,
  Hidden,
  IconButton,
  Typography,
  Switch,
  FormControlLabel,
  Chip,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Toolbar,
  Grid,
  useMediaQuery
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { useAmplifyAuth } from '../../../../context';
import { useShops } from 'context/shop/shopsContext';
import { API, graphqlOperation } from 'aws-amplify';
import { updateShop } from '../../../../graphql/mutations';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none'
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      marginRight: theme.spacing(1)
    }
  },
  headerColor: {
    color: 'white'
  },
  labelStyle: {
    marginRight: '20px'
  },
  extraToolbar: {
    minHeight: '22px'
  }
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;
  const { selectedShop, dispatch } = useShops();
  const {
    state: { user, isLaundryAdmin, isAdmin, isSales, isLaundryStaff }
  } = useAmplifyAuth();
  const classes = useStyles();
  const [notifications] = useState([]);
  const [showConfirmLiveDialog, setShowConfirmLiveDialog] = useState(false);
  const [newSwitchVal, setNewSwitchVal] = useState(
    selectedShop && selectedShop.isLive ? selectedShop.isLive : false
  );
  const { handleSignOut } = useAmplifyAuth();
  const sm = useMediaQuery(useTheme().breakpoints.up('sm'));

  const SignOut = (event) => {
    handleSignOut();
    window.location.reload();
  };

  useEffect(() => {
    if (!selectedShop && user?.shopId) {
      dispatch({
        type: 'getSelectedShop',
        payload: { shopId: user.shopId }
      });
    }
  }, [user]);

  const onSwitch = (e, newVal) => {
    setNewSwitchVal(newVal);
    setShowConfirmLiveDialog(true);
  };

  const handleChange = async () => {
    const input = {
      id: selectedShop.id,
      isLive: newSwitchVal,
      _version: selectedShop._version
    };
    try {
      const result = await API.graphql(graphqlOperation(updateShop, { input }));
      dispatch({
        type: 'updateShop',
        payload: result.data.updateShop
      });
    } catch (error) {
      console.log('error', error);
    } finally {
      setShowConfirmLiveDialog(false);
    }
  };

  const handleClose = () => setShowConfirmLiveDialog(false);

  return (
    <>
      <AppBar {...rest} className={clsx(classes.root, className)}>
        <Toolbar>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            // style={{ padding: '10px 15px' }}
          >
            <Grid item xs={12} sm={7}>
              <Typography className={classes.headerColor} variant="h3">
                {isAdmin ? (
                  <RouterLink className={classes.headerColor} to="/">
                    Admin
                  </RouterLink>
                ) : isSales ? (
                  <RouterLink className={classes.headerColor} to="/">
                    Sales
                  </RouterLink>
                ) : isLaundryAdmin || isLaundryStaff ? (
                  selectedShop && (
                    <>
                      <RouterLink className={classes.headerColor} to="/">
                        {`${selectedShop.name} (${selectedShop.shopID}) `}
                      </RouterLink>
                      {sm && (
                        <Chip
                          color="primary"
                          style={{
                            backgroundColor: selectedShop.status
                              ? selectedShop.status === 'pending'
                                ? 'orange'
                                : selectedShop.status === 'active'
                                ? 'green'
                                : 'red'
                              : 'orange',
                            fontWeight: '600'
                          }}
                          label={
                            selectedShop.status
                              ? selectedShop.status
                              : 'pending'
                          }
                        />
                      )}
                    </>
                  )
                ) : null}
              </Typography>
            </Grid>
            <Grid
              container
              justify={sm ? 'flex-end' : 'space-evenly'}
              alignItems="center"
              item
              style={{ paddingTop: sm ? 0 : '10px' }}
              sm={5}
              xs={12}>
              {!sm && (isLaundryAdmin || isLaundryStaff) && selectedShop && (
                <Chip
                  color="primary"
                  style={{
                    backgroundColor: selectedShop.status
                      ? selectedShop.status === 'pending'
                        ? 'orange'
                        : selectedShop.status === 'onhold'
                        ? 'orange'
                        : selectedShop.status === 'active'
                        ? 'green'
                        : 'red'
                      : 'orange',
                    fontWeight: '600'
                  }}
                  label={selectedShop.status ? selectedShop.status : 'pending'}
                />
              )}
              {isLaundryAdmin &&
                selectedShop &&
                selectedShop.status &&
                selectedShop.status === 'active' && (
                  <FormControlLabel
                    labelPlacement="start"
                    classes={{ labelPlacementStart: classes.labelStyle }}
                    control={
                      <Switch
                        checked={
                          selectedShop && selectedShop.isLive
                            ? selectedShop.isLive
                            : false
                        }
                        onChange={onSwitch}
                        name="isLive"
                        inputProps={{
                          'aria-label': 'topbar-shop-isLive-switch'
                        }}
                      />
                    }
                    label={
                      <Typography style={{ color: 'white' }}>
                        {selectedShop && selectedShop.isLive
                          ? 'Online'
                          : 'Offline'}
                      </Typography>
                    }
                  />
                )}
              <IconButton color="inherit">
                <Badge
                  badgeContent={notifications.length}
                  color="primary"
                  variant="dot">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <IconButton
                className={classes.signOutButton}
                color="inherit"
                onClick={SignOut}>
                <InputIcon />
              </IconButton>
              <Hidden lgUp>
                <IconButton color="inherit" onClick={onSidebarOpen}>
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Grid>
          </Grid>
        </Toolbar>
        {/* <Toolbar /> */}
      </AppBar>
      {!sm && <Toolbar classes={{ root: classes.extraToolbar }} />}
      <Dialog
        open={showConfirmLiveDialog}
        onClose={handleClose}
        aria-labelledby="confirm-live-switch-dialog-title"
        aria-describedby="confirm-live-switch-dialog-description">
        <DialogTitle id="confirm-live-switch-dialog-title">
          Are you sure you want to update the shop's status to
          <strong>
            {selectedShop && selectedShop.isLive ? ' Offline' : ' Online'}?
          </strong>
        </DialogTitle>
        <DialogActions>
          <Button size="small" onClick={handleClose} variant="outlined">
            No
          </Button>
          <Button
            size="small"
            onClick={handleChange}
            color="primary"
            variant="outlined">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
