import React, { useRef, useState, useEffect } from 'react';
import { capitalizeStr } from 'common/utilFunctions';
import {
  Button,
  Grid,
  Typography,
  IconButton,
  GridList,
  GridListTile
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CatOffers from '../assets/offersSvg.svg';
import CatDryCleaning from '../assets/dryCleaningSvg.svg';
import { useServices } from 'views/Services/serviceContext';
import { useHistory } from 'react-router-dom';
// import CatRewards from '../assets/rewardsSvg.svg';
// import CatWashFolding from '../assets/washSvg.svg';
// import CatLaundry from '../assets/laundrySvg.svg';
// import CatIroning from '../assets/ironingSvg.svg';
// import CatRinsing from '../assets/rinsingSvg.svg';
// import CatSteamWash from '../assets/steamWashSvg.svg';
// import CatSteamPress from '../assets/steamPressSvg.svg';
// import CatPremiumLaundry from '../assets/premiumLaundrySvg.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    padding: '2rem 0 0 0'
  },
  root2: {
    marginTop: '74px'
  },
  categoriesList: {
    gap: '22px',
    maxWidth: 'calc(100% - 160px)'
  },
  img: {
    height: 40,
    width: 40,
    [theme.breakpoints.up('md')]: {
      height: 58,
      width: 58
    }
  },
  button: {
    minWidth: '100px',
    width: 'fit-content'
  },
  divider: {
    backgroundColor: theme.palette.black
  },
  heading: {
    fontSize: '35px',
    fontWeight: '800'
  },
  viewBtn: {
    fontSize: '12px',
    color: theme.palette.blue.main,
    fontWeight: '500',
    marginRight: '40px'
  },
  scrollBtn: {
    maxHeight: '26px',
    maxWidth: '26px',
    boxShadow: '0px 8px 18px rgb(45 69 157 / 16%)',
    margin: '0 5px',
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.white,
      color: theme.palette.primary.main
    }
  },
  list: {
    marginTop: '24px'
  },
  cardFooter: {
    minHeight: '24px'
  },
  categoryCard: {
    backgroundColor: '#F3F7FA',
    padding: '12px',
    marginBottom: '22px',
    height: '78px'
  },
  categoryCardText: {
    fontSize: '18px',
    fontWeight: '700',
    letterSpacing: '0.115em',
    lineHeight: '20px'
  },
  navigationBreadcrumbWrapper: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: '0 3rem',
    [theme.breakpoints.up('md')]: {
      padding: 0,
      maxWidth: '90%'
    }
  }
}));

const CategoryCard = ({ item }) => {
  const classes = useStyles();
  return (
    <Grid container justify="space-between" className={classes.categoryCard}>
      <Grid item xs={9}>
        <Typography className={classes.categoryCardText}>
          {item.name}
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <img src={item.logo} alt={item.name} className={classes.img} />
      </Grid>
    </Grid>
  );
};

const CategoryList = (props) => {
  const { showAsCard } = props;
  const classes = useStyles();
  const [categoriesList, setCategoriesList] = useState([]);
  const scrollListDiv = useRef(null);
  const { services = [], dispatch: dispatchService } = useServices();
  const [postalCode, setPostalCode] = useState(null);
  const cat1 = useRef(null);
  let history = useHistory();
  const navigateTo = (value, serviceId) =>
    history.push(`/shop/postcode/${value}?filterByService=${serviceId}`);

  useEffect(() => {
    const postalAddress = localStorage.getItem('recentPostCode');
    if (postalAddress) {
      setPostalCode(postalAddress);
    }
  }, []);

  useEffect(() => {
    if (services?.length === 0) {
      dispatchService({ type: 'getService', payload: { showLoader: false } });
    }
  }, []);

  useEffect(() => {
    setCategoriesList([
      ...services.map((item) => ({
        logo: CatDryCleaning,
        name: item?.name,
        id: item.id
      }))
    ]);
  }, [services]);

  const categoryHalfLength = () =>
    (categoriesList.length % 2 === 0
      ? categoriesList.length
      : categoriesList.length + 1) / 2;

  const scrollDiv = (isLeft) => {
    const scrollWidth = isLeft
      ? cat1.current?.offsetWidth
      : -1 * cat1.current?.offsetWidth;
    scrollListDiv.current.scrollBy({
      left: scrollWidth,
      behavior: 'smooth'
    });
  };

  const serviceHandler = (id) => {
    navigateTo(postalCode, id);
  };

  return postalCode ? (
    showAsCard ? (
      <Grid container className={classes.root2}>
        <Grid item container justify="space-between" alignItems="center">
          <Typography variant="h2" className={classes.heading}>
            Category
          </Typography>
          <Grid item container xs={5} justify="flex-end" alignItems="center">
            <Button className={classes.viewBtn}>View All</Button>
            <IconButton
              onClick={() => scrollDiv(false)}
              className={classes.scrollBtn}>
              <ArrowBackIcon style={{ fontSize: '11px' }} />
            </IconButton>
            <IconButton
              onClick={() => scrollDiv(true)}
              className={classes.scrollBtn}>
              <ArrowForwardIcon style={{ fontSize: '11px' }} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          item
          justify="space-between"
          alignItems="center"
          className={classes.list}>
          <GridList
            ref={scrollListDiv}
            className="hide-scrollbar"
            style={{
              flexWrap: 'nowrap',
              transform: 'translateZ(0)',
              gap: '22px'
            }}>
            {[...Array(categoryHalfLength()).keys()].map((item) => (
              <GridListTile
                key={`search-results-card-categories-items-${item}`}
                ref={cat1}
                style={{
                  height: 'fit-content',
                  width: '230px'
                }}>
                <Grid container direction="column" justify="space-between">
                  <CategoryCard item={categoriesList[item]} />
                  <CategoryCard
                    item={categoriesList[item + categoryHalfLength()]}
                  />
                </Grid>
              </GridListTile>
            ))}
          </GridList>
        </Grid>
      </Grid>
    ) : (
      <Grid container justify="center" className={classes.root}>
        <Grid container justify="center">
          <Grid
            container
            item
            justify="flex-end"
            alignItems="center"
            style={{ width: 40 }}>
            <IconButton
              onClick={() => scrollDiv(false)}
              className={classes.scrollBtn}>
              <ArrowBackIosIcon fontSize="small" style={{ fontSize: '10px' }} />
            </IconButton>
          </Grid>
          <Grid
            container
            item
            justify="center"
            alignItems="center"
            className={classes.categoriesList}>
            <GridList
              ref={scrollListDiv}
              className="hide-scrollbar"
              style={{
                flexWrap: 'nowrap',
                transform: 'translateZ(0)',
                gap: '22px'
              }}>
              {categoriesList.map((item, index) => (
                <GridListTile
                  ref={cat1}
                  key={`shop-categories-item-${index}`}
                  style={{ width: 'fit-content', height: 'fit-content' }}>
                  <Grid
                    container
                    item
                    justify="center"
                    alignItems="center"
                    className={classes.button}>
                    <Button onClick={() => serviceHandler(item.id)}>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center">
                        <img
                          src={item.logo}
                          alt={item.name}
                          className={classes.img}
                        />
                        <Typography
                          variant="body1"
                          style={{
                            color: 'black',
                            fontWeight: 700,
                            letterSpacing: '0.115em'
                          }}>
                          {capitalizeStr(item.name)}
                        </Typography>
                      </Grid>
                    </Button>
                  </Grid>
                </GridListTile>
              ))}
            </GridList>
          </Grid>
          <Grid
            container
            item
            justify="flex-start"
            alignItems="center"
            style={{ width: 40 }}>
            <IconButton
              onClick={() => scrollDiv(true)}
              className={classes.scrollBtn}>
              <ArrowForwardIosIcon style={{ fontSize: '10px' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    )
  ) : null;
};

export default CategoryList;
