import React from 'react';
import { Image } from 'components/organisms';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    height: 'calc(100vw * 0.375)',
    [theme.breakpoints.up('md')]: {
      height: 'calc(90vw * 0.375)'
    }
  },
  image: {
    objectFit: 'contain'
  }
}));
const Template5 = ({
  content = {
    image: ''
  },
  className = ''
}) => {
  const classes = useStyles();

  return (
    <Grid container item justify="center" xs={12} className={classes.root}>
      <Image
        docKey={content?.image || ''}
        loadFromStorage={content?.image?.split('/').at(0) === 'images'}
        height="100%"
        width="100%"
        className={clsx(className, classes.image)}
      />
    </Grid>
  );
};

export default Template5;
