import React from 'react';
import { Button, Grid, Typography, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAmplifyAuth } from 'context';
import { addressAttributes } from '../constants';

const useStyles = makeStyles((theme) => ({
  addressCard: {
    borderRadius: '8px',
    border: '1px solid',
    borderLeft: `8px solid`,
    borderColor: `${theme.palette.grey.secondary} ${theme.palette.grey.secondary} ${theme.palette.grey.secondary} ${theme.palette.secondary.main}`,
    marginBottom: '10px'
  },
  addressCardTopSection: {
    padding: '0 35px 20px 35px',
    borderRadius: '8px'
  },
  addressCardText: {
    lineHeight: '28px',
    fontWeight: '500'
  },
  addressCardText2: {
    fontWeight: '400',
    lineHeight: '28px'
  },
  infoBtn: {
    backgroundColor: theme.palette.blue.main,
    color: 'white'
  },
  defaultAddressChip: {
    backgroundColor: '#4caf50',
    color: 'white',
    margin: '8px 0',
    borderRadius: '0 16px 16px 0'
  }
}));

const MyAddresses = ({
  addressList = [],
  addAddressHandler = () => {},
  handleShowAddressClick = () => {},
  handleSetDefaultAddress = () => {}
}) => {
  const classes = useStyles();
  let {
    state: { user }
  } = useAmplifyAuth();

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        style={{ margin: '10px 0' }}
        justify="flex-end">
        <Button
          onClick={addAddressHandler}
          variant="contained"
          color="secondary">
          Add New Address
        </Button>
      </Grid>
      {addressList.length > 0 ? (
        addressList.map((address) => (
          <Grid
            container
            justify="space-between"
            className={classes.addressCard}
            key={`my-addresses-items-item-${address.id}`}>
            {user && user.defaultAddress && user.defaultAddress === address.id && (
              <Grid item xs={12}>
                <Chip
                  size="small"
                  className={classes.defaultAddressChip}
                  label={'Default Address'}
                />
              </Grid>
            )}
            <Grid
              container
              justify="space-between"
              className={classes.addressCardTopSection}
              style={{
                paddingTop:
                  user &&
                  user.defaultAddress &&
                  user.defaultAddress === address.id
                    ? 0
                    : '15px'
              }}>
              <Grid container item xs={12} md={8}>
                <Grid
                  container
                  direction="column"
                  item
                  xs={3}
                  alignItems="flex-start">
                  {Object.entries(addressAttributes).map(
                    ([key, value]) =>
                      address[key] && (
                        <Typography
                          variant="h6"
                          key={`my-address-attributes-keys-${key}-${address.id}`}
                          className={classes.addressCardText}>
                          {value}
                        </Typography>
                      )
                  )}
                </Grid>
                <Grid
                  container
                  direction="column"
                  item
                  xs={6}
                  alignItems="flex-start">
                  {Object.entries(addressAttributes).map(
                    ([key]) =>
                      address[key] && (
                        <Typography
                          key={`my-address-attributes-values-${key}-${address.id}`}
                          variant="h6"
                          className={classes.addressCardText2}>
                          {address[key] || ''}
                        </Typography>
                      )
                  )}
                </Grid>
              </Grid>
              <Grid
                container
                item
                direction="column"
                xs={12}
                md={3}
                justify="center"
                alignItems="flex-end">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleShowAddressClick(address)}
                  className={classes.infoBtn}>
                  SHOW DETAILS
                </Button>

                <Button
                  variant="contained"
                  fullWidth
                  disabled={user?.defaultAddress === address?.id}
                  onClick={handleSetDefaultAddress(address?.id)}
                  style={{ marginTop: '1rem' }}>
                  Set As Default
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ))
      ) : (
        <Grid container justify="center">
          No saved addresses found!
        </Grid>
      )}
    </Grid>
  );
};

export default MyAddresses;
