import React, { useState, useEffect } from 'react';
import OrderDetailsDialog from './components/dialogs/OrderDetailsDialog';
import OrdersTable from './components/OrdersTable';
import { TabPanel } from 'components/molecules';
import { CustomSearchBar } from 'components/organisms';
import { routes } from 'common/constants';
import { useAmplifyAuth } from 'context';
import { useOrders } from 'context/order/ordersContext';
import {
  TextField,
  Typography,
  Grid,
  IconButton,
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const statusByTab = [
  ['cancel', 'declined', 'delivered'],
  ['declined'],
  ['delivered']
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: '12px'
  },
  title: {
    margin: '25px 0 10px'
  }
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const OrderHistory = () => {
  const classes = useStyles();
  const { dispatch } = useOrders();
  const [dialogType, setDialogType] = useState(null);
  const [payload, setPayload] = useState({});
  const [value, setValue] = React.useState(0);
  const [status, setStatus] = useState(statusByTab[0]);
  const [searchBy, setSearchBy] = React.useState('');
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const {
    state: { user }
  } = useAmplifyAuth();

  useEffect(() => {
    setStatus(statusByTab[value]);
  }, [value]);

  const loadOrders = (useSearchString = true, newQuery = true) => {
    const { shopId, shopID } = user;
    if (shopId || shopID) {
      const payload = {
        shopId: shopID || shopId,
        status,
        orderId: useSearchString ? searchBy : '',
        newQuery
      };
      if (!!dateRange.startDate && !!dateRange.endDate)
        payload['createdDateRange'] = dateRange;

      dispatch({ type: 'getOrders', payload });
    }
  };

  useEffect(() => {
    loadOrders();
  }, [user, status]);

  useEffect(() => {
    if (
      (!!dateRange.startDate && !!dateRange.endDate) ||
      (!dateRange.startDate && !dateRange.endDate)
    )
      loadOrders();
  }, [dateRange]);

  const onSearchChange = (event) => {
    if (event.key === 'Enter') loadOrders();
    else setSearchBy(event.target.value.toLowerCase());
  };

  const onSearchClear = () => {
    setSearchBy('');
    loadOrders(false);
  };

  const handleChange = (event, newValue) => setValue(newValue);

  const updateOrder = (order, sts) => {
    setPayload({ order, sts });
    setDialogType(sts);
  };

  const handleDateChange = (e) =>
    setDateRange({ ...dateRange, [e.target.name]: e.target.value });

  const ordersTableCommonProps = {
    showMessages: false,
    updateOrder,
    loadOrders
  };

  return (
    <div className={classes.root}>
      <Grid container justify="space-between">
        <Grid container item xs={3} justify="flex-start" alignItems="center">
          <IconButton
            component="a"
            href={routes.serviceProvider.ordersNew}
            variant="outlined"
            size="small">
            <ArrowBackIcon />
          </IconButton>
          <Typography style={{ marginLeft: '1.5rem' }} variant="h5">
            Order History
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <CustomSearchBar
            value={searchBy}
            onChange={onSearchChange}
            placeholder="Order No / Name"
            onClear={onSearchClear}
            onSearch={() => loadOrders()}
          />
        </Grid>
      </Grid>
      <AppBar
        position="static"
        style={{
          backgroundColor: 'transparent',
          color: '#263238',
          marginTop: '2rem',
          paddingBottom: '1rem',
          boxShadow: 'none'
        }}>
        <Grid container justify="space-between" direction="row">
          <Grid container item xs={6} justify="flex-start">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example">
              <Tab label="All" {...a11yProps(0)} />
              <Tab label="Declined " {...a11yProps(1)} />
              <Tab label="Delivered" {...a11yProps(2)} />
            </Tabs>
          </Grid>
          <Grid container item xs={6} justify="flex-end" alignItems="center">
            <TextField
              type="date"
              size="small"
              variant="outlined"
              name="startDate"
              value={dateRange.startDate}
              onChange={handleDateChange}
            />
            <Typography style={{ margin: '1rem' }}>to</Typography>
            <TextField
              size="small"
              variant="outlined"
              name="endDate"
              type="date"
              value={dateRange.endDate}
              onChange={handleDateChange}
            />
          </Grid>
        </Grid>
      </AppBar>
      <TabPanel value={value} index={0}>
        <OrdersTable stage={7} {...ordersTableCommonProps} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrdersTable stage={8} {...ordersTableCommonProps} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <OrdersTable stage={9} {...ordersTableCommonProps} />
      </TabPanel>

      <OrderDetailsDialog
        dialogType={dialogType}
        order={payload.order}
        setDialogType={setDialogType}
      />
    </div>
  );
};

export default OrderHistory;
