import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  Grid,
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  TextField
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginRight: theme.spacing(1),
    width: 200
  },
  days: {
    minWidth: 150,
    display: 'inline-block'
  },
  toggleButton: {
    borderRadius: '50% !important',
    color: '#5f6368 !important',
    border: '1px solid #5f6368 !important',
    backgroundColor: '#e0e0e0 !important',
    margin: '10px 15px  !important',
    height: 50,
    width: 50
  },
  toggleButtonSelected: {
    color: '#1967d2!important',
    borderColor: '#174ea6 !important',
    backgroundColor: '#e8f0fe !important'
  }
}));

const WorkingHoursEditPanel = (props) => {
  const {
    setCanEdit,
    selectedDay,
    type: hrsType,
    hours = [],
    setSelectedShop,
    selectedShop,
    setDisabled = () => {},
    workingDays = []
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const selectedHours =
    hours?.filter((obj) => obj?.openDay === selectedDay) || [];
  const [days, setDays] = React.useState(
    (!isNaN(selectedDay) && [selectedDay]) || []
  );
  const [error, setError] = useState('Enter Valid Hours');
  const [hoursArr, setHoursArr] = React.useState(
    selectedHours.length !== 0 ? selectedHours : [{}]
  );
  const [type, setType] = React.useState(0);
  const classes = useStyles();
  const daysArr = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ];

  useEffect(() => {
    selectedHours.length === 0 && setStatusType(2);
    selectedHours.length === 1 &&
      selectedHours[0].closeTime === null &&
      setStatusType(1);
  }, []);

  const validateOpeningTime = (arr) => {
    for (let i = 1; i < arr.length; i++) {
      if (arr[i].openTime < arr[i - 1].closeTime) return false;
    }
    return true;
  };
  const timeFormatter = (intValue) => {
    if (!intValue) return intValue;
    const str = ('0000' + intValue).slice(-4);
    const hours = str.slice(0, 2);
    const minutes = str.slice(2);
    return hours + ':' + minutes;
  };
  const setStatusType = (v) => {
    if (type === v) {
      setType(0);
    } else {
      setType(v);
    }
  };
  const changeHandler = (obj, i) => {
    setHoursArr([
      ...hoursArr.map((h, pos) => {
        if (pos === i) {
          return { ...h, ...obj };
        }
        return h;
      })
    ]);
  };
  const removeHandler = (i) => {
    setHoursArr([...hoursArr.filter((h, pos) => pos !== i)]);
  };
  const updateHours = () => {
    const _validate = hoursArr.every(
      ({ closeTime, openTime }) => closeTime && openTime && closeTime > openTime
    );

    if (!_validate && type === 0) {
      enqueueSnackbar(
        <div>
          <b>{error}</b>
          {' : Closing time can not be earlier than opening time.'}
        </div>,

        {
          variant: 'error',
          preventDuplicate: true
        }
      );
      return;
    } else {
      if (!validateOpeningTime(hoursArr) && type === 0) {
        enqueueSnackbar(
          <div>
            <b>{error}</b>
            {' : Opening time can not be earlier than previous closing time.'}
          </div>,
          {
            variant: 'error',
            preventDuplicate: true
          }
        );
        return;
      }
    }

    let unModifiedHours =
      hours?.filter((hour) => !days.includes(hour.openDay)) || [];
    let modifiedHours = [];

    days.forEach((i) => {
      if (type === 0) {
        hoursArr.forEach(({ closeTime, openTime }) => {
          let obj = {
            openTime: parseInt(openTime, 10),
            openDay: i,
            closeTime: parseInt(closeTime, 10),
            closeDay: i
          };
          modifiedHours.push(obj);
        });
      } else if (type === 1) {
        let obj = {
          openTime: 0,
          openDay: i
        };
        modifiedHours.push(obj);
      }
    });
    setSelectedShop({
      ...selectedShop,
      [hrsType]: [...unModifiedHours, ...modifiedHours]
    });
    setDisabled(false);
    setCanEdit(false);
  };

  const renderHours = ({ closeTime, openTime }, i) => {
    return (
      <Grid key={i} item xs={12}>
        <TextField
          label="Open Time"
          type="time"
          value={timeFormatter(openTime) || ''}
          className={classes.textField}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            step: 300 // 5 min
          }}
          onChange={(e) =>
            changeHandler({ openTime: e?.target?.value?.replace(':', '') }, i)
          }
        />
        <TextField
          variant="outlined"
          label="Close Time"
          value={timeFormatter(closeTime) || ''}
          type="time"
          className={classes.textField}
          onChange={(e) =>
            changeHandler({ closeTime: e?.target?.value?.replace(':', '') }, i)
          }
          InputLabelProps={{
            shrink: true
          }}
          inputProps={{
            step: 300 // 5 min
          }}
        />
        <IconButton
          aria-label="delete"
          onClick={() => {
            removeHandler(i);
          }}>
          <CloseIcon fontSize="default" />
        </IconButton>
      </Grid>
    );
  };

  return (
    <Dialog open={true} onClose={() => {}}>
      <DialogTitle>Select days & time </DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12}>
            <ToggleButtonGroup
              onChange={(e, newFormats) => setDays(newFormats)}
              value={days}
              aria-label="text alignment">
              {daysArr.map((day, i) =>
                workingDays.length === 0 ||
                workingDays.some((el) => el === i) ? (
                  <ToggleButton
                    classes={{
                      root: classes.toggleButton,
                      selected: classes.toggleButtonSelected
                    }}
                    value={i}
                    key={i}
                    aria-label={day}>
                    {day.slice(0, 3)}
                  </ToggleButton>
                ) : null
              )}
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={type === 1}
                  onChange={() => {
                    setStatusType(1);
                  }}
                  name="open_24_hours"
                />
              }
              label="Open 24 hours"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={type === 2}
                  onChange={() => {
                    setStatusType(2);
                  }}
                  name="closed"
                />
              }
              label="Closed"
            />
          </Grid>
          {!type && (
            <>
              {hoursArr.map((o, i) => renderHours(o, i))}
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setHoursArr([...hoursArr, {}]);
                  }}>
                  addHours
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setCanEdit(false);
          }}
          color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={days.length === 0 || (type === 0 && hoursArr.length === 0)}
          onClick={updateHours}
          color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkingHoursEditPanel;
