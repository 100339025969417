import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ProductsRow from './ProductsRow';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  autoComplete: {
    margin: theme.spacing(1),
    minWidth: 500
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  paper: {
    padding: 10
  },
  footer: {
    flexGrow: 1,
    padding: 10
  },
  amount: {
    width: 60,
    '& input': {
      padding: '10.5px 8px'
    }
  },
  column: {
    maxWidth: 100
  },
  imgHolder: {
    width: 38,
    height: 38,
    textAlign: 'center',
    border: '1px solid #bbb',
    marginRight: 10
  },
  image: {
    maxHeight: 36,
    maxWidth: 36
  },
  imageColumn: {
    width: 50
  }
}));

const ProductsTable = ({
  services,
  products,
  dispatch,
  initialProducts,
  updateInitialProducts,
  disabled,
  setDisabled,
  items
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const [priceHelper, setPriceHelper] = useState('');

  const onCancel = () => {
    dispatch({
      type: 'resetProducts',
      payload: initialProducts
    });
    setDisabled(true);
  };

  const onSubmit = () => {
    const serviceIDs = services
      .map((item) => item?.id || '')
      .filter((item) => !!item);

    if (
      Object.values(products).some(
        (product) =>
          product.enabled &&
          serviceIDs.includes(product.serviceID) &&
          !(product?.unit && product?.price)
      )
    ) {
      enqueueSnackbar('Enter valid product prices and units', {
        variant: 'error',
        persist: false,
        preventDuplicate: true
      });
      setPriceHelper('enter valid product prices');
    } else {
      updateInitialProducts();
      dispatch({
        type: 'saveProducts'
      });
      setDisabled(true);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell>Image</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Item Description</TableCell>
            <TableCell align="center" className={classes.column}>
              Amount
            </TableCell>
            <TableCell align="center" className={classes.column}>
              Units
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products !== undefined &&
            items.map((row, i) => (
              <ProductsRow
                dispatch={dispatch}
                key={`product_${row.serviceID}_${i}`}
                product={products[row.id]}
                products={products}
                row={row}
                setDisabled={setDisabled}
                priceHelper={priceHelper}
              />
            ))}
        </TableBody>
      </Table>

      <div className={classes.footer}>
        <Grid container justify="flex-end">
          <Grid item>
            <Button
              onClick={onCancel}
              style={{ marginRight: '1rem' }}
              disabled={disabled}
              variant="outlined">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              onClick={onSubmit}
              disabled={disabled}
              variant="contained">
              Save
            </Button>
          </Grid>
        </Grid>
      </div>
    </TableContainer>
  );
};

export default ProductsTable;
