import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import SearchShop from 'components/SearchShop';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '15px'
  },
  inputContainer: {
    padding: '0.5rem'
  }
}));

const AdssToolbar = (props) => {
  const classes = useStyles();

  const {
    className = '',
    handleClick = () => {},
    filters = {},
    handleFilterUpdate = () => {},
    handleResetFilters = () => {},
    showResetButton = false,
    ...rest
  } = props;

  const handleSelectChange = (event, newValue) =>
    handleFilterUpdate({ target: { name: 'shop', value: newValue } });

  const handleDateRangeUpdate = (e) =>
    handleFilterUpdate({
      target: {
        name: 'dateRange',
        value: { ...filters.dateRange, [e.target.name]: e.target.value }
      }
    });

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      {...rest}
      className={clsx(classes.root, className)}>
      <Grid item xs={6} className={classes.inputContainer}>
        {showResetButton ? (
          <Grid item xs={12} sm={6} lg={3}>
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={handleResetFilters}
              fullWidth>
              reset filters
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Grid
        item
        xs={6}
        container
        justify="flex-end"
        className={classes.inputContainer}>
        <Grid item xs={12} sm={4} lg={2}>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={handleClick}
            fullWidth>
            Add Ads
          </Button>
        </Grid>
      </Grid>

      {/* shop search filter */}
      <Grid item xs={12} sm={6} lg={3} className={classes.inputContainer}>
        <SearchShop
          value={filters.shop}
          onChange={handleSelectChange}
          name="shop"
        />
      </Grid>

      {/* ad date range filter */}
      <Grid
        container
        item
        xs={12}
        sm={6}
        lg={5}
        justify="flex-end"
        alignItems="center"
        className={classes.inputContainer}>
        <Grid item xs={5}>
          <TextField
            type="date"
            size="small"
            name="start"
            variant="outlined"
            value={filters.dateRange.start}
            onChange={handleDateRangeUpdate}
            fullWidth
          />
        </Grid>
        <Grid item xs={2} container justify="center" alignItems="center">
          <Typography>to</Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            size="small"
            variant="outlined"
            type="date"
            name="end"
            value={filters.dateRange.end}
            onChange={handleDateRangeUpdate}
            fullWidth
          />
        </Grid>
      </Grid>

      {/* ad type filter */}
      <Grid
        item
        xs={12}
        sm={6}
        lg={2}
        container
        justify="flex-end"
        className={classes.inputContainer}>
        <TextField
          fullWidth
          size="small"
          name="type"
          label="Types"
          select
          onChange={handleFilterUpdate}
          value={filters.type}
          variant="outlined">
          <MenuItem value="all">
            <i>All</i>
          </MenuItem>
          <MenuItem value="default">Default Ads</MenuItem>
          <MenuItem value="shop">Shop Ads</MenuItem>
        </TextField>
      </Grid>

      {/* ad validity filter */}
      <Grid
        item
        xs={12}
        sm={6}
        lg={2}
        container
        justify="flex-end"
        className={classes.inputContainer}>
        <TextField
          fullWidth
          size="small"
          name="showCurrentAds"
          select
          onChange={handleFilterUpdate}
          value={filters.showCurrentAds}
          variant="outlined">
          <MenuItem value={true}>Current Ads</MenuItem>
          <MenuItem value={false}>Expired Ads</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
};

AdssToolbar.propTypes = {
  className: PropTypes.string
};

export default AdssToolbar;
