import React from 'react';
import { Switch, Redirect, Route, BrowserRouter } from 'react-router-dom';
import {
  Main as MainLayout,
  NonLoggedIn,
  MinimalWithRecaptcha
} from './layouts';
import { routes } from 'common/constants';
import {
  Dashboard,
  Products,
  Customers,
  Users,
  AddUser,
  Shops,
  LaundryShop,
  Services,
  AdsList,
  DummyOrder,
  Orders,
  ServiceSettings,
  Settings,
  Invoices,
  Drivers,
  Home,
  ShopList,
  ShopDetail,
  Login,
  NotFound,
  Review,
  Profile,
  Account,
  Chat,
  SalesAndMarketing,
  PhotoLibrary,
  Insights,
  SearchBanner,
  OrderHistory,
  Coupons,
  AdminLogin,
  VerifyEmail,
  SalesDashboard
} from './views';
import WrapWithLayout from 'components/organisms/WrapWithLayout';
import ProtectedRoute from 'components/organisms/ProtectedRoute';
import WrapWithProtectedLayout from 'components/organisms/WrapWithProtectedLayout';
import OrderDetails from 'views/Customers/OrderDetails';
import { Staff } from 'views/ServiceSettings/components';
import '@aws-amplify/ui/dist/style.css';

const ProppedRoute = ({ render: C, props: childProps, ...rest }) => (
  <Route {...rest} render={(rProps) => <C {...rProps} {...childProps} />} />
);

const protectedRoutes = [
  { path: routes.admin.dashboard, component: Dashboard },
  { path: routes.sales.dashboard, component: SalesDashboard },
  { path: routes.admin.products, component: Products },
  { path: routes.sales.products, component: Products },
  { path: routes.admin.insights, component: Insights },
  { path: routes.admin.searchBanner, component: SearchBanner },
  { path: [routes.admin.settings, routes.sales.settings], component: Settings },
  {
    path: routes.admin.addUser,
    component: AddUser
  },
  {
    path: [routes.admin.users, routes.admin.usersUser],
    component: Users
  },
  { path: routes.admin.ads, component: AdsList },
  { path: routes.admin.salesMarketing, component: SalesAndMarketing },
  { path: routes.admin.createOrder, component: DummyOrder },
  {
    path: [
      routes.admin.shops,
      routes.admin.shopsAction,
      routes.admin.shopsActionWithID
    ],
    component: Shops
  },
  {
    path: [
      routes.sales.shops,
      routes.sales.shopsAction,
      routes.sales.shopsActionWithID
    ],
    component: Shops
  },
  { path: routes.admin.services, component: Services },
  { path: routes.admin.photoLibrary, component: PhotoLibrary },
  {
    path: routes.serviceProvider.dashboard,
    component: SalesDashboard
  },
  {
    path: [
      routes.serviceProvider.shop,
      routes.serviceProvider.shopDetail,
      routes.serviceProvider.shopWorkingHours,
      routes.serviceProvider.shopCollectionDeliveryHours,
      routes.serviceProvider.shopInstructions,
      routes.serviceProvider.shopDocuments,
      routes.serviceProvider.shopDocumentsAdd,
      routes.serviceProvider.shopPhotos,
      routes.serviceProvider.shopPhotosAdd,
      routes.serviceProvider.shopMap,
      routes.serviceProvider.shopAbout
    ],
    component: LaundryShop
  },
  { path: routes.serviceProvider.productsPricing, component: Products },
  {
    path: [
      routes.serviceProvider.manageStaff,
      routes.serviceProvider.manageStaffAdd
    ],
    component: Staff
  },
  {
    path: [
      routes.serviceProvider.settings,
      routes.serviceProvider.settingsProfile,
      routes.serviceProvider.settingsPassword
    ],
    component: ServiceSettings
  },
  { path: routes.serviceProvider.drivers, component: Drivers },
  { path: routes.serviceProvider.invoices, component: Invoices },
  { path: routes.admin.customers, component: Customers },
  { path: routes.admin.customerDetail, component: Customers },
  {
    path: routes.admin.customerOrderDetail,
    component: OrderDetails
  },
  {
    path: [
      routes.serviceProvider.orders,
      routes.serviceProvider.ordersNew,
      routes.serviceProvider.orderDelivery,
      routes.serviceProvider.orderItemPricing,
      routes.serviceProvider.orderProcessing
    ],
    component: Orders
  },
  {
    path: [routes.serviceProvider.orderHistory],
    component: OrderHistory
  },
  {
    path: [
      routes.serviceProvider.coupons,
      routes.serviceProvider.couponsAction
    ],
    component: Coupons
  }
];

const unProtectedRoutes = [
  { path: '/not-found', component: NotFound, layout: NonLoggedIn },
  {
    path: routes.customer.login,
    component: Login,
    layout: MinimalWithRecaptcha
  },
  { path: routes.customer.home, component: Home, layout: NonLoggedIn },
  {
    path: routes.customer.shops,
    component: ShopList,
    layout: NonLoggedIn
  },
  {
    path: routes.customer.shopDetail,
    component: ShopDetail,
    layout: NonLoggedIn
  }
];

const protectedUserRoutes = [
  { path: routes.customer.profile, component: Profile, layout: NonLoggedIn },
  { path: routes.customer.checkout, component: Review, layout: NonLoggedIn },
  {
    path: [routes.customer.order, routes.customer.orderHistory],
    component: Account,
    layout: NonLoggedIn
  },
  { path: routes.customer.chat, component: Chat, layout: NonLoggedIn }
];

const Routes = ({ childProps }) => (
  <BrowserRouter>
    <Switch>
      <Redirect exact from="/" to={routes.customer.home} />
      <ProppedRoute path="/auth" props={childProps} render={AdminLogin} />
      <ProppedRoute
        path="/verify-email"
        props={childProps}
        render={VerifyEmail}
      />
      {protectedRoutes.map((prop, key) => (
        <ProtectedRoute
          exact
          key={key}
          layout={prop.layout || MainLayout}
          path={prop.path}
          props={childProps}
          render={() => <prop.component />}
        />
      ))}
      {protectedUserRoutes.map((prop, key) => (
        <Route
          exact
          key={key}
          path={prop.path}
          props={childProps}
          render={(props) => (
            <WrapWithProtectedLayout
              component={prop.component}
              layout={prop.layout}
              rest={props}
            />
          )}
        />
      ))}
      {unProtectedRoutes.map((prop, key) => (
        <Route
          exact
          key={key}
          path={prop.path}
          props={childProps}
          render={(props) => WrapWithLayout(prop.component, prop.layout, props)}
        />
      ))}
      <Redirect to="/not-found" />
    </Switch>
  </BrowserRouter>
);
export default Routes;
