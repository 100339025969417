import React, { useState, useCallback, useContext } from 'react';
import ActionButtons from '../../../../../LaundryShop/components/ActionButtons';
import { DocumentUploadDropzone } from 'components/organisms';
import { Storage, graphqlOperation, API } from 'aws-amplify';
import { StaffIdContext } from 'context/StaffIdContext';
import { useSnackbar } from 'notistack';
import { updateUser } from 'graphql/mutations';
import { Grid, Typography } from '@material-ui/core';
import { getFileUploadKey } from 'common/utilFunctions';
import { useLoader } from 'layouts/loaderContext';

Storage.configure({ level: 'protected' });

function StaffDocumentationDropzone({
  onClose = () => {},
  setSelectedStaff = () => {}
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const staffId = useContext(StaffIdContext);
  const { setLoading } = useLoader();

  const onDrop = useCallback(
    (acceptedFiles) => {
      setSelectedFiles([
        ...selectedFiles,
        ...acceptedFiles.map((file) => ({
          file,
          name: '',
          documentType: '',
          description: ''
        }))
      ]);
    },
    [selectedFiles]
  );

  const handleChange = (id) => (e) =>
    setSelectedFiles(
      selectedFiles.map((file, index) =>
        index === id ? { ...file, [e.target.name]: e.target.value } : file
      )
    );

  const onFileClose = (id) =>
    setSelectedFiles(selectedFiles.filter((item, index) => index !== id));

  const onCancel = () => setSelectedFiles([]);

  const onSubmit = async (event) => {
    event.preventDefault();
    if (
      selectedFiles.length === 0 ||
      selectedFiles.some((item) => !(item.name && item.documentType))
    ) {
      return enqueueSnackbar('All fields are mandatory', {
        variant: 'warning',
        preventDuplicate: true
      });
    }
    let sBar = enqueueSnackbar('Saving Documents...', {
      variant: 'info',
      preventDuplicate: true
    });
    setLoading(true);
    const allUrls = [];
    const uploadedFiles = selectedFiles.map(
      async ({ file, name, documentType, description }) => {
        const { type: mimeType } = file;
        const key = getFileUploadKey(file, 'documents');
        return Storage.put(key, file, {
          contentType: mimeType
        })
          .then(() => ({
            name,
            documentType,
            description,
            documentURL: key,
            MIMETypes: mimeType
          }))
          .catch((error) => {
            console.log('error', error);
            return null;
          });
      }
    );

    try {
      const results = await Promise.allSettled(uploadedFiles);
      results.forEach(
        (item) => item.status === 'fulfilled' && allUrls.push(item.value)
      );
      const data = await API.graphql(
        graphqlOperation(updateUser, {
          input: { documents: allUrls, id: staffId.staffId }
        })
      );
      if (data) {
        setSelectedStaff(data.data.updateUser);
        closeSnackbar(sBar);
        enqueueSnackbar('Documents updated successfully', {
          variant: 'success',
          preventDuplicate: true
        });
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Something went wrong...', {
        variant: 'error',
        preventDuplicate: true
      });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <Grid container>
      <Grid container>
        <Typography style={{ marginBottom: '0.5rem' }} variant="h6">
          Upload staff Documents
        </Typography>
      </Grid>
      <DocumentUploadDropzone
        onDrop={onDrop}
        selectedFiles={selectedFiles}
        onClose={onFileClose}
        handleChange={handleChange}
      />
      <ActionButtons
        disabled={selectedFiles.length === 0}
        handleSubmit={onSubmit}
        handleCancel={onCancel}
      />
    </Grid>
  );
}

export default StaffDocumentationDropzone;
