import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
  useState
} from 'react';
import shopsReducer from './shopsReducer';
import {
  loadShops,
  loadShopsWithProductByID,
  updateShopData
} from './shopsAPIs';
import { useLoader } from '../../layouts/loaderContext';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import {
  getShop,
  searchShops,
  getShopWithoutRangeByPostalCode
} from 'graphql/queries';
import { getShopByPostalCode, shopQueryItems } from 'graphql/customQueries';

const ShopsContext = createContext({});

const ShopsProvider = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [shops, dispatch] = useReducer(shopsReducer, []);
  const [selectedShop, setSelectedShop] = useState(null);
  const [nextPageToken, setNextPageToken] = useState('');
  const [currentPostCode, setCurrentPostCode] = useState('');
  const [currentRange, setCurrentRange] = useState('');
  const [getShopPayload, setGetShopPayload] = useState({
    newQuery: false,
    limit: 100,
    salesUser: null,
    searchString: null,
    status: null,
    dateRangeData: null
  });
  const { setLoading } = useLoader();

  // TODO remove API calls from context

  const asyncDispatch = useCallback(
    async (action) => {
      switch (action.type) {
        case 'getShops': {
          if (
            Object.keys(action.payload).length ===
              Object.keys(getShopPayload).length &&
            Object.keys(action.payload).every(
              (el) => getShopPayload[el] === action?.payload[el]
            )
          ) {
            return;
          }
          setGetShopPayload(action?.payload);
          setLoading(true);
          const snackBar = enqueueSnackbar('Shops are loading...', {
            variant: 'info',
            persist: true
          });

          const {
            newQuery = false, // send this as true to ignore next page token
            limit = 100,
            salesUser = '',
            searchString = '',
            status = '',
            dateRangeData = ''
          } = action?.payload || {};

          let data;
          let nextToken = '';

          const callSearchShops =
            salesUser || searchString || status || dateRangeData;

          if (callSearchShops) {
            const input = {
              searchString,
              createdBy: salesUser
            };
            if (status) input['status'] = status;
            if (dateRangeData) input['createdDateRange'] = dateRangeData;
            if (!newQuery) input['nextToken'] = nextPageToken;

            try {
              const resp = await API.graphql(
                graphqlOperation(searchShops, input)
              );
              data = resp.data.searchShops.items;
              nextToken = resp.data.searchShops.nextToken;
            } catch (error) {
              console.error('error', error);
              enqueueSnackbar('Something went wrong!!!', {
                variant: 'error',
                autoHideDuration: 2000
              });
            }
          } else {
            const resp = await loadShops(limit, newQuery ? '' : nextPageToken);
            data = resp.shops;
            nextToken = resp.nextToken;
          }

          if (!newQuery) {
            dispatch({
              type: 'addShops',
              payload: data
            });
          } else {
            dispatch({
              type: 'updateData',
              payload: data
            });
          }
          setNextPageToken(nextToken);
          setLoading(false);
          closeSnackbar(snackBar);
        }
        case 'getShopByID': {
          setLoading(true);

          const { shopID } = action?.payload || {};
          let data;
          if (shopID) {
            data = shops.find((item) => item.shopID === shopID);
            if (!data) {
              const snackBar = enqueueSnackbar('Shops are loading...', {
                variant: 'info',
                persist: true
              });
              data = await loadShopsWithProductByID({ shopID });
              closeSnackbar(snackBar);
            }
          }
          setSelectedShop(data);
          setLoading(false);
          return data;
        }
        case 'updateShop': {
          const { payload } = action;
          setSelectedShop(payload);
          const updatedShops = shops
            .map((shop) => (shop.id === payload.id ? payload : shop))
            .filter(({ _deleted }) => !_deleted);
          dispatch({
            type: 'updateData',
            payload: updatedShops
          });
          break;
        }
        case 'getSelectedShop': {
          const { payload } = action;
          if (!payload.shopId) return;
          if (selectedShop && selectedShop.id === payload.shopId)
            return selectedShop;
          const sBar = enqueueSnackbar('Loading Shop data...', {
            variant: 'info',
            preventDuplicate: true
          });
          setLoading(true);
          return API.graphql(
            graphqlOperation(getShop, { id: payload.shopId })
          ).then((data) => {
            //TODO error catch
            if (data.data.getShop) {
              const res = data.data.getShop;
              if (res._deleted) {
                enqueueSnackbar(`Shop(${payload.shopId}) is deleted`, {
                  variant: 'error',
                  preventDuplicate: true
                });
              } else {
                setSelectedShop(res);
                closeSnackbar(sBar);
                setLoading(false);
                return res;
              }
            } else {
              enqueueSnackbar(`couldn't find Shop : ${payload.shopId}`, {
                variant: 'error',
                preventDuplicate: true
              });
            }
            closeSnackbar(sBar);
            setLoading(false);
          });
        }
        case 'getShopsByPostCode': {
          const {
            postalCode = '',
            range = 10,
            useLoader = true,
            showSnackbar = false,
            setExtraLoading = () => {}
          } = action?.payload || {};
          let snackBar;
          if (currentPostCode !== postalCode || currentRange !== range) {
            try {
              setCurrentPostCode(postalCode);
              setCurrentRange(range);
              useLoader && setLoading(true);
              setExtraLoading(true);
              if (showSnackbar) {
                snackBar = enqueueSnackbar('Shops are loading...', {
                  variant: 'info',
                  persist: true
                });
              }
              const resp = await API.graphql(
                graphqlOperation(getShopByPostalCode, {
                  postalCode,
                  range
                })
              );
              const data = resp.data.getShopByPostalCode;
              dispatch({
                type: 'updateData',
                payload: data
              });
            } catch (error) {
              console.log('error', error);
            } finally {
              useLoader && setLoading(false);
              closeSnackbar(snackBar);
              setExtraLoading(false);
            }
          }
          break;
        }
        case 'getShopWithoutRangeByPostalCode': {
          const {
            postalCode = '',
            useLoader = true,
            showSnackbar = false,
            setExtraLoading = () => {}
          } = action?.payload || {};
          let snackBar;
          if (currentPostCode !== postalCode) {
            try {
              setCurrentPostCode(postalCode);
              useLoader && setLoading(true);
              setExtraLoading(true);
              if (showSnackbar) {
                snackBar = enqueueSnackbar('Fetching shops data...', {
                  variant: 'info',
                  persist: true
                });
              }
              const resp = await API.graphql(
                graphqlOperation(getShopWithoutRangeByPostalCode, {
                  postalCode
                })
              );
              const data = resp.data.getShopWithoutRangeByPostalCode;
              dispatch({
                type: 'updateData',
                payload: data
              });
            } catch (error) {
              console.log('error', error);
            } finally {
              useLoader && setLoading(false);
              closeSnackbar(snackBar);
              setExtraLoading(false);
            }
          }
          break;
        }
        case 'getRandomShops':
          const { limit = 10 } = action?.payload || {};
          setLoading(true);
          try {
            const resp = await API.graphql(
              graphqlOperation(
                `query ListShops {
                  listShops(limit: ${limit}) {
                    items ${shopQueryItems}
                  }
                }`
              )
            );
            const data = resp.data.listShops.items;
            dispatch({
              type: 'updateData',
              payload: data
            });
          } catch (error) {
            console.log('error', error);
          } finally {
            setLoading(false);
          }
          break;
        // for updating shops in DB
        case 'updateShops':
          const { payload } = action;
          if (!payload.shops?.length) return;
          setLoading(true);
          let updatedShops = await Promise.allSettled(
            payload.shops.map((shop) =>
              updateShopData({
                _version: shop._version,
                id: shop.id,
                consentLetterCount: (shop.consentLetterCount || 0) + 1,
                consentLetterLastDate: new Date()
              })
            )
          );
          updatedShops = updatedShops
            .map((item) => (item.status === 'fulfilled' ? item.value : ''))
            .filter((item) => !!item);
          const data = shops.map((shop) => {
            const updatedData = updatedShops.find(
              (item) => item.id === shop.id
            );
            return updatedData ? updatedData : shop;
          });

          dispatch({
            type: 'updateData',
            payload: data
          });
          setLoading(false);
          break;
        default:
          dispatch(action);
      }
    },
    [shops, nextPageToken]
  );

  const value = {
    shops,
    isMoreShopsAvailable: !!nextPageToken,
    dispatch: asyncDispatch,
    selectedShop,
    setSelectedShop
  };

  return (
    <ShopsContext.Provider value={value}>
      {props.children}
    </ShopsContext.Provider>
  );
};

function useShops() {
  const context = useContext(ShopsContext);
  if (context === undefined || !Object.keys(context).length) {
    throw new Error('useShops must be used within a ShopsContext');
  }
  return context;
}

export { ShopsProvider, useShops };
