const shopItemQuery = `items {
    id
    contactName
    postcode
    about
    phoneNumber
    email
    address
    language
    town
    isLive
    websiteLink
    status
    name
    distanceRange
    minPriceForFree
    shopID
    range
    googlePlaceId
    location {
        lat
        lng
    }
    workHrs{
        closeTime
        openTime
        closeDay
        openDay
    }
    _deleted
    products {
        items {
            id
            name
            shopID
            description
            unit
            image      
            serviceID
            service {
                name
            }
            itemID
            price
            item {
                name
                image
                description
            }
            _deleted
        }
    }
    primaryPhoto{
        photoURL
        thumbURL
        name
        description
        status
        MIMETypes
    }
    photos {
        name
        photoURL
        thumbURL
        description
        MIMETypes
        status
    }
    videos {
        name
        videoURL
        autoPlay
        thumbURL
        description
        MIMETypes
        status
    }
    reviews {
        items {
            id
            shopID
            userID
            user {
                id
                firstName
                username
                lastName
                picture
            }
            title
            category1
            category2
            category3
            category4
            category5
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
        }
        nextToken
        startedAt
    }
    coupons {
        items {
            id
            shopID
            productID
            title
            description
            startDateTime
            endDateTime
            maxUsesPerUser
            discountType
            discountAmount
            discountUnit
            conditionAmount
            conditionUnit
            createdAt
            _version
            _deleted
            _lastChangedAt
            updatedAt
        }
        nextToken
        startedAt
    }
}`;

const listShops = (limit = 100, nextToken = '') => `
    query ListShops {
        listShops(limit: ${limit}${
  !!nextToken ? `, nextToken: "${nextToken}"` : ''
}) {
            items {
                isLive
                contactName
                postcode
                phoneNumber
                email
                createdBy
                address
                town
                name
                id
                status
                shopID
                googlePlaceId
                _deleted
                _version
                consentLetterCount
                consentLetterLastDate
            }
            nextToken
        }
    }
    `;

const listShopsWithProductByID = (
  shopID = ''
) => `query ListShops { listShops(filter: {shopID: {eq: "${shopID}"}}, limit: 5000) {
        ${shopItemQuery}
    }}`;

export { listShops, listShopsWithProductByID };
