import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  TextField,
  Checkbox,
  Typography,
  InputAdornment,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import WorkingHours from '../../Shops/components/ShopDetails/WorkingHours';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { data as availableLanguage } from '../../Shops/components/ShopDetails/languages.json';
import ActionButtons from './ActionButtons';
import { useAmplifyAuth } from 'context';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const useStyles = makeStyles(() => ({
  root: {},
  languageHeader: {
    paddingBottom: 0
  }
}));

const CollectionAndDeliveryHours = (props) => {
  const {
    validation,
    className,
    google,
    value,
    index,
    shopId,
    pageType,
    formErrors,
    onCancel,
    handleSubmit,
    handleChange,
    disabled,
    handleNumberChange,
    enablePriceText,
    setEnablePriceText,
    selectedShop,
    setDisabled,
    setSelectedShop,
    ...rest
  } = props;
  const classes = useStyles();
  const {
    state: { isAdmin = false, isSales = false }
  } = useAmplifyAuth();

  const minPriceFreeCheckboxHandler = () => {
    setEnablePriceText(!enablePriceText);
    setSelectedShop({
      ...selectedShop,
      minPriceForFree: ''
    });
    if (!isAdmin && !isSales) setDisabled(false);
  };

  const workingDays = selectedShop?.workHrs?.map((day) => day.openDay).sort();

  return (
    <>
      <WorkingHours
        sorryMsg="No collection"
        subheader="The information about the collection timings"
        title="Collection Hours"
        type="collectionHrs"
        hours={
          selectedShop['collectionHrs'] ? selectedShop['collectionHrs'] : []
        }
        selectedShop={selectedShop}
        setSelectedShop={setSelectedShop}
        setDisabled={setDisabled}
        workingDays={workingDays}
      />

      <Divider />

      <WorkingHours
        sorryMsg="No delivery"
        subheader="The information about the delivery timings"
        title="Delivery Hours"
        type="deliveryHrs"
        hours={selectedShop['deliveryHrs'] ? selectedShop['deliveryHrs'] : []}
        selectedShop={selectedShop}
        setSelectedShop={setSelectedShop}
        setDisabled={setDisabled}
        workingDays={workingDays}
      />

      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid item xs={12} md={8}>
              <TextField
                margin="dense"
                label="Standard collection or delivery fee"
                placeholder="Enter fee"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">£</InputAdornment>
                  )
                }}
                name="standardDeliveryFee"
                onChange={handleNumberChange}
                required
                disabled={
                  selectedShop['collectionHrs']?.length === 0 &&
                  selectedShop['deliveryHrs']?.length === 0
                }
                value={selectedShop?.standardDeliveryFee || ''}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} container alignItems="center">
            <Checkbox
              checked={enablePriceText}
              checkedIcon={checkedIcon}
              disabled={
                selectedShop['collectionHrs']?.length === 0 &&
                selectedShop['deliveryHrs']?.length === 0
              }
              icon={icon}
              onChange={minPriceFreeCheckboxHandler}
              style={{
                marginRight: 0,
                marginLeft: 0,
                padding: 0,
                verticalAlign: 'middle'
              }}
            />
            <Typography
              component="span"
              style={{ marginRight: '1rem', marginLeft: '0.5rem' }}
              variant="body1">
              Free collection and delivery for order amount starting from
            </Typography>
            <TextField
              disabled={
                !enablePriceText ||
                (selectedShop['collectionHrs']?.length === 0 &&
                  selectedShop['deliveryHrs']?.length === 0)
              }
              // error={!(selectedShop && selectedShop.minPriceForFree)}
              margin="dense"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">£</InputAdornment>
                )
              }}
              name="minPriceForFree"
              onChange={handleNumberChange}
              style={{ width: 80 }}
              required
              value={
                selectedShop && selectedShop.minPriceForFree
                  ? selectedShop.minPriceForFree
                  : ''
              }
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item md={12} xs={12} container alignItems="center">
            <Typography
              component="span"
              variant="body1"
              style={{ marginRight: '1rem' }}>
              Collection and delivery distance range
            </Typography>
            <TextField
              style={{ width: 100 }}
              size="small"
              disabled={
                selectedShop['collectionHrs']?.length === 0 &&
                selectedShop['deliveryHrs']?.length === 0
              }
              error={!(selectedShop && selectedShop.distanceRange)}
              margin="dense"
              name="distanceRange"
              onChange={handleNumberChange}
              required
              value={
                selectedShop && selectedShop.distanceRange
                  ? selectedShop.distanceRange
                  : ''
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">miles</InputAdornment>
                )
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <FormControlLabel
              control={
                <Switch
                  checked={selectedShop?.enableAsapDelivery || false}
                  onChange={handleChange}
                  name="enableAsapDelivery"
                  color="primary"
                />
              }
              style={{ margin: 0 }}
              label="Enable / Disable ASAP delivery"
              labelPlacement="start"
            />
          </Grid>
          {selectedShop?.enableAsapDelivery && (
            <Grid item xs={12}>
              <Grid item xs={12} md={8}>
                <TextField
                  margin="dense"
                  label="ASAP fee"
                  placeholder="Enter fee"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">£</InputAdornment>
                    )
                  }}
                  name="AsapDeliveryFee"
                  onChange={handleNumberChange}
                  required
                  value={selectedShop?.AsapDeliveryFee || ''}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardHeader
        className={classes.languageHeader}
        subheader="Choose the language which the shop person speak?"
        title="Language"
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <Autocomplete
              getOptionLabel={(option) => option}
              multiple
              name="language"
              onChange={(e, newValue) =>
                handleChange({ target: { name: 'language' } }, newValue)
              }
              options={availableLanguage}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="language"
                  variant="outlined"
                />
              )}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    checked={selected}
                    checkedIcon={checkedIcon}
                    icon={icon}
                    style={{ marginRight: 8 }}
                  />
                  {option}
                </React.Fragment>
              )}
              style={{ width: 500 }}
              value={(selectedShop && selectedShop.language) || []}
              disableClearable
            />
          </Grid>
        </Grid>
      </CardContent>
      {!isAdmin && !isSales ? (
        <CardActions>
          <ActionButtons
            handleSubmit={handleSubmit}
            handleCancel={onCancel}
            disabled={disabled}
          />
        </CardActions>
      ) : null}
    </>
  );
};

export default CollectionAndDeliveryHours;
