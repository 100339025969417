import React, { useEffect, useState } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useShops } from 'context/shop/shopsContext';
import { useLoader } from 'layouts/loaderContext';

const DashboardMarker = ({ text, postalCode, area }) => {
  const [showModal, setShowModal] = useState(false);
  const [postalCodeState, setPostalCodeState] = useState('');
  const { shops = [], dispatch } = useShops();
  const { loading } = useLoader();

  useEffect(() => {
    if (postalCodeState) {
      dispatch({
        type: 'getShopWithoutRangeByPostalCode',
        payload: { postalCode: postalCodeState, showSnackbar: true }
      });
    }
  }, [postalCodeState]);

  const pendingShops = shops?.filter(
    (shop) => shop.status === 'pending' || !shop.status
  ).length;
  const activeShops = shops?.filter((shop) => shop.status === 'active').length;
  const holdShops = shops?.filter((shop) => shop.status === 'onhold').length;
  const otherShops = shops?.filter(
    (shop) =>
      shop.status !== 'pending' &&
      shop.status !== 'active' &&
      shop.status !== 'onhold' &&
      shop.status !== null
  ).length;

  const totalShops = shops?.length;

  return (
    <Grid
      container
      direction="column"
      style={{
        minWidth: '12.5rem',
        cursor: 'pointer'
      }}>
      <LocationOnIcon
        style={{ color: '#ea4335', fontSize: '32px' }}
        onClick={() => {
          setShowModal(!showModal);
          setPostalCodeState(postalCode);
        }}
      />
      {showModal && (
        <Grid
          style={{
            width: '250px',
            backgroundColor: 'white',
            boxShadow: '0 2px 7px 1px rgb(0 0 0 / 30%)',
            padding: '1rem',
            lineHeight: '1.2rem',
            zIndex: 999
          }}>
          <Typography variant="h6" style={{ marginBottom: '0.5rem' }}>
            {`Estimated shops data (${area})`}
            <Divider />
          </Typography>
          {!loading ? (
            totalShops > 0 ? (
              <>
                <Typography>
                  Total shops: &nbsp;<b>{totalShops}</b>
                </Typography>
                <Typography>
                  Active shops: &nbsp;<b>{activeShops}</b>
                </Typography>
                <Typography>
                  Pending shops: &nbsp;<b>{pendingShops}</b>
                </Typography>
                <Typography>
                  Offline shops: &nbsp;<b>{holdShops}</b>
                </Typography>
                <Typography>
                  Other shops: &nbsp;<b>{otherShops}</b>
                </Typography>
              </>
            ) : (
              <Typography>No shops available in this area</Typography>
            )
          ) : null}
        </Grid>
      )}
    </Grid>
  );
};

export default DashboardMarker;
