import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useAdTemplateStyles } from '../templateStyles';
import { Image } from 'components/organisms';
import TemplateFooter from './TemplateFooter';
import clsx from 'clsx';

const Template1 = ({
  content = {
    image: '',
    description: ''
  },
  shopName = ''
}) => {
  const classes = useAdTemplateStyles();

  return (
    <Grid
      container
      justify="space-between"
      component={Paper}
      className={clsx(classes.card, classes.shopListAdCard)}>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Image
            docKey={content?.image || ''}
            loadFromStorage={content?.image?.split('/').at(0) === 'images'}
            height="130px"
            width="100%"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" align="justify" style={{ color: '#000' }}>
            {content?.description || ''}
          </Typography>
        </Grid>
      </Grid>
      <TemplateFooter shopName={shopName} />
    </Grid>
  );
};

export default Template1;
