import React from 'react';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  Tooltip
} from '@material-ui/core';
import noImagePic from 'assets/png/no-image-available.png';
import { makeStyles } from '@material-ui/core/styles';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import DistanceIcon from '../assets/Distance.png';
import { Image } from 'components/organisms';
import {
  capitalizeStr,
  getShopTotalRating as getTotalRating,
  showPrice
} from 'common/utilFunctions';
import { useAmplifyAuth } from 'context';
import CatRewards from '../assets/rewardsSvg.svg';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  favouriteButton: {
    color: theme.palette.favourite.main,
    height: '16px',
    width: '16px'
  },
  rating: {
    fontSize: '9px',
    maxWidth: 'fit-content',
    gap: '5px'
  },
  ratingCount: {
    fontSize: '9px',
    backgroundColor: '#C4C4C45C',
    borderRadius: '50%',
    height: '23px',
    width: '23px'
  },
  cardFooter: {
    height: '2.375rem',
    paddingTop: '15px'
  },
  picture: {
    height: '122px',
    width: '218px'
  },
  shopName: {
    color: 'black',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '90%',
    overflow: 'hidden'
  },
  onHoldContainer: { position: 'relative' },
  freeCollAndDelBox: {
    backgroundColor: '#99ccee44',
    marginTop: '0.5rem',
    padding: '0.3rem',
    borderRadius: '4px'
  },
  closedText: {
    position: 'absolute',
    top: '35%',
    left: '25%',
    fontSize: '24px',
    fontWeight: '600',
    color: '#fff',
    textShadow: '2px 1px #000'
  }
}));

const currTime = new Date().toISOString();

const ShopCardContent = ({ item, serviceID = '' }) => {
  const classes = useStyles();
  let {
    state: { user }
  } = useAmplifyAuth();
  const shouldEnableShop = item.status === 'onhold' || item.isLive;
  const showOffersText =
    item.coupons?.items?.filter(
      (item) =>
        !item._deleted &&
        item.startDateTime < currTime &&
        currTime < item.endDateTime
    ).length > 0;

  return (
    <>
      <Box className={classes.onHoldContainer}>
        {item?.photos?.some((photo) => photo?.status === 'approved') ? (
          <Image
            docKey={
              item.photos.find(
                (photo) =>
                  photo?.status === 'approved' && photo?.serviceID === serviceID
              )?.photoURL ||
              item.primaryPhoto?.photoURL ||
              item.photos.find((photo) => photo?.status === 'approved')
                ?.photoURL
            }
            width="100%"
            className={classes.picture}
          />
        ) : (
          <img src={noImagePic} width="100%" className={classes.picture} />
        )}
        {shouldEnableShop ? null : (
          <Grid container className={clsx(classes.picture, classes.closedText)}>
            CLOSED
          </Grid>
        )}
      </Box>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        item
        style={{
          opacity: !shouldEnableShop ? 0.5 : 1
        }}>
        <Grid item container alignItems="center" xs={10}>
          <Tooltip title={capitalizeStr(item.name)} placement="bottom">
            <Typography variant="subtitle2" className={classes.shopName}>
              {capitalizeStr(item.name)}
            </Typography>
          </Tooltip>
        </Grid>
        {user && user.username && (
          <Grid item xs={1}>
            <FormControlLabel
              onClick={(e) => e.stopPropagation()}
              control={
                <Checkbox
                  icon={
                    <FavoriteBorder
                      fontSize="small"
                      className={classes.favouriteButton}
                    />
                  }
                  checkedIcon={
                    <Favorite
                      fontSize="small"
                      className={classes.favouriteButton}
                    />
                  }
                  name="shop-favourite-check"
                />
              }
              style={{ marginRight: 0 }}
            />
          </Grid>
        )}
      </Grid>

      <Grid
        container
        justify="flex-start"
        className={classes.cardFooter}
        alignItems="center"
        style={{
          opacity: !shouldEnableShop ? 0.3 : 1
        }}>
        {!!item.range && (
          <>
            <img src={DistanceIcon} alt="distance-icon" />
            <Typography
              variant="body2"
              align="center"
              color="secondary"
              style={{
                margin: '0 1rem 0 0.5rem'
              }}>
              {item.range > 152
                ? (item.range / 1609).toFixed(2) + ' mi'
                : Math.ceil(item.range * 3.281) + ' ft'}
            </Typography>
          </>
        )}
        {/* ---- show rating ---- */}
        {item.reviews?.items?.length > 0 && (
          <Grid
            container
            item
            justify="space-evenly"
            alignItems="center"
            className={classes.rating}>
            <Grid
              item
              container
              alignItems="center"
              justify="center"
              className={classes.ratingCount}>
              {getTotalRating(item.reviews.items)}
            </Grid>
            Rating
          </Grid>
        )}
        {/* offers text */}
        {showOffersText && (
          <Tooltip title="Offers available for this Shop" placement="bottom">
            <Grid
              container
              item
              alignItems="center"
              className={classes.rating}
              style={{ marginLeft: '0.8rem' }}>
              <img src={CatRewards} height="23" width={23} alt="O%" />
              Offers
            </Grid>
          </Tooltip>
        )}
      </Grid>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.freeCollAndDelBox}>
        <Grid item xs={9}>
          <Typography variant="body1">
            Free Collection <br /> &amp; Delivery
          </Typography>
        </Grid>
        <Grid container justify="flex-end" item xs={3}>
          <Typography variant="body1" align="right">
            {item.minPriceForFree ? (
              <>
                <strong>{showPrice(item.minPriceForFree)}</strong>
                <br /> &amp; above
              </>
            ) : (
              'Not available'
            )}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ShopCardContent;
