import { Auth, Storage } from 'aws-amplify';
import { v4 as uuid } from 'uuid';
import { num, tens } from './constants';

export const showPrice = (value = 0) =>
  typeof value === 'number' ? `£${value.toFixed(2)}` : '';

export const capitalizeStr = (str) =>
  str
    .split(' ')
    .filter((item) => item !== '')
    .map((item) => `${item[0].toUpperCase()}${item.substr(1).toLowerCase()}`)
    .join(' ');

export const getOrderTotal = (order) =>
  order.orderList?.length > 0
    ? order.orderList
        .map((item) => item.price * item.quantity - (item.overallDiscount || 0))
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        )
    : 0;

export const getOrderDiscountTotal = (order) =>
  order.orderList?.length > 0
    ? order.orderList
        .map((item) =>
          item.discount
            ? item.discount * item.quantity
            : item.overallDiscount || 0
        )
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        )
    : 0;

export const getTotalQuantity = (order) =>
  order.orderList?.length > 0
    ? order.orderList
        .map((item) => item.quantity)
        .reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          0
        )
    : 0;

export const getShopTotalRating = (items = []) =>
  items && items.length > 0
    ? (
        items
          .map(
            (item) =>
              (item.category1 +
                item.category2 +
                item.category3 +
                item.category4) /
              4
          )
          .reduce((p, c) => p + c, 0) / items.length
      ).toFixed(1)
    : 0;

export const getFileUploadKey = (file, folder = 'images') =>
  `${folder}/${uuid()}.${file.name.split('.').at(-1)}`;

export const unitFormatter = (str = '', separator = ' ') =>
  !!str
    ? `${str.charAt(0).toUpperCase()}${str.substring(1)}`.replace(
        /([a-z])([A-Z])/g,
        `$1${separator}$2`
      )
    : 'N/A';

export const getStorageUrl = async (url, accessLevel = 'public') => {
  try {
    const result = await Storage.get(url, {
      level: accessLevel
    });
    return result;
  } catch (error) {
    console.log('error', error);
    return '';
  }
};

export const isExternalUser = async () => {
  const data = await Auth.currentAuthenticatedUser();
  return !!data.attributes.identities;
};

export const getFormattedName = (user, withTitle = true) =>
  `${withTitle ? `${user?.title || ''} ` : ''}${user?.firstName || ''} ${
    user?.lastName || ''
  }`.trim();

export const uploadImage = async (file) => {
  const { type: mimeType } = file;
  const key = getFileUploadKey(file);
  try {
    await Storage.put(key, file, {
      contentType: mimeType,
      level: 'public'
    });
    return key;
  } catch (err) {
    console.error('error: ', err);
    return '';
  }
};

const inWordConvertion = (n = 0) => {
  if (n < 20) return num[n];
  const digit = n % 10;
  if (n < 100) return tens[~~(n / 10) - 2] + (digit ? '-' + num[digit] : '');
  if (n < 1000)
    return (
      num[~~(n / 100)] +
      ' hundred' +
      (n % 100 === 0 ? '' : ' ' + inWordConvertion(n % 100))
    );
  return (
    inWordConvertion(~~(n / 1000)) +
    ' thousand' +
    (n % 1000 !== 0 ? ' ' + inWordConvertion(n % 1000) : '')
  );
};

export const inWords = (n = 0) => {
  if (!isNaN(n)) {
    const convertNumberToString = n
      .toFixed(Number.isInteger(n) ? 0 : 2)
      .split('.');
    return `${inWordConvertion(parseInt(convertNumberToString[0]))} £${
      convertNumberToString.length > 1
        ? ` and ${inWordConvertion(parseInt(convertNumberToString[1]))} penny`
        : ''
    }`;
  } else {
    return '';
  }
};

export const getCollectionAndDeliveryFee = (order) =>
  showPrice(
    order?.collectionAndDeliveryFee -
      (order?.collection?.type === 'asap'
        ? order?.currentShopFees?.AsapDeliveryFee
        : 0) || 0
  );

export const getCollectionAndDeliveryFeeText = (order) =>
  `${
    !(order?.collection?.address || order?.delivery?.address)
      ? 'Collection and Delivery'
      : ''
  }${order?.collection?.address ? 'Collection' : ''}${
    order?.collection?.address && order?.delivery?.address ? ' and ' : ''
  }${order?.delivery?.address ? 'Delivery' : ''} Fee`;

export const showPartialPaymentRecord = (payments = [], total) =>
  !(payments.length === 1 && payments[0].amount === total);

export const getCustomShopProductName = (productName = '') =>
  `shopProduct_${productName.split(' ').join('_')}`;

// TODO Image compression Logic

const fileToDataUri = (field) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      resolve(reader.result);
    });
    reader.readAsDataURL(field);
  });
};

export const compressImage = async (_imageFile) => {
  const { name, type, size } = _imageFile;
  const imgToCompress = new Image();
  const originalImage2 = await fileToDataUri(_imageFile);
  imgToCompress.src = originalImage2;
  const sizeInKb = size / 1024;

  try {
    if (sizeInKb > 500) {
      return await compressImageHandler(imgToCompress, 0.5, 0.5, type, name);
    } else if (sizeInKb > 50) {
      return await compressImageHandler(imgToCompress, 0.8, 0.5, type, name);
    } else {
      return _imageFile;
    }
  } catch (e) {
    console.error(e);
    return _imageFile;
  }
};

const compressImageHandler = async (
  imgToCompress,
  resizingFactor,
  quality,
  type,
  name
) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const originalWidth = imgToCompress.width;
  const originalHeight = imgToCompress.height;

  const canvasWidth = originalWidth * resizingFactor;
  const canvasHeight = originalHeight * resizingFactor;

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  context.drawImage(
    imgToCompress,
    1,
    1,
    originalWidth * resizingFactor,
    originalHeight * resizingFactor
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          resolve(new File([blob], name));
        } else {
          reject(new Error('Erroe in compression: Blob not created!'));
        }
      },
      type,
      quality
    );
  });
};

// search a string in a list of items
export const searchStringInItems = (str = '', items = []) =>
  items.some(
    (item) =>
      item.toLowerCase().includes(str) ||
      item.toLowerCase().split(' ').join('').includes(str)
  );

export const calculatePriceWithDiscount = (price, discount, unit) => {
  let newPrice;
  try {
    newPrice =
      unit === 'amount'
        ? price - discount
        : unit === 'percentage'
        ? (price * (100 - discount)) / 100
        : price;
  } catch (error) {
    console.error('something went wrong', error);
    newPrice = price;
  }
  return newPrice;
};

export const checkCouponCondition = (
  conditionUnit = '',
  conditionAmount = 0,
  cart = [],
  productID = ''
) =>
  conditionUnit === 'noOfItems'
    ? cart.find((item) => item?.id === productID)?.quantity >= conditionAmount
    : conditionUnit === 'amount'
    ? getOrderTotal({ orderList: cart }) >= conditionAmount
    : false;
