import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Tabs, Tab } from '@material-ui/core';
import { NavigationBreadcrumbs } from 'components/organisms';
import { AddressModal, Details, MyAddresses } from './components';
import { useAmplifyAuth } from 'context';
import { Password } from '../Settings/components';
import { listAddresss } from 'graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { updateUserDetail } from 'graphql/mutations';
import { isExternalUser } from 'common/utilFunctions';
import { tabs } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'hidden',
    padding: '0 30px',
    marginBottom: '100px',
    borderTop: '1px solid #0000001A',
    [theme.breakpoints.up('md')]: {
      padding: '0 5rem'
    }
  },
  tabSelected: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white !important'
  },
  tabRoot: {
    color: theme.palette.grey.secondary,
    textTransform: 'none',
    maxWidth: '100%'
  },
  tabsRoot: {
    borderRadius: '8px',
    border: '1px solid',
    borderColor: theme.palette.grey.secondary
  },
  ordersHeader: {
    width: '100%',
    textAlign: 'left',
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    lineHeight: '0.1em',
    margin: '10px 0'
  },
  ordersHeaderText: {
    background: '#fff',
    paddingRight: '10px',
    fontWeight: '600'
  },
  pageContentBox: {
    paddingTop: '1.5rem',
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      paddingLeft: '2.125rem'
    }
  }
}));

const a11yProps = (index) => ({
  id: `vertical-tab-${index}`,
  'aria-controls': `vertical-tabpanel-${index}`
});

const Profile = () => {
  const classes = useStyles();
  const [showAddNewAddressForm, setShowAddNewAddressForm] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState(0);
  const [isGoogleUser, setIsGoogleUser] = useState(false);
  const [showAddressDetails, setShowAddressDetails] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  let {
    state: { user },
    _refreshUser
  } = useAmplifyAuth();

  useEffect(() => {
    if (user?.username) {
      isExternalUser()
        .then((res) => setIsGoogleUser(res))
        .catch((error) => console.log('error', error));
      API.graphql(
        graphqlOperation(listAddresss, {
          filter: { userID: { eq: user.username } },
          limit: 1000
        })
      )
        .then(({ data }) => {
          const _addressList = data.listAddresss.items;
          if (_addressList.length > 0) {
            setAddressList(_addressList.filter((a) => !a._deleted));
          }
        })
        .catch((error) => console.log("couldn't fetch addresses", error));
    }
  }, [user]);

  const addAddressHandler = () => {
    setShowAddNewAddressForm(true);
    setShowAddressDetails(true);
  };

  const handleShowAddressClick = (address) => () => {
    setShowAddNewAddressForm(false);
    setSelectedAddress(address);
    setShowAddressDetails(true);
  };

  const updateAddressList = (data, operation) => {
    if (operation === 'add') {
      setAddressList([...addressList, data]);
    } else {
      setAddressList(addressList.filter((a) => a.id !== data.id));
    }
    setShowAddressDetails(false);
  };

  const handleSetDefaultAddress = (id) => async () => {
    const snackBar = enqueueSnackbar('Updating default address...', {
      variant: 'info',
      persist: true
    });
    try {
      const resp = await API.graphql(
        graphqlOperation(updateUserDetail, { defaultAddress: id })
      );
      enqueueSnackbar('Address updated', {
        variant: 'success',
        autoHideDuration: 2000
      });
      _refreshUser();
      console.log('address update response', resp);
    } catch (error) {
      console.error('Something went wrong', error);
    } finally {
      closeSnackbar(snackBar);
    }
  };

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <NavigationBreadcrumbs currentLink="My Profile" />
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          style={{ minHeight: '64px' }}>
          <Typography variant="h3">My Profile</Typography>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12} md={2}>
            <Tabs
              classes={{
                root: classes.tabsRoot
              }}
              orientation="vertical"
              value={currentTab}
              onChange={(e, newVal) => setCurrentTab(newVal)}
              aria-label="my-orders-vertical-tabs">
              {tabs
                .filter((item) => !(isGoogleUser && item.id === 4))
                .map((tab) => (
                  <Tab
                    value={tab.id}
                    disabled={tab.id === 4 && isGoogleUser}
                    classes={{
                      root: classes.tabRoot,
                      selected: classes.tabSelected
                    }}
                    label={
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center">
                        <Grid
                          item
                          container
                          alignItems="center"
                          justify="space-between"
                          style={{ width: 'fit-content' }}>
                          {tab.icon}
                          &nbsp; &nbsp;
                          {tab.name}
                        </Grid>
                        <Grid
                          item
                          container
                          alignItems="center"
                          style={{ width: 'fit-content' }}>
                          <ChevronRightIcon fontSize="small" />
                        </Grid>
                      </Grid>
                    }
                    {...a11yProps(tab.id)}
                    key={`my-orders-vertical-tabs-items-${tab.id}`}
                  />
                ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={10} className={classes.pageContentBox}>
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.ordersHeader}>
                <span className={classes.ordersHeaderText}>
                  {tabs[currentTab].name}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {currentTab === 0 ? (
                <Details />
              ) : currentTab === 3 ? (
                <MyAddresses
                  addressList={addressList}
                  addAddressHandler={addAddressHandler}
                  handleShowAddressClick={handleShowAddressClick}
                  handleSetDefaultAddress={handleSetDefaultAddress}
                />
              ) : currentTab === 4 ? (
                <Password style={{ marginTop: '20px', width: '100%' }} />
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddressModal
        open={showAddressDetails}
        handleClose={() => setShowAddressDetails(false)}
        updateAddressList={updateAddressList}
        showAddNewAddressForm={showAddNewAddressForm}
        hideAddNewAddressForm={() => setShowAddNewAddressForm(false)}
        selectedAddress={selectedAddress}
      />
    </>
  );
};

export default Profile;
