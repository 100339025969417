import React, { useEffect, useState } from 'react';
import {
  Grid,
  List,
  ListItemText,
  Avatar,
  ListItem,
  Box,
  Typography,
  ListItemAvatar,
  Button,
  Input
} from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SendIcon from '@material-ui/icons/Send';
import AttachmentIcon from '@material-ui/icons/Attachment';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import { makeStyles } from '@material-ui/core/styles';
import UserMessage from './UserMessage';
import ShopMessage from './ShopMessage';
import { createMessage, updateMessage } from 'graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import { useAmplifyAuth } from 'context';
import { getMessage, getMessageByOrderID, listMessages } from 'graphql/queries';
import { onCreateMessage } from 'graphql/subscriptions';

const useStyles = makeStyles(theme => ({
  container: {
    border: '1px solid #e5e5e5',
    backgroundColor: theme.palette.white,
    boxShadow: '2px 2px #e5e5e5',

    marginLeft: '3.3rem',

    borderRadius: '8px',
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',

    ['@media(max-width: 990px)']: {
      marginLeft: '0',
      marginTop: '1rem'
    }
  },
  greenDot: {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    color: '#15CF74',
    marginRight: '4px'
  },
  chatBoxHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#FFFFFF',
    borderBottom: '1px solid #E5E5E5',
    borderRadius: '8px 8px 0px 0px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.2)'
  },
  shopName: {
    fontWeight: '700'
  },
  messageHeader: {
    height: '4rem',
    marginRight: '2rem'
  },
  messageBody: {
    backgroundColor: theme.palette.white,
    height: '25rem',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  inputBox: {
    height: '5rem',
    padding: '9px 15px 8px 9px',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: '#F5F8FE'
  },
  messageBox: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    padding: '1rem',
    overflow: 'auto'
  },
  msgReceived: {
    padding: '0.5rem 1rem',
    marginBottom: '5px',
    color: '#000',
    backgroundColor: '#eee',
    borderRadius: '15px 15px 15px 0px'
  },
  msgSent: {
    padding: '0.5rem 1rem',
    marginBottom: '5px',
    color: '#000',
    backgroundColor: '#EAFCFE',
    borderRadius: '15px 15px 0 15px'
  }
}));

const ChatMessageBox = props => {
  const { orderActive } = props;
  const classes = useStyles();
  const [messageTerm, setMessageTerm] = useState({});
  const {
    state: { user }
  } = useAmplifyAuth();

  const [userMessage, setUserMessage] = useState([{}]);

  const newUserMessageHandler = e => {
    setMessageTerm({
      id: Date.now(),
      text: e.target.value
    });
  };

  const sendHandler = async () => {
    setMessageTerm({ id: '', text: '' });
    const data = await API.graphql(
      graphqlOperation(createMessage, {
        input: {
          sender: user.id,
          orderID: orderActive.id,
          message: messageTerm.text,
          status: 'sent'
        }
      })
    );
  };
  useEffect(() => {
    API.graphql(
      graphqlOperation(getMessageByOrderID, {
        orderID: orderActive.id
      })
    ).then(({ data }) => {
      const msgs = data?.getMessageByOrderID?.items
        .map(msg => ({
          ...msg,
          text: msg.message
        }))
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setUserMessage(msgs);
    });
    // Subscribe to creation of message
    API.graphql(graphqlOperation(onCreateMessage)).subscribe({
      next: ({ provider, value }) => {
        const newMsg = value?.data?.onCreateMessage;
        if (newMsg?.orderID === orderActive.id) {
          setUserMessage(userMessage => [...userMessage, { ...newMsg,text:newMsg.message }]);
        }
      },
      error: error => console.warn(error)
    });
  }, [orderActive]);

  return (
    <Grid item className={classes.container}>
      <Box className={classes.chatBoxHeader}>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <ImageIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="h5" className={classes.shopName}>
                  #{orderActive.orderID} ({orderActive.shop?.name})
                </Typography>
              }
              secondary={
                <Typography variant="body1">
                  <FiberManualRecordIcon className={classes.greenDot} />
                  Online
                </Typography>
              }
            />
          </ListItem>
        </List>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Button variant="outlined" className={classes.messageHeader}>
            View order Details
          </Button>
          <MoreVertIcon />
        </Box>
      </Box>

      {/* message body */}
      <Box className={classes.messageBody}>
        <Box className={classes.messageBox}>
          {userMessage.map((msg, i) => (
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              className={
                user.id === msg.sender ? classes.msgSent : classes.msgReceived
              }
              key={i}>
              {msg.text}
            </Grid>
          ))}
        </Box>
        <Box className={classes.inputBox}>
          <Button>
            <AttachmentIcon
              style={{
                height: '3rem',
                color: '#808AA0',
                transform: 'rotate(-45deg)'
              }}
            />
          </Button>
          <Input
            placeholder="Enter Message..."
            style={{ width: '80%', border: 'none', outline: 'none' }}
            onChange={newUserMessageHandler}
            value={messageTerm.text}
          />
          <Button onClick={sendHandler}>
            <SendIcon
              style={{
                color: '#fff',
                backgroundColor: '#194376',
                height: '2.5rem',
                width: '2.5rem',
                borderRadius: '50%',
                padding: '6px'
              }}
            />
          </Button>
        </Box>
      </Box>
    </Grid>
  );
};

export default ChatMessageBox;
