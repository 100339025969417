import React from 'react';
import { Image } from 'components/organisms';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh'
  },
  image: {
    maxWidth: '100%',
    height: 'auto'
  }
}));

const ViewDialog = props => {
  const {
    open,
    handleCancel,
    docKey,
    accessLevel = 'public',
    mimeType = ''
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="draggable-dialog-title"
      className={classes.dialogPaper}>
      <DialogContent>
        <DialogContentText>
          <Image
            docKey={docKey}
            className={classes.image}
            accessLevel={accessLevel}
            mimeType={mimeType}
          />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ViewDialog;
