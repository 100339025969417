import React from 'react';
import PropTypes from 'prop-types';
import { routes } from 'common/constants';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Grid } from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';

const ShopsToolbar = () => {
  let { actionType } = useParams();
  const useStyles = makeStyles((theme) => ({
    root: {},
    row: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1)
    },
    spacer: {
      flexGrow: 1
    },
    importButton: {
      marginRight: theme.spacing(1)
    },
    exportButton: {
      marginRight: theme.spacing(1)
    }
  }));
  const classes = useStyles();

  return (
    <div>
      <Grid alignItems="flex-end" container justify="space-between" spacing={2}>
        <Grid item>
          <Typography gutterBottom variant="overline">
            Shop
          </Typography>
          <Typography variant="h3">
            {actionType === 'add'
              ? 'Add New Shop'
              : actionType === 'edit'
              ? 'Update Shop Details'
              : 'List of Shop Registered'}
          </Typography>
        </Grid>
        <Grid item>
          <span className={classes.spacer} />
          {actionType ? (
            <Link to={routes.admin.shops}>
              <Button color="primary" variant="contained">
                Back to Shop list
              </Button>
            </Link>
          ) : (
            <Link id="addShop" to="/admin/shops/add">
              <Button color="primary" variant="contained">
                Add Shop
              </Button>
            </Link>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

ShopsToolbar.propTypes = {
  className: PropTypes.string
};

export default ShopsToolbar;
