import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useSnackbar } from 'notistack';
import moment from 'moment';

const OrderTimeEditBar = ({
  heading = '',
  date = '',
  time = '',
  setTime,
  setDate
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isEditingTime, setIsEditingTime] = useState(false);
  const [startTime, setStartTime] = useState('00:00');
  const [endTime, setEndTime] = useState('23:59');
  const todayDate= new Date();

  useEffect(() => {
    if (!!time && time.includes('-')) {
      const [a, b] = time.split('-');
      setStartTime(moment(a, ['h:mmA']).format('HH:mm'));
      setEndTime(moment(b, ['h:mmA']).format('HH:mm'));
    }
  }, [time]);

  const startTimeHandler = e => setStartTime(e.currentTarget.value);

  const endTimeHandler = e => setEndTime(e.currentTarget.value);

  const timeSlotUpdate = () =>
    setTime(
      `${moment(startTime, ['HH:mm']).format('h:mmA')} - ${moment(endTime, [
        'HH:mm'
      ]).format('h:mmA')}`
    );

  const onSaveHandler = () => {
    setIsEditingTime(true);
    if (startTime > endTime) {
      enqueueSnackbar('Choose a valid time slot', {
        variant: 'error',
        autoHideDuration: 1000
      });
    } else {
      setIsEditingTime(false);
      timeSlotUpdate();
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      spacing={3}
      justify="flex-start"
      alignItems="center">
      <ExitToAppIcon />
      <Typography style={{ marginLeft: '0.5rem' }}>{heading}</Typography>
      <form noValidate>
        <Grid container alignItems="center">
          <TextField
            style={{
              marginLeft: '1.5rem',
              marginRight: '1.5rem'
            }}
            id="date"
            type="date"
            value={moment(date).format('YYYY-MM-DD')}
            onChange={e => setDate(e.target.value)}
            disabled={!isEditingTime}
            InputProps={{ inputProps: {min:moment(todayDate).format('YYYY-MM-DD')} }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <Typography>between</Typography>
          <TextField
            id="startTime"
            style={{ marginLeft: '1.5rem', marginRight: '1rem' }}
            type="time"
            value={startTime}
            disabled={!isEditingTime}
            InputLabelProps={{
              shrink: true
            }}
            onChange={startTimeHandler}
          />
          <Typography>to</Typography>
          <TextField
            id="endTime"
            style={{ marginLeft: '1rem', marginRight: '1rem' }}
            type="time"
            value={endTime}
            disabled={!isEditingTime}
            InputLabelProps={{
              shrink: true
            }}
            onChange={endTimeHandler}
          />
          {isEditingTime ? (
            <Button color="primary" onClick={onSaveHandler}>
              Save
            </Button>
          ) : (
            <Button
              color="primary"
              onClick={() => setIsEditingTime(!isEditingTime)}>
              Edit
            </Button>
          )}
        </Grid>
      </form>
    </Grid>
  );
};

export default OrderTimeEditBar;
