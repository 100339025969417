import React from 'react';
import {
  FormControlLabel,
  Grid,
  InputAdornment,
  RadioGroup,
  TextField,
  Typography,
  MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StyledRadio from 'components/molecules/StyledRadio';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';

const useStyles = makeStyles((theme) => ({
  hideElement: {
    display: 'none'
  },
  fomrControlLabelRoot: {
    marginRight: '5rem'
  },
  paymentYearField: {
    padding: '10px 0px',
    [theme.breakpoints.up('md')]: {
      padding: '10px 30px'
    }
  }
}));

const months = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' }
];

const PaymentOptions = ({
  paymentMethod = 'cash',
  setPaymentMethod = () => {}
}) => {
  const classes = useStyles();

  const getYears = () => {
    let resultArr = [];
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 20; i++) resultArr.push(currentYear + i);
    return resultArr;
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3">Payment Options</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '10px' }}>
        <RadioGroup
          row
          aria-label="payment-options-radio"
          name="payment-options-radio"
          value={paymentMethod}
          onChange={(e, newVal) => setPaymentMethod(newVal)}>
          <FormControlLabel
            value="card"
            classes={{ root: classes.fomrControlLabelRoot }}
            control={<StyledRadio disabled color="primary" />}
            label="Debit / Credit Card"
          />
          <FormControlLabel
            value="cash"
            classes={{ root: classes.fomrControlLabelRoot }}
            control={<StyledRadio color="primary" />}
            label="Cash"
          />
        </RadioGroup>
      </Grid>

      <Grid item xs={12}>
        {paymentMethod === 'card' ? (
          <Grid container>
            <Grid item xs={12} style={{ padding: '10px 0' }}>
              <TextField variant="outlined" fullWidth label="Card Number" />
            </Grid>
            <Grid item xs={12} style={{ padding: '10px 0' }}>
              <TextField
                variant="outlined"
                fullWidth
                label="Card Holder's Name"
              />
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: '10px 0' }}>
              <TextField
                variant="outlined"
                fullWidth
                select
                value={''}
                SelectProps={{ classes: { icon: classes.hideElement } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodayOutlinedIcon fontSize="small" />
                    </InputAdornment>
                  )
                }}
                label="Expire Month"
                type="month">
                {months.map((item) => (
                  <MenuItem
                    key={`payment-methods-card-expire-months-items-${item.value}`}
                    value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4} className={classes.paymentYearField}>
              <TextField
                variant="outlined"
                fullWidth
                select
                value={''}
                SelectProps={{ classes: { icon: classes.hideElement } }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodayOutlinedIcon fontSize="small" />
                    </InputAdornment>
                  )
                }}
                label="Expire Year"
                type="year">
                {getYears().map((item) => (
                  <MenuItem
                    key={`payment-methods-card-expire-years-items-${item}`}
                    value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4} style={{ padding: '10px 0' }}>
              <TextField variant="outlined" fullWidth label="CVV" />
            </Grid>
            <Grid item xs={12} style={{ padding: '10px 0' }}>
              <Typography variant="h6">
                Note : We will authorise your card with a pre-payment of £20.
              </Typography>
            </Grid>
          </Grid>
        ) : paymentMethod === 'cash' ? (
          <></>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default PaymentOptions;
