import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  Grid,
  TextField,
  MenuItem
} from '@material-ui/core';

const OrderCancelDialog = ({
  open,
  close,
  orderCancelReasonHandler,
  handleCancelConfirm
}) => {
  const [value, setValue] = useState('');
  const [otherValue, setOtherValue] = useState('');
  const [isOtherDecline, setIsOtherDecline] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value !== 'other') {
      orderCancelReasonHandler(event.target.value);
    }
    event.target.value === 'other'
      ? setIsOtherDecline(true)
      : setIsOtherDecline(false);
  };

  const otherDeclineReasonHandler = (event) => {
    setOtherValue(event.target.value);
    orderCancelReasonHandler(event.target.value);
  };

  const handleClose = () => {
    setValue('');
    setOtherValue('');
    close(false);
    setIsOtherDecline(false);
  };

  const handleSubmit = () => {
    handleCancelConfirm();
    close(false);
    setValue('');
    setOtherValue('');
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={handleClose}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Are you sure you want to cancel this order?
      </DialogTitle>
      <DialogContent style={{ height: 'fit-content', overflow: 'hidden' }}>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{ marginBottom: '0.5rem', marginTop: '1.5rem' }}>
            <TextField
              select
              variant="outlined"
              size="small"
              fullWidth
              value={value}
              required
              error={value === ''}
              onChange={handleChange}
              label="Select Reason">
              <MenuItem value={'Product is not required anymore.'}>
                Product is not required anymore.
              </MenuItem>
              <MenuItem
                value={'Cheaper alternative available for lesser price.'}>
                Cheaper alternative available for lesser price.
              </MenuItem>
              <MenuItem
                value={
                  'Bad review from friends/relatives after ordering the product.'
                }>
                Bad review from friends/relatives after ordering the product.
              </MenuItem>
              <MenuItem value={'other'}>Other reasons</MenuItem>
            </TextField>
          </Grid>

          {isOtherDecline && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="other-reasons-text"
                label="Other Reasons"
                multiline
                rows={3}
                variant="outlined"
                onChange={otherDeclineReasonHandler}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" autoFocus>
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={value === '' || (value === 'other' && otherValue === '')}
          variant="contained"
          color="primary"
          autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderCancelDialog;
