import React, { useState } from 'react';
import {
  Grid,
  List,
  ListItemText,
  Avatar,
  Divider,
  ListItem,
  Box,
  Typography,
  ListItemAvatar,
  Badge
} from '@material-ui/core';

import ImageIcon from '@material-ui/icons/Image';

import { makeStyles } from '@material-ui/core/styles';
import { useOrders } from 'context/order/ordersContext';

const useStyles = makeStyles((theme) => ({
  container: {
    border: '1px solid #e5e5e5',
    backgroundColor: theme.palette.white,
    boxShadow: '2px 2px #e5e5e5',
    borderRadius: '8px',
    padding: '1rem'
  },
  list: {
    borderRadius: '8px'
  },
  listItemRoot: {
    '&.Mui-selected': {
      backgroundColor: theme.palette.blue.main,
      color: theme.palette.white,
      '&:hover': {
        backgroundColor: theme.palette.blue.main,
        color: theme.palette.white
      }
    }
  },
  listTextPrimary: {
    fontWeight: '700'
  },
  listTextSecondary: {
    fontSize: '1rem'
  },
  badge: {
    display: 'flex',
    alignItem: 'flex-end',
    justifyContent: 'center'
  }
}));

const ChatContactBox = (props) => {
  const { orders } = useOrders();
  const { shopName, setOrderActive } = props;
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleListItemClick = (event, index, order) => {
    setSelectedIndex(index);
    setOrderActive(order);
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12} md={12}>
        <Box sx={{ width: '100%' }}>
          <List className={classes.container}>
            {orders.map((order, i) => (
              <ListItem
                button
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, i, order)}
                classes={{ root: classes.listItemRoot }}
                className={classes.list}>
                <ListItemAvatar>
                  <Avatar>
                    <ImageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="h6"
                      className={classes.listTextPrimary}>
                      #{order.orderID} ({order.shop?.name})
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body1">
                      <Badge
                        badgeContent={2}
                        color="error"
                        className={classes.badge}
                      />
                      last msg will be displayed
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChatContactBox;
