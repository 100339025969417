import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import { useShops } from '../../../../context/shop/shopsContext';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TableSortLabel,
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  MenuItem,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import { getInitials } from 'helpers';
import { useHistory } from 'react-router';
import { CustomSearchBar } from 'components/organisms';
import { useAmplifyAuth } from 'context';
import { useLoader } from 'layouts/loaderContext';
import CloseIcon from '@material-ui/icons/Close';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  searchInput: {
    margin: theme.spacing(2),
    marginLeft: 0,
    maxWidth: 500
  },
  successText: {
    color: theme.palette.success.main
  },
  errorText: {
    color: theme.palette.error.main
  },
  width: {
    width: '20%',
    marginRight: 10
  },
  marginBottom: {
    marginBottom: 25
  }
}));

const ShopsTable = (props) => {
  const history = useHistory();
  const { className, setConsentLetterShop = () => {}, ...rest } = props;
  const { shops, isMoreShopsAvailable, dispatch } = useShops();
  const [searchBy, setSearchBy] = useState('');
  const [filterValue, setFilterValue] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [showDeletedShops, setShowDeletedShops] = useState(false);
  const { loading } = useLoader();
  let {
    state: { user, isSales = false, isAdmin = false }
  } = useAmplifyAuth();

  useEffect(() => {
    loadShops(true);
  }, [isSales, showDeletedShops, filterValue]);

  const loadShops = (newQuery = false, clearString) => {
    if (newQuery) {
      setPage(0);
    }
    const payload = { newQuery, limit: 100 };
    if (searchBy) {
      payload.searchString = searchBy;
    }
    if (clearString) {
      payload.searchString = '';
    }
    if (isSales) {
      payload.salesUser = user?.sub || user?.id;
    }
    if (showDeletedShops) {
      payload.status = 'rejected';
    }
    if (filterValue) {
      payload.status = filterValue;
    }
    dispatch({
      type: 'getShops',
      payload
    });
  };

  const handlePageChange = (_event, page) => {
    isMoreShopsAvailable && shops.length <= page * rowsPerPage && loadShops();
    setPage(page);
  };

  const onSearchChange = (event) => {
    setSearchBy(event.target.value);
    if (event.key === 'Enter') onSearchSubmit();
  };

  const onSearchSubmit = () => loadShops(true);

  const onSearchClear = () => {
    setSearchBy('');
    loadShops(true, true);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    const newOrder = isAsc ? 'desc' : 'asc';
    setOrder(newOrder);
    setOrderBy(property);
    dispatch({
      type: 'sortShop',
      payload: { property, direction: newOrder }
    });
  };

  const statusFilterHandler = (event) => {
    setFilterValue(event.target.value);
    setShowDeletedShops(false);
  };

  const handleShowDeletedSwitch = (e) => {
    setShowDeletedShops(e.target.checked);
    setFilterValue('');
  };

  const handleDownloadConsentLetterClick = (id) => (e) => {
    e.stopPropagation();
    dispatch({
      type: 'updateShops',
      payload: {
        shops: shops.filter((shop) => shop.id === id)
      }
    });
    setConsentLetterShop(id);
  };

  return (
    <>
      <Grid container justify="space-between" className={classes.marginBottom}>
        <Grid container item xs={12} sm={8}>
          <Grid item xs={12} sm={4} style={{ marginRight: '0.5rem' }}>
            <CustomSearchBar
              value={searchBy}
              onChange={onSearchChange}
              placeholder="id / name / postcode"
              onClear={onSearchClear}
              onSearch={onSearchSubmit}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Status"
              margin="none"
              size="small"
              name={'status'}
              select
              onChange={statusFilterHandler}
              value={filterValue}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {filterValue ? (
                      <IconButton
                        size="small"
                        edge="end"
                        style={{ marginRight: '0.5rem' }}
                        onClick={() => setFilterValue('')}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </InputAdornment>
                )
              }}>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="deactivate">Deactivate</MenuItem>
              <MenuItem value="suspended">Suspended</MenuItem>
              <MenuItem value="onhold">On Hold</MenuItem>
            </TextField>
          </Grid>
        </Grid>

        {isAdmin ? (
          <Grid item xs={12} sm={3} container justify="flex-end">
            <FormControlLabel
              control={
                <Switch
                  checked={showDeletedShops}
                  onChange={handleShowDeletedSwitch}
                  name="shop-delete-show"
                  color="primary"
                />
              }
              label="Show Deleted"
              labelPlacement="start"
            />
          </Grid>
        ) : null}
      </Grid>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'shopID'}
                        direction={orderBy === 'shopID' ? order : 'asc'}
                        onClick={(e) => {
                          handleRequestSort(e, 'shopID');
                        }}>
                        Shop Id
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === 'name'}
                        direction={orderBy === 'name' ? order : 'asc'}
                        onClick={(e) => {
                          handleRequestSort(e, 'name');
                        }}>
                        Shop Name
                      </TableSortLabel>
                    </TableCell>
                    {filterValue === 'active' ? (
                      <TableCell>Live Status</TableCell>
                    ) : null}
                    <TableCell>Contact</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Location</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell align="center">Consent Letter</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!loading &&
                    shops
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((Shop) => (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={Shop.id}
                          onClick={() => {
                            history.push('/admin/shops/edit/' + Shop.id);
                          }}>
                          <TableCell>{Shop.shopID}</TableCell>
                          <TableCell>
                            <div className={classes.nameContainer}>
                              <Avatar className={classes.avatar}>
                                {getInitials(Shop.name)}
                              </Avatar>
                              <Typography variant="body1">
                                {Shop.name}
                              </Typography>
                            </div>
                          </TableCell>
                          {filterValue === 'active' ? (
                            <TableCell
                              className={
                                Shop?.isLive
                                  ? classes.successText
                                  : classes.errorText
                              }>
                              {Shop?.isLive ? 'Online' : 'Offline'}
                            </TableCell>
                          ) : null}
                          <TableCell>{Shop.contactName}</TableCell>
                          <TableCell>{Shop.email}</TableCell>
                          <TableCell>
                            {Shop.address}, {Shop.town}, {Shop.postcode}
                          </TableCell>
                          <TableCell>{Shop.phoneNumber}</TableCell>
                          <TableCell align="center">
                            <IconButton
                              size="small"
                              onClick={handleDownloadConsentLetterClick(
                                Shop.id
                              )}
                              title="Download consent letter">
                              <GetAppRoundedIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={isMoreShopsAvailable ? -1 : shops.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </CardActions>
      </Card>
    </>
  );
};

ShopsTable.propTypes = {
  className: PropTypes.string
};

export default ShopsTable;
