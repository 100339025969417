import React, { useState } from 'react';
import MessageDialog from '../../../Messages/MessageDialog';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Grid,
  Avatar
} from '@material-ui/core';
import { getFormattedName } from 'common/utilFunctions';

const useStyles = makeStyles((theme) => ({
  inner: {
    minWidth: 1050
  },
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const ItemizedOrderTable = ({ order }) => {
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const classes = useStyles();

  return (
    <>
      <PerfectScrollbar>
        <div className={classes.inner}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>NAME & CONTACT</TableCell>
                <TableCell align="center">ORDER NO.</TableCell>
                <TableCell align="center">DATE OF ORDER</TableCell>
                <TableCell align="center">EST. DELIVERY</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.tableRow} hover key={order.id}>
                <TableCell>
                  <Grid container item direction="row" alignItems="center">
                    <Avatar className={classes.avatar}></Avatar>
                    <Typography>
                      {getFormattedName(order?.userDetail)} <br />
                      {order?.userDetail?.phoneNumber || ''}
                    </Typography>
                  </Grid>
                </TableCell>
                <TableCell align="center">{order.orderID}</TableCell>
                <TableCell align="center">
                  {moment(order.createdAt).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell align="center">
                  {order?.delivery?.date
                    ? moment(order.delivery.date).format('DD/MM/YYYY')
                    : 'N/A'}
                </TableCell>
                <TableCell align="center">
                  <Button
                    onClick={() => setShowMessageDialog(true)}
                    variant="contained">
                    Message
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
      <MessageDialog
        open={showMessageDialog}
        setOpen={setShowMessageDialog}
        order={order}
      />
    </>
  );
};

ItemizedOrderTable.propTypes = {
  className: PropTypes.string
};

export default ItemizedOrderTable;
