import React from 'react';
import { Grid, GridListTile } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  listTileRoot: {
    width: '13.875rem !important',
    height: '100% !important'
  }
}));

const ShopWrapper = ({ children, lg = false, ...rest }) => {
  const classes = useStyles();

  return lg ? (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} {...rest}>
      {children}
    </Grid>
  ) : (
    <GridListTile classes={{ root: classes.listTileRoot }} {...rest}>
      {children}
    </GridListTile>
  );
};
export default ShopWrapper;
