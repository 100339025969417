import React from "react";
import ReactDOM from "react-dom";
import App from './App';
import Amplify, { Auth } from 'aws-amplify';
import aws_exports from './aws-exports';


const isLocalhost = !!(window.location.hostname === 'localhost');

const [
  localRedirectSignIn, productionRedirectSignIn
] = ["http://localhost:3000/","https://www.do-gooder.co.uk/"];
//aws_exports.oauth.redirectSignIn.split(',');

const [localRedirectSignOut,
  productionRedirectSignOut
] = aws_exports.oauth.redirectSignOut.split(',');

const redirectSignIn = isLocalhost ? localRedirectSignIn : productionRedirectSignIn;
const redirectSignOut = isLocalhost ? localRedirectSignOut : productionRedirectSignOut;

Amplify.configure({
  ...aws_exports, API: {
    graphql_endpoint: aws_exports.aws_appsync_graphqlEndpoint,
    graphql_headers: async () => {
      try {
        let token = await Auth.currentSession();
        token = token.idToken.jwtToken;
        return {
          Authorization: token,
        };
      } catch (e) {
        return {
          'x-api-key': aws_exports.aws_appsync_apiKey
        };
      }
    }
  },
  oauth: {
    ...aws_exports.oauth,
    redirectSignIn,
    redirectSignOut,
  }
});



ReactDOM.render(<App />, document.getElementById('root'));
