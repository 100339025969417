import { makeStyles } from '@material-ui/core/styles';

export const useAdTemplateStyles = makeStyles(() => ({
  card: {
    padding: '2px',
    borderRadius: '4px',
    height: '100%',
    width: '100%',
    minHeight: '15rem',
    '&:hover': {
      backgroundColor: '#eee'
    }
  },
  shopListAdCard: {
    maxHeight: '15rem',
    maxWidth: '18rem'
  },
  templateButton: {
    borderRadius: '4px',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none'
    }
  }
}));
