import React, { useEffect, useState } from 'react';
import TinySlider from 'tiny-slider-react';
import { API, graphqlOperation } from 'aws-amplify';
import { getCurrentOpenAds } from 'graphql/customQueries';
import { AdTemplates } from 'components';
import { makeStyles } from '@material-ui/core/styles';
import 'tiny-slider/dist/tiny-slider.css';
import clsx from 'clsx';

const tinySliderSettings = {
  lazyload: true,
  mouseDrag: true,
  autoplayButtonOutput: false,
  autoplay: true,
  speed: 500,
  autoplayTimeout: 5000,
  controls: false,
  center: true,
  nav: false,
  autoHeight: true,
  rewind: true
};

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    margin: '0 auto',
    padding: 0,
    [theme.breakpoints.up('md')]: {
      maxWidth: '90%'
    }
  },
  adSlide: {
    position: 'relative',
    textAlign: 'center',
    opacity: 1
  },
  homeBanner: {
    borderRadius: 12
  }
}));

const Banner = () => {
  const classes = useStyles();
  const [ads, setAds] = useState([]);

  useEffect(() => {
    let mounted = true;
    API.graphql(graphqlOperation(getCurrentOpenAds, { page: 'home' }))
      .then(
        (data) =>
          mounted &&
          setAds(data.data.getCurrentOpenAds?.filter((item) => !item._deleted))
      )
      .catch((e) => console.error(e));

    return () => {
      mounted = false;
    };
  }, []);

  const priorityShopAds = ads
    .filter((item) => item?.type === 'shop')
    .sort((a, b) => (a?.priority > b?.priority ? 1 : -1));
  const defaultAds = ads.filter((item) => item?.type === 'default');

  const filteredAds = [...priorityShopAds, ...defaultAds];

  return (
    <div className={classes.root}>
      <TinySlider settings={tinySliderSettings}>
        {filteredAds.map((ad, index) => (
          <div
            key={`home-ads-items-${index}`}
            className={clsx(classes.adSlide, 'tns-lazy-img')}>
            <AdTemplates
              ad={ad}
              showAsButton={true}
              className={classes.homeBanner}
            />
          </div>
        ))}
      </TinySlider>
    </div>
  );
};

export default Banner;
